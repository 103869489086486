import { GlobalEvents } from '.'
import { IndexDb } from '@/plugins/eruda/eruda-indexdb'
import { traceLog } from '@/context/log'
import axios from 'axios'
// import localforage from 'localforage'
// const traceStore = localforage.createInstance({
//   name: 'trace-store'
// })

// const clearTraceStore = () => {
//   const now = Date.now()
//   traceStore.keys().then((keys) => {
//     keys.forEach((key) => {
//       const start = Number(key.split('::')[1] || 0)
//       if (now - start >= 1 * 24 * 60 * 60 * 1000) {
//         traceStore.removeItem(key)
//       }
//     })
//   })
// }

let events = [] as string[]
try {
  const pkgs =
    window.LOBBY_SITE_CONFIG?.INJECT_DATA?.apiGetSiteInfo?.data?.data
      ?.vestBagJumpConfig
  events = (pkgs && Array.isArray(pkgs) ? pkgs : [pkgs])
    .map((v) => v.packageName)
    .filter((v) => v?.includes('wg.log.'))
    .map((v) => {
      return v.split('.')[2]
    })
    .filter((v) => v)
} catch (e) {}

export function trace(
  event: string,
  data: Record<string, unknown>,
  foceReport = false // 是否需要强推
): void {
  data.href = data.href || location.href
  data.created = Date.now()
  /**
   *
   */
  if (['init'].includes(event)) {
    traceLog.push({ event, data })
  }
  if (event === 'resource' && (data.time as number) < 3000) {
    return
  }

  // 内置条件只报错误及强制
  const isInner =
    event === 'error' &&
    [
      '024',
      '032',
      '380',
      '539',
      '385',
      '272',
      '296',
      '128',
      '136',
      '169',
      '170',
      '230',
      '135',
      '611',
      '511',
      '819',
      '912',
      '508',
      '587',
      '5082',
      '5381',
      '5376',
      '5123',
      '5127',
      '5138',
      '722'
    ].includes(window.LOBBY_SITE_CONFIG?.siteCode)

  // 生产环境内置上报或者云端配置了上报
  const payload = { event, source: 'lobby', ...data }
  // traceStore.setItem(`${event}::${data.created}`, payload)
  // clearTraceStore()
  if (
    process.env.NODE_ENV === 'production' &&
    (events.includes(event) || foceReport || isInner)
  ) {
    GlobalEvents.dispatch({
      type: 'TRACE_REPORT',
      payload
    })
  }
}

export function upgrade() {
  axios
    .get(`/op.json?${Date.now()}`)
    .then((res) => {
      GlobalEvents.dispatch({
        type: 'VERSION_CHECK',
        payload: {
          version: res.data.version
        }
      })
    })
    .catch(() => {
      //
    })
}

window.onerror = function (message, source, lineno, colno, error) {
  if (['webPush'].find((x) => source?.includes(x)) || lineno === 0) {
    return
  }
  trace('jse', { message: message || error?.message, source, lineno, colno })
  GlobalEvents.dispatch({
    type: 'MONITOR_EVENT',
    eventName: 'Error',
    payload: {
      message: (() => {
        let filename = source
        try {
          const urlObject = new URL(source || '')
          filename = urlObject.pathname
        } catch (error) {}
        return `${message || error?.message} ${filename}:${lineno}:${colno}`
      })()
    }
  })
  // 发版后资源文件不存在
  if (error?.message && error.message.includes("Unexpected token '<'")) {
    upgrade()
  }
}

export async function initErudaDebug() {
  if (window?.eruda) {
    return
  }
  import('eruda').then(({ default: eruda }) => {
    window.eruda = eruda
    eruda.init()
    eruda.add(IndexDb)
  })
}
