import { BuriedConfigRequest, BuriedConfigResponse } from '@/api/common/type'
import { apiGetBuriedConfig } from '@/api/common'
import { loadScripts } from '@/utils/Loader'
import { useMainStore } from '@/store/index'
import Appier from '@/utils/Appier'
import GoogleTagManager from '@/utils/GoogleTagManager'
import KwaiPixelBaseCode from '@/utils/KwaiPixelBaseCode'
import MetaPixel from '@/utils/MetaPixel'
import MonitoringAnalysis from './MonitoringAnalysis'
import TiktokBaesCode from '@/utils/TiktokBaesCode'

enum GoogleType {
  GTAG = 1,
  GTM = 2
}
enum BuriedCategory {
  Google = 1,
  FacebookMatePixel = 2,
  KwaiPixel = 3,
  TikTokPixel = 4
}
/**
 * 埋点配置 使用方法
 * 1.后台根据代理或是渠道返回配置的埋点平台以及平台ID
 * 2.URL 串接模式下有串的就使用该平台埋点
 * 3.最早期写死ID在前台
 * 优先级 1>2>3
 */
enum IdType {
  CHANNEL = 1,
  PARENT_AGENT_ID = 2,
  SELF_ID = 3
}

// 试玩账号类型
const TryAccountType = 1

export default class Buried {
  public static buriedOption: BuriedConfigRequest
  public static buriedConfig: Array<BuriedConfigResponse>
  /** 统一管理埋点事件 */
  public static initAction() {
    /** 我方全站点监测埋点 */
    this.initMonitoringAnalysis()
    /** 初始化埋点 */
    this.initBuried()
    /** 品牌设置 统计代码插入 */
    this.injectScript()
    this.addOnAppEventListener()
  }
  /** 初始化埋点 */
  public static initBuried() {
    const url = new URL(location.href)
    const uid = useMainStore().userInfos?.username
    const account_type =
      useMainStore().userInfos?.account_type || TryAccountType
    console.log('initBuried get uid: ', uid)
    /** 渠道 */
    const cid = url.searchParams.get('cid') || undefined
    /** 代理ID */
    const id = url.searchParams.get('id') || undefined
    const idx = cid || id || uid
    const idxType = cid
      ? IdType.CHANNEL
      : id
      ? IdType.PARENT_AGENT_ID
      : IdType.SELF_ID
    console.log('initBuried get buriedOption: ', this.buriedOption)
    /** 包装各种埋点群体
     * 多种埋点就算没有配置如果地只有串接一样可以载入所以包在一起管理
     */
    const buriedPackage = () => {
      this.initPixelBuried()
      this.initGoogleAnalytics()
      this.initKwaiPixel()
      this.inittiktok()
      this.initFirebase()
      this.initAppier()
      this.initUniAPP()
    }

    /** 如果不是试玩＆有登入＆跟刚刚载入的是同个帐号就不需要在重新载入
     * 因为试玩状态会无法判断 uid 导致错误所以过滤掉
     * 判断 不是试玩 ＆ 有登入 ＆ 登录帐号一样就直接跳出避免重复读取
     */
    if (account_type != TryAccountType && uid && uid == this.buriedOption?.uid)
      return
    /** 如果有代理/渠道/登入 跑接口获取是否有配置好的埋点id */
    if (id || cid || uid) {
      const payload: BuriedConfigRequest = {
        idx: Number(idx),
        idxType: Number(idxType)
      }
      if (uid) payload.uid = uid
      this.buriedOption = payload
      console.log('initBuried set buriedOption', this.buriedOption)
      apiGetBuriedConfig(payload).then((res) => {
        console.log('initBuried res: ', res)
        this.buriedConfig = res as Array<BuriedConfigResponse>
        buriedPackage()
      })
    } else {
      /**如果都没有配置走原有地址串接逻辑即可 */
      buriedPackage()
    }
  }

  /**
   * 190 站点Pixel埋点
   */
  private static initPixelBuried() {
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pixelIds: any = {
      '190': '502096145140928',
      '252': '2659889657642835'
    }
    const configuration = this.buriedConfig?.find(
      (el) => el.category === BuriedCategory.FacebookMatePixel
    )
    /** fb 接入许多业主要分一个代理一个id 写死代码不实际 请业主自己串地址去推广 我们获取参数来决定载入 */
    const pixelId =
      configuration?.gid ||
      new URL(location.href).searchParams.get('fb_dynamic_pixel') ||
      pixelIds[siteCode]
    if (pixelId) {
      if (window.Pixel) {
        window.Pixel.options.pixelId = pixelId
        window.Pixel.init()
      } else {
        window.Pixel = new MetaPixel({ pixelId })
      }
    }
  }
  /**
   * 291 tiktok 埋点
   */
  private static inittiktok() {
    const configuration = this.buriedConfig?.find(
      (el) => el.category === BuriedCategory.TikTokPixel
    )
    const tiktokID =
      configuration?.gid ||
      new URL(location.href).searchParams.get('tiktokBaesCode')
    if (tiktokID) {
      if (window.Tiktok) {
        window.Tiktok.options.tiktokID = tiktokID
        window.Tiktok.init()
      } else {
        window.Tiktok = new TiktokBaesCode({ tiktokID })
      }
    }
  }

  /**
   * 230 快手埋点
   */
  private static initKwaiPixel() {
    const configuration = this.buriedConfig?.find(
      (el) => el.category === BuriedCategory.KwaiPixel
    )
    const kwaiqID =
      configuration?.gid ||
      new URL(location.href).searchParams.get('kwaiPixelBaseCode')
    if (kwaiqID) {
      if (window.KwaiPixe) {
        window.KwaiPixe.options.kwaiqID = kwaiqID
        window.KwaiPixe.init()
      } else {
        window.KwaiPixe = new KwaiPixelBaseCode({ kwaiqID })
      }
    }
  }

  /**
   * Google Analytics埋点
   */
  private static async initGoogleAnalytics() {
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    // <!-- Google Tag -->
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const gtagIds: any = {
      '135': 'G-9X82YGV0Q2',
      '169': 'G-GNDTTGM3NH',
      '186': 'G-EMBLFYWWDV',
      '187': 'G-6CJMZXRHJZ',
      '226': 'G-R74FL1K499',
      '232': 'G-GPGDN0CSWR',
      '255': 'AW-11157983322',
      '105': 'G-6T2LLVRP8B'
    }
    // GTM
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const gtmIds: any = {
      '135': 'GTM-NZSPCGN',
      '190': 'GTM-KFL64BH',
      '218': 'GTM-PJNQGPK7',
      '226': 'GTM-PKXMZVW',
      '352': 'GTM-MQ9V4BG'
    }
    // <!-- Google Tag -->
    const gtagConfiguration = this.buriedConfig?.find(
      (el) =>
        el.category === BuriedCategory.Google && el.type == GoogleType.GTAG
    )
    const gtmConfiguration = this.buriedConfig?.find(
      (el) => el.category === BuriedCategory.Google && el.type == GoogleType.GTM
    )
    const gtagId =
      gtagConfiguration?.gid ||
      new URL(location.href).searchParams.get('gtagId') ||
      gtagIds[siteCode]
    const gtmId =
      gtmConfiguration?.gid ||
      new URL(location.href).searchParams.get('gtmId') ||
      gtmIds[siteCode]
    // GTM
    const parmas = {
      gtagId,
      gtmId
    }
    if (gtagId || gtmId) {
      if (window.GTM) {
        window.GTM.options.gtagId = gtagId
        window.GTM.options.gtmId = gtmId
        window.GTM.init()
      } else {
        window.GTM = new GoogleTagManager(parmas)
      }
    }
  }

  /**
   * 238 Firebase 载入
   */
  private static async initFirebase() {
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const configs: any = {
      '230': {
        apiKey: 'AIzaSyDDHonDGjU0zyFHV89178_1ggDvMYGboAk',
        authDomain: 'ssceshi-c26fb.firebaseapp.com',
        projectId: 'ssceshi-c26fb',
        storageBucket: 'ssceshi-c26fb.appspot.com',
        messagingSenderId: '742449233486',
        appId: '1:742449233486:web:8a1ac1d8c7988ed0bacd1f',
        measurementId: 'G-QZVVMWP821'
      }
    }
    if (configs[siteCode]) {
      const { initializeApp } = await import('firebase/app')
      const { getAnalytics } = await import('firebase/analytics')
      // Initialize Firebase
      const app = initializeApp(configs[siteCode])
      // Initialize Analytics and get a reference to the service
      const analytics = getAnalytics(app)
      console.log('analytics: ', analytics)
    }
  }

  /** 品牌设置 统计代码插入 */
  private static async injectScript() {
    const script = useMainStore()?.siteConfig?.statisticalCode
    if (!script) return
    const unescapeHtml = (str: string): string => {
      const map: { [key: string]: string } = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#039;': "'"
      }
      return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, (m) => map[m])
    }
    const decodedString = unescapeHtml(script)
    const elements = document
      ?.createRange()
      .createContextualFragment(decodedString).children
    const arr = Array.prototype.slice.call(elements)
    const initScript = arr.map((el) => {
      return () =>
        new Promise((resolve) => {
          if (el.innerHTML?.replace(/\s/g, '')) {
            document?.head.appendChild(el)
            resolve('')
          } else {
            el.addEventListener('load', () => {
              resolve('')
            })
            document?.head.appendChild(el)
          }
        })
    })
    for (const iterator of initScript) {
      await iterator()
    }
  }
  /**
   * Appier 埋点
   */
  private static initAppier() {
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const appierIds: any = {
      '135': 'nn.vip'
    }
    const appierId = appierIds[siteCode]
    if (appierId) {
      window.Appier = new Appier({ appierId })
    }
  }

  /**
   * 238 uniapp jsbridge载入
   */
  private static async initUniAPP() {
    /**
     * 按需载入uniapp
     * 日后需要载入uniapp的商户 请他们马甲包新增字段到 userAgent
     * 否则只判断站点就载入 会导致一般web使用windowopen有问题 */
    if (navigator.userAgent.includes('uniappVersion')) {
      await loadScripts([
        {
          id: 'script-uniApp',
          async: true,
          src: `https://unpkg.com/@dcloudio/uni-webview-js@0.0.3/index.js`
        }
      ])
    }
  }
  /** 291 EVENT_CODE_MAP 有插入的话会监听上报message
   * EVENT_CODE_MAP是藉由gtm插入的，为了内容配置
   */
  private static addOnAppEventListener() {
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    if (['291'].includes(siteCode)) {
      window.addEventListener('message', (event) => {
        // 如果该消息不是从预期的源URL发出，就不处理
        if (event.origin !== location.origin) {
          return
        }
        const { eventName, eventType } = event.data
        /**事件名onAppEvent之下的才是埋点推送消息*/
        if (window.EVENT_CODE_MAP && eventType == 'onAppEvent') {
          const htmlCode = window.EVENT_CODE_MAP[eventName] || ''
          if (htmlCode) {
            const container = document.createElement('div')
            container.innerHTML = htmlCode
            document.body.appendChild(container)
            setTimeout(() => {
              container.remove()
            }, 5000)
          }
        }
      })
    }
  }

  private static initMonitoringAnalysis() {
    window.MonitoringAnalysis = new MonitoringAnalysis()
  }
}
