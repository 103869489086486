import { Base, Component, Emit, Prop, Watch } from '@/vue-property-decorator'
import { CommonLoading } from '@/components/business-components'
import {
  DEFAULT_MOBILE_PAGE_SIZE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TOTAL,
  defaultComTableColumnConfig,
  defaultComTableConfig
} from './config'
import { Data, Props, UncertainRecordType } from './type'
import { Debounce } from '@/utils/Decorator'
import { ExtractClassProps } from '@/plugins/ant-design-vue/type'
import { GlobalConst } from '@/context'
import {
  Input,
  List,
  Select,
  Table
} from '@/plugins/ant-design-vue/tsx-support'
import { Pagination, Table as _Table } from 'ant-design-vue'
import { Pagination as PluginsPagination } from '@/plugins/ant-design-vue/tsx-support'
import { merge } from 'lodash'
import { ofType } from '@/vue-tsx-support/src'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import NetRetry, {
  DisplayType
} from '@/components/business-components/net-retry'
import style from './style.module.scss'

/**
 * 通用表格组件
 */
@Component<CommonTable>({ name: 'CommonTable' })
export class CommonTable extends Base<
  Data,
  Props,
  {
    onChange: (
      pagination: Pagination,
      filter: UncertainRecordType,
      sorter: UncertainRecordType
    ) => void
  },
  {
    footer: void
  }
> {
  state: Data = {
    /**
     * 数据源
     */
    source: [],
    /**
     * 移动端的暂存串连数据源
     */
    infinitySource: [],
    /**
     * 分页页码
     */
    pageNo: 1,
    /**
     * 分页大小
     */
    pageSize: 0,
    /**
     * loading
     */
    isLoading: false,
    /**
     * 数据总计
     */
    total: DEFAULT_TOTAL,
    /**
     * 虚拟加载分页码
     */
    virtualPageNo: 1,
    /**
     * 数据源是否是持久化缓存，是的话，下次真实数据回来要完整覆盖掉数据源而不是concat追加
     */
    isCacheSource: false,
    /**
     * 接口错误时的json信息
     */
    axiosError: null
  }

  private scrollLock = false

  /**
   * 列表模式
   */
  @Prop({ default: false })
  isHiddenPagination?: Props['isHiddenPagination']
  /**
   * 列表模式
   */
  @Prop({ default: false })
  listMode?: Props['listMode']
  /**
   * 是否启用虚拟加载
   */
  @Prop({ default: false })
  virtualLoading?: Props['virtualLoading']
  /**
   * 列表模式下可滚动高度
   */
  @Prop({ default: BusinessUtils.px2rem(1100) })
  listScrollHeight?: Props['listScrollHeight']

  /**列表内容回调方法 */
  @Prop({ default: () => ({}) })
  listRenderItem?: Props['listRenderItem']

  /**
   * 列数据 参考antd Table.Column 增强了部分属性，详情./type.ts
   */
  @Prop(AppTypes.array.def([]))
  columns!: Props['columns']

  /**
   * 默认页数
   */
  @Prop({
    required: false,
    default: () =>
      useMainStore().isWeb ? DEFAULT_PAGE_SIZE : DEFAULT_MOBILE_PAGE_SIZE
  })
  defaultPageSize!: Props['defaultPageSize']

  /**
   * 数据查询函数 返回值要适配column 中的dataIndex 字段
   */
  @Prop({ required: true })
  query!: Props['query']

  /**
   * 当数据为空时是否隐藏footer
   */
  @Prop({ default: true })
  hiddenFooterForNullData?: Props['hiddenFooterForNullData']
  /**
   * 是否显示当前翻页组件
   */
  @Prop({ default: true })
  isShowPagination?: Props['isShowPagination']

  /**
   * 查询前置钩子
   */
  @Prop({ required: false })
  preQueryHook?: Props['preQueryHook']

  /**
   * 查询后置狗子
   */
  @Prop({ required: false })
  postQueryHook?: Props['postQueryHook']

  /**
   * 占位图以及表格最小高度
   */
  @Prop({ default: BusinessUtils.px2rem('500px') })
  emptyHeight?: Props['emptyHeight']

  /**
   * 查询条件 如 时间 排序等
   */
  @Prop({ default: () => ({}) })
  condition?: Props['condition']

  /**
   * 列表模式下，是否背景色反转
   */
  @Prop({ default: false })
  reverseBgInListMode?: Props['reverseBgInListMode']

  /**
   * 表格模式下，是否背景色反转
   */
  @Prop({ default: false })
  reverseBgInTableMode?: Props['reverseBgInTableMode']

  /**
   * 向下滑动是否加载数据
   */
  @Prop({ default: true })
  scrollDownLoading?: boolean

  /**
   * 向下滑动是否加载数据
   */
  @Prop({ default: true })
  showGoto?: boolean

  /**
   * 翻页组件定位bottom的值
   */
  @Prop({ default: -100 })
  flipPositioningBottom?: number

  /**是否需要loading状态下的背景蒙版 */
  @Prop({ required: false, default: false })
  needLoadingBackgroundMask?: boolean

  /**是否展示超时页 */
  @Prop({ required: false, default: false })
  isTimeoutError?: boolean

  /**
   * 计算最后一页
   */
  get maxPage() {
    const { total, pageSize } = this.state
    return Math.ceil(total / pageSize)
  }

  /**
   * 虚拟加载-数据源
   */
  private get virtualState() {
    const { pageSize } = this.state
    return {
      /**
       * 数据源
       */
      source: this.state.source.slice(
        (this.state.virtualPageNo - 1) * pageSize,
        this.state.virtualPageNo * pageSize
      ),
      /**
       * 移动端的暂存串连数据源
       */
      infinitySource: this.state.source.slice(
        0,
        this.state.virtualPageNo * pageSize
      ),
      /**
       * 分页页码
       */
      pageNo: this.state.virtualPageNo,
      /**
       * 分页大小
       */
      pageSize: pageSize,
      /**
       * 数据总计
       */
      total: this.state.source.length
    }
  }
  /**
   * 虚拟加载-计算最后一页
   */
  get maxVirtualPage() {
    const { total, pageSize } = this.virtualState
    return Math.ceil(total / pageSize)
  }

  get isWeb() {
    return useMainStore().isWeb
  }

  /**
   * 设置分页器默认页数
   * @param num
   */
  setDefaultPages(num: number) {
    this.$nextTick(() => {
      this.setState({ pageNo: num })
    })
  }

  @Watch('condition', { deep: true })
  @Watch('state.pageSize')
  resetPage() {
    this.setState({
      pageNo: 1,
      infinitySource: []
    })
  }

  @Watch('condition', { deep: true })
  @Watch('state.pageSize')
  @Watch('state.pageNo')
  async onConditionChange() {
    const { pageNo, pageSize, isLoading } = this.state
    if (isLoading) return
    this.setState({
      isLoading: true
    })
    await this.preQueryHook?.call(this)
    const { data, total, isCache, err } = await this.query(
      pageNo,
      pageSize,
      this.condition ?? {}
    ).finally(() => {
      this.setState({ isLoading: false })
    })
    this.setState({
      axiosError: err
    })
    await this.postQueryHook?.call(this)

    //isCache query返回来的数据是否是持久化数据，是的话isCacheSource记录下来。下次真数据来了要清理掉infinitySource
    if (isCache) {
      this.setState({ isCacheSource: true })
    }
    if (!isCache && this.state.isCacheSource) {
      this.setState({
        isCacheSource: false,
        infinitySource: []
      })
    }

    this.setState({
      isLoading: false,
      source: data ?? [],
      infinitySource: this.state.infinitySource.concat(data) ?? [],
      total: total ?? DEFAULT_TOTAL
    })
    if (this.virtualLoading) {
      const scrollBody = this.listMode
        ? document.querySelector('.ant-list')
        : document.querySelector('.ant-table-body')
      scrollBody?.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.state.virtualPageNo = 1
    }
  }

  @Watch('defaultPageSize')
  initPageSize() {
    this.setState({
      pageSize: this.defaultPageSize ?? DEFAULT_PAGE_SIZE
    })
  }

  /**
   * 父级调用修改状态
   */
  public changeState(param: Partial<Data>) {
    this.setState(param)
  }

  /**
   * web 渲染列
   * @returns
   */
  private renderColumn() {
    if (!this.columns || this.columns?.length === 0) {
      return <div></div>
    }

    return this.columns.map((column) => {
      return (
        <Table.Column.Tsx
          {...{ props: merge({}, defaultComTableColumnConfig, column) }}
          scopedSlots={{
            default(
              text: string,
              record: Record<string, unknown>,
              index: number
            ) {
              return column.customRender
                ? column.customRender(text, record, index)
                : text
            }
          }}
        ></Table.Column.Tsx>
      )
    })
  }

  /**
   * 表格配置
   */
  private get tableConfig() {
    return Object.assign(
      {},
      defaultComTableConfig,
      { pagination: false },
      this.$attrs
    )
  }

  /**
   * 自定义分页器变更页数
   * @param num
   * @returns
   */
  @Debounce()
  private changePage(num: number) {
    if (this.virtualLoading) {
      const { virtualPageNo } = this.state
      if (virtualPageNo + num < 1 || virtualPageNo + num > this.maxVirtualPage)
        return
      this.state.virtualPageNo += num
    } else {
      const { pageNo } = this.state
      if (pageNo + num < 1 || pageNo + num > this.maxPage) return
      this.setState({ pageNo: pageNo + num })
    }
  }

  private beforeScroll = 0
  /**
   * 原生绑定事件
   */
  @Watch('isWeb', { immediate: true })
  private bindMobileEventHandler() {
    this.$nextTick(() => {
      const scrollBody = this.listMode
        ? document.querySelector('.ant-list')
        : document.querySelector('.ant-table-body')
      this.scrollLock = false
      const handler = (event: Event) => {
        if (this.isKeptAlive) {
          return
        }
        const target = event.target as HTMLDivElement
        const maxScroll = target.scrollHeight - target.clientHeight
        const nowScroll = target.scrollTop
        if (
          !this.scrollLock &&
          this.beforeScroll < nowScroll /**只有向下滚动才加载 */ &&
          nowScroll >= maxScroll * 0.95 &&
          this.scrollDownLoading
        ) {
          this.changePage(1)
          this.scrollLock = true
          setTimeout(() => {
            this.scrollLock = false
          }, 200)
        }
        this.beforeScroll = nowScroll
      }
      scrollBody?.removeEventListener('scroll', handler)
      scrollBody?.addEventListener('scroll', handler)
    })
  }

  @Emit('change')
  private handleChange(
    pagination: Pagination,
    filter: UncertainRecordType,
    sorter: UncertainRecordType
  ) {
    return {
      pagination,
      filter,
      sorter
    }
  }

  /**
   * 是否展示footer
   */
  private get hiddenFooter() {
    return (
      (this.state.source.length === 0 && this.hiddenFooterForNullData) ||
      !this.$scopedSlots?.footer
    )
  }

  /**
   * 当数据不足,且仍然需要展示footer的情况,使用padding给footer预留空隙
   * 当分页器被隐藏,且footer存在
   */
  private get showFooterPadding() {
    return (
      (this.state.source.length < this.defaultPageSize! &&
        this.$scopedSlots?.footer) ||
      (!this.showPagination && this.$scopedSlots?.footer)
    )
  }

  /**
   * 是否展示分页器
   */
  private get showPagination() {
    return (
      this.isShowPagination &&
      !this.isHiddenPagination &&
      this.isWeb &&
      !this.listMode &&
      this.state.total > 0
      // (this.maxPage > 1 || (this.virtualLoading && this.maxVirtualPage > 1))
    )
  }

  /**
   * 数据源
   */
  get dataSource() {
    if (this.listMode) return this.state.infinitySource
    return this.isWeb ? this.state.source : this.state.infinitySource
  }

  /**
   * 虚拟滚动数据源
   */
  get virtualDataSource() {
    return this.isWeb
      ? this.virtualState.source
      : this.virtualState.infinitySource
  }

  private renderList(dataSource: Array<unknown>) {
    const page =
      this.state.axiosError && !this.state.isLoading ? (
        <NetRetry
          displayType={DisplayType.ListData}
          axiosError={this.state.axiosError}
          onRetry={this.onConditionChange}
          height={this.listScrollHeight}
          loading={this.state.isLoading}
        />
      ) : (
        <List
          class={{
            [style.reverseBgInListMode]: !!this.reverseBgInListMode
          }}
          loading={{
            spinning: this.state.isLoading,
            indicator: <template />
          }}
          style={{
            '--empty-height': this.emptyHeight,
            height: this.listScrollHeight,
            overflow: 'scroll'
          }}
          itemLayout="horizontal"
          dataSource={dataSource}
          renderItem={(item: unknown) =>
            this.listRenderItem && this.listRenderItem(item)
          }
        />
      )
    return page
  }

  created() {
    // 通过init page size 触发watch 初始化数据
    this.initPageSize()
  }

  mounted() {
    this.bindMobileEventHandler()
  }

  render() {
    const dataSource = this.dataSource
    const virtualDataSource = this.virtualDataSource
    const { pageNo, pageSize, total } = this.state
    const { t } = useI18n()
    return (
      <CommonLoading
        spinning={this.state.isLoading}
        needLoadingBackgroundMask={this.needLoadingBackgroundMask}
      >
        <section
          class={[
            style.comTable,
            this.reverseBgInTableMode ? style.reverse : ''
          ]}
          style={{
            'min-height': this.emptyHeight,
            '--empty-height': this.emptyHeight
          }}
          // onTouchmove={(e) => e.preventDefault()}
        >
          {this.listMode ? (
            this.renderList(
              this.virtualLoading ? virtualDataSource : dataSource
            )
          ) : (
            <Table.Tsx
              dataSource={this.virtualLoading ? virtualDataSource : dataSource}
              onChange={this.handleChange}
              {...{
                props: this.tableConfig,
                on: this.$listeners,
                directives: [
                  {
                    name: 'perfect-scrollbar',
                    value: {
                      el: (el: HTMLElement) =>
                        el.querySelector('.ant-table-body')
                    }
                  }
                ]
              }}
            >
              {this.renderColumn()}
            </Table.Tsx>
          )}

          {!this.hiddenFooter && (
            <div
              class={[
                this.isWeb ? style.footer : style.mobileFooter,
                GlobalConst.CssClassName.Lobby.FixedNoDesktop,
                'mobileFooter'
              ]}
            >
              {this.$scopedSlots?.footer?.call(this)}
            </div>
          )}
        </section>
        {this.showPagination && (
          <div
            class={style.pagination}
            style={{
              bottom: BusinessUtils.px2rem(
                `${this.flipPositioningBottom}px`
              ) as string
            }}
          >
            <PluginsPagination.Tsx
              class={style.pluginsPagination}
              current={pageNo}
              pageSize={pageSize}
              total={total}
              onChange={(page: number, pageSize: number) => {
                console.log('Pagination onChange: ', { page, pageSize })
                this.setState({
                  pageNo: page
                })
              }}
            />
            <span>{t('lobby.common.pagination.total', [total])}</span>
            <Select.Tsx
              value={pageSize}
              style={{ width: BusinessUtils.px2rem(`160px`) }}
              key={'pageSize'}
              onChange={(value: number) =>
                this.setState({
                  pageSize: value
                })
              }
            >
              {[10, 20, 30, 40, 50].map((item) => (
                <Select.Option.Tsx value={item} key={String(item)}>
                  {t('lobby.common.pagination.select', [item])}
                </Select.Option.Tsx>
              ))}
            </Select.Tsx>
            {this.showGoto ? (
              <span>
                {t('lobby.common.pagination.goto')}
                <Input.Tsx
                  value={pageNo}
                  onKeyup={(event: KeyboardEvent) => {
                    if (event.key == 'Enter') {
                      const target = event.target as HTMLInputElement
                      let _temp = Number(target.value.replace(/\D/g, ''))
                      const max = Math.ceil(total / pageSize)
                      _temp = _temp < 1 ? 1 : _temp
                      _temp = _temp > max ? max : _temp
                      this.setState({
                        pageNo: _temp
                      })
                    }
                  }}
                />
                {t('lobby.common.pagination.page')}
              </span>
            ) : (
              ''
            )}
          </div>
        )}
      </CommonLoading>
    )
  }
}

export default ofType<
  ExtractClassProps<Omit<_Table, 'dataSource' | 'columns'>>
>().convert(CommonTable)
