export interface ElementObj extends HTMLElement {
  [key: string]: unknown
  resizeListners: (() => void)[]
  observer: MutationObserver
}

export interface BarMapItem {
  key: BarDirection
  size: string
  axis: 'Y' | 'X'
  client: 'clientY' | 'clientX'
  scrollSize: 'scrollHeight' | 'scrollWidth'
  scroll: 'scrollTop' | 'scrollLeft'
  offset: 'offsetHeight' | 'offsetWidth'
  direction: 'top' | 'left'
  wide: 'height' | 'width'
}

export type BarMap = Record<BarDirection, BarMapItem>

export type BarDirection = 'vertical' | 'horizontal'

export type BarTrigger = 'hover' | 'always' | 'none'

export const BAR_MAP: BarMap = {
  vertical: {
    offset: 'offsetHeight',
    key: 'vertical',
    size: 'height',
    wide: 'width',
    axis: 'Y',
    client: 'clientY',
    scroll: 'scrollTop',
    scrollSize: 'scrollHeight',
    direction: 'top'
  },
  horizontal: {
    offset: 'offsetWidth',
    key: 'horizontal',
    size: 'width',
    wide: 'height',
    axis: 'X',
    client: 'clientX',
    scroll: 'scrollLeft',
    scrollSize: 'scrollWidth',
    direction: 'left'
  }
}
