import {
  ChopOneKnifeAnnouncementData,
  ChopOneKnifeMyLogsData,
  ChopOneKnifeMySubInfo,
  ChopOneKnifeTurnData,
  PopChopOneResponse,
  PromoteShareResponse,
  SubTaskReceiveResponse
} from '@/api/event/cutOnce/type'
import { GlobalService } from '@/context'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'

/**
 * 活动-抽奖助力 中奖公告
 * @param activeId {活动ID}
 */
export const apiChopOneKnifeAnnouncement = (payload: {
  activeId: number
}): Promise<ChopOneKnifeAnnouncementData> =>
  GlobalService.post<ChopOneKnifeAnnouncementData>({
    url: '/active/chop_one_knife_announcement',
    data: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ChopOneKnifeAnnouncementData)

/**
 * 活动-抽奖助力 抽奖
 * @param activeId {活动ID}
 * @param CurrentLotteryCount {当前第几次抽奖 0：表示第一次抽奖，1:表示第二次抽奖}
 */
export const apiChopOneKnifeTurn = (payload: {
  activeId: number
  CurrentLotteryCount: number
}): Promise<ChopOneKnifeTurnData> =>
  GlobalService.post<ChopOneKnifeTurnData>({
    url: '/active/chop_one_knife_turn',
    data: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ChopOneKnifeTurnData)

/**
 * 活动-抽奖助力 我的记录
 * @param chop_one_knife_id
 */
export const apiChopOneKnifeMyLogs = (payload: {
  chop_one_knife_id: number
}): Promise<ChopOneKnifeMyLogsData> =>
  GlobalService.post<ChopOneKnifeMyLogsData>({
    url: '/active/chop_one_knife_my_logs',
    data: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ChopOneKnifeMyLogsData)

/**
 * 活动-抽奖助力 二级任务详情
 * @param chop_one_knife_id
 */
export const apiChopOneKnifeMySubInfo = (payload: {
  chop_one_knife_id: number
}): Promise<ChopOneKnifeMySubInfo> =>
  GlobalService.post<ChopOneKnifeMySubInfo>({
    url: '/active/chop_one_knife_my_sub_info',
    data: {
      ...payload
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ChopOneKnifeMySubInfo)

/**
 * 抽奖助力-快捷分享（获取宣传图配置）
 */
export const apiPromoteShare = () =>
  GlobalService.post<PromoteShareResponse>({
    url: '/promote/share',
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as PromoteShareResponse)

/**
 * 抽奖助力-二级任务完成领取
 */
export const apiChopOneKnifeSubTaskReceive = (payload: {
  chop_one_knife_id: number
}): Promise<SubTaskReceiveResponse> =>
  GlobalService.post<SubTaskReceiveResponse>({
    url: '/active/chop_one_knife_sub_task_receive',
    data: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as SubTaskReceiveResponse)

/**
 * 抽奖助力-所有活动（静态化弹窗）
 */
export const apiPopChopOneKnife = (): Promise<PopChopOneResponse[]> =>
  GlobalService.get<PopChopOneResponse[]>({
    url: '/active/pop_chop_one_knife',
    customParams: {
      staticKeyOmit: ['platformType'],
      tryStaticMode: true,
      keyOmit: true,
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as PopChopOneResponse[])
