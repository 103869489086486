import { IThirdPartyLoginStrategy } from './bases/LoginStrategy'
import { SingletonStrategy } from './bases/SingletonStrategy'
import { loadScripts } from '@/utils/Loader'
import { useMainStore } from '@/store/index'
import Modal from '@/controller/Modal'

interface FBCallBackInfo {
  first_name: string
  id: string
  name: string
  email?: string
  error: {
    code: number
    fbtrace_id: string
    message: string
    type: 'OAuthException'
  }
}
export default class FaceBookLoginStrategy
  extends SingletonStrategy
  implements IThirdPartyLoginStrategy
{
  public static readonly className = 'FaceBookLoginStrategy'

  private get hasSDK(): boolean {
    return typeof window?.FB?.init === 'function'
  }

  public async initSDK() {
    if (!this.hasSDK) {
      await loadScripts({
        id: 'facebook-sdk',
        src: 'https://connect.facebook.net/en_US/sdk.js'
      })
      await new Promise((reslove) => {
        const { systemInfos } = useMainStore()
        window.fbAsyncInit = () => {
          FB?.init({
            appId: systemInfos?.facebook.app_id || '0123456789',
            cookie: true, // Enable cookies to allow the server to access the session.
            xfbml: true, // Parse social plugins on this webpage.
            version: 'v12.0' // Use this Graph API version for this call.
          })
          reslove(void 0)
        }
      })
    }
  }

  public async prepare(): Promise<void> {
    await this.initSDK()
  }

  public async logIn() {
    return new Promise((resolve) => {
      FB?.login(
        function (response) {
          if (response.status === 'connected') {
            const accessToken = response.authResponse.accessToken //取得 accessToken
            FB?.api(
              '/me?fields=name,first_name,last_name,email',
              function (data: FBCallBackInfo) {
                // /me为API指定的调用方法，用于获取登陆用户相关信息
                if (!data.error) {
                  resolve({
                    access_token: accessToken,
                    email: data.email,
                    id: data?.id || ''
                  })
                } else {
                  if (data.error?.type === 'OAuthException') {
                    Modal.message({
                      type: 'warning',
                      content: data.error?.message
                    })
                    FB.logout()
                  }
                }
              }
            )
          }
        },
        { scope: 'email' }
      ) //需要获取的信息scope
    })
  }
}
