import {
  Base,
  Component,
  Emit,
  Prop,
  Ref,
  Watch
} from '@/vue-property-decorator'
import { Bind, Throttle } from 'lodash-decorators'
import { CommonTabsH5 } from '../business-components'
import { RenderItem, TabTypes } from '../business-components/tabs-h5'
import { RouterViewWithTransitionMode } from '@/store/index/type'
import BaseTransition from '@/layouts/components/baseTransition'
import GuidingArrow from '@/views/game/business-components/baseSubGamePage/guidingArrow'
import VueI18n from 'vue-i18n'
import style from './style.module.scss'

interface State {
  isLeft: boolean
  isRight: boolean
  activeTab: number | string | undefined
  showContent: boolean
}

export interface TabsItemData {
  label: VueI18n.TranslateResult | string | number
  value: string | number
}

interface Props {
  /** 选中值 */
  value?: string | number
  /** tablist */
  tabList: TabsItemData[]
}
/** 插槽 */
type ScopedSlots = {
  'tab-bottom'?: void
  'tab-top'?: void
  item: TabsItemData
}

type Events = {
  /** 点击切换 */
  onInput?: (value: TabsItemData['value']) => void
}

@Component<ScrollTabBar>({
  name: 'ScrollTabBar'
})
export default class ScrollTabBar extends Base<
  State,
  Props,
  Events,
  ScopedSlots
> {
  state: State = {
    isLeft: false,
    isRight: false,
    activeTab: undefined,
    showContent: false
  }
  @Prop()
  public value!: Props['value']
  @Prop()
  public tabList!: Props['tabList']
  @Ref()
  private readonly scrollRef!: CommonTabsH5

  // 监听滚动,控制左右箭头显示隐藏
  @Bind()
  @Throttle(100)
  private scrollChange() {
    if (this.isKeptAlive) {
      return
    }
    if (this.scrollRef?.getTabsNavDiv) {
      const { scrollWidth, clientWidth, scrollLeft } =
        this.scrollRef?.getTabsNavDiv
      const scrollRight = scrollWidth - clientWidth - scrollLeft
      this.setState({
        isLeft: scrollLeft <= 20,
        isRight: scrollRight <= 20
      })
    }
  }

  @Watch('value', { immediate: true })
  private initTab(newVal: string | number | undefined) {
    if (newVal !== this.state.activeTab) {
      this.setState({
        activeTab: this.value
      })
    }
  }

  mounted() {
    this.setState({
      activeTab: this.value,
      showContent: true
    })
    // 初始化的时候左右的箭头状态不正确，但是scroll事件还没有派发，所以需要手动调用
    setTimeout(() => {
      this.scrollChange()
    }, 0)
    // base-transition动画为0.3s，这期间不能执行自动居中，否则base-transition动画会异常
    setTimeout(() => {
      const dom =
        this.scrollRef?.getTabsNavDiv?.querySelector(`.van-tab--active`)
      dom?.scrollIntoView?.({
        inline: 'center',
        block: 'nearest',
        behavior: 'smooth'
      })
    }, 500)
  }

  @Watch('state.activeTab')
  private tabChange() {
    this.onInput(this.state.activeTab as TabsItemData['value'])
  }

  @Emit('input')
  private onInput(value: TabsItemData['value']) {
    return value
  }

  toLeft() {
    this.scrollRef?.getTabsNavDiv?.scrollTo({
      left: 0,
      behavior: 'smooth'
    })
  }

  toRight() {
    this.scrollRef.getTabsNavDiv.scrollTo({
      left: this.scrollRef?.getTabsNavDiv?.scrollWidth,
      behavior: 'smooth'
    })
  }

  private transitionMode: RouterViewWithTransitionMode = 'Push'

  render() {
    const myScopedSlots = this.$scopedSlots
    return (
      <div class={[style.bar, 'scroll-tab-bar']} key="scroll-tab-bar">
        {this.$scopedSlots?.['tab-top']?.()}
        <BaseTransition transitionMode={() => this.transitionMode}>
          <div class={style.content} v-show={this.state.showContent}>
            <div
              v-show={!this.state.isLeft}
              class={[style.left, 'left']}
              onClick={this.toLeft}
            >
              <GuidingArrow />
            </div>
            <CommonTabsH5
              ref={'scrollRef'}
              soleId={'scrollTabbar'}
              class={style.commontabList}
              needAutoCenter
              itemHeight={90}
              tabType={TabTypes.LARGE_TEXT}
              tabsLeftMargin={30}
              persistedActiveName={''}
              v-model={this.state.activeTab}
              tabsNavHiddenForOne={true}
              listenScroll
              onScroll={this.scrollChange}
              renderList={
                this.tabList.map((tab) => {
                  return {
                    value: tab.value,
                    label: () => myScopedSlots?.['item']?.(tab) || tab.label
                  }
                }) as RenderItem[]
              }
              itemWidth={'auto'}
              isScrollX={true}
            />
            <div
              v-show={!this.state.isRight}
              class={[style.right, 'right']}
              onClick={this.toRight}
            >
              <GuidingArrow />
            </div>
          </div>
        </BaseTransition>
        {this.$scopedSlots?.['tab-bottom']?.()}
      </div>
    )
  }
}
