import { GlobalConst, GlobalManager } from '.'
import { IErrorResponse } from '@/api/selfoperated-games/sports/type'
import { LobbyService, ResponseInstance, createErrorMessage } from './network'
import { Net } from '@/core/network/net'
// import { RouteName } from '@/router/config'
import { SportStatusCode } from '@/views/selfoperated-games/sports/const'
import {
  apiFreshToken
  // apiGetSportsToken
} from '@/api/selfoperated-games/sports'
import { cloneDeep, merge, pick } from 'lodash'
import { useMainStore } from '@/store/index'
import { useSportsStore } from '@/views/selfoperated-games/sports/store'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import jsonBigint from 'json-bigint'
// import router from '@/router'
const jsonBigintInstance = jsonBigint({ storeAsString: true })
export type Response<T = unknown> = {
  time?: number
  msg: string
  message: string
  code: number
  errorCode?: number
  data?: T
}
/** 超时时间 */
const TIMEOUT = 1 * 60 * 1000
/** token 10分钟过期，9分钟重新拿 */
const TOKEN_EXPIRED = 9 * 60 * 1000

/** 体育服务底层处理：
 * 1、token，sabaUrl
 * 2、请求前处理、错误处理
 * 3、X-Forwarded-For 生产环境去掉，ip限制地区不可用
 */
export class WGSportService extends Net {
  public static token = ''
  private sabaUrl = ''

  /** 接口拿三方 token，9分钟定时刷新token */
  public setTokenApiDomin(token: string) {
    WGSportService.token = token
    setTimeout(() => {
      apiFreshToken()
    }, TOKEN_EXPIRED)
  }

  /**设置三方http协议中得baseUrl */
  public setBaseUrlApiDomian(sabaUrl = '') {
    const { setSabaUrl } = useSportsStore()
    this.sabaUrl = sabaUrl
    setSabaUrl(sabaUrl)
  }

  /**设置三方得token和baseUrl */
  public setBaseUrlAndTokenApiDomian(token: string, sabaUrl = '') {
    this.setBaseUrlApiDomian(sabaUrl)
    this.setTokenApiDomin(token)
  }

  /** 装载 */
  protected setup(config: AxiosRequestConfig): void {
    const { method, url } = config
    this.setCommonParams(config)
    // if (!WGSportService.token || !this.sabaUrl) {
    //   return this.goBackHome()
    // }
    merge(config, this.getConfigHeader())
    LobbyService.log(
      `[C2S HTTP Sport]:${method} >>>>>> ${url}\n${JSON.stringify(
        pick(config, ['params', 'data', 'customParams'])
      )}`,
      config
    )
  }

  protected unpack(res: AxiosResponse<Response>) {
    const config = res.config
    LobbyService.log(
      `[S2C HTTP Sports]:${config.method} <<<<<< ${
        config.url
      }\n${JSON.stringify(res)}`
    )
    return res
  }

  // private goBackHome() {
  //   if (router.currentRoute.name !== RouteName.GAME) {
  //     router.push({
  //       name: RouteName.GAME
  //     })
  //   } else {
  //     apiGetSportsToken()
  //   }
  // }

  protected catch(res: AxiosResponse<Response> | AxiosError) {
    const config = res.config
    const errorType = this.checkErrorType(res)
    if (errorType) {
      switch (errorType) {
        case 'axiosError':
          const message = (res as AxiosError).message
          const response = (res as AxiosError).response?.data as IErrorResponse
          if (message && !response) {
            createErrorMessage(message)
          } else if (response) {
            if (
              response.statusCode === SportStatusCode.RequestFalied &&
              !config.customParams?.silentOnError
            ) {
              GlobalManager.Modal.message({
                type: 'error',
                content: response.message
              })
            }
            LobbyService.log(
              `[S2C HTTP Sports]:${res.config.method} <<<<<< ${
                res.config.url
              }\n${JSON.stringify(res)}`
            )
            return Promise.reject(response)
          }
          break
        case 'dataError':
          createErrorMessage((res as AxiosError).message)
          break
      }
    }
  }

  /** 获取错误类型 */
  protected checkErrorType(res: AxiosResponse<Response> | AxiosError) {
    const isAxiosError = res instanceof AxiosError
    if (isAxiosError) {
      return 'axiosError'
    }

    if (res.data.errorCode) {
      return 'dataError'
    }
    return false
  }
  /** 添加语言公共参数 */
  private setCommonParams(config: AxiosRequestConfig) {
    const { language } = useMainStore()
    const serverLan =
      GlobalConst.SportsServiceLanguageMap[language] ??
      GlobalConst.SportsServiceLanguageMap['en_US']
    if (config.method === 'post') {
      config.data.language = serverLan
      // 三方特殊操作，post 请求参数放url
      if (config.params) {
        config.params.language = serverLan
      }
    } else if (config.method === 'get') {
      config.params.language = serverLan
    }
  }

  /** 三方接口所需 header */
  public getConfigHeader(): AxiosRequestConfig {
    const { token } = WGSportService
    return {
      baseURL: this.sabaUrl,
      responseType: 'json',
      headers: {
        Authorization: token
      }
    }
  }

  public async post<AxiosResponseData = Response>(
    config: Omit<AxiosRequestConfig, 'method'>
  ): ResponseInstance<AxiosResponseData> {
    return this.request(merge(config, { method: 'post' }))
  }

  public async get<AxiosResponseData = Response>(
    config: Omit<AxiosRequestConfig, 'method'>
  ): ResponseInstance<AxiosResponseData> {
    return this.request(merge(config, { method: 'get' }))
  }
  /** 请求发送前的处理 */
  protected beforeSend(): void {
    return
  }
  protected encode(): void {
    return
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected decode(res: AxiosResponse): void {
    return
  }
}

function transformResponseEnsureBigint(data: string): Response {
  // Replacing the default transformResponse in axios because this uses JSON.parse and causes problems
  // with precision of big numbers.
  // https://github.com/axios/axios/blob/6642ca9aa1efae47b1a9d3ce3adc98416318661c/lib/defaults.js#L57
  if (typeof data === 'string') {
    try {
      //jsonBigintInstance.parse会删除对象原型，需要重新clone时行恢复
      data = cloneDeep(jsonBigintInstance.parse(data))
    } catch (e) {
      /* Ignore */
    }
  }
  return data as unknown as Response
}

const service = new WGSportService(
  axios.create({
    timeout: TIMEOUT,
    headers: {
      // 'X-Forwarded-For':
      //   process.env.NODE_ENV === 'development' && '18.230.100.142', // TODO: 相当于加白，生产环境去掉，ip限制地区不可用
      'X-Forwarded-For': '18.230.100.142', // TODO: 跟进返回code 界面做相应处理
      'Content-type': 'application/json'
    },
    transformResponse: [transformResponseEnsureBigint]
  })
)

export default service
