import { ThirdWayRequesterType } from '@/api/common/type'

export interface LoginInfos {
  email?: string
  access_token: string
  id?: string
}

export enum ThirdWayType {
  FACEBOOK = ThirdWayRequesterType.FACEBOOK,
  GOOGLE = ThirdWayRequesterType.GOOGLE,
  WECHAT = ThirdWayRequesterType.WECHAT,
  QQ = ThirdWayRequesterType.QQ,
  LINE = ThirdWayRequesterType.LINE
}
