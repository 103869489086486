import { IThirdPartyLoginStrategy } from './bases/LoginStrategy'

export default class WechatLoginStrategy implements IThirdPartyLoginStrategy {
  public async prepare(): Promise<void> {
    // TODO等待接入
  }

  public async logIn() {
    // TODO等待接入
  }
}
