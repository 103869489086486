import { ThemeComponentType } from './type'

export default {
  [ThemeComponentType.HomePage]: {
    com: () =>
      import(
        /* webpackChunkName: "home@theme=14" */ '@/views/game/themes/theme-14/home'
      )
  },
  [ThemeComponentType.SearchPage]: {
    com: () =>
      import(
        /* webpackChunkName: "search@theme=14" */ '@/views/game/themes/theme-14/search'
      )
  },
  [ThemeComponentType.SubGamePage]: {
    com: () =>
      import(
        /* webpackChunkName: "subGame@theme=14" */ '@/views/game/themes/theme-14/subGame'
      )
  },
  [ThemeComponentType.EmbeddedPage]: {
    com: () =>
      import(
        /* webpackChunkName: "embedded@theme=14" */ '@/views/game/themes/theme-14/embedded'
      )
  },
  [ThemeComponentType.Footer]: {
    com: () =>
      import(
        /* webpackChunkName: "footer@theme=14" */ '@/layouts/components/footer/themes/theme-14'
      )
  },
  [ThemeComponentType.FloatBar]: {
    com: () =>
      import(
        /* webpackChunkName: "floatBar@theme=14" */ '@/layouts/components/floatBar/themes/theme-14'
      )
  },
  [ThemeComponentType.Styles]: {
    com: () =>
      import(
        /* webpackChunkName: "style@theme=14" */ '@/views/global-styles/themes/theme-14'
      )
  },
  [ThemeComponentType.Layouts]: {
    com: () =>
      import(
        /* webpackChunkName: "layout@theme=14" */ '@/layouts/themes/theme-14'
      )
  }
}
