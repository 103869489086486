import { ComponentConfig } from '../../type'
import { Holiday, NormalHoliday, OtherHoliday } from '@/store/index/type'

// 节日主题组件类型
export enum HolidayComponentType {
  Main = 'main'
}

export const HolidayType = Holiday
export type HolidayType = OtherHoliday | NormalHoliday

export type HolidayConfigInfo = Record<HolidayComponentType, ComponentConfig>
