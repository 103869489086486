import { Bind } from '@/utils/Decorator'
import { ScreenSize } from '@/store/index/type'
import { debounce } from 'lodash'
import { getCurrentDevice } from '@/utils/Device'
import { raf } from '@/utils/Tool'
import { useHomeStore } from '@/store/home'
import { useMainStore } from '@/store/index'

/**
 * 媒体查询,响应式布局的断点
 */
export default class Media {
  constructor() {
    this.mediaScreen()
  }

  public watchResize() {
    const func = () => {
      this.leading()
      raf(this.leading)
    }

    func()
    /**
     * 避免ios双指放大缩小首页卡死
     */
    window.addEventListener(
      'resize',
      debounce(() => {
        func()
      })
    )
  }

  @Bind()
  public leading() {
    this.mediaScreen()
    this.calc1vh()
    useMainStore().updateRootFontSize()
  }

  private mediaScreen() {
    const { setScreenSize, setScreenAspectRatio } = useMainStore()
    const { setLongScreen } = useHomeStore()
    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight
    const screenAspectRatio = screenHeight / (screenWidth ?? 1)
    const docEle = document.querySelector('html')
    setScreenAspectRatio(screenAspectRatio)
    const { size } = window.LOBBY_UTILS.mediaScreen()
    docEle?.setAttribute('data-device-os', getCurrentDevice().os)
    setScreenSize(size as ScreenSize)
    /** 是否为长屏幕，根据美术那边给的公示 */
    const isLongScreen = screenHeight > 1334 / (750 / screenWidth)
    setLongScreen(isLongScreen)
  }

  private calc1vh() {
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}
