import {
  AllowAny,
  GeeHeaders,
  IGeeGuardToken,
  TGeetestGuardResult
} from '../type/geeGuard'
import { omit } from 'lodash'
import { useMainStore } from '@/store/index'
import GeeTestHelper from '../helpers/index'

export default class GeeTest {
  private static get _geetestDeviceAppId() {
    const { systemInfos } = useMainStore()
    return systemInfos?.geetestDeviceAppId ?? ''
  }

  /** 调用注册接口，获取极验设备验对象 */
  private static async _callRegister(
    appId: string
  ): Promise<TGeetestGuardResult> {
    return new Promise<TGeetestGuardResult>((resolve, reject) => {
      try {
        window?.initGeeGuard?.({ appId }, resolve)
      } catch (error) {
        console.error(`极验设备验证获取失败: ${error}`)
        reject(error)
      }
    })
  }

  /** 加载极验设备验 SDK */
  public static async initSDK(): Promise<void> {
    // 没有开启设备验无需加载 SDK
    if (!this._geetestDeviceAppId) {
      return
    }
    await GeeTestHelper.loadSDK('gt5', '/libs/gt@5/gt.js', 'initGeeGuard')
  }

  /**
   * 预先加载极验设备验 token。
   * 在部分业务启动时事先获取 token，避免后续操作时的等待
   */
  public static preloadGeeGuardToken() {
    const { $patch, geeGuardToken } = useMainStore()

    if (!geeGuardToken) {
      this.getGeeGuardToken().then((res) => {
        $patch({
          geeGuardToken: res?.geeToken
        })
      })
    }
  }

  /** 获取极验设备验证 token 流程 */
  public static async getGeeGuardToken(): Promise<IGeeGuardToken | undefined> {
    // 当没有 geeTestId 时，不进行设备验证
    if (!this._geetestDeviceAppId) {
      return
    }

    // 记录整个流程开始的时间
    const startTime = performance.now()
    // 加载设备验证 SDK
    await this.initSDK()
    // 记录 SDK 加载完成时间，并计算加载 SDK 耗时
    const sdkEndTime = performance.now()
    const sdkDuration = Math.floor(sdkEndTime - startTime)

    const geetokenStartTime = performance.now()
    // 如果geetoken获取时间超过7秒直接停止加载，不阻塞登录注册流程
    const timeOutPromise = new Promise<TGeetestGuardResult>((_, reject) =>
      setTimeout(() => reject(new Error('Geetest token timeout')), 7000)
    )
    let captchaObj: TGeetestGuardResult | undefined
    try {
      captchaObj = await Promise.race([
        this._callRegister(this._geetestDeviceAppId),
        timeOutPromise
      ])
    } catch (error) {
      console.error(error)
      captchaObj = {
        status: 'error',
        data: {
          code: 'timeout',
          msg: 'Geetest token timeout',
          gee_token: '',
          local_id: ''
        }
      }
    }

    const geetokenEndTime = performance.now()
    // token 获取耗时
    const tokenDuration = Math.floor(geetokenEndTime - geetokenStartTime)
    // 整个流程总耗时
    const totalDuration = Math.floor(geetokenEndTime - startTime)

    const geeToken = captchaObj?.data?.gee_token ?? ''
    const deviceError = !geeToken
      ? `${captchaObj?.data?.code}-${captchaObj?.data?.msg}`
      : ''
    return {
      sdkDuration,
      tokenDuration,
      totalDuration,
      geeToken,
      deviceError
    }
  }

  /** 获取或加载 GeeGuardToken */
  public static async getOrLoadGeeGuardToken(): Promise<
    IGeeGuardToken | undefined
  > {
    const { geeGuardToken } = useMainStore()

    // 检查中是否已经存在 GeeGuardToken，如果存在，则返回格式化后的 GeeGuardToken；
    if (geeGuardToken) {
      return Promise.resolve({
        sdkDuration: 0,
        tokenDuration: 0,
        totalDuration: 0,
        geeToken: geeGuardToken,
        deviceError: ''
      })
    } else {
      // store 不存在 GeeGuardToken，则从 GeeTest 获取。
      return GeeTest.getGeeGuardToken()
    }
  }
}

/**
 * WithGeeGuard 装饰器
 * 将原始方法包装为具有获取 GeeGuardToken 功能
 * 并在调用原始方法之前修改方法参数的 payload 和 headers 的功能
 */
export function WithGeeGuard() {
  return function (
    // eslint-disable-next-line @typescript-eslint/ban-types
    _target: Object,
    _propertyName: string,
    propertyDescriptor: TypedPropertyDescriptor<
      (...args: [AllowAny, GeeHeaders]) => Promise<AllowAny>
    >
  ) {
    const method = propertyDescriptor.value
    // 用新的函数替换原始方法
    propertyDescriptor.value = async function (
      ...args: [AllowAny, GeeHeaders]
    ) {
      // 获取 GeeGuardToken
      const geeGuardResult = await GeeTest.getOrLoadGeeGuardToken()

      // 修改请求的 payload
      const modifiedPayload = {
        geeToken: geeGuardResult?.geeToken,
        ...args[0]
      }

      // 修改请求的 headers
      const modifiedHeaders = omit(geeGuardResult, [
        'geeToken',
        !geeGuardResult?.deviceError ? 'deviceError' : ''
      ])

      // 调用原始方法并返回结果
      return await method?.apply(this, [
        modifiedPayload,
        modifiedHeaders as GeeHeaders
      ])
    }
  }
}
