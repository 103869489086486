import { AxiosResponse } from 'axios'
import {
  Base,
  Component,
  Emit,
  ModelSync,
  Prop,
  Ref,
  Watch
} from '@/vue-property-decorator'
import { Bind, Debounce } from '@/utils/Decorator'
import { CreateModeOptType } from '@/api/common/type'
import { GlobalEvents } from '@/context'
import { Icon, Input } from '@/plugins/ant-design-vue/tsx-support'
import {
  apiGetEmailCodeAfterLogin,
  apiGetEmailCodeBeforceLogin,
  apiGetSmsCode,
  apiValidSmsCode
} from '@/api/common'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { merge } from 'lodash'
import { numberOnlyFormatter } from '@/utils/FormatterUtils'
import { resCodeMessage } from '@/utils/Tool'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import Const from '@/context/const'
import CountdownBtn from '@/components/business-components/countdown-btn'
import FuncRender from '../func-render'
import Modal from '@/controller/Modal'
import Rules from '@/controller/Rules'
import style from './style.module.scss'

interface Props {
  /** @default sms */
  mode?: 'sms' | 'email'
  createMode?: CreateModeOptType
  prop: string
  targetValue: string
  username?: string
  getCodeFunPromise?: (targetValue: string) => Promise<unknown>
  disabled?: boolean
  useDialogValid?: boolean
  isRequired?: boolean
  showRequiredLabel?: boolean
  value?: string
  label?: string
  model?: Record<string, unknown>
  hiddenPrefix?: boolean
  hiddenIcon?: boolean
  showLabel?: boolean
  placeholder?: BaseResult
  maxInputLength?: number // 验证码输入框长度
  customIcon?: string
  inputTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Input.Tsx,
    unknown
  >
}

export type Events = {
  onBeenGot?: boolean
  onQuickVerify?: void
}
@Component<VerificationCode>({ name: 'VerificationCode' })
export default class VerificationCode extends Base<unknown, Props, Events> {
  @ModelSync('value', 'input', AppTypes.string)
  public verificationCode!: string

  @Prop()
  private placeholder!: Props['placeholder']
  /**
   * 获取验证码的异步函数
   * 若为img模式，返回值为 Promise包裹的base64字符串
   */
  @Prop()
  private getCodeFunPromise!: Props['getCodeFunPromise']
  /**
   * 表单验证绑定的key
   */
  @Prop(AppTypes.string.isRequired)
  private prop!: Props['prop']

  /**
   * 是否必填
   */
  @Prop(AppTypes.bool.def(true))
  private isRequired!: Props['isRequired']
  /**
   * label
   */
  @Prop(AppTypes.string)
  private label!: Props['label']
  /**
   * username
   */
  @Prop(AppTypes.string)
  private username!: Props['username']

  /**
   * mode
   */
  @Prop(AppTypes.string.def('sms'))
  private mode!: NonNullable<Props['mode']>
  /**
   * sms和email模式必填，img模式可以传任意字符串比如none
   * 短信验证发送的号码 或者邮箱
   */
  @Prop(AppTypes.string.isRequired)
  private targetValue!: Props['targetValue']
  /**
   * 外部控制是否禁止发送按钮
   */
  @Prop(AppTypes.looseBool.def(false))
  private disabled!: NonNullable<Props['disabled']>
  /**
   * 控制是否隐藏内部默认的 左占位
   */
  @Prop(AppTypes.looseBool.def(false))
  private hiddenPrefix!: NonNullable<Props['hiddenPrefix']>
  /**
   * 控制是否隐藏label的图标
   */
  @Prop(AppTypes.looseBool.def(false))
  private hiddenIcon!: NonNullable<Props['hiddenIcon']>
  /**
   * 控制是否渲染内部默认的label渲染
   */
  @Prop(AppTypes.looseBool.def(false))
  private showLabel!: NonNullable<Props['showLabel']>
  /**
   * 是否用独立的弹层验证错误，默认为false，走表单验证体系，错误消息在表单项下面显示
   */
  @Prop(AppTypes.looseBool.def(false))
  private useDialogValid!: NonNullable<Props['useDialogValid']>

  @Prop()
  maxInputLength!: Props['maxInputLength']

  @Prop()
  customIcon: Props['customIcon']

  @Prop(AppTypes.object.def({}))
  inputTsxData!: Props['inputTsxData']
  /**
   * 整个表单对象绑定的 model 值
   */
  @Prop(AppTypes.object.def(() => ({})))
  private model!: NonNullable<Props['model']>
  @Prop(AppTypes.looseBool.def(false))
  private showRequiredLabel!: NonNullable<Props['showRequiredLabel']>

  @Prop()
  private createMode: Props['createMode']

  @Ref()
  private countdownBtnRef!: CountdownBtn

  /**
   * 是否正在获取验证码
   */
  private loading = false
  /**
   * 是否获取成功了
   */
  public hasBeenGot = false

  /**
   * 快速验证
   */
  private quickVerify = false

  @Watch('targetValue')
  onTargetValue() {
    this.quickVerify = false
  }

  @Watch('hasBeenGot', { immediate: true })
  @Emit('beenGot')
  onBeenGot(value: boolean) {
    return value
  }
  /**
   *  获取按钮是否可以点击
   */
  private get isDisabled() {
    return (
      this.disabled ||
      /**
       * 未填写发送对象
       */
      !this.targetValue ||
      /**
       * 正在获取验证码接口
       */
      this.loading
    )
  }

  @Bind()
  private async doGetVerificationCode() {
    const { t } = useI18n()
    const { hasLogined } = useMainStore()
    if (this.isDisabled || this.loading) return
    this.loading = true
    this.hasBeenGot = false
    this.quickVerify = false

    let promise
    if (this.getCodeFunPromise) {
      promise = this.getCodeFunPromise(this.targetValue)
    } else {
      const create_mode = this.createMode ?? CreateModeOptType.Verify
      const limit_type = 'phone'
      const phone_number = this.targetValue
      const email = this.targetValue
      if (this.mode === 'sms') {
        promise = apiGetSmsCode({ create_mode, limit_type, phone_number })
      } else if (this.mode === 'email') {
        promise = hasLogined
          ? apiGetEmailCodeAfterLogin({ create_mode, limit_type, email })
          : apiGetEmailCodeBeforceLogin({ create_mode, limit_type, email })
      } else {
        promise = Promise.resolve()
      }
    }

    try {
      const res = (await promise) as AxiosResponse
      const isExist = res?.data?.data?.isExist as boolean | undefined
      const quickVerify = res?.data?.data?.quickVerify as boolean | undefined
      useMainStore().setEmailExist(isExist)
      this.hasBeenGot = true

      const envQuick = this.createMode === CreateModeOptType.RegisterIntegration
      if (quickVerify && envQuick) {
        // 快速验证成功
        this.quickVerify = true
        this.verificationCode = ''
        this.$emit('quickVerify')
      } else {
        Modal.message({
          type: 'success',
          content: t('lobby.common.components.auth.phone.sendSuccess')
        })
        setTimeout(() => {
          this.$nextTick(() => {
            this.countdownBtnRef?.doCountDown()
          })
        }, 1500)
      }
    } catch (error) {
      const { code } = resCodeMessage(error)
      if (
        code === Const.ServiceCode.EMAIL_SECURITY_CONFIG_EXPIRED ||
        code === Const.ServiceCode.PHONE_SECURITY_CONFIG_EXPIRED
      ) {
        GlobalEvents.dispatch({ type: 'CONFIG_CHANGE_ERR' })
      }
      console.error(error)
    }

    setTimeout(() => {
      this.loading = false
    }, 1000)
  }

  @Bind()
  @Debounce()
  public getVerificationCode(e: Event | null = null) {
    e?.stopPropagation()
    this.doGetVerificationCode()
  }

  /** 外部调用校验 */
  public async valid() {
    const { t } = useI18n()
    if (this.useDialogValid) {
      if (!this.verificationCode) {
        Modal.message({
          content: () => t('lobby.modal.login.form.smsCode.required') as string
        })
        return Promise.reject()
      }
      if (!this.hasBeenGot) {
        Modal.message({
          content: () => this.requiredMessage as string
        })
        return Promise.reject()
      }
    }
    if (this.mode === 'sms') {
      return apiValidSmsCode({
        captcha: this.verificationCode,
        phone_number: this.targetValue
      })
    }
  }

  private get requiredMessage() {
    const { t } = useI18n()
    if (this.mode === 'sms') {
      return t('lobby.modal.login.form.smsCode.getCode')
    }
    if (this.mode === 'email') {
      return t('lobby.modal.login.form.smsCode.emailCode')
    }
    return t('lobby.modal.register.imageVerificationCode')
  }

  private get maxLength() {
    if (this.maxInputLength) {
      return this.maxInputLength
    }
    return this.mode === 'sms' ? 4 : 5
  }

  private get defaultPlaceholder() {
    const { t } = useI18n()
    if (this.mode === 'sms') {
      return t('lobby.center.security.phone.smsPlaceholder')
    }
    if (this.mode === 'email') {
      return t('lobby.center.security.email.auth.codePlaceholder')
    }
    return t('lobby.center.security.verifyCode')
  }

  private funcRender = FuncRender.createFormModelItem.call(this)

  render() {
    const { t } = useI18n()
    return this.funcRender.custom({
      prop: this.prop,
      model: this.model,
      formModelItemTsxData: {
        props: {
          rules: [
            ...(this.useDialogValid || !this.isRequired || this.quickVerify
              ? []
              : [
                  Rules.required({
                    customPrompt: () => {
                      return t('lobby.modal.login.form.smsCode.required')
                    }
                  }),
                  Rules.create({
                    required: true,
                    validator: (_rule, _value, callback) => {
                      if (!this.hasBeenGot) {
                        callback(this.requiredMessage as string)
                      }
                      return true
                    }
                  })
                ]),
            Rules.create({
              validator: (_rule, value, callback) => {
                this.verificationCode = numberOnlyFormatter(value || '', {
                  maxLength: this.maxLength
                })

                callback()
                return true
              }
            })
          ]
        }
      },
      render: () => {
        return [
          this.showLabel ? (
            <template slot="label">
              <div>
                <Icon.Tsx
                  v-show={!this.hiddenIcon}
                  component={{
                    functional: true,
                    render: () => {
                      return (
                        <icon-sprite
                          sprite={createSvgSprite(
                            this.customIcon ?? 'input_icon_yz'
                          )}
                        />
                      )
                    }
                  }}
                />
                <span>
                  {this.label ||
                    (t('lobby.modal.login.form.smsCode.label') as string)}
                </span>
              </div>
            </template>
          ) : null,

          <Input.Tsx
            allowClear
            v-model={this.verificationCode}
            placeholder={
              (this.placeholder || this.defaultPlaceholder) as string
            }
            class={[style.codeInput, 'verification__codeInput']}
            {...merge(
              { attrs: { autocomplete: 'off' } },
              this.inputTsxData as unknown
            )}
          >
            {!this.hiddenPrefix && (
              <div slot="prefix" class={style.inputRequire}>
                <Icon.Tsx
                  component={{
                    functional: true,
                    render: () => {
                      return (
                        <icon-sprite
                          sprite={createSvgSprite(
                            this.customIcon ?? 'input_icon_yz'
                          )}
                        />
                      )
                    }
                  }}
                />
                {this.showRequiredLabel && <span class={style.require}>*</span>}
              </div>
            )}
            <template slot="suffix">
              <CountdownBtn
                class={[style.smsCode, 'verification__smsCode']}
                ref={'countdownBtnRef'}
                loading={this.loading}
                disabled={this.isDisabled}
                onClick={this.getVerificationCode}
              />
            </template>
          </Input.Tsx>,

          // 快速验证
          <div v-show={this.quickVerify} class={style.quickVerify}>
            <span class={style.iconPhone}>
              <Icon.Tsx
                component={{
                  functional: true,
                  render: () => {
                    return (
                      <icon-sprite
                        sprite={createSvgSprite(
                          this.customIcon ?? 'input_icon_sj'
                        )}
                      />
                    )
                  }
                }}
              />
            </span>
            <span class={style.iconOk}>
              <Icon.Tsx
                component={{
                  functional: true,
                  render: () => {
                    return (
                      <icon-sprite sprite={createSvgSprite('comm_icon_gou')} />
                    )
                  }
                }}
              />
            </span>
            <span class={style.quickVerifyText}>
              {t('lobby.modal.loginRegister.quickVerifySuccess')}
            </span>
          </div>
        ]
      }
    })
  }
}
