/**
 * 常用颜色
 */
export const RED_COLOR = `#ea4e3d`
export const BLUE_COLOR = `#1678ff`
export const YELLOW_COLOR = `#ffaa09`
export const GREEN_COLOR = `#04be02`
export const GREY_COLOR = `#999999`
export const BORDER_LINE_COLOR = '#e3e3e3'
export const GALAXY_COLOR = '#A37C3C'
export const CROWN_COLOR = '#866638'
