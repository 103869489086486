import { ThemeComponentType } from './type'

export default {
  [ThemeComponentType.HomePage]: {
    com: () =>
      import(
        /* webpackChunkName: "home@theme=36" */ '@/views/game/themes/theme-36/home'
      )
  },
  [ThemeComponentType.SearchPage]: {
    com: () =>
      import(
        /* webpackChunkName: "search@theme=36" */ '@/views/game/themes/theme-36/search'
      )
  },
  [ThemeComponentType.SubGamePage]: {
    com: () =>
      import(
        /* webpackChunkName: "subGame@theme=36" */ '@/views/game/themes/theme-36/subGame'
      )
  },
  [ThemeComponentType.EmbeddedPage]: {
    com: () =>
      import(
        /* webpackChunkName: "embedded@theme=36" */ '@/views/game/themes/theme-36/embedded'
      )
  },
  // [ThemeComponentType.Header]: {
  //   com: () =>
  //     import(
  //       /* webpackChunkName: "home@theme=36" */ '@/layouts/components/header/themes/theme-36'
  //     )
  // },
  [ThemeComponentType.Footer]: {
    com: () =>
      import(
        /* webpackChunkName: "footer@theme=36" */ '@/layouts/components/footer/themes/theme-36'
      )
  },
  // [ThemeComponentType.TabBar]: {
  //   com: () =>
  //     import(
  //       /* webpackChunkName: "home@theme=36" */ '@/layouts/components/tabBar/themes/theme-36'
  //     )
  // },
  // [ThemeComponentType.Aside]: {
  //   com: () =>
  //     import(
  //       /* webpackChunkName: "home@theme=36" */ '@/layouts/components/aside/themes/theme-36'
  //     )
  // },
  [ThemeComponentType.FloatBar]: {
    com: () =>
      import(
        /* webpackChunkName: "floatBar@theme=36" */ '@/layouts/components/floatBar/themes/theme-36'
      )
  },
  [ThemeComponentType.Styles]: {
    com: () =>
      import(
        /* webpackChunkName: "style@theme=36" */ '@/views/global-styles/themes/theme-36'
      )
  },
  [ThemeComponentType.Layouts]: {
    com: () =>
      import(
        /* webpackChunkName: "layouts@theme=36" */ '@/layouts/themes/theme-36'
      )
  }
}
