import { CategoryCanReceive } from '@/views/task/api/apiEventAndDiscountsRedDot'
import { GlobalService } from '@/context'
import { merge } from 'lodash'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'

/** 活动的小红点对象 */
export class ActivityRedDot {
  /** 活动id */
  activeId = 0
  /** 小红点数量 */
  activeCount = 0
  /** 小红点金额 */
  activeAmount = 0
}

/** 活动分类下对应的小红点对象 */
export class CategoryRedDot {
  /** 活动分类的id */
  categoryId = 0
  /** 小红点数量(该分类下所有活动小红点相加) */
  activeCount = 0
  /** 小红点金额(该分类下所有活动金额相加) */
  activeAmount = 0
}

/** 任务小红点对象 */
export class TaskRedDot {
  /** 任务模板 */
  template!: 1 | 2 | 3 | 4
  /** 未领取小红点数量 */
  unReceiveCount = 0
  /** 未领取金额 */
  unReceiveAmount = 0
}

export class EventDiscountsRedDot {
  /** 活动小红点 */
  activeCount = 0
  /** 任务下红点 */
  taskCount = 0
  /** 奖励小红点 (v3.8后弃用)*/
  rewardCount = 0
  /** 返水小红点，接口预留 */
  returnGoldCount = 0
  /** 活动金额 */
  activeAmount = 0
  /** 任务金额 */
  taskAmount = 0
  /** 奖励金额 (v3.8后弃用) */
  returnAmount = 0
  /** 返水金额，接口预留 */
  rewardAmount = 0
  /** 活动小红点映射 */
  activeRedDot: {
    /** 活动小红点列表 */
    activeList: ActivityRedDot[]
    /** 分类小红点列表 */
    categoryList: CategoryRedDot[]
  } = { activeList: [], categoryList: [] }
  /** 任务小红点列表 */
  taskRedDot: TaskRedDot[] = []
  /** 一键领取开关 0-开启 1-关闭 */
  disableReceiveAwardAll = 1
  /** 待领取弹框 0-不弹框 1-每日一次 4-奖励待领取时弹窗、5-只弹一次、6-每次登录 */
  disableReceiveLogPop = 0
  /** vip小红点*/
  vipCount = 0
  VIPAmount = 0
  /** 利息宝（原余额宝）金额 接口预留 */
  yuEBaoAmount = 0
  /** 利息宝（原余额宝）小红点 */
  yueBaoCount = 0
  /** 待领取小红点 */
  receiveLogCount = 0
  /** 待领取金额 */
  receiveLogAmount = 0
  /** 公积金小红点 */
  rechargeFundCount = 0
  /**代理推广气泡小红点 */
  agentPromoteReward = 0
}

/**
 * @deprecated
 * 实际的发送小红点请求的函数
 * */
export const apiEventAndDiscountsRedDotV1 = async (isRefresh = false) => {
  const response = await GlobalService.post<EventDiscountsRedDot>({
    url: '/active/getred_dot',
    data: {},
    customParams: {
      silentOnError: true,
      cache: isRefresh ? 0 : true,
      throttleDelay: 1000 * 2
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  })
  const eventRedDot = response.data?.data as EventDiscountsRedDot
  // 对返回的数据做一个纠错,避免出现undefiend等破坏数据结构的内容
  if (!eventRedDot.activeRedDot.activeList) {
    eventRedDot.activeRedDot.activeList = []
  }
  if (!eventRedDot.activeRedDot.categoryList) {
    eventRedDot.activeRedDot.categoryList = []
  }
  if (!eventRedDot.taskRedDot) {
    eventRedDot.taskRedDot = []
  }
  return eventRedDot
}

/**
 * 小红点接口V2，取代/active/getred_dot接口
 */
export const apiEventAndDiscountsRedDot = async (isRefresh = false) => {
  const response = await GlobalService.post<EventDiscountsRedDot>({
    url: '/active/getRedDotV2',
    data: {},
    customParams: {
      silentOnError: true,
      cache: isRefresh ? 0 : true,
      throttleDelay: 1000 * 2
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  })
  const eventRedDot = response.data?.data as EventDiscountsRedDot

  // 对返回的数据做一个纠错,避免出现undefiend等破坏数据结构的内容
  if (!eventRedDot.activeRedDot.activeList) {
    eventRedDot.activeRedDot.activeList = []
  }
  if (!eventRedDot.activeRedDot.categoryList) {
    eventRedDot.activeRedDot.categoryList = []
  }
  if (!eventRedDot.taskRedDot) {
    eventRedDot.taskRedDot = []
  } else {
    eventRedDot.taskRedDot = eventRedDot.taskRedDot.map((item) => {
      return merge(new CategoryCanReceive({ template: item.template }), item)
    })
  }
  return eventRedDot
}
