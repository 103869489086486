import { ForceType } from '@/views/notice/const'
import { GlobalService } from '@/context'
import {
  LoginPrompterResponse,
  Marquee,
  MessageAllRequest,
  MessageAllResult
} from './type'

/**
 * 游戏页公告消息
 */
export const apiGetMarqueeList = async () => {
  const ret = await GlobalService.get<Array<Marquee>>({
    url: '/message/marquee',
    customParams: {
      tryStaticMode: true,
      cache: true
    }
  }).then((res) => res.data.data || [])
  return ret
}

/**
 * 获取未登录智能弹窗配置
 */
export const apiLoginPrompterSetting = async () => {
  return GlobalService.get<Array<LoginPrompterResponse>>({
    url: '/message/popupcfg',
    customParams: {
      tryStaticMode: true,
      staticKeyOmit: ['language', 'platformType'],
      keyOmit: true
    }
  })
}
export const apiGetMessageAll = async (payload: MessageAllRequest) => {
  const ret = await GlobalService.get<MessageAllResult>({
    url: '/message/all/index',
    params: payload,
    customParams: {
      tryStaticMode: true,
      staticKeyOmit: ['platformType'],
      keyOmit: true,
      cache: 1 // 需求测试，每次点击消息模块都会请求，sql连表多，访问频次高 现改为前端缓存1分钟看看效果
    }
  })
    .then((res) => {
      if (res.data.data?.frame) {
        const { frame: frameData } = res.data.data
        frameData.frame?.forEach((item) => {
          item.forceType = ForceType.PUBLICIZE
        })
        frameData.notice?.forEach((item) => {
          item.forceType = ForceType.NOTICE
        })
        frameData.announcement?.forEach((item) => {
          item.forceType = ForceType.ANNOUNCEMENT
        })
      }
      return res.data.data
    })
    .catch((error) => {
      return Object.assign(error, { isError: true })
    })
  return ret
}
