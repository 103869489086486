import { Base, Component, Prop } from '@/vue-property-decorator'
import { createSvgSprite } from '@/utils/business-utils/assets'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
// const icon_gou = createSvgSprite('comm_icon_gou')
// const icon_gou_bg = createSvgSprite('comm_img_corner')
import style from './style.module.scss'
type Props = {
  width?: string
  height?: string
}

@Component<SubscriptComp>({ name: 'SubscriptComp' })
export default class SubscriptComp extends Base<unknown, Props> {
  /**
   * width
   */
  @Prop(AppTypes.string.def(BusinessUtils.px2rem('30px')))
  width!: Props['width']

  /**
   * height
   */
  @Prop(AppTypes.string.def(BusinessUtils.px2rem('30px')))
  height!: Props['height']

  render() {
    return (
      <p class={[style.subscript, 'subscript']}>
        <icon-sprite
          class={[style.gou, 'gou']}
          sprite={createSvgSprite('comm_icon_gou')}
        ></icon-sprite>
        <icon-sprite
          class={[style.bg, 'bg']}
          sprite={createSvgSprite('comm_img_corner')}
        ></icon-sprite>
      </p>
    )
  }
}
