import { Base, Component, Prop } from '@/vue-property-decorator'
import { GlobalConst } from '@/context'
import { PortalTarget } from 'portal-vue'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { markScrollType } from '@/router/scroll'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import AutoShrinkText from '../auto-shrink-text'
import BusinessUtils from '@/utils/business-utils'
import TooltipsComp from '../business-components/tooltips'
import router from '@/router'
import style from './style.module.scss'

export interface Props {
  /**
   * 标题
   */
  title?: NonNullable<BaseRenderContent>
  /**
   * 是否显示后退
   */
  showBack?: boolean
  /**
   * 返回按钮事件
   */
  goBack?: () => void
  /**
   * H5是否需要占位高度
   */
  needH5Height?: boolean
  /**
   * title 汽泡数字显示
   */
  badge?: number
}

@Component<MyTitle>({
  name: 'MyTitle'
})
export default class MyTitle extends Base<unknown, Props, unknown> {
  @Prop(AppTypes.string.def(''))
  private readonly title!: NonNullable<Props['title']>

  @Prop(AppTypes.bool.def(true))
  private readonly showBack!: NonNullable<Props['showBack']>

  @Prop(
    AppTypes.func.def(() => {
      markScrollType('auto')
      router.back()
    })
  )
  private readonly goBack!: NonNullable<Props['goBack']>

  @Prop(AppTypes.bool.def(true))
  private readonly needH5Height!: NonNullable<Props['needH5Height']>

  @Prop({ default: 0 })
  private readonly badge?: number

  private get decorateImg() {
    // return '/lobby_asset/{layout}-{skin}-common/web/common/img_title_decor@x3.png'
    return '/lobby_asset/{layout}-{bg}-common/web/common/img_title_decor@x3.png'
  }
  /** 本地跳转回首页,对跳转回首页的方法进行一层装饰 */
  private localGoBack() {
    // 触发一下去到home页的事件,传入当前路由
    this.$root.$emit('gotoHomePage', this.$route.path)
    this.goBack()
  }
  render() {
    const { isWeb } = useMainStore()
    const { t } = useI18n()

    return (
      <div
        class={{
          myTitleContainer: true,
          [style.myTitleContainer]: true,
          [style.noHeight]: !this.needH5Height
        }}
      >
        <div
          class={[
            style.myTitle,
            'myTitle',
            'boxShadow',
            GlobalConst.CssClassName.Lobby.FixedNoDesktop
          ]}
        >
          <div class={style.back}>
            {this.showBack && (
              <div onClick={this.localGoBack.bind(this)}>
                <icon-sprite sprite={createSvgSprite('comm_icon_fh')} />
                <span>{isWeb ? t('lobby.common.tips.return') : ''}</span>
              </div>
            )}
          </div>
          <div class={style.titleWrap}>
            <div class={style.title}>
              <my-img
                v-show={isWeb}
                src={this.decorateImg}
                width={74.33}
                height={8.67}
              />
              <span class="global-wrapper-title">
                <AutoShrinkText
                  limitHeight={isWeb ? `0.5rem` : `0.7rem`}
                  minFontSize={isWeb ? `0.12rem` : `0.14rem`}
                  fontSize={isWeb ? `0.24rem` : `0.30rem`}
                  text={this.title as string}
                ></AutoShrinkText>
                {this.badge && this.badge > 0 ? (
                  <TooltipsComp
                    class="global-wrapper-badge"
                    text={<span>{this.badge}</span>}
                    top={BusinessUtils.px2rem('-18px')}
                    right={BusinessUtils.px2rem('-22px')}
                    charge={this.badge}
                  />
                ) : (
                  ''
                )}
              </span>
              <my-img
                v-show={isWeb}
                src={this.decorateImg}
                width={74.33}
                height={8.67}
                style={'transform: rotate(180deg)'} //改成左侧旋转180
              />
            </div>
            <PortalTarget
              class={style.mySubTitle}
              name={GlobalConst.PortalName.MySubTitle}
            ></PortalTarget>
          </div>
          <div class={style.btns}>
            {isWeb && [
              <PortalTarget
                class={style.myTitleRightSlotsLeft}
                name={GlobalConst.PortalName.MyTitleRightSlotsLeft}
              ></PortalTarget>,
              <PortalTarget
                class={style.myTitleRightSlotsRight}
                name={GlobalConst.PortalName.MyTitleRightSlotsRight}
              ></PortalTarget>
            ]}
            {!isWeb && (
              <PortalTarget
                class={style.myTitleRightSlotsRight}
                name={GlobalConst.PortalName.MyTitleH5Slots}
              ></PortalTarget>
            )}
          </div>
        </div>
      </div>
    )
  }
}
