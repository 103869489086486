import { Language } from '@/i18n'
import { PngSprite } from '@/components/icon-sprite'
import { createPngSprite } from '@/utils/business-utils/assets'

export const rectIconMap: Record<Language, () => PngSprite> = {
  en_US: () => createPngSprite('sprite_main_flag_4x3_UnitedStates'), // 英语
  zh_CN: () => createPngSprite('sprite_main_flag_4x3_ChineseMainland'), // 简体中文
  zh_TW: () => createPngSprite('sprite_main_flag_4x3_HongKong'), // 繁体中文
  th_TH: () => createPngSprite('sprite_main_flag_4x3_Thailand'), // 泰国语言
  vi_VN: () => createPngSprite('sprite_main_flag_4x3_Vietnam'), // 越南语言
  ko_KR: () => createPngSprite('sprite_main_flag_4x3_SouthKorea'), // 韩语
  ja_JP: () => createPngSprite('sprite_main_flag_4x3_Japan'), // 日本语言
  pt_PT: () => createPngSprite('sprite_main_flag_4x3_Brazil'), // 葡萄牙语
  es_ES: () => createPngSprite('sprite_main_flag_4x3_Spain'), // 西班牙
  de_DE: () => createPngSprite('sprite_main_flag_4x3_Germany'), // 德国语言
  fr_FR: () => createPngSprite('sprite_main_flag_4x3_France'), // 法国语言
  it_IT: () => createPngSprite('sprite_main_flag_4x3_Italy'), // 意大利
  ru_RU: () => createPngSprite('sprite_main_flag_4x3_Russia'), // 俄罗斯
  my_MM: () => createPngSprite('sprite_main_flag_4x3_Myanmar'), // 缅甸语
  id_ID: () => createPngSprite('sprite_main_flag_4x3_Indonesia'), // 印尼语
  hi_IN: () => createPngSprite('sprite_main_flag_4x3_India'), // 印地语
  tl_PH: () => createPngSprite('sprite_main_flag_4x3_Philippines'), // 菲律宾语
  km_KH: () => createPngSprite('sprite_main_flag_4x3_Cambodia'), // 高棉语

  te_IN: () => createPngSprite('sprite_main_flag_4x3_India'), // 泰卢固语（印度语系）
  ta_LK: () => createPngSprite('sprite_main_flag_4x3_India'), // 泰米尔语（印度语系）
  mr_IN: () => createPngSprite('sprite_main_flag_4x3_India'), // 马拉地语（印度语系）
  kn_IN: () => createPngSprite('sprite_main_flag_4x3_India'), // 卡纳达语（印度语系）
  pa_PA: () => createPngSprite('sprite_main_flag_4x3_India'), // 旁遮普语（印度语系）

  bn_BN: () => createPngSprite('sprite_main_flag_4x3_Bangladesh'), // 孟加拉语

  tr_TR: () => createPngSprite('sprite_main_flag_4x3_Turkey') // 土耳其语
}

export const minRectIconMap: Record<Language, () => PngSprite> = {
  en_US: () => createPngSprite('sprite_main_flag_1x1_UnitedStates'), // 英语
  zh_CN: () => createPngSprite('sprite_main_flag_1x1_ChineseMainland'), // 简体中文
  zh_TW: () => createPngSprite('sprite_main_flag_1x1_HongKong'), // 繁体中文
  th_TH: () => createPngSprite('sprite_main_flag_1x1_Thailand'), // 泰国语言
  vi_VN: () => createPngSprite('sprite_main_flag_1x1_Vietnam'), // 越南语言
  ko_KR: () => createPngSprite('sprite_main_flag_1x1_SouthKorea'), // 韩语
  ja_JP: () => createPngSprite('sprite_main_flag_1x1_Japan'), // 日本语言
  pt_PT: () => createPngSprite('sprite_main_flag_1x1_Brazil'), // 葡萄牙语
  es_ES: () => createPngSprite('sprite_main_flag_1x1_Spain'), // 西班牙
  de_DE: () => createPngSprite('sprite_main_flag_1x1_Germany'), // 德国语言
  fr_FR: () => createPngSprite('sprite_main_flag_1x1_France'), // 法国语言
  it_IT: () => createPngSprite('sprite_main_flag_1x1_Italy'), // 意大利
  ru_RU: () => createPngSprite('sprite_main_flag_1x1_Russia'), // 俄罗斯
  my_MM: () => createPngSprite('sprite_main_flag_1x1_Myanmar'), // 缅甸语
  id_ID: () => createPngSprite('sprite_main_flag_1x1_Indonesia'), // 印尼语
  hi_IN: () => createPngSprite('sprite_main_flag_1x1_India'), // 印地语
  tl_PH: () => createPngSprite('sprite_main_flag_1x1_Philippines'), // 菲律宾语
  km_KH: () => createPngSprite('sprite_main_flag_1x1_Cambodia'), // 高棉语

  te_IN: () => createPngSprite('sprite_main_flag_1x1_India'), // 泰卢固语（印度语系）
  ta_LK: () => createPngSprite('sprite_main_flag_1x1_India'), // 泰米尔语（印度语系）
  mr_IN: () => createPngSprite('sprite_main_flag_1x1_India'), // 马拉地语（印度语系）
  kn_IN: () => createPngSprite('sprite_main_flag_1x1_India'), // 卡纳达语（印度语系）

  tr_TR: () => createPngSprite('sprite_main_flag_4x3_Turkey') // 土耳其语
}

/**
 * 当前只开发了（中文，英文，印地语，越南语，日语，印尼语，繁中、西班牙、菲律宾、泰语、
 * 葡萄牙、高棉语、韩语、法语、德语、意大利、泰卢固语、泰米尔语、马拉地语、卡纳达语、缅甸语）
 */
export const CURRENT_LANGUAGE_CONFIG = [
  'zh',
  'en',
  'hi',
  'vi',
  'ja',
  'id',
  'zh_hk',
  'es',
  'tl',
  'th',
  'pt',
  'km',
  'ko',
  'fr',
  'de',
  'it',
  'te',
  'ta',
  'mr',
  'kn',
  'my',
  'tr',
  'ru',
  'bn',
  'pa'
]
