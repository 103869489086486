export interface MusicItem {
  id: number
  music_name: string
  music_size: number
  oss_url: string
}

export interface MusicResponse {
  sys_list: Array<MusicItem> | []
  my_list: Array<MusicItem> | []
}

export interface AddMusicPayload {
  sys_music_id: number
}

export interface MusicListPayload {
  page?: number
  pageLimit?: number
  reqType: number // 1系统音乐   2我的音乐
}

export interface DelMusicPayload {
  my_music_id: number
}

export interface PrizePoolWinAmountResponse {
  totalAmount: string
}

export interface VirtualBonusPoolData {
  /** 用来banner模式控制key的 */
  key?: number
  /**
   * 背景风格   0:自定义  1:样式1  2:样式2   3:样式3
   */
  backgroundStyle: number // 非必须
  /**
   * 样式url
   */
  backgroundUrl: string // 非必须
  /**
   * 轮播名称
   */
  carouselName: string // 非必须
  /**
   * 小数点位数
   */
  decimalPlaces: number // 非必须
  /**
   * 外部链接
   */
  externalLinks: string // 非必须
  /**
   * 字体样式 json
   */
  fontTypeface: string // 非必须
  /**
   * 虚拟彩金池ID
   */
  id: number // 非必须
  /**
   * 彩金池数字生成时间戳
   */
  virtualPoolTimestamp: number // 非必须
  /**
   * 彩金池数字
   */
  virtualPoolNumbers: string[] //非必须
  /**
   * 跳转位置
   */
  jumpPosition: JumpPosition // 非必须
  /**
   * 最大展示金额
   */
  maxValue: string // 非必须
  /**
   * 最小展示金额
   */
  minValue: string // 非必须
  /**
   * 金额数值样式   0:自定义  1:样式1  2:样式2   3:样式3
   */
  numericStyle: number // 非必须
  /**
   * 展示位置 格式:json数组字符
   */
  showPosition: string // 非必须
  /**
   * 金额展示状态   0:不显示 1:显示
   */
  showStatus: number // 非必须
  /**
   * 展现形式  1:独立模块 2:展示平台上 3:轮播
   */
  showType: ShowType // 非必须
  /**
   * 服务器时间戳
   */
  timestamp: number // 非必须
}

/**
 * 自定义样式
 */
export interface FontTypeface {
  fontFamily: string
  fontSize: string
  textColor: string
  textStroke: string
  strokeColor: string
}

/**
 * 展示位置配置
 */
export interface ShowPosition {
  name: string
  type: ShowPositionType
  /** 属性值 游戏类型id */
  value: string
  /** 附属值 活动/任务id 模板id 游戏平台id */
  value2: string
}

/**
 * 展示位置
 * 格式:json字符
 * 10:跑马灯上方,
 * 20:Banner上方,
 * 30:中部菜单上方,
 * 40:热门上方
 * 50:游戏类型上方,
 * 60:页脚上方,
 * 70:平台类型
 *  */
export enum ShowPositionType {
  /** 跑马灯上方 */
  Marquee = 10,
  /** Banner上方 */
  Banner = 20,
  /** 中部菜单上方 */
  MiddlMenu = 30,
  /** 热门上方 */
  Popular = 40,
  /** 游戏类型上方 */
  PlatformTop = 50,
  /** 页脚上方 */
  Footer = 60,
  /** 平台类型 */
  PlatformCard = 70
}

/**
 * 跳转位置配置
 */
export interface JumpPosition {
  name: string
  type: JumpPositionType
  /** 属性值 游戏类型id */
  value: string
  /** 附属值 活动/任务id 模板id 游戏平台id */
  value2: string
}

/** 展现形式 1:独立模块 2:展示平台上 3:轮播 */
export enum ShowType {
  /** 独立模块 */
  Independent = 1,
  /** 展示平台上 */
  onPlatform = 2,
  /** 轮播 */
  Carousel = 3
}

export interface CalculatePolling {
  /**
   * 虚拟彩金池ID
   */
  id: number // 非必须
  /**
   * 彩金池数字集合
   */
  virtualPoolNumbers: string[] // 非必须
  /**
   * 彩金池数字生成时间戳
   */
  virtualPoolTimestamp: number // 非必须
  /**
   * 服务器时间戳
   */
  timestamp: number // 非必须
  /** 展示位置 */
  showType: ShowType
}

export interface PrizePoolWinnnerList {
  prizeTime: string
  userId: string
  nickname: string
  gameName: string
  prizeAmount: number
  gameType: number
}

export interface PrizePoolWinnnerListResponse {
  prizeRecordToFrontRecords: Array<PrizePoolWinnnerList>
}

/**
 * 跳转位置配置
 */
export interface JumpPosition {
  name: string
  type: JumpPositionType
  /** 属性值 游戏类型id */
  value: string
  /** 附属值 活动/任务id 模板id 游戏平台id */
  value2: string
}

/**
 * 跳转位置
 * 格式:json字符
 * 10:平台类型
 * 20:活动
 * 30:任务
 * 40:外部链接
 */
export enum JumpPositionType {
  Platform = 10,
  Event = 20,
  Task = 30,
  OtherUrl = 40
}

/** 跳转任务映射 */
export enum JackPotJumpEvent {
  /** 新人彩金 */
  NewcomerBenefits = 1,
  /** 每日任务 */
  DailyTasks,
  /** 每周任务 */
  WeeklyTasks,
  /** 神秘任务 */
  MysteriousTasks
}
