import {
  Modal as AntModal,
  Icon,
  message
} from '@/plugins/ant-design-vue/tsx-support'
import { ConditionalKeys, Merge } from 'type-fest'
import { GlobalManager } from '@/context'
import { MessageOptions } from 'ant-design-vue/types/message'
import { ModalConfirm } from 'ant-design-vue/types/modal'
import { VueTsx } from '@/vue-property-decorator'
import { merge, omit } from 'lodash'
import { raf } from '@/utils/Tool'
import { routerNProgressController } from '@/router/guard'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import GlobalModal, { GetModalNameToOpen } from '@/views/global-modal'
import ModalBase from '@/mixins/business/modal/modal-base'
/**
 * Modal在 my-img 全局注册前有引用关系，故此处需要显示引入组件
 * 直接调用<my-img/> 无效
 */
import { createSvgSprite } from '@/utils/business-utils/assets'
import IconSprite from '@/components/icon-sprite'
import MyImg from '@/components/img'
import VueUtils from '@/utils/VueUtils'
import type { ModalOptions } from 'ant-design-vue/types/modal'

/**
 *  Modal.info
    Modal.success
    Modal.error
    Modal.warning
    Modal.confirm
 */

type MyModalOptions = Merge<
  ModalOptions,
  {
    type?: 'info' | 'success' | 'error' | 'warning' | 'confirm'
    // /** 默认、成功、重要、三种类型对应美术设计 */
    titleType?: 'info' | 'success' | 'error'
    titlePosition?: 'left' | 'center' | 'right'
    contentPosition?: 'left' | 'center' | 'right'
    withOutAnimation?: boolean
    transitionName?: 'none' | string //默认弹层的动画名 传给 内部 transition 组件的 name
    maskTransitionName?: 'none' | string //默认弹层遮罩的动画名
    icon?: RenderContent
    title?: RenderContent
    content?: RenderContent
    hiddenBtns?: boolean
    destroyOnClose?: boolean
    confirmLoading?: boolean
    getContainer?: () => HTMLElement
  }
>

type MyMessageOptions = Merge<
  MessageOptions,
  {
    type?: MessageOptions['type']
    content?: RenderContent
    contentPosition?: 'left' | 'center' | 'right'
    onAutoClose?: () => void // 关闭回调，组件内置的onClose调用不生效
  }
>

/**
 * 提取GlobalModal中 继承 ModalBase 的弹层REF 的key
 */

export type ModalName = Exclude<
  ConditionalKeys<GlobalModal, ModalBase>,
  '$ssrContext'
>
/**
 * 全局modal管理类
 */
export default class Modal extends VueUtils {
  private static get globalModalRef(): GlobalModal {
    return this.getChilds(
      (context) => context.$options.name === 'GlobalModal',
      GlobalManager.App
    )[0] as GlobalModal
  }

  /** 当前打开的弹框名称集合 */
  public static openedModalName = new Set<ModalName>()

  /**
   * 打开弹框的函数
   * @param modalName 弹框的名称
   * @param rest 其它参数
   * @returns {Promise<ModalBase>} 重点:返回值是当前打开弹框的组件环境
   */
  public static open<Name extends keyof GetModalNameToOpen>(
    modalName: Name,
    ...options: Parameters<GetModalNameToOpen[Name]>
  ): Promise<ModalBase>
  public static open(
    modalName: ModalName,
    ...rest: Parameters<GlobalModal[ModalName]['open']>
  ): Promise<ModalBase> {
    const key = `modal-${modalName}`
    return new Promise((reslove) => {
      const done = () => {
        const context = this.globalModalRef[modalName] as ModalBase
        context.open(...rest)
        this.globalModalRef.status[modalName] = 'loaded'
        // 将当前打开的modalName缓存.
        this.openedModalName.add(modalName)

        // 返回值仍是一个ModalBase的环境
        reslove(context)
      }
      if (this.globalModalRef.status[modalName] !== 'loaded') {
        this.globalModalRef.status[modalName] = 'loading'
        const step = () => {
          const context = this.globalModalRef[modalName] as ModalBase
          // 如果当前组件还未加载,则在下一个刷新帧重新尝试开启
          if (!context) {
            raf(step)
          } else {
            routerNProgressController.done(key)
            done()
          }
        }
        step()
        routerNProgressController.start(key)
      } else {
        done()
      }
    })
  }

  public static removeModalName(modalName: ModalName) {
    this.openedModalName.delete(modalName)
  }

  public static hasOpeningModal() {
    return this.openedModalName.size > 0
  }

  /**
   * 判断某个弹窗是否打开状态
   */
  public static isOpening(modalName: ModalName) {
    return this.openedModalName.has(modalName)
  }

  /**
   * 关闭弹框,如果未指定弹框名,则关闭最近打开的弹窗
   * @param modalName  要关闭的弹框名
   * @param closeEmitOptions 关闭弹框的closeEmit事件的回调参数
   */
  public static close(modalName?: ModalName, ...closeEmitOptions: unknown[]) {
    if (!modalName) {
      modalName = Array.from(this.openedModalName).pop()
    }
    if (!modalName) {
      return
    }
    // 关闭弹框,清除当前打开的名字记录
    this.globalModalRef[modalName]?.close(...closeEmitOptions)
    this.removeModalName(modalName)
  }
  /**
   * 创建一个函数方式的弹窗，默认关闭后会销毁实例及dom
   * 将 ant-design 中 Modal.info|Modal.success|Modal.error|Modal.warning|Modal.confirm 融合成一个create方法
   * 原版 开启时有动画，关闭时无动画 ，有点奇怪 
   * 该方法封装后 可通过 withOutAnimation 参数控制动画 position参数控制标题内容对齐方式等
   * @link https://1x.antdv.com/components/modal-cn/#API
   * @example  const modal = GlobalManager.Modal.create({
                type:'confirm',
                titlePosition:'left',
                contentPosition:'left',
                title:()=> '温馨提示',
                content:()=> '确认？',
                withOutAnimation:true // 控制开启和关闭时默认是否有动画 为 true 时关闭动画
              })
              //无动画
              modal.destroy(false)
              //有动画
              modal.destroy()
   */
  public static create(
    options: MyModalOptions
  ): Merge<ModalConfirm, { destroy: (...args: unknown[]) => Promise<void> }> {
    const type = options.type ?? 'confirm'
    const hiddenBtnsClassName = options.hiddenBtns ? 'hidden-btns' : ''
    const className = [
      'my-modal-base',
      hiddenBtnsClassName,
      ...(options.class || '')?.split(' ')
    ]
    const { isWeb } = useMainStore()
    /**
     * 当不传type时图标设置为空
     */
    const icon = options.icon ?? options.type ? undefined : () => <i></i>
    const withOutAnimation = options.withOutAnimation
    const parentContext = this.globalModalRef || GlobalManager.App
    const titleType = options?.titleType ?? 'info'
    const modal = AntModal[type](
      omit(
        merge<MyModalOptions, MyModalOptions, MyModalOptions>(
          {
            centered: true,
            width:
              options.width ||
              (isWeb ? BusinessUtils.px2rem(550) : BusinessUtils.px2rem(600)),
            icon,
            parentContext,
            cancelButtonProps: {
              props: {
                type: 'default'
              }
            },
            okButtonProps: {
              props: {
                type: 'primary',
                loading: options?.confirmLoading
              }
            },
            title: () => {
              const title =
                options.title instanceof Function
                  ? options.title()
                  : options.title
              return (
                <div
                  class={`ant-modal-custom-title__${titleType}`}
                  style={{ textAlign: options.titlePosition || 'center' }}
                >
                  {titleType === 'error' && (
                    <Icon.Tsx
                      component={{
                        functional: true,
                        render() {
                          return (
                            <IconSprite
                              sprite={createSvgSprite('comm_icon_zyts')}
                            />
                          )
                        }
                      }}
                    />
                  )}
                  {title}
                </div>
              )
            },
            content: () => {
              const content =
                options.content instanceof Function
                  ? options.content()
                  : options.content
              return (
                <div style={{ textAlign: options.contentPosition || 'center' }}>
                  {titleType === 'error' ? (
                    <span class={`ant-modal-custom-content__error`}>
                      {content}
                    </span>
                  ) : (
                    content
                  )}
                  {options.closable === true && !isWeb ? (
                    <div
                      class={'closeIcon'}
                      onClick={VueTsx.modifiers.stop(() => {
                        modal.destroy()
                      })}
                    >
                      <MyImg
                        src={
                          '/lobby_asset/common/common/common/img_close_s2.png'
                        }
                      />
                    </div>
                  ) : null}
                </div>
              )
            }
          },
          omit(options, ['title', 'content']),
          {
            ...(withOutAnimation
              ? {
                  transitionName: 'none',
                  maskTransitionName: 'none'
                }
              : {}),
            class: className.filter(Boolean).join(' '),
            closable: isWeb ? options.closable : false
          }
        ),
        ['type', 'titlePosition']
      ) as ModalOptions
    )
    /**
     * 拷贝原版
     */
    const destroy = modal.destroy as (...args: unknown[]) => void
    /**
     * 原版函数式调用关闭时无动画
     * hack 为默认为有动画销毁
     */
    let animationDone!: (value: unknown) => void
    /**
     * 将关闭方法重写为异步，先等动画完成再销毁
     */
    const close = async (...rest: unknown[]) => {
      modal.update({
        visible: false
      })
      await new Promise((reslove) => {
        animationDone = reslove
      })
      destroy(...rest)
    }
    modal.update({
      close,
      afterClose: () => {
        options?.afterClose?.()
        animationDone('done')
      }
    })
    return merge(modal, {
      destroy: close
    })
  }
  /**
   * 相同的错误提示语不要一直弹，放个变量记录一下
   * */
  public static lastTipsText: RenderContent | undefined = null
  public static message(options: MyMessageOptions) {
    /**
     * 优先形参
     * 有图标的情况（有icon或者有type）为默认靠左
     * 无图标的情况居中对齐
     */
    console.log(GlobalManager.Modal.lastTipsText, options.content)
    const content =
      options.content instanceof Function ? options.content() : options.content
    // 没有提示语或者相同提示语不要弹
    if (!content || GlobalManager.Modal.lastTipsText == content) return
    GlobalManager.Modal.lastTipsText = content

    const contentPosition =
      options.contentPosition ?? (options.type || options.icon)
        ? 'left'
        : 'center'
    const defaultDuration = 2
    const opt = omit(
      merge<MyMessageOptions, MyMessageOptions>(
        {
          duration: defaultDuration,
          content: () => {
            return <div style={{ textAlign: contentPosition }}>{content}</div>
          }
        },
        omit(options, ['content'])
      ),
      ['contentPosition', 'onAutoClose']
    ) as MessageOptions
    const messageInstance = message.open(opt)
    setTimeout(() => {
      // 所有提示消息及时在hover的时候也需要在2秒内自动关闭
      message.destroy.call(message)
      GlobalManager.Modal.lastTipsText = null
      options.onAutoClose?.()
    }, (opt.duration as number) * 1000)
    return merge(messageInstance, {
      destroy: message.destroy.bind(message)
    })
  }

  /**
   * @打开登录注册一体化的弹窗
   * @登录
   */
  public static openLoginRegisterModal(
    options?: Parameters<Awaited<GlobalModal['loginRegisterModal']['open']>>[0]
  ) {
    Modal.open('loginRegisterModal', options)
  }
}
