import { manager as Apng } from '@/components/apng-canvas'
import Buried from '@/controller/Buried'
import FuncRender from '@/components/business-components/func-render'
import LongConnection from '@/controller/LongConnection'
import Media from '@/controller/Media'
import Modal from '@/controller/Modal'
import Page from '@/controller/Page'
import Polling from '@/controller/Polling'
import Rules from '@/controller/Rules'
import Site from '@/controller/Site'
import User from '@/controller/User'
import locale from '@/i18n'
import type Vue from 'vue'

export default class Manager {
  /**
   * vue 根实例
   */
  public static App: Vue
  /**
   * 国际化管理
   */
  public static readonly I18n = locale
  /**
   * 全局弹层管理
   */
  public static readonly Modal = Modal
  /**
   * 和系统、站点有关的一些流程 主要是初始化的时候必要的一些数据请求
   * 获取站点服务器时间，本地时间等
   */
  public static readonly Site = Site
  /**
   * 统一的表单校验规则
   */
  public static readonly Rules = Rules
  /**
   * 埋点相关事件
   */
  public static readonly Buried = Buried
  /**
   * 用户相关的流程，各种登录注册行为验证等
   */
  public static readonly User = User
  /**
   * 全站轮询管理中心，特殊业务场景统一控制
   */
  public static readonly Polling = Polling
  /**
   * 媒体查询,响应式布局的断点
   */
  public static readonly Media = Media
  /**
   * 全站Apng动画的管理，均用它进行创建，控制播放等
   */
  public static readonly Apng = Apng
  /**
   * 页面管理，跳转到三方链接，如跳转客服
   */
  public static readonly Page = Page
  /**
   * 可复用的函数方式渲染节点
   */
  public static readonly FuncRender = FuncRender
  /**
   * WebSocket 统一管理
   */
  public static readonly WS = LongConnection
}

/**
 * 开发模式将 Manager 挂载到 window 上
 */
process.env.NODE_ENV === 'development' &&
  ((window as unknown as { LobbyManager: Manager }).LobbyManager = Manager)
