import { Base, Component, Emit, Mixins, Prop } from '@/vue-property-decorator'
import { assetsPath } from '@/utils/business-utils/assets'
import { createOssAssetsPathOrigialRetry } from '@/utils/business-utils/retry'
import { v4 } from 'uuid'
import ApngCanvasClass, { Item as APNGItem } from '@/utils/ApngCanvas'
import AppTypes from '@/vue-types'
import OssAssets from '@/mixins/business/ossAssets'
import UnitMixins, { Props as UnitMixinsProps } from '@/mixins/unit'
export const manager = new ApngCanvasClass()
export interface Props extends UnitMixinsProps {
  src: string
  scale?: number
  width?: number
  height?: number
  numPlays?: number
  autoPlay?: boolean
}

export type Events = {
  onEnd?: () => void
}

@Component<ApngCanvas>({
  name: 'ApngCanvas'
})
export default class ApngCanvas extends Mixins<
  Base<unknown, Props, Events>,
  UnitMixins,
  OssAssets
>(Base, UnitMixins, OssAssets) {
  @Prop(AppTypes.string.isRequired)
  private readonly src!: Props['src']
  @Prop(AppTypes.number)
  private readonly scale!: Props['scale']
  @Prop(AppTypes.number)
  private readonly width!: Props['width']
  @Prop(AppTypes.number)
  private readonly height!: Props['height']

  @Prop(AppTypes.looseBool.def(true))
  private readonly autoPlay!: NonNullable<Props['autoPlay']>

  /**
   * 0 为无限次数
   */
  @Prop(AppTypes.number.def(0))
  private readonly numPlays!: NonNullable<Props['numPlays']>

  private apngItem: APNGItem | null = null

  private uuid!: string

  private loading = false

  private get InnerSrc() {
    return assetsPath(this.src)
  }

  public play() {
    if (this.apngItem) {
      const { player } = this.apngItem
      /**
       * 如果时非暂停的先stop，下一帧再播放，避免播放加速
       */
      if (!player.paused) {
        player.stop()
        return requestAnimationFrame(() => {
          player.play()
        })
      }
      player.play()
    }
  }
  public stop() {
    if (this.apngItem) {
      this.apngItem.player.stop()
    }
  }
  public pause() {
    if (this.apngItem) {
      this.apngItem.player.pause()
    }
  }

  public destroy() {
    manager.destroy(this.uuid)
  }

  @Emit('end')
  private onEnd() {
    return
  }

  /**
   * 需要检测的目标资源路径
   */
  protected get ossTargetSrc() {
    return this.InnerSrc || ''
  }

  private async init() {
    this.uuid = v4()
    this.loading = true

    const apngItem = await createOssAssetsPathOrigialRetry((origin) =>
      manager.create(
        this.InnerSrc.replace(this.ossLobbyAssetPathOrigin || '', origin),
        {
          autoPlay: this.autoPlay,
          width: this.width,
          height: this.height,
          scale: this.scale,
          numPlays: this.numPlays
        },
        this.uuid
      )
    ).finally(() => {
      this.loading = false
    })

    if (apngItem) {
      this.apngItem = apngItem
      this.apngItem.player.on('end', this.onEnd)
    } else {
      throw new Error('init error')
    }
    return this.apngItem
  }

  mounted() {
    this.init().then(() => {
      if (!this.apngItem) return
      this.apngItem.canvas.style.width = '100%'
      this.apngItem.canvas.style.height = '100%'
      this.$el.appendChild(this.apngItem.canvas)
    })
  }

  beforeDestroy() {
    this.destroy()
  }

  render() {
    const { width, height } = this || {}
    return (
      <span
        {...{
          attrs: {
            'aria-hidden': 'true',
            focusable: 'false'
          },
          style: {
            display: 'inline-block',
            ...(width
              ? {
                  width: `${width / this.baseUnit}${this.unit}`
                }
              : {}),
            ...(height
              ? {
                  height: `${height / this.baseUnit}${this.unit}`
                }
              : {})
          }
        }}
      >
        {this.loading ? this.$slots.loading : null}
      </span>
    )
  }
}
