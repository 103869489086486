/** 版式 0 无底色 1 有底色, 2欧美版式, 3青蓝版 */
export enum mainSkinStyle {
  DEFAULT = 0, // 无底色普通版式
  DEFAULT_HASCOLOR = 1, // 有底色普通版式
  EUROPEAN_AMERICAN = 2, // 欧美风版式
  CYANBLUE = 3, // 青蓝版式
  STAKE = 4, // stake版式
  CROWN_GOLD = 5, // 128 皇冠金定制版
  GALAXY_GOLD = 6, // 126银河金定制版
  DQ = 7 // DQ定制版
}
