/**
 * 此组件不可引入@/store
 * 因为其过早的引入
 * 会导致 src\plugins\ant-design-vue\tsx-support 在 src\plugins\ant-design-vue\index.ts 之前调用 导致所有ant 重写失效
 */
import { Base, Component, Prop } from '@/vue-property-decorator'
import { ScreenSize } from '@/store/index/type'
import AppTypes from '@/vue-types'

type Props = {
  width?: number
  height?: number
  src?: string
}

/**
 * 游戏
 */
@Component
class EmptyImageGame extends Base<unknown, Props> {
  @Prop(AppTypes.number.def(210))
  width?: Props['width']

  @Prop(AppTypes.number.def(210))
  height?: Props['height']

  @Prop(AppTypes.number.def(210))
  mobileWidth?: Props['width']

  @Prop(AppTypes.number.def(210))
  mobileHeight?: Props['height']

  @Prop(AppTypes.string.def(''))
  src?: Props['src']

  render() {
    const isWeb = this.$pinia.state.value.app.screenSize !== ScreenSize.S
    const width = isWeb ? this.width : this.mobileWidth
    // const height = isWeb ? this.height : this.mobileHeight
    const skinId = this.$pinia.state.value.app.skinId
    const imgSrc = this.src
      ? this.src
      : ['1-1-5'].includes(skinId)
      ? '/lobby_asset/{layout}-{bg}-{skin}/common/common/img_none_sj.png'
      : '/lobby_asset/{layout}-{bg}-common/common/common/img_none_sj.png'
    return <my-img class={'empty-image-game'} src={imgSrc} width={width} />
  }
}
/**
 * 报表
 */
@Component
class EmptyImageReport extends Base {
  @Prop(AppTypes.number.def(210))
  width?: Props['width']

  @Prop(AppTypes.number.def(210))
  height?: Props['height']

  @Prop(AppTypes.number.def(210))
  mobileWidth?: Props['width']

  @Prop(AppTypes.number.def(210))
  mobileHeight?: Props['height']

  render() {
    const isWeb = this.$pinia.state.value.app.screenSize !== ScreenSize.S
    const width = isWeb ? this.width : this.mobileWidth
    const height = isWeb ? this.height : this.mobileHeight
    const skinId = this.$pinia.state.value.app.skinId

    const imgSrc = ['1-1-5'].includes(skinId)
      ? '/lobby_asset/{layout}-{bg}-{skin}/common/common/img_none_jl.png'
      : '/lobby_asset/{layout}-{bg}-common/common/common/img_none_jl.png'
    return (
      <my-img
        class={'empty-image-report'}
        src={imgSrc}
        width={width}
        height={height}
      />
    )
  }
}

export { EmptyImageGame, EmptyImageReport }
