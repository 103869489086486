import { AccountRootTypeEnum } from '@/views/finance/withdraw/store/type'
import { CurrencyEnum } from '../common/type'
import { RootCategoryEnum } from '@/views/global-modal/modal/pay/store/type'
import { SmartPayScene } from '@/views/global-modal/modal/smart-pay/types'
/**
 * 充值相关接口
 */

/* --------------- pay/payListV3 ------------------ */

export interface GetFinancePayListData {
  list: FinancePayListDataWrapper[]
  sign_key: string
  mobileVerify?: string
  emailVerify?: string
}

export interface GetFinancePayTypeData {
  payKind: FinancePayListDataWrapper
  sign_key: string
  mobileVerify?: string
  emailVerify?: string
}
export interface QianNengDetalData {
  success: boolean
  msg?: string
  code?: number
  data: {
    errcode: number
    errmsg?: string
    data?: qianNengData
  }
}

// 钱能钱包账户余额和跳转链接信息
export type qianNengData = {
  wap_url: string // 带token可直接访问的WAP网址
  balance: string // 钱能用户的余额，可能有3位小数
  pc_url: string // 带token可直接访问的PC网址
}

export interface BobiDetailData {
  success: boolean
  msg?: string
  code?: number
  data: bobiData
}
export type bobiData = {
  code: number
  msg?: string
  balance?: string
  user_adress?: string
}

export type bobiLoginData = {
  code: number
  msg: string
  login_url: string
}

export interface FinancePayListDataWrapper {
  /**
   * 充值分类名称
   */
  name: string
  /**
   * 图标
   */
  icon: string
  /**
   * 充值分类(1-在线充值，2-转账，3-虚拟货币，4-人工充值)
   */
  type: number
  /**
   * 最大优惠赠送(百分比)
   */
  charge_rate: number
  /**
   * 列表
   */
  list: FinanceListData[]
  /**
   * 排序值
   */
  weight: number
  /**
   * 当前充值大类下的币种列表
   */
  payCurrency: string[]
}

export interface FinanceListData {
  /**
   * 充值平台唯一id
   */
  payplatformid: number
  /**
   * 显示图标类型
   */
  payicon: string
  /**
   * unknown
   */
  pay_prize: number
  /**
   * 支付方式
   */
  paymentid: number
  /**
   * 支付方式名称
   */
  payname: string
  /**
   * 支持客户端类型
   */
  app_type: number[]
  /**
   *  优惠赠送(百分比)
   */
  chargeRateConfig: {
    chargeRate: string
    text: string
  }
  /**
   * 增加自定义充值角标
   */
  customTag: string
  /**
   * 是否已合并通道
   */
  merge_status: number
  /**
   * 前端显示名称
   */
  pay_type_name: string
  /**
   * unknown
   */
  min_per_money: number
  /**
   * unknown
   */
  max_per_money: number
  /**
   * 最小充值限额（仅转账）
   */
  min_recharge_limit: number
  /**
   * 最大充值限额（仅转账）
   */
  max_recharge_limit: number
  /**
   *
   * 排序值
   */
  weight: number
  /**
   * 支付类型
   */
  payment_type: string
  /**
   * 图标链接
   */
  iconUrl: string
  payKind: number
}

/**
 * 第三方链接打开方式： 1:新开页签 2:内嵌打开
 */
export enum SubmitLinkOpenWay {
  NewWindow = 1,
  Inner = 2,
  QRCode = 4,
  NewWindow2 = 5 // 后端返回跳转链接
}

/* --------------- pay/payplatformlistV3 ------------------ */
// 平台支付通道

export interface FinanceGetPayPlatformListPayload {
  payplatformid: number
  payCurrency: string
  payKind: number
}

interface FinancePayPlatformCommonData {
  realNameSwitch: number

  // 付款人地址开关 0-关闭 1-开启 2 强制校验
  payAddressSwitch: number
  // 验证码开关        0-关闭 1-开启
  captchaSwitch: number
  // 电话验证开关 0-关闭 1-开启 2 强制校验
  mobileSupport: number
  // 邮箱验证开关 0-关闭 1-开启 2 强制校验
  emailSupport: number
  // 充值优惠配置
  chargeRateConfig?: {
    chargeRate: string
    text: string
  }
  // 自定义通道角标
  channelTooltip?: string
  // 钱包优惠配置
  walletConfig?: {
    chargeRate: string
    text: string
    posterTxt: string // No钱包宣传语
    posterNum: string // No钱包宣传语数字
    downloadUrl?: string
  }
  /**
   * 	推荐金额
   */
  moneybtns: string[]
  money_btns: string[] //任务 94308 充值加赠功能废弃
  recommendList: Array<{
    amount: string //金额
    chargeRate: string // 充值赠送的赠送比例
    bonus: string // 推荐金额的叠加赠送 金额或比例
  }> // 任务 94308 充值加赠功能增加
}

export type FinanceGetAccountInfoPayload = {
  siteCode: string
  merchId: string
  userId: string
  time: number
  sign?: string
  deviceType?: string
}

export type QianNengMsgCodePayload = {
  siteCode: string
  merchId: string
  userId: string
  phoneNumber: string
  time: number
  sign?: string
}

export type BindQingNengPayload = {
  siteCode: string
  merchId: string
  userId: string
  realName: string
  phoneNumber: string
  phoneCode: string
  time: number
  sign?: string
}
export type FinanceGetNoUserInfoPayload = {
  siteCode: string
  merchantId: string
  merchantMemberNo: string
  time: number
  sign?: string
}

export type FinanceGetUPAYUserInfoPayload = {
  siteCode: string
  merchId: string
  userId: string
  time: number
  sign?: string
}

export interface GetFinanceMemberBalanceData {
  bind: number // 会员是否已绑定 1已绑定 2未绑定
  bindUrl: string
  buyUrl: string
  rmbBalance: string
  moveBalance: string
  usdtBalance: string
}

export interface FinanceGetPayPlatformListData
  extends FinancePayPlatformCommonData {
  /**
   * 充值类型 1-在线充值 2-转账 4-客服
   */
  type: number
  /**
   * 名称
   */
  name: string
  /**
   * 	1-在线充值 2-离线充值
   */
  payline_type: number
  /**
   * 充值方式id
   */
  paymentid: number
  /**
   * 充值方式名称
   */
  payment_name: string
  /**
   * 充值跳转地址
   */
  url: string
  /**
   * 是否已合并，如果已经合并list字段就没有用了，按照这层对象的描述进行显示。支付时发送给后台的字段变成payment_ids
   */
  merge_status: number
  /**
   * 显示通道名称
   */
  pay_type_name: string
  /**
   *支持的充值通道id
   */
  payment_ids: string
  /**
   * 备注
   */
  remark: string
  /**
   * 最低限额
   */
  min: number
  /**
   * 最高限额
   */
  max: number
  /**
   * 最低限额
   */
  min_recharge_limit: number
  /**
   *  最高限额
   */
  max_recharge_limit: number
  /**
   * 是否固定金额
   */
  balance_switch: number

  /**
   * 是否特定三方
   */
  specialCPFSwitch: number
  /**
   * 充值子通道
   */
  list: Array<
    | OnlinePayData
    | TransferPayData
    | CustomerServicePayData
    | SilverMerchantData
  >

  /** 通道二维码过期时间 */
  orderEffectiveTime?: number

  /** 合并东涛 */
  combineOpenWay?: SubmitLinkOpenWay
}

export interface OnlinePayData extends FinancePayPlatformCommonData {
  channel_type: string
  channelCode: string // 通道编码
  remark: string
  subtitle: string
  title: string
  payplatformid: number
  min_recharge_limit: number
  max_recharge_limit: number
  merch_agent_id: number // 钱包支付商户Id
  merch_desc: string
  app_type: number
  balance_switch: number
  paymentid: number
  payment_type: string
  payicon: string
  weight: number
  currencySign: string
  money_ranges: Array<{
    amount_name: string
    amount_type: number
    between: string
    btns?: FinanceGetWalletPayRecommendMoneyListData['money_btns']
  }>

  payCurrency: string
  // 3.1需求,后台配置下载地址
  downloadUrl?: {
    url: string
    text: string
  }
  // 3.2需求
  feeRate: string //费率
  operators: string[] // 运营商
  merchCode: string // 网银使用三方商户银行编码

  /**
   * 第三方链接打开方式
   */
  openWay?: SubmitLinkOpenWay
}

export type qianNengFormData = {
  realName: string
  phoneNumber: string
  phoneCode: string
}

export interface TransferPayData extends FinancePayPlatformCommonData {
  bank_code: string
  bank_name: string
  bankDisplayName: string
  bank_no: string
  bank_user: string
  qrcode_url: string
  id: number
  paymentid: number
  min_recharge_limit: number
  max_recharge_limit: number
  app_type: number
  payment_type: string
  payicon: string
  remark: string
  balance_switch: number
  icon_url: string
  payCurrency: string
  specialCPFSwitch: number
}

export interface CustomerServicePayData {
  avatar_url: string
  day_charge_money: number
  flag: string
  id: number
  nickname: string
  paymentid: number
  name: string
  merch_desc: string
  app_type: number
  charge_rate: number
  online: {
    url: string
    type: string
  }
  payment_type: string
  payicon: string
  weight: number
  remark: string
  money_btns?: string[]
  balance_switch: number
  min_recharge_limit: number
  max_recharge_limit: number
}
export interface SilverMerchantData {
  app_type?: null
  agentName: string
  avatarUrl: string
  propagationLanguage: string
  giftingMemberRatio: number
  customerServiceLink: string
}

/* --------------- /pay/orderListV3 ------------------ */
// 获取充值订单列表

export interface FinanceGetPayOrderListPayload {
  /**
   * 从1开始
   */
  page: number
  /**
   * 时间类型 (0-今天，1-昨天，7-近7日，15-近15日，30-近30日）
   */
  time_type?: number
  size: number
  startTime?: number
  endTime?: number
}

export interface FinanceGetPayOrderListData {
  total_amount: number
  list: FinanceGetPayOrderDetailsData[]
  total: number
}

/* --------------- pay/orderInfo ------------------ */

export interface FinanceGetPayOrderDetailsPayload {
  order_no: string
}

export interface FinanceGetPayOrderDetailsData {
  order_no: string
  order_type: number
  pay_kind: number
  order_type_name: string
  payment_id: number
  payment_name: string
  amount: string
  status: number
  status_name: string
  createtime: number
  payedtime: number
  bank_user: string
  bank_no: string
  bank_name: string
  bank_code: string
  bank_addr: string
  qrcode_url: string
  payment_type: string
  walletName: string
  walletLogo: string
  walletDomain: string
  payicon: string
  wallet_url: string
  icon_url: string
  orderName: string
  extendValue?: string
  extendName?: string
  channelAmount: string // 充值通道金额
  currencyRate: string // 充值币种汇率拼接好的字符串
  memberCurrency: string // 会员币种
  payCurrency: string // 充值通道币种
  exchangeRate: number //汇率
  cryptoProtocol: string
  realNameSwitch: number // 真实姓名开关
  specialCPFSwitch: number // 特定三方开关
  utrSwitch: number // utr开关
  payOrderCountdown: number // 转账倒计时
  payOrderTimeOut?: number // 到期时间
  postscript: string // 附言
  cardNumber?: string // 卡号
  cardPassword: string // 序列号
  networkMerch: string // 运营商
  feeRate: string // 费率
  fee: string // 手续费
  reduceFee: string // 减免
  realFee: string // 实际手续费
  transferCertificateSwitch: number // 转账凭证开关 0关 1选填 2必传
  transferCertificate: ''
  remarkSwitch: number // 备注 0关 1选填 2必传
  frontRemark?: string
  contactUrl?: string // 银商客服链接
  templateType?: CurrencyEnum // 订单币种类型
}

/* --------------- home/maxChargeRate ------------------ */
// 获取充值最大优惠金额
export interface FinanceGetPayMaxChargeData {
  charge_rate: number
}

/* --------------- pay/transferConfirm ------------------ */
// 转账充值确认
export interface FinanceTransferPayConfirmPayload {
  /**
   * // 订单号
   */
  order_no: string
  /**
   * 付款人姓名
   */
  name: string
  /**
   * utr 后四位
   */
  utrCode?: string
  /**
   * 转账凭证
   */
  transferCertificate?: string

  /**
   * 转账备注
   */
  payRemark: string
  /**
   * 用户备注
   */
  userRemark: string
}

/* --------------- pay/walletMoneyRanges ------------------ */
// 钱包充值推荐金额列表
export interface FinanceGetPayMaxChargeData {
  charge_rate: number
}

/* --------------- pay/walletMoneyRanges ------------------ */
// 钱包充值推荐金额列表
export interface FinanceGetWalletPayRecommendMoneyListPayload {
  /**
   * 充值通道id，对应payplatformid
   */
  payplatformid: number
  /**
   * 获取区间时传0，点击充值区间按钮时传对应类型
   */
  amount_type: number
}

export interface FinanceGetWalletPayRecommendMoneyListData {
  /**
   * 充值区间，amount_type传0时有该值
   */
  ranges: Array<{
    /**
     * 区间类型
     */
    amount_type: number
    /**
     * 区间名称
     */
    amount_name: string
    /**
     * 区间描述
     */
    between: string
  }>
  /**
   * 2-微信 3-支付宝 4-银行卡
   */
  pay_icon: string
  /**
   * 充值金额列表
   */
  money_btns: Array<{
    /**
     * 	充值方式（1 银行卡 2支付宝 3 微信 ）
     */
    payment_method: number
    /**
     * 订单号
     */
    order_no: string
    /**
     * 金额
     */
    amount: string
  }>
}

/* --------------- pay/offlineOrderV3 ------------------ */
// 线下充值(转账)
export interface FinancePayOfflinePayload {
  paymentid: number // 大类为合并通道时，paymentid为大类paymentid，否则为大类payplatformid
  name: string
  receive_id: number //大类合并时传0，否则为转账通道list里面的id
  money: string
  payCurrency: string // 充值通道币种（当选择上分金额模式时传空字符串）
  payAddress?: string
  utrCode?: string // utrCode
  mergeStatus: number // 转账大类合并时传1
  userRemark?: string // 用户备注
}

export interface FinancePayOfflineData {
  order_no: string
  order_type: number
  pay_kind: number
  order_type_name: string
  payment_id: number
  payment_name: string
  amount: number
  status: number
  status_name: string
  createtime: number
  payedtime: number
  bank_user: string
  bank_no: string
  bank_name: string
  bank_code: string
  bank_addr: string
  qrcode_url: string
  payment_type: string
  payicon: string
  wallet_url: string
  icon_url: string
  orderName: string
  payOrderCountdown: number // 转账倒计时
  postscript: string // 附言
  transferCertificateSwitch: number // 转账凭证开关 0关 1选填 2必传
}

/* --------------- /pay/getPayOrderFee ------------------ */

export interface FinancePayGetOrderFeeData {
  feeRate: string // 费率
  totalFee: string // 总手续费
  reduceFee: string // 减免手续费
  realFee: string // 实际手续费
}

/* --------------- /api/lobby/finance/getPayChooseBank ------------------ */

export interface FinanceGetOnlinePayBankData {
  bankName: string // 银行名称
  bankCode: string // 三方商户银行编码
  sysCode: string // 系统银行编码
  imgId: string // 图片logo id
}

/* --------------------------------------------------------------------------------------------------------------------------------- */

/**
 * 提现相关接口
 */

/* --------------- certify/withdrawRecord ------------------ */
// 获取提现记录列表

export interface FinanceGetWithdrawRecordPayload {
  page: number
  startTime?: number | string
  endTime?: number | string
  time?: number
  size?: number
}

export interface FinanceGetWithdrawRecordDataList {
  total: number
  totalCount: number
  records: FinanceGetWithdrawRecordData[]
}

export interface FinanceGetWithdrawRecordData {
  orderNo: string
  money: number
  createTime: number
  applyTime: number
  status: number
  statusText: string
  remark: string //取消原因或备注
  withdrawType: number
  withdrawTypeName: string
  cardNo: string
  cardBank: string
  orderType: string
  bankCode: string
  logo: string
  typeId: number // 提现大类id
  account: string // 提现账号(包括银行卡号、微信支付宝、提现地址)
  walletUrl: string //钱包跳转地址
  currencyRate: string // 提现汇率字符串(U1:￥7.09)
  currencyAmount: number // 提现币种金额
  currencyCode: string // 提现币种
  cryptoCurrency: boolean // 是否虚拟币
  cryptoProtocol: string // 虚拟币协议
  rejectReason: string //拒绝原因
}

/* --------------- certify/withdrawInfo ------------------ */
// 获取提现配置信息
export interface WithdrawAccountRuleItem {
  accountErrText: string
  accountMaxLength: number
  accountMinLength: number
  accountRegexp: RegExp
  accountSubType: number
  accountType: number
}

export interface VerifyWithdrawalPasswordData {
  key: string
  /**
   * 二步验证信息
   */
  twoStepVerify?: {
    verifyItem: VerifyItem
    bindItem: BindItem
  }
}

export interface FinanceGetWithdrawInfoData {
  withdrawTypes: WithdrawTypes[]
  accounts?: AccountInfo[] | null
  cpf: string
  withdrawMax: number // 默认第一个提现类型的最大限制金额
  withdrawMin: number // 默认第一个提现类型的最小限制金额
  withdrawTimes: number
  requireBet: number
  // tab页签排序顺序
  tabSort: Array<{
    tabName: string
    tabCode: string // No钱包优惠
    awardConfig?: {
      chargeRate: string
      text: string
    }
  }>
  isEnableChannel: boolean // 是否开启通道
  showWithdrawAccountSwitch: number // 是否查看完整提现账号开关
  withdrawAccountValidationRule: Array<WithdrawAccountRuleItem>
}

export interface WithdrawSettingV2Data {
  showWithdrawAccountSwitch: number // 是否查看完整提现账号开关
  withdrawMultiple: number // 提现倍数设置
  withdrawAccountValidationRule: Array<WithdrawAccountRuleItem> // 提现账号校验规则列表
  updatedAt: number // 数据更新时间
  bankInfo: FinanceGetWithdrawBankListData // 银行卡列表
}

export interface WithdrawInfoV2Data {
  requireBet: number // 提现必须打码量
  withdrawTimes: number // 当日已提现次数
  accounts?: AccountInfo[] | null // 账户信息列表
  cpf: string // 用户CPF
  withdrawClose: FinanceGetWithdrawMaintainInfo
  isEnableChannel: boolean // 是否开启通道
  withdrawMax: number // 最大金额限制
  withdrawMin: number // 最小金额限制
  withdrawTypes: WithdrawTypes[] // 提现方式列表
  // tab页签排序顺序
  tabSort: Array<{
    tabName: string
    tabCode: string // No钱包优惠
    awardConfig?: {
      chargeRate: string
      text: string
    }
  }>
  cryptoList: FinanceWithdrawQueryCryptoListData // 数字货币通道列表
}

export interface FinanceGetUserWalletInfoData {
  bind: number // 绑定状态，1-已绑定 2-未绑定 3-已解绑
  bindUrl: string // 绑定地址
  buyUrl: string // 挂单出售
  account: string // 已绑定No钱包账号
  posterTxt: string // No钱包宣传语
  posterNum: string // No钱包宣传语数字
  userAccount: string
  accountId: number
  accountType: number
  rmbBalance: number //钱包余额
}
export interface FinanceWalletSettingInfoData {
  settingUrl: string
}
export interface WithdrawTypes {
  typeId: number // 提现大类id
  typeName: string // 提现大类名称 ()
  addTypeName: string // 提现大类名称 (添加)
  withdrawMax: number // 最大金额限制
  withdrawMin: number // 最小金额限制
  cryptoCurrency: boolean // 是否数字货币
  currencyCodes: string[] // 提现大类支持币种列表
  logo: string // 后台配置图标
  withdrawMethod: WithdrawMethod[]
  subTypes: string[] // 子类型 (PIX使用)
}

export interface WithdrawMethod {
  typeId: number // 提现大类id
  /**
    1 - 银行卡
    2 - 支付宝
    3 - 微信
    4 - 支付宝扫码
    5 - 微信扫码
    6 - 虚拟币（usdt-trc20）
    7 - PIX
   */
  withdrawType: number // 提现通道类型id
  withdrawTypeName: string // 提现通道名称
  addWithdrawTypeName: string // 提现通道名称 (添加)
  withdrawMax: number // 提现最大金额限制
  withdrawMin: number // 提现最小金额限制
  cryptoCurrency: boolean // 是否数字货币
  currencyCode: string // 提现通道币种
  protocol: string // 数字货币协议
  bindEnabled: boolean // 是否允许绑定
  deleteEnabled: boolean // 是否允许删除
  normalWithdrawEnabled: boolean // 是否支持普通提现
  walletWithdrawEnabled: boolean // 是否支持No钱包提现
  accountLimit: number // 绑定账号数量限制
  subTypes?: Array<string>
  kindTips?: string //3.1温馨提示
  logo: string // 后台配置图标3.1
  bindAccounts: number
  requiredSelectBank?: boolean //开户支行是否必填
  addAccountKindTips: string // 添加账户的温馨提示
}

export interface AccountInfo {
  id: number // 账号id
  name: string // 会员名称
  logo: string // 图标
  bankName: string // 银行名称
  default: number // 是否默认
  account: string // 账号
  typeId: number // 提现大类id
  /**
    1 - 银行卡
    2 - 支付宝
    3 - 微信
    4 - 支付宝扫码
    5 - 微信扫码
    6 - 虚拟币（usdt-trc20）
    7 - PIX
   */
  accountType: number // 账号类型id 对应提现通道类型id
  withdrawTypeName: string // 提现通道名称
  code: string // 银行卡code
  currencyCode: string // 账号通道所属币种
  cryptoCurrency: boolean // 是否虚拟币
  channelName: string // 子通道类型(银行卡对应具体银行名称  pix对应具体子类型)
  qrCode: string //微信支付宝二维码
  selfCurrency: boolean // 当前币种是否可会员币种一致
  cpf?: string
  stop: number // 是否停用 0正常 1停用
  bankStop: number // 是否停用 0正常 1停用
}

/* --------------- /certify/auditRecordPageList ------------------ */
// 获取稽核记录列表

export interface FinanceGetWithdrawAuditRecordListData {
  total: number
  needAuditAmount: number
  data?: FinanceGetWithdrawAuditRecordData[]
}

export interface FinanceGetWithdrawAuditRecordData {
  id: number
  /**
   * 订单号
   */
  order_no: string
  /**
   * 账变金额
   */
  amount: number
  /**
   * 稽核倍数
   */
  audit_rate: number
  /**
   * 稽核金额
   */
  audit_amount: number
  /**
   * 已完成打码量
   */
  already_audit_amount: number
  /**
   * 还需要的打码量
   */
  need_audit_amount: number
  /**
   * 状态 1未开始，2进行中，3已完成，4系统解除，5人工解除
   */
  status: number
  /**
   * 状态名称
   */
  status_name: string
  /**
   * 账变类型名称
   */
  transfer_type_name: string
  /**
   * 账变时间戳
   */
  charge_timestamp: number
  /**
   * 有效平台名称
   */
  valid_platform_name: string
  /**
   * 有效游戏名称
   */
  valid_game_name: string
  /**
   * 活动名称
   */
  activeName?: string
  /**
   * 完成时间
   */
  finishTime: number
}

/* --------------- 验证提现密码 ------------------ */
export interface VerifyWithdrawalPasswordPayload {
  withdrawalPassword: string
  addWithdrawAccountType?: AccountRootTypeEnum
  twoStepVerifyInfo?: TwoStepVerifyInfo
}

/* --------------- certify/bindalipay ------------------ */
// 绑定微信支付宝

export type TwoStepVerifyInfo = {
  googleAuth: string
  phone: string
  email: string
  captcha: string
  verifyType: 1 | 2 | 3 // 1=谷歌验证码 2=邮箱 3=手机
  onClose?: () => void
}

export type Extend = {
  /**
   * 银行分行
   */
  branchBank: string
  /**
   * 银行
   */
  bank: string
  /**
   * 企业名称
   */
  companyName: string
}
export type FinanceWithdrawBindWechatOrAliPayPayload = {
  name: string
  accountType: number
  account?: string
  codeUrl?: string
  subType?: string
  extendInfo?: string
  twoStepVerifyInfo?: TwoStepVerifyInfo
  subTypeExtend?: Extend
}

// 绑定微信支付宝
export type FinanceWithdrawBindWechatOrAliPayData = {
  id: number // 账户id
  name: string // 姓名
  logo: string // 二维码图片地址
  default: number // 是否默认账户
  account: string // 账户信息
  accountType: number // 账户类型
  code: string // 账号类型code
  accountName: string
  currencyCode: string
  cryptoCurrency: false
  subTypeName: string
  cpf: string
}

/* --------------- certify/bindCrypto ------------------ */
// 绑定虚拟货币
export type FinanceWithdrawBindCryptoPayload = {
  name?: string
  coinType: string
  protocol: string
  address: string
  qrcodeUrl?: string
  twoStepVerifyInfo?: TwoStepVerifyInfo
}

/* --------------- certify/bindUserWallet ------------------ */
// 绑定虚拟货币
export type FinanceWithdrawBindOtherWalletPayload = {
  accountType: number // 提现账号类型
  address: string // 钱包地址
  twoStepVerifyInfo?: TwoStepVerifyInfo
}

export type FinanceWithdrawBindCryptoData = {
  id: number
  name: string // 姓名
  bankName: string // 虚拟币类型
  default: number // 是否默认
  account: string // 提现账户地址
  accountType: number // 账号类型
}

/* --------------- certify/bankInfo ------------------ */
// 绑定银行卡选择列表

export type FinanceGetWithdrawBankListData = Array<FinanceGetWithdrawBankData>

export type FinanceGetWithdrawBankData = {
  en: string
  Name: string
  Img: string
}

/* --------------- certify/cach ------------------ */
// 发起提现
export type FinanceReleaseWithdrawPayload = {
  /**
   * 提现金额
   */
  money: string
  /**
   * 提现账户对应id
   */
  id: string
  /**
   * 是否no钱包提现
   */
  isWallet: boolean
  passwd: string
  accountType: number
  twoStepVerifyInfo: TwoStepVerifyInfo
}

export enum ValidationStatus {
  OFF = 0,
  ON = 1
}

export type VerifyItem = {
  googleAuth: boolean // 谷歌验证器, true=要求验证
  phone: string // 非空=要求验证
  email: string // 非空=要求验证
}

export type BindItem = {
  phone: ValidationStatus // 0关闭，1开启
  email: ValidationStatus
  googleAuth: ValidationStatus
}

export type FinanceReleaseWithdrawData = {
  /**
   * 余额
   */
  gameGold: number
  /**
   * 钱包提现时返回钱包订单跳转地址
   */
  walletUrl: string
  /**
   * 二步验证信息
   */
  twoStepVerify?: {
    verifyItem: VerifyItem
    bindItem: BindItem
  }
}

// 余额宝 返回数据
export interface FinanceGetYuebaoData {
  switchStatus: number
  yuebaoGold: number
  curIncome: number
  yearRate: number
  totalIncome: number
  dayRate: number
  cycleIncome: number
  cycleTime: number
  minSave: number
  gameGold: number
  validBetTimes: number
  nextCalculateIncomeTime: number
  principal: number
  isPop: 0 | 1 // 0 关闭 1 开启
}

// 获取汇率 返回数据
export interface FinanceGetExchangeRateByCurrencyCodeData {
  [key: string]: string
}

// 获取提现维护信息 返回数据
export interface FinanceGetWithdrawMaintainInfo {
  startTime: number
  endTime: number
  remark: string //公告内容
  closeType: 0 | 1 //0手动关闭 1定时关闭
}

/* --------------- /pay/paysubmit ------------------ */
// 在线充值
export type FinanceOnlinePayPayload = {
  username: string
  amount: string
  /**
   * 支付方式
   */
  paytype: string
  time: number
  sign?: string
  language: string
  /**
   * 单个通道-支付通道id
   */
  payplatformid?: string
  /**
   * 通道合并-支付通道id集合
   */
  payplatformids?: string
  /**
   * 通道合并-支付方式Id对应通道中的paytypeId
   */
  id?: number
  /**
   * 	充值方式 6:钱包充值 其他：普通充值
   */
  payWay?: string
  /**
   * 支付真实姓名
   */
  payRealName?: string
  /**
   * 游戏id
   */
  gaimeId?: string
  /**
   * 钱包充值抢单单号，钱包充值专用，其他充值不用传
   */
  walletOrderNo?: string
  /**
   * 站点id
   */
  siteCode: string
  /**
   * 游戏平台id
   */
  platformId?: number
  /**
   * 当前金额所属币种
   */
  currencyCode: string
  source: 'web'
  /**
   * 钱包地址[可选]
   */
  payAddress?: string

  /**
   * 手机号[可选]
   */
  mobilePhone?: string

  /**
   * 邮箱[可选]
   */
  email?: string
  /**
   * 卡号[刮刮卡]
   */
  cardNumber?: string
  /**
   * 卡密[刮刮卡]
   */
  cardPassword?: string
  /**
   * 运营商[刮刮卡]
   */
  networkMerch?: string
  /**
   * 银行编码[网银]
   */
  bankCode?: string
  /**
   * 银行名称[网银]
   */
  bankName?: string

  /**
   * 大厅地址
   */
  webUrl?: string
  /**
   * 网银使用三方商户银行编码
   */
  merchCode?: string

  webVersion?: number // 版本时间戳
  signKey?: string // signkey后四位
  /**
   * CPF
   */
  cpf?: string
  version?: number // 合并通道写死传3，不参与签名

  smartBox?: number // 支付来源

  /**
   * 购宝
   */
  walletPassword?: string
}

export type preSubmitPayload = {
  sign?: string
  siteCode: string
  source: string
}

export type preSubmitParams = {
  sign: string
  payload: unknown
  payUrl: string
}

export type FinanceOnlinePayData = {
  code: string | number
  msg: string
  success: boolean
  type: string
  data: unknown
  url: string
  payAddress: string
  orderNo: string
  autoQueryOrder: boolean
  internalJump?: boolean
  qrCode?: string
  outTradeNo?: string
  createTime?: number
}

export type FinanceNoWalletBuyPayload = {
  siteCode: string
  merchantId: string
  userId: string
  amount: string
  time: number
  sign?: string
}

// 会员绑定余额信息
export type MemberBalanceData = {
  bind: 1 | 2 // 会员是否已绑定 1已绑定 2未绑定
  bindUrl: string // 绑定跳转url
  buyUrl: string // 购买跳转url
  rmbBalance: string // 游戏钱包账户余额
  moveBalance: string // 搬砖账户余额
  usdtBalance: string // usdt账户余额
  usdtBalanceU: string // usdt 账号余额 （u的数量）
  usdtRate: string // No钱包 usdt兑换rmb汇率
  qaccount: string // 钱包账号
}

// UPAY用户信息
export type UPAYBalanceData = {
  accountNo: string // 钱包帐号
  accountAddr: string // 钱包地址
  balanceAmount: number // 余额
}

/* --------------- certify/bindcard ------------------ */
//绑定提现银行卡

export type FinanceWithdrawBindBankCardPayload = {
  name: string
  number: string
  bank: string
  addr: string
  default: string
  accountType: number
  currencyCode: string
  twoStepVerifyInfo?: TwoStepVerifyInfo
}
export type FinanceWithdrawAccountParams = {
  withdrawalPassword?: string
}

export type FinanceWithdrawBindBankCardData = {
  id: number // 账户id
  cardLastFour: string // 银行卡后4位
  code: string // 银行代码
  bankName: string // 银行名称
  logo: string // 银行图标地址
  name: string // 银行卡账户名称
  default: number // 是否默认提现账户
  accountType: number // 账户类型 (银行卡统一为1)
  account: string // 银行卡账号
}

/* --------------- certify/cryptoList ------------------ */
//查询提现数字币列表
export type FinanceWithdrawQueryCryptoListData = Array<{
  currency: string
  protocol: string[]
}>

/* ---------------------充值/提现气泡----------------- */
export type FinanceWithdrawOrDepositOrderProgressData = {
  merchantOrderNo: string //店户会员编号
  merchantMemberNo: string //店家充值订单号
  url: string //跳转链接
  progress: number //	进度
  stateForApp: number //状态
  stateDesc: string //	状态描述
  countdown: boolean //倒计时true 正计时false
  leftTime: number //	倒计时就是剩余秒数，正计时就是已经用时秒数 -1代表不计时
  orderSucceed: boolean //订单成功true， 订单失败或在进行中 false
  expiredTime: number //倒计时结束时间
  currentTime: number //	当前服务器时间
}

export interface PingResult {
  url: string
  pingTime: number
}

export interface FeeSettingData {
  exemptWithdrawFeeTime: number // 免手续费次数
  todayExemptWithdrawFeeTime: number // 当天还剩免手续费次数
}

export interface SmartPayTitlePayload {
  scene?: SmartPayScene
  currency: string
}
export interface SmartPayTitleResponse {
  scene: SmartPayScene
  slogan: string[]
}

export interface SmartPaySettingAndSlogansResponse {
  publicSetting: {
    timeInterval: number // 时间间隔, 单位:分钟
    forcedDisplay: number // 强制展示，单位:秒
  }
  sceneSettings: Array<{
    scene: SmartPayScene
    minBalance: Array<{
      currency: string
      amount: string
    }>
  }>
  slogans: Array<SmartPayTitleResponse>
}

export interface SmartPayContentResponse {
  recommendedAmount: string //推荐充值金额;单位: 通道币种金额
  payKind: RootCategoryEnum // 充值类型;
  recommendedCurrency: string // 推荐充值通道币种
  payTypeId?: number // 充值大类id
  payTypeName?: string // 充值大类名称
  channelId?: number //充值通道id
  channelName?: string // 充值通道名称
  deduceAmount?: string // 赠送会员币种金额
  payedAmount?: string // 预计到账会员币种金额
  merchCode?: string // 三方商户code
  mergeStatus?: number // 合并状态
  signKey?: string // 签名key
  payUrl?: string //支付调转地址
  openWay?: number
  singlePay?: boolean // 可直接拉单：true 不可直接拉单：false
}

export interface OrderStatusResponse {
  status: boolean
  successTime: number
}
