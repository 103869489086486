import {
  AccountPayload,
  AccountResponse,
  NewDirectAccountSettingResponse
} from './type'
import { GlobalService } from '@/context'

export const apiCreateAccount = (payload: AccountPayload) => {
  return GlobalService.post<AccountResponse>({
    url: '/promote/createaccount',
    data: payload
  }).then((res) => res.data.data as AccountResponse)
}
/** 直属开户配置设置获取 */
export const apiGetNewDirectAccountSetting = () => {
  return GlobalService.get<NewDirectAccountSettingResponse>({
    url: '/promote/getCreateAccountConfig',
    customParams: {
      cache: true,
      tryStaticMode: true,
      staticKeyOmit: ['platformType'],
      keyOmit: true
    }
  }).then((res) => res.data.data as NewDirectAccountSettingResponse)
}
