import { Base, Component, Prop } from '@/vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { string } from 'vue-types'
import { xssFilter } from '@/utils/xss'

export type Props = {
  /** 标签类型 */
  type?: 'div' | 'span' | 'a' | 'ul' | 'li' | 'p' | 'section'
  /** 文本内容 */
  text: TranslateResult
  format?: (value: string | TranslateResult) => string
  /**
   * 是否开启xss防控
   */
  xssProtection?: boolean
}
export type Events = {
  onClick: (event?: MouseEvent) => void
}
@Component<InnerHTML>({
  name: 'InnerHTML'
})
export default class InnerHTML extends Base<unknown, Props, Events, unknown> {
  @Prop(string<Required<Props>['type']>().def('span'))
  private readonly type!: NonNullable<Props['type']>

  @Prop({
    type: String,
    required: true
  })
  private readonly text!: NonNullable<Props['text']>

  @Prop({
    type: Function,
    default: (value: string) => {
      // 如果是undefined，或者是null的场景，不需要转化为字符串
      if (typeof value === 'undefined') {
        return undefined
      }
      if (value === null) {
        return null
      }
      const raw = String(value)
      /**
       * 内部默认非html才进行\n替换成<br/>标签
       */
      const isHtmlString = /<\/?[a-z][\s\S]*>/i.test(raw)
      return isHtmlString ? raw : raw.replace(/\n/g, `<br/>`)
    }
  })
  private readonly format!: NonNullable<Props['format']>

  @Prop({
    type: Boolean,
    default: true
  })
  private readonly xssProtection!: NonNullable<Props['xssProtection']>

  render() {
    const raw = this.format(this.text)
    const data = {
      attrs: {
        ...this.$attrs
      },
      props: {
        ...this.$attrs
      },
      on: {
        ...this.$listeners
      }
    }
    return (
      <this.type
        domPropsInnerHTML={this.xssProtection ? xssFilter(raw) : raw}
        {...data}
      />
    )
  }
}
