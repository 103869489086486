import { Base, Component, Prop } from '@/vue-property-decorator'
import { GlobalManager } from '@/context'
import { copyText } from '@/utils/Tool'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { useI18n } from '@/i18n'
// const comm_icon_copy = createSvgSprite('comm_icon_copy')
import { windowOpen } from '@/utils/business-utils'
import style from './style.module.scss'

type Props = {
  text: string
  showToast?: boolean
  url?: string
}

/**
 * 复制字符串公共组件
 * 目前仅支持复制字符串，后面如果有其它业务形态，可自行拓展
 * @Date 2022.6.28
 */
@Component<CommonCopy>({ name: 'CommonCopy' })
export default class CommonCopy extends Base<unknown, Props> {
  @Prop({ required: true })
  text!: Props['text']

  @Prop({ default: true })
  showToast?: Props['showToast']

  /**
   * 客服链接
   */
  @Prop()
  url?: Props['url']

  private handleCopy() {
    // 确保this.text是字符串
    copyText(this.text?.toString() ?? '')
    const { t } = useI18n()
    if (this.showToast) {
      GlobalManager.Modal.message({
        type: 'success',
        content: t('lobby.common.tips.copy')
      })
    }
    if (this.url) {
      setTimeout(() => {
        /**
         * 和cocos一致，复制成功显示一秒后再打开窗口
         */
        windowOpen(this.url ?? '')
      }, 500)
    }
  }
  render() {
    return (
      <span
        onClick={(event) => {
          event.stopPropagation()
          this.handleCopy()
        }}
        class={[style.copy, 'copyIcon']}
      >
        {this.$slots.default || (
          <icon-sprite useSkeleton sprite={createSvgSprite('comm_icon_copy')} />
        )}
      </span>
    )
  }
}
