import {
  Base,
  Component,
  Prop,
  ProvideReactive,
  Watch
} from '@/vue-property-decorator'
import { useMainStore } from '@/store/index'
import style from './style.module.scss'

/** 外层盒子的尺寸,都转成数字直接使用 */
type State = {
  /** 宽度(单位px) */
  width?: number
  /** 高度(单位px) */
  height?: number
  /** 字号(单位px) */
  fontSize?: number
}
type Props = {
  /** 宽度 */
  width?: string
  /** 高度 */
  height?: string
  /** 字号 */
  fontSize?: string
}
@Component<VeryLargeTextBox>({ name: 'VeryLargeTextBox' })
/** 超大字体盒子(超大字体,缩小到浏览器允许的最小字号限制后,仍然太大) */
export default class VeryLargeTextBox extends Base<State, Props> {
  @ProvideReactive('largeHeight')
  protected largeHeight!: number
  @Prop() readonly width!: Props['width']
  @Prop() readonly height!: Props['height']
  @Prop() readonly fontSize!: Props['fontSize']

  state: State = {
    width: undefined,
    height: undefined,
    fontSize: undefined
  }
  /** 外层主要用来(占位/定位)盒子样式 */
  get outStyle() {
    return {
      width: this.width,
      height: this.height,
      fontSize: this.fontSize
    }
  }

  /** 内部实际收缩盒子的样式 */
  get innerStyle() {
    const { width, height, fontSize } = this.state
    if (!width || !height || !fontSize) {
      return {}
    }
    /** 将边线转成百分比 公式:(side*(1-scale))/2/(side*scale)+0.5 */
    const side2rate = (side: number) => {
      return ((side * (1 - 0.5)) / 2 / (side * 0.5) + 0.5) * 100 + '%'
    }
    /** 宽度移动的比例 */
    const widthRate = side2rate(width * 2)
    /** 高度移动的比例 */
    const heightRate = side2rate(height * 2)
    return {
      width: width * 2 + 'px',
      height: height * 2 + 'px',
      fontSize: fontSize * 2 + 'px',
      transform: `scale(0.5) translate(-${widthRate},-${heightRate})`
    }
  }

  /** 视图尺寸切换触发 */
  protected get rootFontSize() {
    return useMainStore().rootFontSize
  }

  @Watch('rootFontSize')
  /** 获得px版的真实尺寸 */
  private getPxRealSize() {
    const largeBoxRef = this.$refs['largeBoxRef'] as HTMLDivElement
    const largeBoxStyle = window.getComputedStyle(largeBoxRef)
    const { fontSize, width, height } = largeBoxStyle
    this.largeHeight = Number(height.replace(/px$/, '')) * 2
    this.setState({
      width: Number(width.replace(/px$/, '')),
      height: Number(height.replace(/px$/, '')),
      fontSize: Number(fontSize.replace(/px$/, ''))
    })
  }

  mounted(): void {
    this.getPxRealSize()
  }

  render() {
    return (
      /** 外部盒子,主要用于校准初始属性值 */
      <div
        class={style['very-large-text-box']}
        style={this.outStyle}
        ref={'largeBoxRef'}
      >
        {/* 实际进行扩大,然后收缩的是内部盒子 */}
        <div class={style['box-inner']} style={this.innerStyle}>
          {this.$slots.default}
        </div>
      </div>
    )
  }
}
