import { ThemeComponentType } from './type'

export default {
  [ThemeComponentType.HomePage]: {
    com: () =>
      import(
        /* webpackChunkName: "home@theme=20" */ '@/views/game/themes/theme-20/home'
      )
  },
  [ThemeComponentType.SearchPage]: {
    com: () =>
      import(
        /* webpackChunkName: "search@theme=20" */ '@/views/game/themes/theme-20/search'
      )
  },
  [ThemeComponentType.SubGamePage]: {
    com: () =>
      import(
        /* webpackChunkName: "subGame@theme=20" */ '@/views/game/themes/theme-20/subGame'
      )
  },
  [ThemeComponentType.EmbeddedPage]: {
    com: () =>
      import(
        /* webpackChunkName: "embedded@theme=20" */ '@/views/game/themes/theme-20/embedded'
      )
  },
  [ThemeComponentType.Footer]: {
    com: () =>
      import(
        /* webpackChunkName: "footer@theme=20" */ '@/layouts/components/footer/themes/theme-20'
      ),
    h5: false
  },
  [ThemeComponentType.FloatBar]: {
    com: () =>
      import(
        /* webpackChunkName: "floatBar@theme=20" */ '@/layouts/components/floatBar/themes/theme-20'
      )
  },
  [ThemeComponentType.Styles]: {
    com: () =>
      import(
        /* webpackChunkName: "style@theme=20" */ '@/views/global-styles/themes/theme-20'
      )
  },
  [ThemeComponentType.Layouts]: {
    com: () =>
      import(
        /* webpackChunkName: "layout@theme=20" */ '@/layouts/themes/theme-20'
      )
  }
}
