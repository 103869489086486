import { GameCateEnum } from '../commission/type'

export interface ProportionData {
  list: Array<ProportionItem[]>
  valid_num_deposit: number
  second_comm_rate: number
  third_comm_rate: number
  minValidBet: number
  minValidDeposit: number
  /** 返佣比例前台显示格式 0佣金数值 1比例 */
  rebateFrontDisplayFormat: number
  /** 佣金计算依据(来源) 0-有效投注(区分游戏)，1-亏损金额（预留字段，本期不做），2-净盈利（不区分游戏） */
  calcPerformance: CalcPerformance
}
/** 佣金计算依据(来源) 0-ValidBet-有效投注(区分游戏)，1-亏损金额（预留字段，本期不做），2-NetProfit-净盈利（不区分游戏） */
export enum CalcPerformance {
  ValidBet = 0,
  NetProfit = 2
}

export type GameCateIdData =
  | GameCateEnum.Chess
  | GameCateEnum.Lottery
  | GameCateEnum.Real
  | GameCateEnum.Sports
  | GameCateEnum.Street
  | GameCateEnum.Fish
  | GameCateEnum.Cockfighting
  | GameCateEnum.Gameing
  | GameCateEnum.Blockchain

export interface ProportionItem {
  agentMode: number
  direct_num: number // 直属推广人数
  game_cateid: GameCateEnum // 游戏分类id
  game_catename: string //游戏分类名
  min: number // 最低业绩投注额
  min_profit: number // 最低净盈利额
  name: string // 代理级别名称
  profit_audit_rate: number // 返佣百分数
  proportion: number // 每万返佣金额
  sum_recharge: number // 总充值金额
  valid_num: number // 最低有效人数
}
