import {
  Base,
  Component,
  ModelSync,
  Prop,
  Watch
} from '@/vue-property-decorator'
import { Debounce } from 'lodash-decorators'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { merge } from 'lodash'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import SubscriptComp from '@/components/business-components/subscript'
import Validator from '@/utils/Validator'
// const comm_icon_fh = createSvgSprite('comm_icon_fh')
import style from './style.module.scss'
type Props = {
  tabsNavHiddenForOne?: boolean
  tabsNavHidden?: boolean
  tabsTransparent?: boolean
  renderList: RenderItem[]
  value?: RenderItem['value']
  needCacheComponentsList?: string[]
  persistedActiveName?: string
  itemWidth?: string | number
  itemHeight?: string | number
  itemSpace?: string | number
  preTriggerHook?: (value: RenderItem) => Promise<void> | boolean
  /**
   * 开启后，可以左右滚动、拖拽
   */
  isScrollX?: boolean
  scrollHeight?: string
  overflowEllipsis?: boolean
  showHoverTips?: boolean
  scrollWidth?: string
  itemListWrapperClassName?: CSSClassValue
  showCallbackIcon?: boolean
  isButtonTab?: boolean
  showLine?: boolean
  /**是否需要自动居中 */
  needAutoCenter?: boolean
  goBack?: () => void
  customRender?: boolean // 自定义渲染，需要完全由自己控制渲染
}

export type RenderItem = {
  hidden?: boolean | (() => boolean)
  label:
    | NonNullable<BaseRenderContent>
    | ((item?: RenderItem) => NonNullable<BaseRenderContent>)
  value: string | number
  scope?: string
}
/**
 * 纯UI渲染tabs组件
 * 使用方法可以参考[/center/report/index.tsx]
 */
@Component({ name: 'CommonTabs' })
export default class CommonTabs extends Base<
  unknown,
  Props,
  {
    onTrigger: (value: RenderItem['value']) => void
  },
  {
    default?: RenderItem
    label?: RenderItem
    footer?: void
  }
> {
  /**
   * 当前选中值
   */
  @ModelSync('value', 'trigger', { required: true })
  current!: Props['value']

  @Prop(AppTypes.looseBool)
  tabsNavHiddenForOne: Props['tabsNavHiddenForOne']

  /**
   * 是否隐藏导航
   */
  @Prop(AppTypes.looseBool)
  tabsNavHidden: Props['tabsNavHidden']

  /**
   * 是否透明背景导航
   */
  @Prop(AppTypes.looseBool.def(false))
  tabsTransparent: Props['tabsTransparent']
  /**
   * 是否是按钮tab
   */
  @Prop(AppTypes.looseBool.def(false))
  isButtonTab: Props['isButtonTab']
  /**
   * 是否超出省略
   */
  @Prop(AppTypes.looseBool.def(false))
  overflowEllipsis!: NonNullable<Props['overflowEllipsis']>

  /**
   * 鼠标放上去是否显示内容
   */
  @Prop(AppTypes.looseBool.def(true))
  showHoverTips!: NonNullable<Props['showHoverTips']>
  /**
   * 控制是否将当前激活项记录在地址栏
   */
  @Prop(AppTypes.string.def('current'))
  persistedActiveName!: NonNullable<Props['persistedActiveName']>

  /**
   * 需要被缓存的组件名称
   */
  @Prop(AppTypes.array.def([]))
  needCacheComponentsList: Props['needCacheComponentsList']

  /**
   * 渲染tab列表
   */
  @Prop({ required: true })
  renderList!: Props['renderList']

  /**
   * tab切换前置钩子
   */
  @Prop({ required: false })
  preTriggerHook!: Props['preTriggerHook']

  /**
   * tab宽度
   */
  @Prop({ default: 'auto' })
  itemWidth?: Props['itemWidth']

  /**
   * tab item高度
   */
  @Prop({ default: '54px' })
  itemHeight?: Props['itemHeight']

  /**
   * tab item间距
   */
  @Prop({ default: '30px' })
  itemSpace?: Props['itemSpace']

  /**
   * 外部可滚动最大宽度
   */
  @Prop({ default: '100%' })
  scrollWidth?: Props['scrollWidth']
  /**
   * 是否沿x轴滚动
   */
  @Prop({ default: false })
  isScrollX?: Props['isScrollX']

  @Prop({ default: '100%' })
  scrollHeight?: Props['scrollHeight']

  /**
   * tabs wrapper class
   */
  @Prop(AppTypes.cssClass)
  itemListWrapperClassName?: Props['itemListWrapperClassName']

  @Prop(AppTypes.bool.def(false))
  showCallbackIcon!: Props['showCallbackIcon']
  /**
   * 是否展示tab下面的横线样式
   */
  @Prop({ default: true })
  showLine?: Props['showLine']

  /**是否需要在改变路由的时候，自动居中 */
  @Prop({ default: true })
  needAutoCenter?: Props['needAutoCenter']

  /**
   * 传入的返回回调
   */
  @Prop(AppTypes.func.def(undefined))
  goBack?: Props['goBack']

  /**
   * 自定义渲染内容
   */
  @Prop({ default: false })
  customRender?: Props['customRender']

  /**
   * tabs item style
   */
  get itemStyle() {
    return {
      width: BusinessUtils.px2rem(`${this.itemWidth ?? 180}px`),
      height: BusinessUtils.px2rem(`${this.itemHeight ?? 54}px`),
      marginRight: BusinessUtils.px2rem(`${this.itemSpace ?? 30}px`)
    }
  }

  get isWeb() {
    return useMainStore().isWeb
  }

  @Debounce()
  @Watch('current')
  protected onCurrentChange() {
    if (this.isKeptAlive) {
      return
    }
    if (this.persistedActiveName) {
      this.$router.replace({
        query: merge({}, this.$route.query, {
          [this.persistedActiveName]: this.current
        })
      })
    }
    // 手动更新current时自动居中
    this.$nextTick(() => {
      const dom = this.$el.querySelector('.common-tab-item-active')
      this.autoCenter(dom as HTMLDivElement)
    })
  }

  @Watch('$route', { immediate: true })
  protected applyPersistedActiveName() {
    if (this.persistedActiveName) {
      const current = this.$route.query[
        this.persistedActiveName
      ] as Props['value']
      if (!Validator.isEmpty(current)) {
        this.current =
          typeof this.current === 'number' ? Number(current) : current
      }
    }

    // 处理路由变更后元素自动居中问题
    if (this.isScrollX && this.needAutoCenter) {
      this.$nextTick(() => {
        const el = document.querySelector(
          `.common-tab-item[data-id='${this.current}']`
        )
        this.autoCenter(el as HTMLDivElement)
      })
    }
  }

  /**
   * tab点击事件
   * @param item
   * @returns
   */
  private async handleClick(event: Event, item: RenderItem) {
    if (item.value === this.current) {
      return
    }
    if (this.preTriggerHook) {
      await this.preTriggerHook(item)
    }
    this.current = item.value
    // 滚动区域点击后选中居中
    // this.autoCenter(event.target as HTMLDivElement)
  }

  /**
   * 自动居中
   * @param el
   */
  private autoCenter(el: HTMLDivElement) {
    if (this.isScrollX && this.needAutoCenter) {
      el?.scrollIntoView?.({ inline: 'center', block: 'nearest' })
    }
  }

  /**
   * 渲染tab
   * @param item
   * @returns
   */
  private renderItem(item: RenderItem) {
    const hidden = item.hidden instanceof Function ? item.hidden() : item.hidden
    if (hidden) return null
    const child =
      (item.label instanceof Function ? item.label(item) : item.label) ||
      this.$scopedSlots?.label?.(item)
    return (
      <li
        key={item.value}
        class={{
          [style.active]: this.current?.toString() === item.value?.toString(),
          [style.item]: true,
          [style.ellipsis]: this.overflowEllipsis,
          'common-tab-item': true,
          'common-tab-item-active': this.current === item.value
        }}
        data-id={item.value}
        style={this.itemStyle}
        onClick={(event) => this.handleClick(event, item)}
        {...{
          directives: this.showHoverTips
            ? [
                {
                  name: 'inner-text-to-title'
                }
              ]
            : []
        }}
      >
        {this.current === item.value && this.isButtonTab && <SubscriptComp />}
        {typeof child === 'string' ? (
          <inner-html text={child}></inner-html>
        ) : (
          child
        )}
      </li>
    )
  }

  /**
   * 包裹使用高级函数
   */
  private sectionWrapper(
    content: VueTsxSupport.JSX.Element,
    opt: { isScrollX: boolean; showBackIcon: boolean }
  ) {
    let result = [content]
    if (opt.isScrollX) {
      result = [
        <my-scroll
          direction="x"
          dragable={this.isWeb}
          trigger="none"
          width={this.scrollWidth}
          height={this.scrollHeight}
        >
          {content}
        </my-scroll>
      ]
    }

    if (opt.showBackIcon) {
      result = [
        <div class={style.backIconWrapper}>
          <div
            class={style.backIcon}
            onClick={() => {
              if (this.goBack) {
                this.goBack()
                return
              }
              this.$router.back()
            }}
          >
            <icon-sprite sprite={createSvgSprite('comm_icon_fh')} />
          </div>
          {result}
        </div>
      ]
    }
    return result
  }

  get tabs() {
    const list = (
      <ul
        // 增加一个id,为了让其它元素获得它的高度
        id={'listUlId'}
        data-len={this.renderList.length}
        class={{
          'common-tabs-nav': true,
          [style.tabs]: !this.isButtonTab,
          [style['btn-tabs']]: !!this.isButtonTab,
          [style['hidden-for-one']]: !!this.tabsNavHiddenForOne,
          [style.hidden]: !!this.tabsNavHidden,
          [style.maxWidth]: !this.isScrollX,
          [this.itemListWrapperClassName as string]: true
        }}
      >
        {this.renderList.map(this.renderItem)}
      </ul>
    )
    if (this.isScrollX) {
      return this.sectionWrapper(list, {
        isScrollX: true,
        showBackIcon: this.showCallbackIcon!
      })
    }
    return this.sectionWrapper(list, {
      isScrollX: false,
      showBackIcon: this.showCallbackIcon!
    })
  }

  render() {
    return (
      <section class={style.tabsRoot}>
        <div
          class={{
            globalTabsWrapper: true,
            [style.tabsWrapper]: true,
            [style.tabsWrapperTabsTransparent]: !!this.tabsTransparent
          }}
        >
          {this.tabs}
          <div class={style.footer}>{this.$scopedSlots.footer?.()}</div>
        </div>
        {this.renderList.length > 0 &&
          !this.tabsNavHidden &&
          !this.isButtonTab &&
          this.showLine && (
            <div class={{ [style.line]: true, globalLine: true }}></div>
          )}
        <div class={'common-tabs-content'}>
          <keep-alive include={this.needCacheComponentsList}>
            {this.renderList.map((item) => {
              if (this.customRender || item.value === this.current) {
                return (
                  this.$slots[item.scope || (item.label as string)] ||
                  this.$scopedSlots?.default?.(item)
                )
              }
              return null
            })}
          </keep-alive>
        </div>
      </section>
    )
  }
}
