// 包裹想要水平拖动的元素即可
import { Base, Component, Prop, Ref } from '@/vue-property-decorator'
import { string } from 'vue-types'
import style from './styles.module.scss'

interface Props {
  direction?: 'x' | 'y'
}

export interface MyScrollEvents {
  onScroll: Event
}

@Component<DrageScrollArea>({
  name: 'DrageScrollArea'
})
export default class DrageScrollArea extends Base<MyScrollEvents> {
  @Ref()
  private containerRef!: HTMLElement
  private isDragging = false
  private startX = 0
  private scrollLeft = 0

  private startY = 0
  private scrollTop = 0
  private showMask = false
  private waitMaskTimer: string | number = 0

  @Prop(string<'x' | 'y'>().def('x'))
  private readonly direction!: NonNullable<Props['direction']>

  handleMouseDown(event: MouseEvent) {
    this.isDragging = true
    if (this.direction === 'x') {
      this.startX = event.pageX - this.containerRef!.offsetLeft
      this.scrollLeft = this.containerRef!.scrollLeft
    } else {
      this.startY = event.pageY - this.containerRef!.offsetTop
      this.scrollTop = this.containerRef!.scrollTop
    }
  }
  handleMouseMove(event: MouseEvent) {
    if (this.isKeptAlive) {
      return
    }
    if (!this.isDragging) return
    let walk: number
    if (this.direction === 'x') {
      const x = event.pageX - this.containerRef!.offsetLeft
      walk = (x - this.startX) * 1 // 控制滚动速度
      this.containerRef!.scrollLeft = this.scrollLeft - walk
    } else {
      const y = event.pageY - this.containerRef!.offsetTop
      walk = (y - this.startY) * 1 // 控制滚动速度
      this.containerRef!.scrollTop = this.scrollTop - walk
    }
    // 移动距离大于20px,则开启遮罩层,避免触发点击事件.
    if (Math.abs(walk) > 20) {
      this.showMask = true
    }
  }

  handleMouseUpOrLeave() {
    if (this.isKeptAlive) {
      return
    }
    this.isDragging = false
    clearTimeout(this.waitMaskTimer)
    // 去mask
    this.showMask = false
  }

  mounted() {
    this.$nextTick(() => {
      document.addEventListener('mousemove', this.handleMouseMove)
      document.addEventListener('mouseup', this.handleMouseUpOrLeave)
    })
  }

  beforeDestroy() {
    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.handleMouseUpOrLeave)
  }

  render() {
    return (
      <div
        ref={'containerRef'}
        class={style.scrollWrap}
        onMousedown={this.handleMouseDown}
        onMouseleave={this.handleMouseUpOrLeave}
        onScroll={(e: Event) => this.$emit('scroll', e)}
      >
        <div
          class={{
            [style.masklayer]: true,
            [style['show-mask']]: this.showMask
          }}
        ></div>
        {this.$slots.default}
      </div>
    )
  }
}
