import { NetworkLine } from './types'
import { PngSprite, SvgSprite } from '../icon-sprite'
import { defineStore } from 'pinia'
import { useMainStore } from '@/store/index'
import Modal from '@/controller/Modal'

// ping公共配置项
interface Config {
  listSelectedIcon?: SvgSprite | PngSprite // 列表中选中时候的图标
}

interface State {
  currentLine: NetworkLine
  lines: NetworkLine[]
  config: Config
}

export const usePingStore = defineStore('ping', {
  state: (): State => {
    return {
      config: {},
      currentLine: {
        ping: 0,
        url: '',
        lineNumber: 1,
        pending: false
      },
      lines: []
    }
  },
  actions: {
    openModal() {
      if (!this.lines.length) {
        return
      }

      Modal.open('pingModal')
    },
    /**
     * 切换当前线路
     * @param line
     */
    updateCurrentLine(line: NetworkLine) {
      const { setApiBaseUrl } = useMainStore()
      this.currentLine = line
      setApiBaseUrl(line.url)
    },

    /**
     * 更新线路 并实时排序
     * @param line
     */
    updateLine(line: NetworkLine) {
      // 更新线路
      const lines = this.lines.map((l) => (l.url === line.url ? line : l))

      // 排序
      this.lines = lines.sort((line1, line2) => line1.ping - line2.ping)

      // 是否当前选中
      if (line.url === this.currentLine.url) {
        this.currentLine.ping = line.ping
      }
    },

    /**
     * 更新线路pending值
     * @param url
     * @param pending
     */
    updateLineState(url: string, pending: boolean) {
      // 更新线路ping值
      this.lines = this.lines.map((line) =>
        line.url === url
          ? {
              ...line,
              pending
            }
          : line
      )
    },

    updateConfig(config: Config) {
      this.config = {
        ...this.config,
        ...config
      }
    }
  }
})
