import { Base, Component } from '@/vue-property-decorator'
import { random } from 'lodash'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import style from './style.module.scss'

@Component<QRCode>({ name: 'QRCode' })
export default class QRCode extends Base {
  render() {
    const { t } = useI18n()
    const { siteURLInfos } = useMainStore()
    const downloadDomainLength = siteURLInfos?.download_domain?.length || 0

    if (!downloadDomainLength) return <span></span>

    return (
      <div class={style.qrcodeWrap}>
        <vue-qr
          class={style.qrcode}
          text={
            /** 根据配置的下载站列表随机展示二维码 */
            siteURLInfos?.download_domain[random(1, downloadDomainLength) - 1]
          }
          logoMargin={1}
          margin={10}
          size={200}
        />
        <div>{t('lobby.rightBar.scanQR')}</div>
        <div>{t('lobby.rightBar.downloadDevice')}</div>
      </div>
    )
  }
}
