import {
  ApiRedPocket,
  ApiRedPocketResult,
  ApiRedPocketValidation,
  RedPocketReq
} from '@/api/redPocket/type'
import { GlobalService } from '@/context'
import { Response } from '@/context/service'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
import { useMainStore } from '@/store/index'
/**
 * 获取红包信息_红包
 * @returns
 */
export const apiRedPocket = (): Promise<ApiRedPocket> =>
  GlobalService.post<ApiRedPocket>({
    url: '/home/index',
    customParams: {
      noLog: true,
      noErrorMessage: true,
      silentOnError: true,
      // noResponseDataCodeChecked: true
      noResponseDataCodeChecked: true
    },
    data: { robot: useMainStore().robot },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiRedPocket)

/**
 * 打开红包
 */
export const apiRedPocketCollect = (
  req: RedPocketReq
): Promise<Response<ApiRedPocketResult>> =>
  GlobalService.post<ApiRedPocketResult>({
    url: '/active/redactive',
    data: {
      ...req
    },
    customParams: {
      cache: false,
      noResponseDataCodeChecked: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data as Response<ApiRedPocketResult>)
/**
 * 获取开红包信息
 * @param req
 * @returns
 */
export const apiRedPocketValidation = (
  req: RedPocketReq
): Promise<Response<ApiRedPocketValidation>> =>
  GlobalService.post<ApiRedPocketValidation>({
    url: '/active/getred',
    data: {
      ...req
    },
    customParams: {
      noResponseDataCodeChecked: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data as Response<ApiRedPocketValidation>)
