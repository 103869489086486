import { UserInfos } from '@/api/common/type'
import { onAppEvent } from '@/utils/business-utils'
import { openAfterRechargeActivityModal } from '@/views/global-modal/modal/after-recharge-activity'
import { useMainStore } from '@/store/index'
import EventEmitter from 'events'
import ReconnectingWebSocket from '@/utils/websocket'

/**
 * 开发同事手动维护[LongConnectionEvent] 中的业务事件,在dispatchMessage中派发消息即可
 * 业务侧调用 GlobalManager.WS.ws.on(LongConnectionEvent.xxx)即可
 */

const DEFAULT_HEARTBEAT_INTERVAL = 4e3

export enum LongConnectionEvent {
  // 通用事件
  CONNECTED = 'CONNECTED',
  ERROR = 'ERROR',
  CLOSED = 'CLOSED',
  MESSAGE = 'MESSAGE',

  // 具体业务事件
  WITHDRAW_ORDER_PROGRESS = 'withdraw/orderProgress',
  DEPOSIT_ORDER_PROGRESS = 'pay/orderProgress',
  /**注册成功 */
  REGISTER_SUCCESS = 'registerSuccess',
  /**存款成功 */
  PAY_ORDER_SUCCESS = 'payOrderSuccessNotice',
  /**取款成功 */
  WITHDRAW_ORDER_SUCCESS = 'withdrawOrderSuccess',
  /**活动数据推送 */
  ACTIVE_DETAILS = 'activeDetails',

  /** NO钱包气泡推送 */
  NO_WALLET_ORDER = 'noWalletOrderMsg',
  /** 用户金额变更 */
  GAME_GOLD_CHANGE = 'gameGoldChange',

  /** 推送查询数据  */
  JACKPOT_DATA = '1005',

  /** 大厅重置提现密码 */
  CHANGE_BANK_PASS_REGISTER = 'changeBankPassRegister'
}

export type WebsocketResponseData = {
  event: 'notice' | 'pong'
  msgId: string
  content: string
}

export type WebsocketResponseDataContent = { type: string; data: unknown }

export type RechargeResponseDataContent = {
  /** 0复充  1首充 */
  isFirst: number
  /** 0失败 1成功 */
  success: number
}
export default class LongConnection extends EventEmitter {
  private static _httpProto: string
  private static _httpDomain: string
  private static _wsProto: string
  private static _prefix = '/ws/websocket'
  private static _siteId: string
  private static _auth: string
  private static _url: string
  private static _domains: string[]
  private static _ws: LongConnection

  static get uri() {
    if (!this._url) {
      console.error('LongConnection must createConnect first')
      return ''
    } else {
      return this._url
    }
  }

  static get ws() {
    if (!this._url) {
      console.error('LongConnection must createConnect first')
    }
    return this._ws
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // static log(...args: any[]) {
  //   console.log('[long connection]', ...args)
  //   if (window.abcdefg_open) {
  //     console.info('[long connection]', ...args)
  //   }
  // }

  /**
   * 创建WS实例，还未连接
   */
  static createWS() {
    this._ws = new LongConnection()
    return this._ws
  }
  /**
   * 连接WS
   * @param siteId
   * @param auth
   */
  static connectWS(
    siteId: string,
    auth: string,
    prefix?: string,
    domains = [window.location.origin]
  ) {
    this._auth = auth
    this._siteId = siteId
    this._domains = domains
    if (prefix) {
      this._prefix = prefix
    }
    this.setUrl(0)
    this._ws.connect()
    return this._ws
  }

  /* instance */
  private ws!: ReconnectingWebSocket
  private heartbeatIntervalHandler?: number
  constructor() {
    super()
  }

  static setUrl(index: number) {
    index = index % this._domains.length
    const domain = this._domains[index] || this._domains[0]
    const isLocalProxy =
      process.env.NODE_ENV === 'development' &&
      domain === window.location.origin
    const prefix = isLocalProxy ? '/hall-ws/ws/websocket' : this._prefix
    const location = new URL(domain)
    this._httpProto = location.protocol
    this._httpDomain = location.host
    this._wsProto = this._httpProto === 'http:' ? 'ws' : 'wss'
    this._url = `${this._wsProto}://${this._httpDomain}${prefix}?authStr=${this._siteId}-${this._auth}`
    return this._url
  }

  connect() {
    this.disconnect()
    this.ws = new ReconnectingWebSocket(
      (context) => {
        const count = context.retryCount
        return LongConnection.setUrl(count)
      },
      [],
      {
        maxRetries: 20
      }
    )
    this.bind()
  }

  /**
   * 发送消息
   */
  get send() {
    return this._send
  }

  /**
   * 关闭socket
   */
  disconnect() {
    try {
      this.ws && this.ws.close()
    } catch (e) {}
  }

  /**
   * 绑定事件
   * @returns
   */
  private bind() {
    if (!this.ws) return
    this.ws.addEventListener('open', () => {
      this.startHeartbeatInterval()
      this.emit(LongConnectionEvent.CONNECTED, this.ws.OPEN)
    })

    this.ws.addEventListener('error', (events) => {
      this.stopHeartbeatInterval()
      this.emit(LongConnectionEvent.ERROR, events)
    })

    this.ws.addEventListener('message', (events) => {
      this.emit(LongConnectionEvent.MESSAGE, events)
      this.dispatchMessage(events.data)
    })

    this.ws.addEventListener('close', () => {
      this.stopHeartbeatInterval()
      this.emit(LongConnectionEvent.CLOSED, this.ws.CLOSED)
    })
  }

  private _send(msg: Record<string, unknown>) {
    this.ws.send(this.pack(msg))
  }

  private startHeartbeatInterval() {
    this.heartbeatIntervalHandler = window.setInterval(() => {
      this.ws.send(this.pack({ event: 'ping' }))
    }, DEFAULT_HEARTBEAT_INTERVAL)
  }

  private stopHeartbeatInterval() {
    if (this.heartbeatIntervalHandler) {
      window.clearInterval(this.heartbeatIntervalHandler)
      this.heartbeatIntervalHandler = undefined
    }
  }

  private pack(pack: Record<string, unknown>) {
    return JSON.stringify(pack)
  }

  private ack(msgId: string) {
    this._send({ event: 'ack', msgId: msgId })
  }

  /**更新充值次数 */
  private updateDepositeCount() {
    const { setUserInfos, userInfos } = useMainStore()
    const depositCount = userInfos?.deposit_count ?? 0
    setUserInfos({
      ...userInfos,
      deposit_count: depositCount + 1
    } as UserInfos)
  }

  private dispatchMessage(msg: string) {
    try {
      const data = JSON.parse(msg) as WebsocketResponseData
      if (data.event === 'notice') {
        const content = JSON.parse(data.content) as WebsocketResponseDataContent
        switch (content.type) {
          case LongConnectionEvent.PAY_ORDER_SUCCESS:
            this.emit(LongConnectionEvent.PAY_ORDER_SUCCESS, content.data)
            this.updateDepositeCount()
            const payOrder = content.data as RechargeResponseDataContent
            // console.log('xxxxxx PAY_ORDER_SUCCESS')
            openAfterRechargeActivityModal()
            console.log('payOrder: ', payOrder)
            onAppEvent(
              payOrder?.isFirst ? 'firstrecharge' : 'recharge',
              payOrder
            )
            break
          case LongConnectionEvent.WITHDRAW_ORDER_SUCCESS:
            this.emit(LongConnectionEvent.WITHDRAW_ORDER_SUCCESS, content.data)
            const withdrawOrder = content.data as {
              success: number
            }
            console.log('withdrawOrder: ', withdrawOrder)
            onAppEvent('withdrawOrderSuccess', withdrawOrder)
            break
          default:
            this.emit(content.type, content.data)
        }
        this.ack(data.msgId)
      } else {
        this.emit(data.event, data)
      }
    } catch (error) {
      console.error('[long connection error]', error)
    }
  }
}

LongConnection.createWS()

/**
 * 测试提前注册pong事件
 */
// LongConnection.ws.on('pong', (data) => {
//   console.log(data)
// })
