import { ThemeComponentType } from './type'

export default {
  [ThemeComponentType.HomePage]: {
    com: () =>
      import(
        /* webpackChunkName: "home@theme=19" */ '@/views/game/themes/theme-19/home'
      )
  },
  [ThemeComponentType.SearchPage]: {
    com: () =>
      import(
        /* webpackChunkName: "search@theme=19" */ '@/views/game/themes/theme-19/search'
      )
  },
  [ThemeComponentType.SubGamePage]: {
    com: () =>
      import(
        /* webpackChunkName: "subGame@theme=19" */ '@/views/game/themes/theme-19/subGame'
      )
  },
  [ThemeComponentType.EmbeddedPage]: {
    com: () =>
      import(
        /* webpackChunkName: "embedded@theme=19" */ '@/views/game/themes/theme-19/embedded'
      )
  },
  [ThemeComponentType.Footer]: {
    com: () =>
      import(
        /* webpackChunkName: "footer@theme=19" */ '@/layouts/components/footer/themes/theme-19'
      ),
    h5: false
  },
  [ThemeComponentType.FloatBar]: {
    h5: false,
    pc: false,
    com: () =>
      import(
        /* webpackChunkName: "floatBar@theme=19" */ '@/layouts/components/floatBar/themes/theme-19'
      )
  },
  [ThemeComponentType.Styles]: {
    com: () =>
      import(
        /* webpackChunkName: "style@theme=19" */ '@/views/global-styles/themes/theme-19'
      )
  },
  [ThemeComponentType.Layouts]: {
    com: () =>
      import(
        /* webpackChunkName: "layout@theme=19" */ '@/layouts/themes/theme-19'
      )
  }
}
