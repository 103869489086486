import { ApiVipSwitchStatusResponse } from '../vip/type'
import { Banner, JumpNewWindow } from '@/api/home/type'
import { CurrencyCode, CurrencySymbol } from '@/config/currencies.config'
import { ForceType } from '@/views/notice/const'
import {
  GameCategorieExtLink,
  PlatformCategories,
  SelfOperatedGameUrl
} from '@/api/game/type'
import { GeetestValidateResult } from '@/controller/GeeTest'
import { GlobalConst } from '@/context'
import { Holiday } from '@/store/index/type'
import { LocaleMessages } from 'vue-i18n'
import { MusicResponse } from '../aside/type'
import { PlatformCategoriesItemInter } from '@/service/game/entity/gameCategorie'
import {
  ReceiveAwardAllSwitchEnum,
  ReceiveLogPopSwitchEnum,
  ReturnGoldSwitchEnum
} from '../event/type'
import { SecurityVerifyModifyType } from '../center/security/type'
import { SocialmediaResponse } from '../promote/myPromote/type'
import { SvgSprite } from '@/components/icon-sprite'
import { ValueOf } from 'type-fest'
import { mainSkinStyle } from './main-skin-style'
import { nodeThemeConfig } from '@/context/const'

/**
 * 分页请求参数
 */
export interface PagerReq {
  current: number
  size: number
}

type LogoInfo = {
  /**
   * 登录 logo 地址
   */
  login_logo: string
  /**
   * 大厅 logo 地址
   */
  hall_logo: string
  /**
   * 实际网址
   */
  real_url: string
  /**
   * 品牌名称
   */
  brand_name: string
  /**
   * 官网地址
   */
  official_website: string
  /**
   *  1.图文结合 2.自定义
   */
  type: 1 | 2
  /**
   * 自定义下载logo
   */
  customize_download_logo_url: string
  /**
   * H5引导下载logo
   */
  download_logo_url: string
  /**
   * H5引导下载说明
   */
  download_guide: string
}

export enum SiteStatus {
  NORMAL = 0,
  MAINTAIN = 1
}

/**
 * 数据源来自java
 */
export enum FreezeStatus {
  FREEZED = 1
}

type ThreeWayInfos = {
  app_id: string
  appSecret: string
  //1 开启 0 关闭
  status: 1 | 0
}

export enum AutoVisitorStatus {
  OPEN = 0,
  CLOSE = 1
}

export enum LoginValidateMode {
  VALIDATE_WITH_GEETEST = 100,
  VALIDATE_WITH_NOTHING = 1,
  VALIDATE_WITH_SMS = 7
}

export enum ModifyPromoteCurrencyStatus {
  CLOSE = 0,
  OPEN = 1
}

export enum RegisterValidateRequired {
  NO = 0,
  YES = 1
}

export enum RegisterValidateSwitch {
  CLOSE = 0,
  OPEN = 1
}

export enum CommonSwitchStatus {
  CLOSE = 0,
  OPEN = 1
}

// 安全验证选项 0-不使用 1-第一步验证 2-最后一步验证
export enum SecurityVerify {
  NO_USE = 0,
  FIRST_VERIFY,
  LAST_VERIFY
}

/**
 * 安全验证选项(以下选单)
 * 0-不使用
 * 1-第一步验证
 * 2-最后一步验证
 */
export interface SecurityVerifyEnum {
  securityLoginPass: SecurityVerify // 账号密码
  securityWithdrawPass: SecurityVerify // 提现密码
  securityPassQuestion: SecurityVerify // 密保问题
  securityPhone: SecurityVerify //手机短信
  securityEmail: SecurityVerify // 邮箱验证
  securityGoogle: SecurityVerify //谷歌验证码
  firstWithdrawPasswdSet: CommonSwitchStatus // 首次绑定提现密码是否需要校验登录密码
}

/**
 * 登录二步验证开关
 * */
export interface LoginVerify {
  phone: string
  email: string
  googleAuth: boolean
  withdrawPasswd?: boolean
  securityQuestion?: number
}

/**
 * 登录二步验证开关
 * */
export interface LoginVerify {
  phone: string
  email: string
  googleAuth: boolean
  withdrawPasswd?: boolean
  securityQuestion?: number
}

/** 消息中心按钮开关类型 */
export interface MessageSwitchType {
  ann: {
    read: SwitchControl
    delete: SwitchControl
  }
  notify: {
    read: SwitchControl
    delete: SwitchControl
  }
}

export interface SystemInfos {
  /**
   * 站点状态 0=正常 1=维护
   */
  site_status: SiteStatus
  key: string
  token: string
  currency_code: string
  language_codes: string[]
  /**
   * 业主是否使用短信平台: 1:开启 0:关闭
   * 是否开启会员基本信息-注册日期 0 关闭 1开启
   */
  userRegisterTimeSwitch: 0 | 1
  /**
   * 是否允许短信登录
   */
  enable_using_sms: CommonSwitchStatus
  /**
   * 是否开启邀请码输入框,1:开启 2:关闭
   */
  inviter_code_switch: 1 | 0
  /**
   * 邮箱验证开关,1开启，0|2关闭，这个字段需要注意，后端是1开2关，使用时要判断 === 1 为开
   */
  enable_using_gmail: CommonSwitchStatus
  /**
   * 注册填写手机号开关 1:开启 0:关闭
   */
  register_phone_switch: 1 | 0
  /**
   * 邀请码是否为必填，1:必填 0 非必填
   * 依赖 inviter_code_switch 为 1
   */
  inviter_code_required: 1 | 0

  /**
   * 邀请码是否可编辑 1:不可修改 0 可修改
   * 依赖 inviter_code_switch 为 1
   */
  inviter_code_update: 1 | 0
  /**
   *登录行为验证选择:1-不验证,100-极验,7-短信验证
   */
  login_validate_mode: LoginValidateMode

  /**
   * 注册行为验证选择:1-不验证,2-手机号验证码(短信验证),4-数字图片验证(验证码),100-极验
   */
  register_validate_mode: 1 | 2 | 4 | 100
  logo_info: LogoInfo
  facebook: ThreeWayInfos
  google: ThreeWayInfos
  wechat: ThreeWayInfos
  qq: ThreeWayInfos
  line: ThreeWayInfos
  /**
   * 个人中心-设置 phone开关
   */
  userPhoneSwitch: number
  /**
   * 个人中心-设置 email开关
   */
  userEmailSwitch: number
  /**
   * 个人中心-设置 facebook开关
   */
  user_facebook_switch: number
  /**
   * 个人中心-设置 telegram开关
   */
  user_telegram_switch: number
  /**
   * 个人中心-设置 wechat开关
   */
  user_wechat_switch: number
  /**
   * 个人中心-设置 whatsapp开关
   */
  user_whatsapp_switch: number
  /**
   * 个人中心-设置 Zalo开关
   */
  userZaloSwitch: number
  /**
   * 个人中心-设置 LINE开关
   */
  userLineSwitch: number

  /**
   * 个人中心-设置 Twitter开关
   */
  userTwitterSwitch: number

  modifyPromoteCurrency: ModifyPromoteCurrencyStatus
  start_time: number
  end_time: number

  auto_visitor_login: AutoVisitorStatus
  /** 是否跳过登录注册页(自适应H5)，0不跳过，1跳过 */
  auto_visitor_login_h5: AutoVisitorStatus
  /** 是否跳过登录注册页，0不跳过，1跳过 */
  auto_visitor_login_web: AutoVisitorStatus
  realNameSwitch: RegisterValidateSwitch
  realNameRequired: RegisterValidateRequired
  registerCpfSwitch: RegisterValidateSwitch
  registerCpfRequired: RegisterValidateRequired
  /**
   * 真实姓名是否必须大写
   */
  realNameMustUppercase: RegisterValidateRequired
  emailSwitch: RegisterValidateSwitch
  emailRequired: RegisterValidateRequired
  /**
   * 大厅展示音乐模块 0-关闭 1-开启
   */
  musicShow: CommonSwitchStatus
  /**
   * 大厅音乐播放开关 0-关闭 1-开启
   */
  musicAutoPlay: CommonSwitchStatus
  /** ip推荐币种 已不可靠 该接口大概率不回源，ip有关的数据用ipCheck的状态 */
  recommendCurrency: string
  /** ip推荐手机区号 已不可靠 该接口大概率不回源，ip有关的数据用ipCheck的状态*/
  recommendAreaCode: string
  /** ip推荐语言 已不可靠 该接口大概率不回源，ip有关的数据用ipCheck的状态*/
  recommendLanguage: string
  /**
   * 是否能修改电话 0-否 1-是
   */
  enableModifyPhone: CommonSwitchStatus
  /**
   * 忘记密码仅验证一个安全项: 0-关闭 1-开启
   */
  resetPwdOneVerify: CommonSwitchStatus
  /**
   * Fingerprint SDK apiKey
   */
  fingerprintJS: {
    publicKey: string
  }
  /**
   * 客服 是否跳过问题列表(1是/0否)
   */
  questionListDisplay: number
  /**
   * 客服开关 1开 0关
   */
  kfOnlineStatus: number
  /**
   *  注册页面快捷入口-免费试玩
   */
  registerQuickEntryFree: CommonSwitchStatus
  /**
   *  注册页面快捷入口-联系客服
   */
  registerQuickEntryCustomer: CommonSwitchStatus
  /**
   * 注册页面快捷入口-短信登录
   */
  registerQuickEntrySms: CommonSwitchStatus
  /**
   * 注册页面快捷入口-立即注册
   */
  registerQuickEntryInstant: CommonSwitchStatus
  /**
   *  登录页面快捷入口-短信登录
   */
  loginQuickEntrySms: CommonSwitchStatus
  /**
   * 登录页面快捷入口-立即注册
   */
  loginQuickEntryInstant: CommonSwitchStatus
  /** int 上级代理展示开关：1-开，2-关 */
  agentDisplayParent: AgentDisplay
  /**int 代理等级展示开关：1-开，2-关 */
  agentDisplayAgentSetting: AgentDisplay
  /**推广教程展示开关：1-开，2-关 */
  agentDisplayTutorial: AgentDisplay
  /**代理入口展示开关：1-开，2-关 */
  agentDisplayEntrance: AgentDisplay
  /**直属开户展示开关：1-开，2-关 */
  agentDisplayCreateAccount: AgentDisplay
  // 安全验证选项 0-不使用 1-第一步验证 2-第二步验证
  securityVerify: SecurityVerifyEnum
  // 忘记密码验证选项 0-不使用 1-第一步验证 2-第二步验证
  forgetPwdVerify: SecurityVerifyEnum
  // 登录二步验证选项
  loginTwoStepVerify: boolean
  // 试玩币种
  tryCurrency: string
  /** 直属数据展示状态：0-关闭 1-开启 */
  directReport: DirectDisplay
  /** 直属财务展示状态：0-关闭 1-开启 */
  directFinancial: DirectDisplay
  /** 直属领取展示状态：0-关闭 1-开启 */
  directTake: DirectDisplay
  /** 直属投注展示状态：0-关闭 1-开启 */
  directOrder: DirectDisplay
  /** 直属开户展示状态：0-关闭 1-开启 */
  directNewAccount: DirectDisplay
  /** 用户真实姓名最大输入长度 */
  realNameConsecutiveCharLimitTimes: number
  /** 极验设备验id */
  geetestDeviceAppId: string
  /**
   * 大额强制绑定项
   * */
  largeAmountMustBind?: LargeAmountMustBind
  largeAmountRange?: Record<string, number>

  /** 账号注册配置 */
  accountRegister?: LoginRegistrySetting
  /** 邮箱注册配置 */
  emailRegister?: LoginRegistrySetting
  /** 手机号注册配置 */
  phoneRegister?: LoginRegistrySetting
  /** 第三方注册配置 */
  thirdPartyRegister?: LoginRegistrySetting
  /** 登录tab a,b,c */
  registerTabSort: string
  /** 登录页内容为空默认展示输入框 */
  loginDefaultHint: LoginRegisterDefaultHint
  /** 注册页内容为空默认展示输入框 */
  registerDefaultHint: LoginRegisterDefaultHint
  /** 联系客服 */
  loginQuickEntryCustomer: CommonSwitchStatus
  /** 忘记密码 */
  loginQuickEntryForgetPassword: CommonSwitchStatus
  /** 免费试玩 */
  loginQuickEntryFree: CommonSwitchStatus
  /** 登录注册强度检测限制开关 0不限制 1复杂密码 */
  loginPasswdStrengthDetectSwitch: DirectDisplay
  /** 复杂密码配置密码长度 */
  strongPasswdLength: number
  /** 是否校验数字：0-关闭 1-开启 */
  strongPasswdRequireNum: DirectDisplay
  /** 是否校验特殊字符：0-关闭 1-开启 */
  strongPasswdRequireSpecial: DirectDisplay
  /** 是否校验小写字母：0-关闭 1-开启 */
  strongPasswdRequireLowercase: DirectDisplay
  /** 是否校验大写字母：0-关闭 1-开启 */
  strongPasswdRequireUppercase: DirectDisplay
  /** 弱登录密码检测提醒 1需要 0不需要 */
  strongPasswdWeakLoginRemind: DirectDisplay
  /** 消息中心公告与通知 【全部已读】【一键删除】开关 0-关闭 1-开启*/
  messageSwitch: MessageSwitchType
}

/** 代理直属开关枚举v2 */
export enum DirectDisplay {
  OPEN = 1,
  CLOSE = 0
}
/**代理开关枚举 */
export enum AgentDisplay {
  OPEN = 1,
  CLOSE = 2
}
/** 消息按钮开关枚举 */
export enum SwitchControl {
  OPEN = 1,
  CLOSE = 0
}
/**
 * IP绑定信息
 */
export interface IpBindInfos {
  /**邀请码Id */
  promoterUserIdx: number
  /** 邀请码账号*/
  promoterUserName: string
  regPkgId: number
  channelName: string
  currency: string
  // 标识渠道推广id是否有变化，id没变化并且本地有数据则不请求ipBindInfo接口
  _customChannelId: string
}

export * from './main-skin-style'

export type CurrencyInfosMap = {
  ca: CurrencyInfos['currencyAisle']
  cc: CurrencyInfos['currencyCode']
  cd: CurrencyInfos['currencyDisplay']
  cn: CurrencyInfos['currencyName']
  cs: CurrencyInfos['currencySign']
  ct: CurrencyInfos['currencyType']
  fn: CurrencyInfos['fullName']
  gr: CurrencyInfos['gameRate']
  id: CurrencyInfos['id']
  mcc: CurrencyInfos['marketCurrencyCode']
}

export interface InjectData {
  ossGetSiteUrlConfig: { data: { data: string; code: number; time: number } }
  ossGetSkinAssetsHash: {
    data: {
      data: Record<string, string>
      code: number
      time: number
    }
  }
  apiGetOptimizationSiteConfig: {
    data: { data: OptimizationSiteConfig; code: number; time: number }
  }
  apiGetSystemStatus: {
    data: { data: SystemStatus; code: number; time: number }
  }
  apiGetSiteInfo: { data: { data: SiteInfos; code: number; time: number } }
  apiGetAllCurrency: {
    data: {
      data: CurrencyInfosMap[]
      code: number
      time: number
    }
  }
}

export interface SiteConfig {
  /**
   * 有该标识说明服务端请求出错，用静态模板数据填充
   */
  CREATE_BY_STATIC?: boolean
  /**
   * 服务端注入的骨架屏的classname
   */
  SPLASH_NODE_CLASSNAME?: string
  /**
   * 有该标识说明为由java提前调用web对外渲染html服务生成上传至oss桶的版本
   */
  OSS_MODE?: boolean
  /**
   * 服务端注入的是否需要去限制页的状态
   * 域名列表是否匹配
   */
  SITE_DOMAIN_NOT_MATCH?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LOG?: Record<string, any>
  /**
   * 服务端注入的主流程数据
   */
  INJECT_DATA?: InjectData
  siteCode: string
  siteName: string
  siteAppIcon: string
  appIconBigPath: string
  appIconSmallPath: string
  appIconSkeletonPath: string
  loadingImg: string
  ossType: string
  ossName: string
  languageCode: string
  title: string
  description: string
  keyword: string
  /** 是否开启添加桌面快捷方式和pwa 0-关闭 1-开启*/
  shortcutSwitch: number
  /**桌面快捷提示语 */
  desktopShortcut: string
  /**
   * 403配置信息
   */
  accessRestrictedBackgroundColor: string
  accessRestrictedContent: string
  accessRestrictedImagePath: string
  type: mainSkinStyle // 版式类型 0 1 普通版式，2欧美版 式, 3青蓝版
  skinType: number // 皮肤类型，欧美版 式和青蓝版式都是1
  statisticalCode: string // 品牌设置 统计代码插入
  // /**
  //  * 公共桶域名
  //  */
  // commonOssBucket: string
  // /**
  //  * 公共桶cdn域名,只有一个
  //  */
  // commonOssDomain: string
}

export interface SiteURLInfos {
  issuedTime: string
  oss_url: Array<string>
  combo_domain: Array<string>
  web_domain: Array<string>
  api_domain: Array<string>
  lobby_domain: Array<string>
  download_domain_url: Array<{
    domain: string
    serverNodeId: number
  }>
  download_domain: Array<string>
  games_lobby_domain_url: Array<{
    isMain: number
    domain: string
    serverNodeId: number
  }>
  h5_domain: Array<string>
  hotfix_domain: Array<string>
  hall_domain: Array<string>
  pay_domain: Array<string>
  oss_domain: Array<string>
  /**
   * 公共桶域名
   */
  commonOssBucket: string
  /**
   * 公共桶cdn域名,只有一个
   */
  commonOssDomain: Array<string>
}

export type ThemeSkinType = ValueOf<typeof nodeThemeConfig.ThemeSkinType>

export enum ThemeSkinName {
  // 经典版
  normal = 'normal',
  // 欧美版
  european_american = 'europeanAmerican',
  // 青蓝版
  cyan_blue = 'cyanBlue',
  // stake（欧美简约）
  stake = 'stake',
  // 128 皇冠金定制版
  crown_gold = 'crown_gold',
  // 126银河金定制版
  galaxy_gold = 'galaxy_gold',
  // dq定制版
  dq = 'dq'
}

/**
 * 节日主题
 */
export enum HolidayThemeName {
  /**
   * 中秋节
   */
  ZQ = 101,
  /**
   * 国庆
   */
  GQ = 102
}

/**
 * 欧美版 风格皮肤
 */
export enum EuropeanAmericanSkinType {
  /**
   * 欧美版 蓝黑
   */
  european_blue_black = 1,
  /**
   * 欧美版 绿底
   */
  european_green_bg = 2,
  /**
   * 欧美版 黄黑
   */
  european_yellow_black = 3,
  /**
   * 欧美版 红黑
   */
  european_red_black = 4,
  /**
   * 欧美版 蓝底
   */
  european_blue_bg = 5,
  /**
   * 欧美版 紫底
   */
  european_purple_bg = 6,
  /**
   * 欧美版 黑金
   */
  european_black_gold = 7,
  /**
   * 欧美版 黄绿
   */
  european_yellow_green = 8,
  /**
   * 欧美版 卡地亚红
   */
  european_cartier_red = 9,
  /**
   * 欧美版 雅诗兰黛蓝
   */
  european_estee_lauder_blue = 10,
  /**
   * 欧美版 勃艮第红
   */
  european_burgundy_red = 11,
  /**
   * 欧美版 IWC蓝
   */
  european_iwc_blue = 12,
  /**
   * 欧美版 Gucci绿金
   */
  european_gucci_green_gold = 13,
  /**
   * 欧美版 Burberry褐
   */
  european_burberry_brown = 14,
  /**
   * 欧美版 La Mer绿
   */
  european_lamer_green = 15,
  /**
   * 欧美版 eBay紫
   */
  european_ebay_purple = 16,
  /**
   * 欧美版 Dior克莱因蓝
   */
  european_dor_blue = 17,
  /**
   * 欧美版 Chivas Regal邦迪蓝
   */
  european_chivas_regal_blue = 18,
  /**
   * 欧美版 Furla蓝
   */
  european_furla_blue = 19,
  /**
   * 欧美版 Bottega Veneta莫兰迪灰
   */
  european_bottega_veneta_grey = 20,
  /**
   * 欧美版 Embraer蓝
   */
  european_embraer_blue = 21,
  /**
   * 欧美版 Bordeaux红
   */
  european_bordeaux_red = 22,
  /**
   * 欧美版 Breguet灰
   */
  european_breguet_grey = 23,
  /**
   * 欧美版 Hermes橙
   */
  european_hermes_orange = 24,
  /**
   * 欧美版 BVLGARI褐
   */
  european_bvlgari_brown = 25,
  /**
   * 欧美版 Hermes棕橙
   */
  european_hermes_brown_orange = 26,
  /**
   * 欧美版 Elsa Schiaparelli粉
   */
  european_elsa_schiaparelli_pink = 27,
  /**
   * 欧美版 Lancome水蜜桃色
   */
  european_lancome_pink = 28,
  /**
   * 欧美版 Lacoste绿
   */
  european_lacoste_green = 29,
  /**
   * 欧美版 Versace黄
   */
  european_versace_yellow = 30,
  /**
   * 欧美版 Bvlgari棕 尼玛 棕色跟褐色单词一样
   */
  european_bvlgari_zong = 31,
  /**
   * 欧美版 Prada黑
   */
  european_prada_black = 32,
  /**
   * 欧美版 Victoria Secret红
   */
  european_victoria_secret_red = 33,
  /**
   * 欧美版 Anna Sui紫
   */
  european_anna_sui_purple = 34,
  /**
   * 欧美版 Facebook蓝
   */
  european_facebook_blue = 35,
  /**
   * 欧美版 Facebook绿
   */
  european_facebook_green = 36,
  /**
   * 欧美版 Twitter蓝
   */
  european_twitter_blue = 37,
  /**
   * 欧美版 USDT绿
   */
  european_usdt_green = 38,
  /**
   * 欧美版 SK-II红
   */
  european_sk2_red = 39,
  /**
   * 欧美版 Patek Philippe浅棕
   */
  european_patek_philippegreen_brown = 40,
  /**
   * 欧美版 Microsoft红
   */
  european_microsoft_red = 41,
  /**
   * 欧美版 3CE提香红
   */
  european_3ce_red = 42,
  /**
   * 欧美版 Louis Vuitton棕
   */
  european_louis_vuitton_brown = 43,
  /**
   * 欧美版 Bottega Veneta绿
   */
  european_bottega_veneta_green = 44
}

/**
 * Stake风格皮肤
 */
export enum StakeSkinType {
  /**
   * Stak灰蓝
   */
  stake_gray_blue = 1
}

/**
 * 青蓝版
 */
export enum CyanBlueSkinType {
  bg_cyan_blue = 1
}

/**
 * 126定制版
 */
export enum GalaxyGoldSkinType {
  galaxy_gold = 1
}

/**
 * 128定制版-皇冠金
 */
export enum CrownGoldSkinType {
  crown_gold = 1
}

/**
 * dq定制版
 */
export enum DQSKinType {
  // 绿黄
  dq_green_yellow = 1,
  // 黄黑
  dq_yellow_black = 2,
  // 绿白
  dq_green_white = 3,
  // 蓝白
  dq_blue_white = 4,
  // 蓝黑
  dq_blue_black = 5
}
export interface SiteInfos extends SmsCountry {
  agentId: number
  agentName: string
  /**
   * 无色皮肤(1蓝白,2黑金,3浪漫紫,4爱马仕橙,5高贵红,6高级绿,7皇冠棕)
   * 有色皮肤(1深色黑金,2深色蓝底,3深色绿底,4深色红底,5深色黄底,6紫蓝,7紫金)
   * 青蓝版(1旧版,2 305定制)
   */
  skinTypeValue: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  /**
   * 皮肤类型(0无色背景,1有色背景,2欧美风格.,)
   */
  type: ThemeSkinType
  skinConfigInfo: Record<string, string>
  /**
   * 1是有底 0是无底
   */
  backgroundColor: 1 | 0
  description: string
  keyword: string
  countryIds: string
  currencyCode: CurrencyCode
  currencySign: CurrencySymbol
  gameRate: number
  languageInfos: Array<{
    id: number
    languageCode: ValueOf<typeof GlobalConst.ServiceLanguageMap>
    languageName: string
    languageTranslateName: string
    defaultLanguageTag: true
  }>
  maintainStatus: number
  professionalClub: number
  siteCode: string
  status: number
  timeZone: string
  title: string
  siteName: string
  /** 当前站点配置币种列表 */
  currencyInfos: Array<CurrencyInfos>
  languageMatchMode: LanguageMatchMode
  vestBagJumpConfig: {
    packageName: string // 包名
    appType: 0 | 1 // 0:android 1:ios
    appJumpValue: string // app跳转配置值
  }
  holidayThemes: HolidayTheme[]
}
export interface HolidayTheme {
  currencyCodes: string
  matchingStatus: 0 | 1
  status: 0 | 1
  /** 获取Holiday的value值作为type */
  themeType: typeof Holiday[keyof typeof Holiday]
  showEndTime: number
  showStartTime: number
}
export enum LanguageMatchMode {
  DEFAULT = 0,
  SMART_MATCHING = 1
}

export interface SmsCountry {
  /**
   * 此地区是否为当前短信地区
   */
  defaultCountry: ContryInfos
  countryInfos: Array<ContryInfos>
}

/**
 * 法币1
 * 虚拟币2
 */
export enum CurrencyEnum {
  NORMAL = 1,
  CRYPTO = 2
}

export type CurrencyInfos = {
  // 币种存在[USDT_7:1]
  currencyCode: CurrencyCode
  currencySign: CurrencySymbol
  currencyName: string
  currencyDisplay: CurrencyCode
  currencyType: CurrencyEnum
  // 真实币种[USDC]不存在[USDT_7:1]
  marketCurrencyCode: CurrencyCode
  gameRate: number
  fullName: string
  currencyAisle: string
  id: number
}

export type ContryInfos = {
  icon: string
  countryName: string
  countryPhonePrefix: string
  id: number
  phoneLenghth: string
  phoneLengthScope: string
  phoneNumberSegment: string
}

export interface GameCategoriesAvailable {
  gameCategoryId: number
  gameCategoryName: string | LocaleMessages
  /**active状态是否是png图片 */
  activePng?: boolean
  display?: boolean
  hidden?: boolean
  list?: Array<PlatformCategoriesItemInter>
  onClick?: () => void
  customizeIcon?: string
  customizeIconActive?: string
  icon?: string | SvgSprite
  iconActive?: string | SvgSprite
  appMenuRow?: number
  appSquare?: number
  appVertical?: number
}

export type GameCategoryWithExtLink = GameCategoriesAvailable &
  Partial<GameCategorieExtLink>

export interface GameCategoriesAvailableInfos {
  gameCategoryId: number
  gameCategoryName: string | LocaleMessages
  display: boolean
  list: Array<PlatformCategories>
  appMenuRow: number
  appSquare: number
  appVertical: number
  pcMenuRow: number
  pcSquare: number
  pcVertical: number
  customizeIcon?: string
  customizeIconActive?: string
}

/**
 * 1显示 0 不显示
 */
export enum EventCategoriesAvailableEnum {
  Show = 1,
  Hide = 0
}
/**
 * 1显示 0 不显示
 */
export enum ClubEntryAvailableEnum {
  Show = 1,
  Hide = 0
}
export interface EventCategoriesAvailable {
  /**
   * 活动是否显示
   */
  activeCount: EventCategoriesAvailableEnum
  /**
   * 任务是否显示
   */
  taskCount: EventCategoriesAvailableEnum
  /**
   * 返水是否显示
   */
  returnGoldClosed: ReturnGoldSwitchEnum
  /**
   * 公积金是否显示
   */
  rechargeFundClosed: ReturnGoldSwitchEnum
  /**
   * 一键领取开关
   */
  disableReceiveAwardAll: ReceiveAwardAllSwitchEnum
  /**
   * 待领取弹窗
   */
  disableReceiveLogPop?: ReceiveLogPopSwitchEnum
  /**
   * 待确定
   */
  rewardCount: EventCategoriesAvailableEnum
  loading: boolean
}

export interface ClubEntryAvailable {
  /**
   * 俱乐部
   */
  state?: ClubEntryAvailableEnum
  /**
   * 朋友局
   */
  clubFriendState?: ClubEntryAvailableEnum
}

export enum GAStatus {
  /**
   * 未开启
   */
  NONE = 0,
  /**
   * 已关闭
   */
  CLOSE = 1,
  /**
   * 已开启
   */
  OPEN = 2
}

export enum GAStatusSimple {
  ON = 1,
  OFF = 0
}

export enum ThirdWayStatus {
  /**
   * 未开启
   */
  NONE = 0,
  /**
   * google
   */
  GOOGLE = 1,
  /**
   * facebock
   */
  FACEBOOK = 2,
  /**
   * wechat
   */
  WECHAT = 4,
  /**
   * qq
   */
  QQ = 5,
  /**
   * LINE
   */
  LINE = 3
}

export enum OptResult {
  /**
   * 无权
   */
  NONE = 0,
  /**
   * 有权
   */
  OPEN = 1
}

/**
 * 用户账号状态：1-正常，2-手动冻结，3-异常冻结，4-禁止领取优惠, 5-黑名单
 */
export enum UserStatusEnum {
  /**
   * 正常
   */
  Normal = 1,
  /**
   * 手动冻结
   */
  FreezeManual = 2,
  /**
   * 异常冻结
   */
  FreezeAbnormal = 3,
  /**
   * 禁止领取优惠
   */
  ForbidReceive = 4,
  /**
   * 黑名单
   */
  BlackList = 5
}

/**
 * 0不需要强制绑定
 * 1强制绑定1项
 * 2强制绑定2项
 * 3强制绑定3项
 */
export enum BindItemType {
  UNNECESSARY = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3
}
/**
 * 0-关闭 1-开启
 */
export enum BindType {
  /** 未开启 */
  OFF = 0,
  /** 开启 */
  NOT_ESSENTIAL = 1,
  /** 开启并必须绑定 */
  REQUIRED = 2
}

export enum MUST_BIND_ITEM {
  PHONE = 'must_bind_phone',
  EMAIL = 'must_bind_email',
  GA = 'must_bind_google_auth',
  WITHDRAWPASS = 'must_bind_withdraw_pass',
  SECRETSECURITY = 'must_bind_question',
  WITHDRAWACCOUNT = 'must_bind_withdraw_account'
}

export interface PermissionOptStatus {
  hasWithdrawPasswd: boolean
  hasSecurityQuestion: boolean
  hasWithdrawAccount: boolean
  hasPhone: boolean
  hasAccountDeviceId: boolean
  usernamePasswdMutable: boolean
}

/**
 * 试玩账号指 UI上显示未登录状态，会自动生成一个试玩账号登录，有一些和用户相关的入口路由需要不显示或拦截
 * 体验账号指 UI上显示用户信息，权限和试玩账号一致
 * 账号类型 1 试玩 2正式 3机器人 4体验账号（主播号） 5代理 9推广
 */
export enum AccountType {
  /*试玩*/
  Try = 1,
  /*正式*/
  Normal = 2,
  /*机器人*/
  Robot = 3,
  /*体验账号（主播号）*/
  Demo = 4,
  /*代理*/
  Agent = 5,
  /*推广*/
  Promote = 9
}
export interface UserInfos {
  /**
   * 0 没有登录过极速包和原生APP
   * 1 APP 登录过极速包和原生APP
   * 2 H5快捷方式登录(后端暂时无法处理-已经和产品沟通了)
   * */
  loginOsType: number
  /**会员是否已经领取过渠道派发的奖励
   * 0表示没有领取
   * 1表示已经领取
   */
  isChannelPrize: number
  /**会员充值得次数 */
  deposit_count: number
  /**
   * 标记是首次从stroage中拿的
   * 可区分是登录还是未登录
   */
  FROM_STROAGE?: boolean
  /**
   * 是否跳过登录密码验证 0不可跳过 1可跳过
   */
  skip_verfify_login_pwd: 1 | 0
  remaining_bind_length: number
  /**
   * 是否需要强制绑定 （0：不强制绑定  >1：需要强制绑定）
   */
  must_bind_item: BindItemType
  /**
   * 强制绑定手机 0-关闭 1-开启
   */
  must_bind_phone: BindType
  /**
   * 强制绑定邮箱 0-关闭 1-开启
   */
  must_bind_email: BindType
  /**
   * 强制绑定谷歌验证码 0-关闭 1-开启
   */
  must_bind_google_auth: BindType

  /**
   * 强制绑定提现密码 0-关闭 1-开启
   */
  mustBindWithdrawPass: BindType

  /**
   * 强制绑定密保问题 0-关闭 1-开启
   */
  mustBindSecurityQuestion: BindType

  /**
   * 强制绑定提现账号 0-关闭 1-开启
   */
  mustBindWithdrawAccount: BindType

  /**
   * 是否需要强制修改密码，0,可正常登录，1，需要强制修改密码，不可关闭
   */
  change_password: 1 | 0
  /**
   * 葡萄牙货币的安全中心绑定的cpf
   */
  cpf?: string
  /**
   * 注册类型，0，普通注册，仅登录； 1，一键注册，可修改一次账号
   */
  reg_type: 0 | 1
  /**
   * session 登录后通行的token有效期1个月
   */
  session_key: string
  /**
   * 登录成功后的jwt_token有效期1年
   */
  jwt_token: string
  /**
   * 大厅金币数量
   */
  game_gold: number
  /**
   * VIP等级
   */
  vip_level: number
  /**
   * 昵称
   */
  nickname: string
  /**
   * 头像图片url
   */
  portrait_id: string
  /**
   * 保险箱状态，1：不能使用保险箱；2：没有创建过保险箱；3：已经创建好保险箱
   */
  strongbox_status: 1 | 2 | 3
  /**
   * 账号状态：1-正常，2-手动冻结，3-异常冻结，4-禁止领取优惠, 5-黑名单
   */
  user_status: UserStatusEnum
  /**
   * 会员层级
   */
  member_level: number
  /**
   * 试玩账号指 UI上显示未登录状态，会自动生成一个试玩账号登录，有一些和用户相关的入口路由需要不显示或拦截
   * 体验账号指 UI上显示用户信息，权限和试玩账号一致
   * 账号类型 1 试玩 2正式 3机器人 4体验账号（主播号） 5代理 9推广
   */
  account_type: AccountType
  /**
   * platfromid为字符串类型的 用户名 非 用户ID
   */
  platfromid: string
  /**
   * 余额宝状态
   */
  bank_status: number
  /**
   * username 为数字类型的 用户ID 非用户名
   */
  username: number

  /**
   * 真实姓名
   */
  realname: string
  /**
   * 用户key 快速登陆密钥
   */
  userkey: string
  /**
   * 代理模式
   */
  mode: number
  /**
   * 操作权限字段列表（1-是，0-否），[EUGORWithdrawalPasswd,EUGORSecretSecurity,EUGORMobilePhoneNumber,EUGORDeviceId,EUGORResetAccount,EUGORResetPwd]
   */
  userOptResult: Array<OptResult>
  /**
   * 继续游戏信息
   */
  lastgameinfo: SelfOperatedGameUrl
  /**
   * 推广权限, 1=正常，2=冻结
   */
  promoter_status: 1 | 2
  /**
   * 手机号码
   */
  mobile_phone: string
  /**
   * 手机验证状态，0未验证，1已验证
   */
  is_verified: number
  /**
   * 邮箱验证状态，0未验证，1已验证
   */
  emailVerified: number
  /**
   * 用户邮箱
   */
  email: string
  /**
   * 三方邮箱
   */
  thirdEmail: string
  /**
   * 谷歌验证设置 0未设置 1关 2开
   */
  is_open_google_auth: GAStatus
  /**
   * 三方账户类型 0 未绑定 1 google 2 fb
   */
  thirdType: ThirdWayStatus
  /**
   * 当前用户币种
   */
  currency: string
  /**
   * 显示帐号密码提示框 0-不显示 1-显示
   */
  showPassword: CommonSwitchStatus
  /**pc左上角头像VIP背景颜色*/
  vip_icon_back_color_value?: string
  /**VIP入口开关*/
  vip_status?: number
  /**
   * 强制绑定数量
   */
  must_bind_num: number
  /**
   * 登录返回字段新增permissionOpt(替代userOptResult)
   */
  permissionOpt: PermissionOptStatus
  /**登录失败时可能返回需要二步验证*/
  loginVerify?: LoginVerify

  /**
   * 是否需要强制修改提现密码 0 不需要  1需要
   */
  changeWithdrawPassword: CommonSwitchStatus
  /** 便捷登录注册，验证是新用户注册 */
  registrable?: {
    username?: string
    password?: string
  }
  /**返回该玩家注册时候的渠道cid */
  regPkgId: number
}

export type NormalLoginFormData = {
  username: string
  userpass: string
  /** 0：兼容旧版登录；1：账号；2：手机；3：邮箱 */
  loginType?: ApiLoginType
  /** 短信验证使用 */
  phone?: string
  email?: string
  captcha?: string
  secondVerify?: object
  /**当前运行环境是否是h5桌面 */
  isPwa?: number
} & GeetokenReq

export enum ApiLoginType {
  /** 账号 + 密码 */
  Account = 1,
  /** 手机 + 验证码 */
  Phone = 2,
  /** 邮箱 + 验证码 */
  Email = 3,
  /** 手机 + 密码 */
  PhonePass = 4,
  /** 邮箱 + 密码 */
  EmailPass = 5
}

/**
 * 账号、手机、邮箱登录
 */
export type LoginRegisterType = 0 | 1 | 2 | 3
export type LoginFormData = {
  username?: string
  userpass?: string
  /** 短信验证使用 */
  phone?: string
  /** 邮箱验证使用 */
  email?: string
  captcha?: string
  /**
   * 1 账号注册(默认)
   * 2 手机注册
   * 3 邮箱注册
   */
  loginType?: LoginRegisterType
}
/**
 * 短信登录
 */
export type SMSLoginReq = {
  phone: string
  vcode: string
} & GeetokenReq

export enum ThirdWayRequesterType {
  /**
   * APP
   */
  APP = 0,
  /**
   * WAP 一键注册sdk参数
   */
  WAP = 1,
  /**
   * facebock
   */
  FACEBOOK = 2,
  /**
   * Google
   */
  GOOGLE = 3,
  /**
   * Wechat
   */
  WECHAT = 4,
  /**
   * qq
   */
  QQ = 5,
  /**
   * Line
   */
  LINE = 6
}

/**
 * 操作系统类型： 1 IOS-APP 2 Android-APP 3 PC-h5 4 PC-Windows 5 IOS-H5 6 ANDROID-H5 IOS-LITE 9 ANDROID-LITE 10  IOS-VEST 11 ANDROID-VEST 12
 */
export enum OsType {
  //原生IOS
  IOS_APP = 1,
  //原生ANDROID
  ANDROID_APP = 2,
  PC_H5 = 3,
  PC_Windows = 4,
  IOS_H5 = 5,
  ANDROID_H5 = 6
}

/**
 * 三方登录
 */
export type ThirdWayLoginReq = {
  /**
   * 绑定账号时的用户名
   */
  platformId?: string
  /**
   * 绑定账号时的用户密码
   */
  passwd?: string
  access_token: string
  /**
   * optional请求方类型：0:APP；1:WAP 一键注册sdk参数 2 facebook 3 Google
   */
  requester_type: ThirdWayRequesterType

  /**
   * 短信验证码或图片验证码
   */
  verificationCode?: string
  /**
   * 手机号
   */
  mobilePhoneNumber?: string
  /**
   * 注册币种
   */
  currency?: string
  /**
   * 云控精聊平台
   */
  chtpf?: string
  /**
   * 云控精聊ID
   */
  chtjid?: string
  /**
   * 真实姓名
   */
  realName?: string
  /**
   * 邮箱
   */
  email?: string
  /**
   * 邮箱验证码
   */
  emailCode?: string
} & GeetokenReq

export type GeetokenReq = {
  /**
   * 设备验token
   */
  geeToken?: string
  /**
   * 设备验加载时间（后面会废弃）
   */
  geeTokenDuration?: number
}

/**
 * 三方登录的返回分为两种，一种已经绑定过的 直接登录，
 * 未绑定过的生成用户名和密码 引导 进行 修改后 登录
 */
export type ThirdWayLoginNewAccountRes = {
  email: string
  local_phone: string
  new_username: string
  new_password: string
  code: 100
}

// See: https://yapi.cg.xxx/project/961/interface/api/33928
export type NormalRegisterRequest = {
  /**
   * 用户名
   */
  platformId: string
  /**
   * 密码
   */
  passwd: string
  /**
   * 短信验证码或图片验证码
   */
  verificationCode: string
  /**
   * 邀请码
   */
  inviterId?: string
  /**
   * 手机号
   */
  mobilePhoneNumber?: string
  /**
   * 上级类型 1-渠道(cid) 2-代理用户(id)
   */
  agent_type?: 1 | 2
  /**
   * 上级渠道号或用户名
   */
  agent_code?: string
  /**
   * 货币
   */
  currency?: string
  /**
   * 云控精聊平台
   */
  chtpf?: string
  /**
   * 云控精聊ID
   */
  chtjid?: string
  /**
   * 真实姓名
   */
  realName?: string
  /**
   * 邮箱
   */
  email?: string
  /**
   * 邮箱验证码
   */
  emailCode?: string
  /**
   * cpf
   */
  cpfCode?: string
  /**
   * 注册类型  1=账号 2=手机 3=邮箱 4=第三方
   */
  registerType: 1 | 2 | 3 | 4
} & GeetokenReq

export type GeetestValidate = {
  /** 手机注册就是手机号，账号注册就是账号，邮箱注册就是邮箱 */
  account: string
  action?: string
} & GeetestValidateResult

export enum CreateModeOptType {
  Register = 1, //注册
  Bind = 2, //绑定
  Verify = 3, //验证
  LoginRegister = 4, // 登录注册
  RegisterIntegration = 5, // 账号邮箱手机一体化注册
  LoginIntegration = 6 // 账号邮箱手机一体化登录
}

export type BindPhoneReq = {
  phone: string
  vcode: string
}

export enum RiskSmsVerifyScene {
  /*
   * 首充进游戏短信验证(风控场景)
   */
  DEPOSIT_GAME_SMS_VERIFY = 'deposit_game_sms_verify',
  /*
   * 首次提现短信验证(风控场景)
   */
  WITHDRAW_SMS_VERIFY = 'withdraw_sms_verify'
}

export type RiskSmsVerifyReq = {
  phone: string
  captcha: string
  /**
   * 风控场景
   */
  scene?: RiskSmsVerifyScene
  flag?: string // 标签(活动使用)
}
/**
 * 获取短信验证码接口入参
 */
export type SmsCodeCreateReq = {
  create_mode: CreateModeOptType
  limit_type: string
  phone_number: string
}
/**
 * 验证短信验证码接口入参
 */
export type SmsCodeValidateReq = {
  captcha: string
  phone_number: string
  scene?: string
  flag?: string
}
/**
 * 验证短信验证码接口入参
 */
export type UserSmsCodeValidateReq = {
  captcha: string
  phone: string
  scene?: string
  flag?: string
}
/**
 * 验证输入的手机号是否正确
 * /user/sms/verifyphone
 */
export type VerifyPhoneInputReq = {
  phone: string
  username: string
}

/**
 * 未登录状态下
 * 获取账号验证方式接口
 */
export type AccountVerifyListNotLoginReq = {
  username: string
}

export type AccountVerifyListRes = {
  a: {
    /**
     * 绑定的邮箱，为空时代表未绑定
     */
    email: string
    /**
     * 绑定的手机号，为空时代表未绑定
     */
    mobile_phone: string
    /**
     * 谷歌验证
     * 1开启 0关闭
     */
    is_open_google_auth: GAStatusSimple
  }
  b: {
    /**
     * 密保问题 id
     * 0关闭 其它 密保问题中的id
     */
    question: number
    /**
     * 提现密码
     * 1开启 0关闭
     */
    withdraw_password: 1 | 0
  }
}

export type QuestionType = {
  id: number
  question: string
}

export type VerifyQuestionAndWithdrawPwdWithNotLoginReq = {
  second_verify:
    | SecondVerifyType<SecondAuthType.WITHDRAW_PWD>
    | SecondVerifyType<SecondAuthType.QUESTION>
  username: string
}

export enum SecondAuthType {
  NONE = 0, // 无(其它验证方式不存在时，才使用)
  /**
   * GA
   */
  GA = 1,
  /**
   * 邮箱
   */
  EMAIL = 2,
  /**
   * 手机
   */
  PHONE = 3,

  /**
   * 问题列表
   */
  QUESTION = 4,
  /**
   * 提现密码
   */
  WITHDRAW_PWD = 5,
  /**
   * login 账号密码
   */
  LOGIN_PWD = 6
}

export enum ValidType {
  WITH_LOGIN = SecondAuthType.LOGIN_PWD, // 登录密码6
  WITH_WITHDRAWER = SecondAuthType.WITHDRAW_PWD, // 提现密码5
  WITH_QUESTION = SecondAuthType.QUESTION, // 密保问题4
  WITH_PHONE = SecondAuthType.PHONE, // 手机3
  WITH_EMAIL = SecondAuthType.EMAIL, // 邮箱2
  WITH_GA = SecondAuthType.GA // 谷歌验证器1
}

export enum phoneVaildErrorCode {
  PHONE_ERROR = 1216
}

/**
 * 二次验证的数据类型
 */
export type SecondVerifyType<T extends SecondAuthType = SecondAuthType> =
  T extends SecondAuthType.NONE
    ? {
        type: T
      }
    : T extends SecondAuthType.GA
    ? {
        type: T
        google_code: string
      }
    : T extends SecondAuthType.EMAIL
    ? { type: T; email: string; email_code: string }
    : T extends SecondAuthType.PHONE
    ? {
        type: T
        phone: string
        phone_code: string
      }
    : T extends SecondAuthType.WITHDRAW_PWD
    ? {
        type: T
        withdraw_pass?: string
      }
    : T extends SecondAuthType.LOGIN_PWD
    ? {
        type: T
        login_pass: string
      }
    : T extends SecondAuthType.QUESTION
    ? {
        type: T
        pass_question_answer: string
        pass_question_id: number
      }
    : never

/**
 * 绑定的数据类型
 */
export type SecurityModifyType<
  T extends SecurityVerifyModifyType = SecurityVerifyModifyType
> = T extends SecondAuthType.NONE
  ? {
      type: T
    }
  : T extends SecurityVerifyModifyType.GA
  ? {
      type: T
      google_code: string
    }
  : T extends SecurityVerifyModifyType.EMAIL
  ? { type: T; email: string; email_code: string }
  : T extends SecurityVerifyModifyType.PHONE
  ? {
      type: T
      phone: string
      phone_code: string
    }
  : T extends SecurityVerifyModifyType.WITHDRAW_PASS
  ? {
      type: T
      withdraw_pass?: string
    }
  : T extends SecurityVerifyModifyType.LOGIN_PASS
  ? {
      type: T
      login_pass: string
    }
  : T extends SecurityVerifyModifyType.QUESTIONS
  ? {
      type: T
      pass_question_answer: string
      pass_question_id: number
    }
  : never

/**
 * 未登录时的重置密码接口
 * /user/notlogin/resetpasswd
 */
export type ResetPasswdNotLoginReq<T extends SecondAuthType = SecondAuthType> =
  {
    second_verify: SecondVerifyType<T>
    password: string
    username: string
  }

/**
 * 登录前获取验证码入参
 * 获取邮箱验证码接口入参
 * 登陆前 uername  必传 ，登陆后 不用传
 */
export type EmailCodeCreateReq = {
  create_mode: CreateModeOptType
  email: string
  limit_type?: string
  username?: string
}

/**
 * 验证输入的邮箱是否正确
 * /user/notlogin/verifyEmail
 */
export type VerifyInputEmailNotLoginReq = {
  email: string
  username: string
}

export type OthersCustomer = {
  androidUrl: string
  iosUrl: string
  pcUrl: string
  nickname: string
  number: string
  picture: string
  url: string
  type: number
}

/* --------------- pay/wallet/downloadUrl ------------------ */
// 获取钱包下载地址
export interface GetWalletDownloadURLPayload {
  /**
   * 设备类型 0: 安卓 1：Ios
   */
  device_type: string
}

export interface GetWalletDownloadURLData {
  url: Array<string>
}

export enum BrandLogoType {
  /**
   * 自定义模式
   */
  CUSTOM = 1,
  /**
   * 走图文配置模式
   */
  IMA_TXT = 2
}

/** 引导方案多语言映射 */
export interface GuideLanguage {
  /** 多语言的key */
  key: string[]
  /** 图片的链接地址 */
  src: string
  /** 多语言value */
  value: string
}

/** 引导下载配置项 */
export interface SuggestDownloadConfig {
  /** 下载横幅背景色 */
  bgColor: string
  /** 品牌名称的颜色 */
  brandNameColor: string
  /** 下载引导文案颜色  */
  downloadTextColor: string
  /** 关闭ICON颜色 */
  iconCloseColor: string
  /** 下载按钮的颜色 */
  iconDownloadColor: string
  /** 多语言数组(此处不清楚为什么是数组) */
  downloadLogoUrlLanguage: GuideLanguage[]
  /** 展示位置(值分为 上|中|下)  */
  placement: 'top' | 'middle' | 'bottom'
}

export interface OfficialChannelConfig {
  /** 官方频道名称 */
  name: string
  /** 官方频道图标链接 */
  icon: string
  /** 官方频道备注 */
  remark: string
  /** 官方频道跳转链接 */
  url: string
  /** 频道对应币种 */
  currency: string
}

export interface BrandLogoInfosRes {
  /** 类型：1自定义上传 2.图文结合 */
  type: BrandLogoType
  /** 关于我们开关状态 0-关闭，1-开启 */
  aboutUsStatus: 0 | 1
  /** 品牌名称-中英文切换 */
  brandName: string
  /** 大厅 logo 地址 */
  hallLogo: string
  /** 自定义下载logo */
  customizeDownloadLogoUrl: string
  /** 官网入口状态 0关闭,1开启 */
  officialWebsiteStatus: 0 | 1
  /** 官网地址 */
  officialWebsite: string
  /** H5引导下载开关 0关闭1开启 */
  downloadSwitch: boolean
  /** H5引导下载说明 */
  downloadGuide: string
  /** 删除icon色值 */
  deleteIconRgb: string
  /** 下载按钮背景色 */
  downloadBackgroundRgb: string
  /** 登录 logo 地址 */
  loginLogo: string
  /** 引导下载mobile配置 */
  h5Config: SuggestDownloadConfig
  /** 引导下载pc配置 */
  webConfig: SuggestDownloadConfig
  /** 自定义H5下载配置(单图片) */
  customizeH5Config: SuggestDownloadConfig
  /** 自定义WEB下载配置(单图片) */
  customizeWebConfig: SuggestDownloadConfig
  /** 自定义是否服务端注入数据 */
  _isFromService: boolean

  // 跟上面的引导下载不一样 上面的是首页上方的引导下载横幅，下面这两个是底部强制安装提示，这个弹出来后界面无法点击
  // https://www.cg.xxx/task-view-124300.html
  /**是否强制安装 开关  0关闭 1开启*/
  forceInstallationSwitch: number
  /** h5强制安装app提示 开关  0关闭 1开启安装app 2开启快捷方式开关 */
  bootInstallationSwitch: number
  /** 下载提示语 */
  installationHint: string
  /** 官方频道列表 */
  hallOfficialCommunitys: OfficialChannelConfig[] | []
  /**保存h5桌面快捷方式图标和App下载图标 */
  appIconBigPath: string
}

export interface UserAgreementRes {
  content: string
}

export interface ForceChangePwdReq {
  newpass: string
}

export interface FooterInfoRes {
  id: number
  siteCode: string
  /**
   * 是否隐藏 1是0否
   */
  quickJumpStatus: CommonSwitchStatus
  partnerInfoStatus: CommonSwitchStatus
  companyInfoStatus: CommonSwitchStatus
  contactInfoStatus: CommonSwitchStatus
  qualificationProofStatus: CommonSwitchStatus
  quickJump: {
    recreationInfo: {
      label: string
      value: string
      child: string[]
      childList: []
    }
    game: {
      label: string
      value: string
      child: string[]
      childList: []
    }
    support: {
      label: string
      value: string
      child: string[]
      childList: []
    }
    login: {
      label: string
      value: string
      child: string[]
      childList: []
    }
    aboutUs: {
      label: string
      value: string
      child: string[]
      childList: []
    }
    other: {
      label: string
      value: string
      child: string[]
      childList: []
    }
  }
  partnerInfo: {
    partnerImage: string
    partnerLink: string
  }[]
  companyInfo: {
    isDefault: boolean
    companyName: string
    issuingAuthority: string
    licenseKey: string
    content: string
    customContent: string
  }
  qualificationProof: {
    qualificationImage: string
    qualificationLink?: string
  }[]
  contactInfo: {
    isDefault: boolean
    copyrightInfo: string
    customCopyrightInfo: string
    contactContent: string[]
  }
}

type Num1234 = 1 | 2 | 3 | 4

export enum InfosReadStatus {
  Unread = 0,
  Read = 1
}

export interface FrameBoxInfos {
  id: number
  publicity_name: string
  img_style: Num1234
  img_color: string
  img_icon: string
  img_url: string
  content: string
  receiver_type: Num1234
  frame_type: Num1234
  frame_switch: 1 | 2
  frameType: Num1234
  frameSwitch: 1 | 2
  frameStartTime: number
  frameEndTime: number
  vip_ids: string
  level_ids: string
  user_ids: string
  user_names: string
  location_type: number
  location_name: string
  location_value: string
  location_template: string
  title: string
  /** 不再提示（本地存储新增的字段） */
  not_remind_again: boolean
  /** 登录后不再提示（本地存储新增的字段） */
  not_remind_login?: boolean
  hidden: 1 | 2 // 1宣传名称隐藏/2宣传名称不隐藏
  is_maintenance: boolean //是否关联维护
  maintenance_start: number //维护时间开始
  maintenance_end: number //维护时间结束
  location_jump_window?: JumpNewWindow //是否跳转新窗口(1是/2否)
  storageExpirationTime?: number //storage过期时间
  forceType: ForceType //弹窗类型 前端自己维护，用于区分首页弹窗tabs的icon 后端没有这个字段
  read: boolean //今日是否已读 前端自己维护的，用户tabs小红点 这个值放在缓存里 每天更新
  read_status: InfosReadStatus // 是否已读
}
// 弹窗集合
export interface FrameSet {
  notice: FrameBoxInfos[]
  announcement: FrameBoxInfos[]
  frame: FrameBoxInfos[]
}

export enum androidDownloadType {
  SPEED = 1,
  NORMAL = 2,
  COMPLETE = 3
}

/**APP名称列表
 */
export interface AppNameLanguage {
  /**语言 */
  key: string
  /**当前语言对应的值 */
  value: string
}

export interface DownloadUrlInfos {
  androidDownloadSize?: string[]
  android_download_url?: string[]
  customer_service_url?: string[]
  androidList?: {
    customizePath: string
    downloadPath: string
    size: number
    type: androidDownloadType
  }[]
  /**iOS下载链接列表 */
  downloadList?: {
    name: string
    value: string
    type: androidDownloadType
  }[]
  ios_super_sign_url?: string[]
  is_valid?: boolean
  qrCode?: string
  qrCodeType?: 0 | 1 // 0默认 1自定义
  webLinkType?: 0 | 1 // 0关闭 1开启
  /**APP图标-h5创建桌面快捷方式通过渠道需要用到 */
  appIcon?: string
  /**APP名称-h5创建桌面快捷方式通过渠道需要用到 */
  appNameLanguage?: Array<AppNameLanguage>
}

export enum RewardBindType {
  /**
   * 不绑定类型
   */
  None = -1,
  /**
   * 绑定手机和邮箱
   */
  BindPhoneAndSMSValid = -2,
  /**
   * 注册账号
   */
  Register = 0,
  /**
   * 绑定手机
   */
  BindPhone = 1,
  /**
   * 首次绑定银行卡
   */
  FirstBindBankCard = 2,
  /**
   * 绑定微信
   */
  BindWechat = 3,
  /**
   * 设置生日
   */
  SetBirthDay = 4,
  /**
   * 设置取款密码
   */
  SetWithdrawalPin = 5,
  /**
   * 设置密保问题
   */
  SetQuestion = 6,
  /**
   * 首次设置昵称
   */
  FirstSetNicname = 7,
  /**
   * 余额宝首存
   */
  YuebaoFirstDeposit = 8,
  /**
   * 设置头像
   */
  SetAvatar = 10,
  /**
   * 下载音乐曲库
   */
  DownloadMusicLibrary = 11,
  /**
   * 短信验证
   */
  SMSValid = 12,
  /**
   * 绑定WhatsApp
   */
  BindWhatsApp = 13,
  /**
   * 绑定Facebook
   */
  BindFacebook = 14,
  /**
   * 绑定Telegram
   */
  BindTelegram = 15,
  /**
   * 设置Google验证器
   */
  SetGa = 16,
  /**
   * 注册首存
   */
  FirstDeposit = 17,
  /**
   * 下载安装并登录APP
   */
  DownloadAndLogin = 18,
  /**
   * 绑定邮箱
   */
  BindEmail = 19,
  /**
   * 绑定数字货币
   */
  BindCrypto = 21,
  /**
   * 绑定Zalo
   */
  BindZalo = 22,
  /**
   * 绑定Line
   */
  BindLine = 23,
  /**
   * 绑定Twitter
   */
  BindTwitter = 24,
  /** 首次提现 */
  FirstWithdraw = 26,
  /** 添加任务联系方式 */
  AddContact = 27
}
export interface RewardBindDataBefore {
  /**新人福利开启的币种 */
  currency: string
  list: RewardBindData[]
}
export interface RewardBindData {
  bindType: RewardBindType
  bindTypeText: string
  /**0/1, 1表示有需要弹气泡，目前为了要压缩数据，只返回为1的记录 */
  isPop?: 1 | 0
  reward: number
  /**静态化接口后，将所以得货币对应的奖励都返回了，是一个JSON类型的字符串
   * @example
   * "[{\"currency\":\"EUR\",\"amount\":1},{\"currency\":\"TRX\",\"amount\":0}]"
   */
  currency?: string
  content?: string // 配置细节
}
/**
 * 注册送礼的金额-多国语言
 * */
export interface RegCurrency {
  currency: string //语言
  amount: number //金额
}

export interface AccessRestrictedInfo {
  accessRestrictedBackgroundColor: string
  accessRestrictedContent: string
  accessRestrictedImagePath: string
}

export enum LinkType {
  /**
   * 下载页
   */
  DOWNLOAD = 1,
  /**
   * 跳转注册页
   */
  REGISTER = 2,
  /**
   * 跳转试玩
   */
  TEST_REGISTER = 3,
  /**
   * 直达大厅
   */
  HOME = 4
}

/**
 * 活动分类ID
 */
export enum CategoryIds {
  /**
   * 斗鸡
   */
  djc = 0,
  /**
   * 全部
   */
  zh = 1,
  /**
   * 棋牌
   */
  qp = 2,
  /**
   * 捕鱼
   */
  by = 3,
  /**
   * 电子
   */
  dz = 4,
  /**
   * 真人
   */
  zr = 26,
  /**
   * 体育
   */
  ty = 27,
  /**
   * 彩票
   */
  cp = 28,
  /**
   * 电竞
   */
  dianjing = 29,
  /**
   * 区块链
   */
  blockchain = 12,
  /**
   * 斗鸡
   */
  dj = 100
}

/**
 * 活动分类ID
 */
export enum HomeLayoutType {
  /** 左右 */
  style0 = 0,
  /** 上下 */
  style1 = 1
}
/**
 * 加载类型
 */
export enum LoadingScreenTypes {
  // 全屏加载
  FULL_SCREEN_LOADING = 0,
  // 模块加载
  PART_SCREEN_LOADING = 1,
  // 默认（按钮加载）
  BTN_LOADING = 2
}
/**
 * 4小图（386*244），默认选中通用（1020*300）
 */
export enum WebBannerMode {
  /**
   * 通用 默认选中
   */
  default = 3,
  /**
   * 小图（386*244）
   */
  min = 4
}

/**
 *  宣传类型:1=web banner,2=加载页轮播,3=大厅弹窗,4=H5/APP banner 5 新数据（不区分平台和版式）
 */
export enum PublicityTypeEnum {
  /**
   * web banner
   */
  WebBanner = 1,
  /**
   * 加载页轮播
   */
  LoadingPage = 2,
  /**
   * 大厅弹窗
   */
  DialogData = 3,
  /**
   * H5/APP banner
   */
  AppBanner = 4,
  /**
   * 新banner数据 （不区分平台和版式）
   */
  NewBanner = 5
}

/**
 *  图片类型:1=默认(oss桶配置),0=非默认(后台配置)
 */
export enum ImgTypeEnum {
  /**
   * 非默认(后台配置)
   */
  NotDefault,
  /**
   * 默认(oss桶配置)
   */
  Default
}

/**
 *  h5/app 2大图（1200*353）4小图（386*244
 */
export enum AppBannerMode {
  /**
   * 大图（1200*353）默认
   */
  default = 1,
  /**
   * 小图（386*244)
   */
  min = 2
}

export enum GameIconStyle {
  // 竖版图片
  VERTICAL_BOTTOM = 1,
  // 方形图标
  SQUARE_BELOW = 2
}

export interface ImageItem {
  key: string
  value: string
}

/**重要按钮/字体颜色 */
export enum ImportantButtonColor {
  /**主色通用按钮/字体颜色，*/
  generalColor = 'generalColor',
  /**主色主色通用按钮中的字体颜色*/
  generalCentreColor = 'generalCentreColor',
  /**重要字体颜色*/
  importantColor = 'importantColor'
}

/**一般按钮/字体颜色 */
export enum GeneralButtonColor {
  /**普通段落字体颜色 */
  ordinaryColor = 'ordinaryColor',
  /**次级字体颜色 */
  secondaryColor = 'secondaryColor',
  /**较弱提示颜色 */
  weakerColor = 'weakerColor'
}

/**其他颜色 */
export enum OtherColor {
  /**左侧栏未选中字体色 */
  leftUnColor = 'leftUnColor',
  /**左侧栏选中字体色 */
  leftOnColor = 'leftOnColor',
  /**底部导航文字未选中色颜色*/
  bottomUnColor = 'bottomUnColor'
}
/**
 * 首页版式配置信息
 */
export enum BackgroundShadingDtoType {
  /**背景色+纹理图*/
  Texture = 1,
  /**背景图片*/
  Image = 2
}
export interface HomeLayoutInfos {
  backgroundColor: number | string
  iconColor: number
  isDefaultBg: number
  layouts: Array<string>
  /**
   * 登录后
   */
  loginAfterBottomLayouts: Array<{ name: string }>
  /**
   * 登陆前
   */
  loginBeforeBottomLayouts: Array<{ name: string }>
  /**
   * 中部导航
   */
  middleLayouts: Array<{ name: string }>
  middleLayoutsMore: Array<{ name: string }>
  topLayout: HomeLayoutType
  webBanner: WebBannerMode
  appBanner: AppBannerMode
  webGameIcon: GameIconStyle
  /**大厅自定义相关字段 */

  /**大厅背景 */
  hallLoginImage: string
  /**小屏幕适配 */
  cutOffset: number
  /**背景颜色 */
  hallLoginImageColor: string
  /**底板颜色 */
  hallLoginBasePlateColor: string
  /**个人中心背景 */
  centerImages: string
  /**游戏平台背景图 */
  gameImages: string
  /**底部导航背景图 */
  bottomImages: string
  /**底纹图配置*/
  backgroundShadingDto: {
    /**背景和底纹样式  1：默认（底纹背景颜色+底纹图），2：自定义*/
    type: BackgroundShadingDtoType
    /**底纹背景颜色*/
    backgroundColor: string
    /**底纹图片*/
    shadingType: string
    //下面两个要type === 2 才生效
    /**底纹自定义图片(web)*/
    webBackgroundShadingValue: string
    /**底纹自定义图片(app)*/
    appBackgroundShadingValue: string
  }
  //游戏菜单底图
  gameMenuBasemap: string
  //游戏菜单底图2
  gameMenuSelectBasemap: string
  //游戏菜单选中底图
  gameMenuBasemap2: string
  //游戏菜单选中底图2
  gameMenuSelectBasemap2: string

  /**重要按钮/字体颜色 */
  importantButtonColor: Array<{ key: ImportantButtonColor; value: string }>
  /**一般按钮/字体颜色 */
  generalButtonColor: Array<{ key: GeneralButtonColor; value: string }>
  /**首页顶部icon*/
  homeTopImage: ImageItem[]
  /**首页中部icon*/
  homeMiddleImage: ImageItem[]
  /**首页底部icon*/
  homeBottomImage: ImageItem[]
  /**游戏类别展示icon*/
  gameShowImage: ImageItem[]
  /**个人中心顶部icon*/
  ownerTopImage: ImageItem[]
  /**个人中心中部icon*/
  ownerMiddleImage: ImageItem[]
  /**个人中心列表icon*/
  ownerBottomImage: ImageItem[]
  /**游戏类别选中icon*/
  gameSelectedImage: ImageItem[]
  /**其他颜色 */
  otherColor: Array<{ key: OtherColor; value: string }>

  /**页脚版式 */
  baseConfig: {
    /**我的页面得布局类型 */
    appMyPage: string
    desktopShortcut: DesktopShortcut
  }
  /** 公共配置 */
  commonConfig: CommonConfig
  /** 游戏卡片样式 */
  appGameIcon: LayoutDesignGameIconEnum
  /** 平台卡片样式 */
  platformIcon: LayoutDesignPlatformIconEnum
}

/** 游戏卡片样式 */
export enum LayoutDesignGameIconEnum {
  /** 竖版蒙层图标 */
  VerticalMask = 1,
  /** 方版圆角图标 */
  Square = 2,
  /** 竖版图标 */
  Vertical = 3,
  /** 方版直角图标 */
  SquareRightAngle = 4,
  /** 圆版图标 */
  Rounded = 5,
  /** 横版图标 */
  Horizontal = 6
}

/** 平台卡片样式 */
export enum LayoutDesignPlatformIconEnum {
  /** 欧美样式 */
  EuropeanAmerican = 1,
  /** 左右样式1 */
  LeftRight1 = 2,
  /** 左右样式2 */
  LeftRight2 = 3,
  /** 圆形样式 */
  Rounded = 4,
  /** 方形样式 */
  Square = 5,
  /** 长方形样式 */
  Rectangle = 6
}

export enum TurnPageType {
  /** 翻页样式 1.手动加载 */
  HandLoadPage = '1',
  /** 翻页样式 2.手动翻页 */
  HandScroll = '2'
}
export interface CommonConfig {
  /** 维护图标样式类型编号(1-11) */
  gameMaintenanceType: string
  hallReturnType: string
  loadingType: string
  /** 登录注册版式：'1  | '2' | '3' */
  registerLogin: string
  /** 翻页样式 1.手动加载 2.手动翻页 */
  turnPageType: TurnPageType
  windowCss: Num1234
  footerStyle: string
  loadingImage: string
}
/**
 * 桌面快捷方式得四种布局
 * */
export enum DesktopShortcut {
  layout1 = '1',
  layout2 = '2',
  layout3 = '3',
  layout4 = '4'
}
export interface ReportDownloadUrlClickPayload {
  accessType: number
  accessUrl: string
  /**
   * 下载包类型: 1-极速包、2-正常包、3-完整包
   */
  packageType: number
  promoter_info: string
  visitor_device: string
}
/**
 * 极光推送的信息
 */
export interface WebPushInfos {
  webKey: string
  webMasterSecret: string
}

export interface IpCheckInfo {
  countryCode: string
  ip: string
  status: boolean
  areaStatus: boolean // 地区访问限制 , true=可访问 false=不可访问
  siteMaintenanceStatus: boolean // 站点状态  true=维护 false=正常
  recommendCurrency: string // 推荐币种，基于请求IP判断
  recommendAreaCode: string // 推荐区码，基于请求IP判断
  recommendLanguage: string // 推荐语种，基于请求IP判断
}

export interface LangItem {
  transName: string
  transId: number
  transContent: Record<string, string>
}

/**
 * 表示埋点配置的响应数据。
 * @typedef {Object} BuriedConfigResponse
 * @property {number} type - 类型（针对Category = 1时，1=GTAG, 2=GTM，当Category ！= 1时此字段无效）。
 * @property {number} category - 类别（1=Google, 2=Facebook/Mate Pixel, 3=Kwai Pixel, 4=TikTok Pixel）。
 * @property {string} gid - 三方ID。
 * @property {number} idxType - Idx的类型（1=渠道, 2=代理）。
 * @property {number} idx - 代理或者渠道ID，根据IdxType区分。
 */
export interface BuriedConfigResponse {
  type: number
  category: number
  gid: string
  idxType: number
  idx: number
}

/**
 * 配置埋点请求参数
 */
export interface BuriedConfigRequest {
  /**
   * ID 类型
   * 1 = 渠道
   * 2 = 上级代理ID
   * 3=自己的ID
   */
  idxType: number
  /**
   * 配置埋点的用户 ID
   */
  idx: number
  /**
   * 自己的ID
   */
  uid?: number
}

export interface SystemStatus {
  /**
   * 获取系统信息
   */
  homeGetSysInfo: SystemInfos

  /**
   *首页轮播
   */
  messageBannerIndex: Array<Banner>
  /**
   * 社交媒体
   */
  promoteGetSocialmedia: Array<SocialmediaResponse>
  /**
   * vip系统开关 让游客可以看到VIP入口
   */
  userVipConfig: Array<ApiVipSwitchStatusResponse>
  /**
   * 查询余额宝开关状态，让游客也可以看到余额宝入口,会员不需要调这个，调下面那个apiGetYuebaoIndex
   */
  yuebaoGetSetting: object
  /**
   * 获取音乐列表
   */
  homeMusicList: MusicResponse
}

export interface SystemStatusReq {
  platformType?: number
  osType: OsType
  gameMode?: number //请求类型 (如: 游戏2D/3D模式支持: 0-2D模式, 1-3D模式 )
  reqType?: number // 1系统音乐   2我的音乐
}

export interface OptimizationSiteConfig {
  engageLabWebPush: WebPushInfos

  layoutDesign: HomeLayoutInfos

  brandLogoUse: BrandLogoInfosRes

  sysLoadingBackdrop: unknown
  /**下载配置数据来自站点数据配置 */
  channelGlobalConfig: DownloadUrlInfos
}

export type MaintainType = {
  site_time_zone?: string
  online_service: string
  end_time: number
  start_time: number
}

export enum KindType {
  /** 正常访问 */
  Normal = 1,
  /** 点击下载 */
  ClickDownLoad = 2,
  /** App访问（极速和马甲） */
  App = 3
}

export enum PackKindType {
  /** 正常访问 */
  Normal = 1,
  /** 极速包 */
  Quickly = 2,
  /** 马甲包 */
  Vest = 3
}

/**
 * 域名埋点 参数
 */
export type AgentConfigRequest = {
  /**
   * 1 web大厅访问
   * 2 web大厅下载
   * 3 app大厅请求
   * 4 app大厅打开
   * 5 app域名更新
   * 6 app域名请求
   * 7 下载站访问
   * 8 下载站下载
   * 9下载站进入大厅
   */
  kind: KindType
  /**
   * 域名（最长250）
   */
  domain: string
  /**
   * 浏览器指纹（最长250）
   */
  fingerId?: string
  /** 点击下载的时候必传 下载包类型 1正常包 2极速包  */
  packKind?: PackKindType
}

/** 大额强制绑定 */
export type LargeAmountMustBind = {
  email: number
  phone: number // 0 关闭 1 开启
  googleAuth: number
}

/**
 * 0 ==> 关闭
 * 1 ==> 开启 · 选填
 * 2 ==> 开启 · 必填
 * 3 ==> 开启 · 必填 · 验证码验证（手机号 / 邮箱）
 */
export interface LoginRegistrySetting {
  currency: LevelStatus // 0 | 1
  /** 自带邀请码不可修改 */
  inviteCodeUnchangeable: LevelStatus // 0 | 1
  cpf: LevelStatus // 0 | 1 | 2
  inviteCode: LevelStatus // 0 | 1 | 2
  realName: LevelStatus // 0 | 1 | 2
  /** account  */
  passwordConfirm?: LevelStatus // 0 | 1 | 2
  /** email & phone  */
  account?: LevelStatus // 0 | 1 | 2
  /** account & phone & thirdParty  */
  email?: LevelStatus // 0 | 1 | 2 | 3
  /** account & email & thirdParty  */
  phone?: LevelStatus // 0 | 1 | 2 | 3
  /** account & phone @3 <手机号，邮箱注册，为3是必填确认密码> */
  password?: LevelStatus // 0 | 1 | 2 | 3
}

export enum LoginRegisterDefaultHint {
  Account = 0,
  Phone = 1,
  Email = 2
}

export enum LevelStatus {
  /** 关闭 */
  CLOSE,
  /** 开启 */
  OPEN,
  /** 开启并必填 */
  OPEN_REQUIRED,
  /** 开启并必填并校验 */
  OPEN_REQUIRED_VERIFY
}

/** 获取推广赚钱广告语/自定代理mode */
export type AgentModeDataResponse = {
  advertise: string
  agent_mode: number
  agent_id: number
  settleDuration: number
}
