import { ApiServiceInformationResponese } from '@/api/service/type'
import { GlobalService } from '@/context'
import siteConfig from '@/config/site.config'

/**
 * 获取客服信息
 */
export const apiServiceListV2 = () => {
  return GlobalService.get<ApiServiceInformationResponese>({
    url: '/customer/staffallv3',
    params: { siteCode: siteConfig.siteCode },
    customParams: {
      tryStaticMode: true,
      cache: true,
      staticKeyOmit: ['platformType'],
      keyOmit: true
    }
  }).then((res) => res.data.data as ApiServiceInformationResponese)
}
