import { Base, Component, Prop } from '@/vue-property-decorator'
import { CommonLoading } from '@/components/business-components'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import Container from '@/layouts/components/container'
import style from './style.module.scss'

type Props = {
  titleContent?: NonNullable<BaseRenderContent>
  loading?: boolean
  showBack?: boolean
  needH5Height?: boolean
  isShadowContent?: boolean
  /**
   * 返回按钮事件
   */
  goBack?: () => void
}

@Component<PageLayout>({ name: 'PageLayout' })
export default class PageLayout extends Base<unknown, Props> {
  @Prop()
  private titleContent!: Props['titleContent']
  @Prop(AppTypes.looseBool)
  private loading!: Props['loading']

  @Prop(AppTypes.bool.def(true))
  private readonly showBack!: NonNullable<Props['showBack']>

  @Prop(AppTypes.bool.def(true))
  private readonly needH5Height!: NonNullable<Props['needH5Height']>

  @Prop(AppTypes.bool.def(true))
  private readonly isShadowContent!: NonNullable<Props['isShadowContent']>

  @Prop(AppTypes.func.def(undefined))
  private readonly goBack!: NonNullable<Props['goBack']>

  private renderTitle() {
    if (this.titleContent)
      return (
        <header class={style.header}>
          <my-title
            showBack={this.showBack}
            title={this.titleContent}
            needH5Height={this.needH5Height}
            goBack={this.goBack}
          ></my-title>
        </header>
      )
    return null
  }

  get isWeb() {
    return useMainStore().isWeb
  }

  render() {
    return (
      <section class={style.pageRoot}>
        <Container>
          {this.renderTitle()}
          <CommonLoading
            spinning={this.loading}
            class={{
              [style.loadingContent]: this.isWeb && this.isShadowContent,
              ['page-loading__content']: true
            }}
          >
            {this.$slots?.default}
          </CommonLoading>
        </Container>
      </section>
    )
  }
}
