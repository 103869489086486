import { ComponentConfig, VueComponentType } from './type'
import {
  HolidayComponentType,
  HolidayConfigInfo,
  HolidayType
} from './config/holiday/type'
import {
  ThemeComponentType,
  ThemeConfigInfo,
  ThemeSkinType
} from './config/themes/type'

import { useMainStore } from '@/store/index'
import Render from '@/components/business-components/func-render/async-component'
import holidayConfig from './config/holiday'
import themeConfig from './config/themes'

// 配置类型
export enum ConfigType {
  Theme = 'theme',
  Holiday = 'holiday'
}

interface ConfigMap {
  [ConfigType.Theme]: Record<ThemeSkinType, ThemeConfigInfo>
  [ConfigType.Holiday]: Record<HolidayType, HolidayConfigInfo>
}

type NamespaceAndModuleType = {
  namespace: keyof ConfigMap
  moduleType: ThemeComponentType | HolidayComponentType
}

type BaseIdentifiersWithType<KeyType> = NamespaceAndModuleType & {
  key: KeyType
}

type ComponentIdentifiers =
  | BaseIdentifiersWithType<ThemeSkinType>
  | BaseIdentifiersWithType<HolidayType>

export default class ComponentsManager {
  // 存储所有的配置信息
  static configMap: ConfigMap = {
    [ConfigType.Theme]: themeConfig,
    [ConfigType.Holiday]: holidayConfig as Record<
      HolidayType,
      HolidayConfigInfo
    >
  }

  /**
   * 获取组件名称
   */
  static getName(identifiers: ComponentIdentifiers): string {
    const { normalName, config } = this.getComponentConf(identifiers)
    const { pc, h5 } = config
    const { isWeb } = useMainStore()
    const calcName = (specialKey?: HolidayType | ThemeSkinType | boolean) => {
      if (specialKey === false) {
        return 'my-empty'
      }

      if (typeof specialKey === 'undefined') {
        return normalName
      }

      const newIdentifiers = {
        namespace: identifiers.namespace,
        moduleType: identifiers.moduleType,
        key: specialKey as HolidayType | ThemeSkinType
      }

      return this.getComponentConf(newIdentifiers).normalName
    }
    return isWeb ? calcName(pc) : calcName(h5)
  }

  private static isTheme(type: ComponentIdentifiers) {
    return type.namespace === ConfigType.Theme
  }

  /**
   * 因为使用联合类型做索引，TS无法确定当前类型,故类型保护来准确指定期望的类型
   */
  private static getConf(identifier: ComponentIdentifiers) {
    return this.isTheme(identifier)
      ? this.configMap[ConfigType.Theme][identifier.key as ThemeSkinType]
      : this.configMap[ConfigType.Holiday][identifier.key as HolidayType]
  }

  /**
   * 获取组件名称和组件
   */
  private static getComponentConf(identifier: ComponentIdentifiers): {
    normalName: string
    config: ComponentConfig
  } {
    const { moduleType, key, namespace } = identifier
    const conf = this.getConf(identifier)
    // 根据模块类型，从配置中获取组件
    const config = conf[moduleType as keyof typeof conf]
    const normalName = `coms-${namespace}-${key}-${moduleType}`
    return { normalName, config }
  }

  /**
   * 获取当前命名空间的配置信息
   */
  static getConfig(
    namespace: ConfigType.Theme
  ): Record<ThemeSkinType, ThemeConfigInfo>
  static getConfig(
    namespace: ConfigType.Holiday
  ): Record<HolidayType, HolidayConfigInfo>
  static getConfig(namespace: keyof ConfigMap) {
    return this.configMap[namespace]
  }

  /**
   * 根据版式或节日主题生成需要注册的组件
   * @return {Record<string, VueComponentType>}
   */
  static register({
    namespace,
    moduleType
  }: NamespaceAndModuleType): Record<string, VueComponentType> {
    const config_entries = Object.entries(this.configMap[namespace])
    return config_entries.reduce((result, [key]) => {
      const identifier = {
        namespace,
        moduleType,
        key
      } as unknown as ComponentIdentifiers
      const { config, normalName } = this.getComponentConf(identifier)

      if (config?.com) {
        const keys = Object.keys(config.com)
        const syncMode = keys.includes('options') && keys.includes('use')
        result[normalName] = syncMode
          ? config.com
          : Render.createAsyncComponent({
              component: config.com as () => Promise<unknown>
            })
      }
      return result
    }, {} as Record<string, VueComponentType>)
  }
}

export { HolidayComponentType, ThemeComponentType }
