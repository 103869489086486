/**
 * 此文件应该尽量无状态引用，涉及到组件的可以用异步注册
 */
import { CommonLoading } from '..'
import VueUtils from '@/utils/VueUtils'
import style from './style.module.scss'
import type { AsyncComponent } from 'vue'

export default class Render extends VueUtils {
  public static createAsyncComponent = ({
    name,
    component,
    loadingComponentConfig = {},
    mode = 'standard'
  }: {
    name?: string
    component: () => Promise<unknown>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadingComponentConfig?: Record<string, any>
    mode?: 'standard' | 'async-on-sync'
  }) => {
    const createStandardAsyncComponent = () => {
      return {
        component: component(),
        delay: 0,
        loading: {
          // components: {
          //   CommonLoading: () => import('../loading')
          // },
          render() {
            return (
              <CommonLoading
                class={[style.loadingComponentCom]}
                /**
                 * 不加loading效果
                 */
                spinning={false}
                {...loadingComponentConfig}
              />
            )
          }
        }
      }
    }

    if (mode === 'async-on-sync') {
      return {
        name: `async-on-sync-${name}`,
        components: {
          'component-view': () => createStandardAsyncComponent()
        },
        render() {
          return <component-view />
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as unknown as AsyncComponent<any, any, any, any>
    }

    return (() => {
      return createStandardAsyncComponent()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as AsyncComponent<any, any, any, any>
  }
}
