import {
  GetYuebaoProfixCountPayload,
  GetYuebaoProfixCountResponse,
  SaveGoldPayload,
  TakeoutGoldPayload,
  YuebaoGetSettingResponse,
  YuebaoIndexResponse,
  YuebaoProfitPayload,
  YuebaoProfitResponse
} from './type'
import { GlobalService } from '@/context'
/**
 * 查询余额宝开关状态，让游客也可以看到余额宝入口,会员不需要调这个，调下面那个apiGetYuebaoIndex
 * @deprecated /system/status代替
 * */
export const apiGetYuebaoSwitchStatus = () => {
  return GlobalService.post<YuebaoGetSettingResponse>({
    url: '/yuebao/getSetting'
  })
}
/**
 * 查询余额宝大厅
 */
export const apiGetYuebaoIndex = (cache?: number) =>
  GlobalService.post<YuebaoIndexResponse>({
    url: '/yuebao/index',
    // 余额宝接口默认缓存时间为30分钟 30 * 60 * 1000，传0则不缓存
    customParams: { cache: cache ?? 30 * 60 * 1000 }
  })

/**
 *
 *  余额宝收益查看
 */
export const apiGetYuebaoProfit = (payload: YuebaoProfitPayload) =>
  GlobalService.post<YuebaoProfitResponse>({
    url: '/yuebao/profitRecord',
    data: payload
  }).then((res) => res.data.data as YuebaoProfitResponse)

/**
 *
 *  余额宝转出
 */
export const apiYuebaoTakeoutGold = (payload: TakeoutGoldPayload) =>
  GlobalService.post<YuebaoIndexResponse>({
    url: '/yuebao/takeoutGold',
    data: payload,
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as YuebaoIndexResponse)

/**
 *
 *  余额宝转入
 */
export const apiYuebaoSaveGold = (payload: SaveGoldPayload) =>
  GlobalService.post<YuebaoIndexResponse>({
    url: '/yuebao/saveGold',
    data: payload,
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as YuebaoIndexResponse)

/**
 *
 *  用户记录统计
 */
export const apiGetYuebaoProfixCount = (payload: GetYuebaoProfixCountPayload) =>
  GlobalService.post<GetYuebaoProfixCountResponse>({
    url: '/yuebao/profitCount',
    data: payload
  }).then((res) => res.data.data as GetYuebaoProfixCountResponse)

/**
 * 余额宝领取
 */
export const apiReceiveYuebao = () =>
  GlobalService.post({
    url: '/yuebao/receive',
    customParams: {
      noErrorMessage: true,
      silentOnError: true
    }
  }).then((res) => res.data.data ?? {})

/**
 * 利息宝领取
 */
export const apiReceiveInterest = () =>
  GlobalService.post({
    url: '/yuebao/getInterest',
    customParams: {
      noErrorMessage: true,
      silentOnError: true
    }
  }).then((res) => res.data.data ?? {})
