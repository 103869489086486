import { ComTableColumnType } from './type'
import { ExtractClassProps } from '@/plugins/ant-design-vue/type'
import { Table } from 'ant-design-vue'
import BusinessUtils from '@/utils/business-utils'
import style from './style.module.scss'

export const defaultComTableConfig: ExtractClassProps<
  Omit<Table, 'dataSource' | 'columns'>
> = {
  rowKey: 'id',
  bordered: false,
  defaultExpandAllRows: false,
  showHeader: true,
  rowClassName(_record, index) {
    if (index % 2 === 0) {
      return style.even
    }
    return style.odd
  }
}

export const defaultComTableColumnConfig: ComTableColumnType = {
  align: 'center',
  ellipsis: true,
  fixed: false,
  width: BusinessUtils.px2rem(120)
}

export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_MOBILE_PAGE_SIZE = 15
export const DEFAULT_TOTAL = 0
