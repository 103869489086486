import { ComponentConfig } from '../../type'
import { ValueOf } from 'type-fest'
import { nodeThemeConfig } from '@/context/const'
// 版式组件类型
export enum ThemeComponentType {
  HomePage = 'homePage',
  SubGamePage = 'subGamePage',
  SearchPage = 'searchPage',
  EmbeddedPage = 'embeddedPage',
  Footer = 'footer',
  FloatBar = 'floatBar',
  Layouts = 'layouts',
  Styles = 'style'
}

export const ThemeSkinType = nodeThemeConfig.ThemeSkinType
export type ThemeSkinType = ValueOf<typeof ThemeSkinType>

export type ThemeConfigInfo = Record<ThemeComponentType, ComponentConfig>
