import { loadScripts } from '@/utils/Loader'
import Logger from '@/utils/Logger'

export default class GeeTestHelper {
  /** 通用 SDK 加载器 */
  public static async loadSDK(
    id: string,
    src: string,
    globalFn: 'initGeetest4' | 'initGeeGuard',
    parallel = false,
    cache = true
  ) {
    // 当指定的全局函数不存在时，加载对应的 SDK
    if (!window[globalFn]) {
      try {
        await loadScripts([{ id, src }], parallel, cache)
      } catch (error) {
        Logger.error(`${globalFn} SDK 加载失败`)
      }
    }
  }
}
