import { AxiosRequestConfig } from 'axios'
import {
  CacheDep as BaseCacheDep,
  Cache,
  CacheConfig
} from '@/core/network/cache'
import { get } from 'lodash'
import { useMainStore } from '@/store/index'
import GlobalService from './network'
import Logger from '@/utils/Logger'
import moment from 'moment'

type Dep = 'uid' | 'language' | 'currency' | 'platformType'
type CacheDep = BaseCacheDep<Dep>
export class LobbyCache extends Cache {
  public dep(config: AxiosRequestConfig): CacheDep {
    const staticKeyOmit = config.customParams?.staticKeyOmit || []
    const defaultDepList: Array<Dep> = [
      'currency',
      'language',
      'uid',
      'platformType'
    ]
    /**
     * 是否依赖登录
     */
    const isNotDepLogin =
      GlobalService.getTokenMode(config) === 'beforeLogined' ||
      GlobalService.notNeedTokenFilter.includes(config?.url || '')
    return defaultDepList.filter((it) => {
      if (staticKeyOmit.includes(it)) {
        return false
      }
      if (isNotDepLogin && it === 'uid') {
        return false
      }
      return true
    })
  }
  // /**
  //  * 默认get直接开启缓存
  //  */
  // public defaultUse(config: AxiosRequestConfig): boolean {
  //   return config.method === 'get'
  // }
  protected id(
    id: string,
    cacheDep: CacheDep,
    axiosRequestConfig: AxiosRequestConfig = {}
  ) {
    if (typeof cacheDep === 'function') {
      return super.id(id, cacheDep, axiosRequestConfig)
    } else {
      const { language, userInfos, currentTryCurrency } = useMainStore()
      const list = [id]
      const map = {
        uid: () => {
          return userInfos?.username || ''
        },
        language: () => {
          return language
        },
        currency: () => {
          return userInfos?.currency || currentTryCurrency?.currencyCode
        },
        platformType: () => {
          const useAppEnv = axiosRequestConfig.customParams?.useAppEnv || false
          return useAppEnv ? 6 : 5
        }
      }
      cacheDep.forEach((dep) => {
        const item = map[dep]?.()
        if (item) {
          list.push(String(item))
        }
      })
      return list.join(':')
    }
  }

  log(
    item: { id: string; expiration: number; raw: unknown },
    config: CacheConfig,
    namespace: string
  ) {
    const url = get(item.raw, 'config.url')
    const data = get(item.raw, 'data')
    const log = `[CACHE ${namespace} ${
      item.id
    }] >>>>>> ${url}\n${JSON.stringify({
      expiration: moment(item.expiration).format()
    })}${JSON.stringify(config)}\n${JSON.stringify(data)}`
    const content = moment().format() + log
    Logger.success('cache', content)
    Logger.myDebug('cache', content)
  }

  protected beforeSet(
    item: { id: string; expiration: number; raw: unknown },
    config: CacheConfig
  ) {
    this.log(item, config, 'SET')
  }

  protected afterGet(
    item: { id: string; expiration: number; raw: unknown },
    config: CacheConfig
  ) {
    this.log(item, config, 'GET')
  }
}
