import { GlobalConst } from '@/context'
import { GoogleHelper } from '..'
import { IThirdPartyLoginStrategy } from './bases/LoginStrategy'
import { LoginInfos } from '../types'
import { SingletonStrategy } from './bases/SingletonStrategy'
import { delay } from '@/utils/Tool'
import { loadScripts } from '@/utils/Loader'
import { useMainStore } from '@/store/index'

export default class GoogleLoginStrategy
  extends SingletonStrategy
  implements IThirdPartyLoginStrategy
{
  public static readonly className = 'GoogleLoginStrategy'

  private loginResolve: ((value: LoginInfos) => void) | null = null

  private get hasSDK(): boolean {
    return typeof window?.google?.accounts?.id?.initialize === 'function'
  }

  public async initSDK() {
    if (!this.hasSDK) {
      await loadScripts({
        id: 'google-sdk',
        src: 'https://accounts.google.com/gsi/client'
      })
    }
  }

  public async prepare(): Promise<void> {
    await this.initSDK()
    return new Promise(async (resolve) => {
      const { systemInfos, language } = useMainStore()
      google.accounts.id.initialize({
        client_id: systemInfos?.google.app_id ?? 'none',
        callback: (data: { credential: string }) => {
          this.loginResolve?.({
            access_token: data.credential,
            email: ''
          } as LoginInfos)
          GoogleHelper.hideButton()
        }
      })

      const lang = GlobalConst.ServiceLanguageMap[language]
      google.accounts.id.renderButton(GoogleHelper.googleBtn, {
        type: 'standard',
        theme: 'filled_blue',
        text: 'continue_with',
        locale: lang
      })

      /** 谷歌国际化按钮会闪一下 */
      const googleDefaultBtn = document.querySelector(
        '#google-btn-help > div > div > div'
      ) as HTMLElement
      if (googleDefaultBtn) {
        googleDefaultBtn.style.display = 'none'
      }

      GoogleHelper.hideButton()
      await delay(1000)
      resolve()
    })
  }

  public async logIn() {
    return new Promise(async (resolve, reject) => {
      this.loginResolve = resolve
      // 假装在请求谷歌客户端
      await delay(1000)
      GoogleHelper.showButton()

      // 捕获谷歌客户端ID配置错误
      const googleIframe = document.querySelector(
        '#google-btn-help iframe'
      ) as HTMLIFrameElement
      await delay(1000)
      // 谷歌是没有提供加载失败或配置域名错误回调方法，目前是根据iframe没加载出来判断失败
      if (googleIframe?.style?.width === '0px') {
        // 有部分移动端浏览器第一次回加载iframe失败，这里重新加载iframe，如果还失败则弹提示框
        const selfSrc = googleIframe.src
        googleIframe.src = selfSrc
        await delay(1000)
        if (googleIframe?.style?.width === '0px') {
          reject('Google.login error')
        }
      }
    })
  }
}
