import { GlobalEventActions } from '@/context/events'
import { GlobalEvents } from '@/context'
import { IThirdPartyLoginStrategy } from './bases/LoginStrategy'
import { LoginInfos } from '../types'
import { useMainStore } from '@/store/index'
import Site from '@/controller/Site'
import axios from 'axios'

export default class LineLoginStrategy implements IThirdPartyLoginStrategy {
  private loginResolve: ((value: LoginInfos) => void) | null = null

  /**
   * 获取 Line ID token 后传给后端进行登录或注册操作
   */
  private async getLineIDToken(code: string) {
    const { systemInfos } = useMainStore()
    const data = new URLSearchParams()
    data.append('grant_type', 'authorization_code')
    data.append('code', code)
    data.append('client_id', systemInfos?.line.app_id ?? '')
    data.append('client_secret', systemInfos?.line.appSecret ?? '')
    data.append(
      'redirect_uri',
      `${window.location.origin}/pages/actions/index.html?type=line`
    )
    const url = 'https://api.line.me/oauth2/v2.1/token'
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => {
        this.loginResolve?.({
          access_token: response.data.id_token,
          email: ''
        })
      })
      .catch((err) => console.error(`line login error: ${err}`))
  }

  /**
   * Line登录成功后的回调，获取code参数
   */
  private onLineCallback(actions: GlobalEventActions) {
    if (actions.type === 'line') {
      if (actions.payload?.code) {
        this.getLineIDToken(actions.payload?.code as string)
      }
    }
  }

  public async prepare(): Promise<void> {
    return new Promise((resolve) => {
      const { systemInfos, uuid } = useMainStore()
      const lineAuth = 'https://access.line.me/oauth2/v2.1/authorize'
      const client_id = systemInfos?.line.app_id
      const local = `${window.location.origin}/pages/actions/index.html?type=line`
      const url = `${lineAuth}?response_type=code&client_id=${client_id}&redirect_uri=${encodeURIComponent(
        local
      )}&state=${Site.getServerTime()}${uuid}&scope=profile%20openid%20email`
      const iWidth = 400
      const iHeight = 550
      const iTop = (window.screen.height - 30 - iHeight) / 2
      const iLeft = -window.screen.width / 2

      window.open(
        url,
        'title',
        `height=${iHeight},innerHeight=${iHeight},width=${iWidth},innerWidth=${iWidth},top=${iTop},left=${iLeft},toolbar=no,menubar=no,scrollbars=auto,resizable=no,location=no,status=no,_blank`
      )
      GlobalEvents.subscribe(this.onLineCallback.bind(this))
      resolve()
    })
  }

  public async logIn() {
    return new Promise((resolve) => {
      this.loginResolve = resolve
      GlobalEvents.unsubscribe(this.onLineCallback.bind(this))
    })
  }
}
