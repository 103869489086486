import { ThemeComponentType } from './type'

export default {
  [ThemeComponentType.HomePage]: {
    com: () => import('@/views/game/themes/theme-25/home')
  },
  [ThemeComponentType.SearchPage]: {
    com: () => import('@/views/game/themes/theme-25/search')
  },
  [ThemeComponentType.SubGamePage]: {
    com: () => import('@/views/game/themes/theme-25/subGame')
  },
  [ThemeComponentType.EmbeddedPage]: {
    com: () => import('@/views/game/themes/theme-25/embedded')
  },
  [ThemeComponentType.Footer]: {
    com: () => import('@/layouts/components/footer/themes/theme-25')
  },
  [ThemeComponentType.FloatBar]: {
    com: () => import('@/layouts/components/floatBar/themes/theme-25')
  },
  [ThemeComponentType.Styles]: {
    com: () => import('@/views/global-styles/themes/theme-25')
  },
  [ThemeComponentType.Layouts]: {
    com: () => import('@/layouts/themes/theme-25')
  }
}
