import './style.scss'
import { BAR_MAP, BarDirection, BarMapItem, BarTrigger } from '../types'

import { Base, Component, Prop, Ref, VueTsx } from '@/vue-property-decorator'
import Animation from '@/utils/Animation'
import AppTypes, { instanceOf, string } from '@/vue-types'

export type MyBarProps = {
  parentRef: HTMLDivElement
  direction?: BarDirection
  trigger?: BarTrigger
  /**
   * 滑块
   */
  barStyle?: StyleValue
  /**
   * 轨道
   */
  thumbStyle?: StyleValue
  size?: string
  move?: string
}

@Component<MyBar>({
  name: 'MyBar'
})
export default class MyBar extends Base<unknown, MyBarProps> {
  @Prop(instanceOf(HTMLDivElement).isRequired)
  private readonly parentRef!: HTMLDivElement
  @Prop(string<BarDirection>().def('vertical'))
  private readonly direction!: NonNullable<BarDirection>
  @Prop(string<BarTrigger>().def('hover'))
  private readonly trigger!: NonNullable<BarTrigger>
  @Prop(
    AppTypes.style.def({
      backgroundColor: ''
    })
  )
  private readonly barStyle!: NonNullable<StyleValue>
  @Prop(
    AppTypes.style.def({
      backgroundColor: 'var(--theme-scroll-bar-bg-color)'
    })
  )
  private readonly thumbStyle!: NonNullable<StyleValue>
  @Prop(AppTypes.string)
  private readonly size!: string
  @Prop()
  private readonly move!: string
  @Ref()
  private readonly barRef!: HTMLDivElement
  @Ref()
  private readonly thumbRef!: HTMLDivElement

  private cursorDown = false

  private X = 0
  private Y = 0

  private get bar(): BarMapItem {
    return BAR_MAP[this.direction]
  }

  private get thumbStyleObj(): StyleValue {
    return Object.assign({}, this.thumbStyle, {
      [this.bar.size]: this.size,
      transform: `translate${this.bar.axis}(${this.move})`
    })
  }

  private clickThumbHandler(e: MouseEvent) {
    if (e.ctrlKey || e.button === 2) {
      return
    }
    this.startDrag(e)
    this[this.bar.axis] =
      this.thumbRef[this.bar.offset] -
      (e[this.bar.client] -
        this.thumbRef.getBoundingClientRect()[this.bar.direction])
  }

  private clickTrackHandler(e: MouseEvent) {
    const offset = Math.abs(
      this.barRef.getBoundingClientRect()[this.bar.direction] -
        e[this.bar.client]
    )
    const thumbHalf = this.thumbRef[this.bar.offset] / 2
    const thumbPositionPercentage =
      ((offset - thumbHalf) * 100) / this.barRef[this.bar.offset]

    const result =
      (thumbPositionPercentage * this.parentRef[this.bar.scrollSize]) / 100
    new Animation({ value: this.parentRef[this.bar.scroll] })
      .to(
        {
          value: result
        },
        300,
        'none'
      )
      .on(Animation.EventType.UPDATE, ({ value }) => {
        this.parentRef[this.bar.scroll] = value
      })
      .on(Animation.EventType.COMPLETE, () => {
        this.parentRef[this.bar.scroll] = result
      })
  }

  private startDrag(e: MouseEvent) {
    e.stopImmediatePropagation()
    this.cursorDown = true
    this.parentRef.querySelectorAll('iframe').forEach((item) => {
      item.style.pointerEvents = 'none'
    })
    document.addEventListener('mousemove', this.mouseMoveDocumentHandler)
    document.addEventListener('mouseup', this.mouseUpDocumentHandler)
    document.onselectstart = () => false
  }

  private mouseMoveDocumentHandler(e: MouseEvent) {
    if (this.isKeptAlive) {
      return
    }
    if (this.cursorDown === false) return
    const prevPage = this[this.bar.axis]

    if (!prevPage) return
    const offset =
      (this.barRef.getBoundingClientRect()[this.bar.direction] -
        e[this.bar.client]) *
      -1
    const thumbClickPosition = this.thumbRef[this.bar.offset] - prevPage
    const thumbPositionPercentage =
      ((offset - thumbClickPosition) * 100) / this.barRef[this.bar.offset]

    this.parentRef[this.bar.scroll] =
      (thumbPositionPercentage * this.parentRef[this.bar.scrollSize]) / 100
  }

  private mouseUpDocumentHandler() {
    if (this.isKeptAlive) {
      return
    }
    this.parentRef.querySelectorAll('iframe').forEach((item) => {
      item.style.pointerEvents = 'auto'
    })
    this.cursorDown = false
    this[this.bar.axis] = 0
    document.addEventListener('mousemove', this.mouseMoveDocumentHandler)
    document.onselectstart = null
  }

  destroyed() {
    document.removeEventListener('mouseup', this.mouseUpDocumentHandler)
  }

  render() {
    return (
      <div
        onClick={VueTsx.modifiers.stop}
        class={`my-scrollbar-bar my-scrollbar-${this.direction}`}
        onMousedown={this.clickTrackHandler}
        style={this.barStyle}
        ref={'barRef'}
      >
        <div
          class="my-scrollbar-thumb"
          ref={'thumbRef'}
          onMousedown={this.clickThumbHandler}
          style={this.thumbStyleObj}
        ></div>
      </div>
    )
  }
}
