import { ApiActiveSingPayload } from '../type'
import { ApiActiveSingResponse, PopSignResponse } from './type'
import { GlobalService } from '@/context'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
/**
 * 获取签到数据接口
 * @returns
 */
export const apiSignGet = (): Promise<PopSignResponse[]> =>
  GlobalService.get<PopSignResponse[]>({
    url: '/active/popSignActive',
    customParams: {
      tryStaticMode: true,
      staticKeyOmit: ['platformType'],
      keyOmit: true
    }
  }).then((res) => res.data.data as PopSignResponse[])

// keyOmit: true
/**
 * 活动-签到&补签
 */
export const apiActiveSing = (payload: ApiActiveSingPayload) =>
  GlobalService.post({
    url: '/active/sing',
    data: payload,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data as ApiActiveSingResponse)
