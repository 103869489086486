import { ValueOf } from 'type-fest'
/**saba系统正在维护中 */
export const sabMaintenanceStatus = 'UM99'

export interface IErrorResponse {
  errorCode: string
  message: string
  statusCode: number
  traceId: string
}

interface TeamInfo {
  homeId: number
  homeName: string
  homeIconUrl: string
  awayId: number
  awayName: string
  awayIconUrl: string
}

interface GameInfo {
  livePeriod: number
  clockDirection: string
  seconds: number
  isNeutral: boolean
  isHt: boolean
  isBreak: boolean
  isClosed: boolean
  inJuryTime: ByteLengthQueuingStrategy
  delayLive: boolean
  gameStatus: ByteLengthQueuingStrategy
  inPlayTime: string
  liveHomeScore: number
  liveAwayScore: number
}

interface SoccerInfo {
  homeRedCard: number
  awayRedCard: number
  homeYellowCard: number
  awayYellowCard: number
}

interface TennisInfo {
  homeGameScore: number[]
  awayGameScore: number[]
  homePointScore: string
  awayPointScore: string
  currentSet: number
  currentServe: number
}

interface BeachVolleyBallInfo {
  homeGameScore: number[]
  awayGameScore: number[]
  currentSet: number
  currentServe: number
  playerInjury: number
  isRain: boolean
}

interface ESportInfo {
  bestOfMap: number
  isStartingSoon: boolean
  moveBO3Down: boolean
  overTimeSession: number
  leagueGroup: string
  leagueGroupId: number
}

interface BasketballInfo {
  homeGameScore: number[]
  awayGameScore: number[]
  latestLivePeriod: number
  homeOverTimeScore: number
  awayOverTimeScore: number
}
interface BaseballInfo {
  homeGameScore: number[]
  awayGameScore: number[]
  homeOverTimeScore: number
  awayOverTimeScore: number
  baseHasRunner: boolean[]
  currentInning: number
  currentBattingTeam: number
  currentOuts: number
}
interface VolleyballInfo {
  homeGameScore: number[]
  awayGameScore: number[]
  homePointScore: number
  awayPointScore: number
  currentServe: number
  homeCurrentPoint: number
  awayCurrentPoint: number
  playerInjury: number
  lastestLivePeriod: number
}
/**赔率相关信息 */
export interface OddsPriceInfo {
  /**串关赔率 */
  parlayPrice: number
  /**马来盘赔率 */
  malayPrice: number
  /**香港盘赔率 */
  hongKongPrice: number
  /**	欧洲盘赔率 */
  decimalPrice: number
  /**印度尼西亚盘赔率	 */
  indoPrice: number
  /**美国盘赔率 */
  americanPrice: number
}

export const MarketStatusType = {
  /**比赛或事件正在进行中 */
  running: 'running',
  /**比赛或事件被临时中断或暂停，可能是由于某些特殊情况，如天气原因、技术问题或其他干扰因素 */
  suspend: 'suspend',
  /**比赛或事件已经结束，但还在进行结算阶段 */
  closePrice: 'closePrice',
  /**比赛或事件已经结束，并且结算已经完成 */
  closed: 'closed'
}

export type MarketStatusTypeValueof = ValueOf<typeof MarketStatusType>

export interface Market {
  /** 盘口是否已经结束了 */
  marketIsDisabled: boolean
  /**体育项目ID */
  sportType: number
  /**赛事ID */
  eventId: number
  /**投注类型 */
  betType: number
  /**投注类型名称 */
  betTypeName: string
  /**盘口ID */
  marketId: number
  /**最大投注限额 */
  maxBet: number
  /**是否为滚球赛事 */
  isLive: boolean
  /**盘口状态 */
  marketStatus: MarketStatusTypeValueof
  gameMap: number
  gameRound: number
  resourceId: string
  category: number
  sort: number
  /**
   * 赛事串关数量限制
   * 0: 该盘口不支持串关
      2: 下注时最少需要选2种组合
      3: 下注时最少需要选3种组合
      4,5....以此类推
   */
  combo: number

  selections: Array<MarketSelection>
}
// 文档地址：https://github.com/Saba-sports/OddsDirectAPI_CN/wiki/GetMarkets
export interface MarketSelection {
  /**投注类型选项 */
  key: string
  /**投注类型选项名称 */
  keyName: string
  /**球头 */
  point: number
  /**球头2 */
  point2: number
  /**赔率相关信息 */
  oddsPrice: OddsPriceInfo
  marketId?: number
  /**赛事Id */
  eventId?: number
}
export interface ModalParam {
  event: MatchEvent
  betType: BetTypeView
}
export interface BetModalParam {
  event: MatchEvent
  market: Market
  selection: MarketSelection
}
export interface MatchEvent {
  /**体育项目ID */
  sportType: number
  /**体育项目名称 */
  sportName: string
  /**联赛ID */
  leagueId: number
  /**联赛名称 */
  leagueName: string
  /**联赛图片URL, 如果图片不存在请使用预设联赛图片URL
{domain}/LeagueImg/league_flag.png
domain请用leagueIconUrl返回的domain取代 */
  leagueIconUrl: string
  /**赛事ID */
  eventId: number
  /**赛事代码 */
  eventCode: string
  /**赛事状态 */
  eventStatus: string
  isMainMarket: boolean
  /**系统开赛时间  */
  kickOffTime: string
  /**	开赛时间 */
  globalShowTime: string
  countryCode: string
  gameSession: number
  parentId: number
  isTest: boolean
  isLive: boolean
  isParlay: boolean
  isCashout: boolean
  isVirtualEvent: boolean
  hasLiveMarket: boolean
  marketCount: number

  marketCategories: number[]
  /**视频ID */
  streamingOption: number
  /**视频代码 */
  channelCode: string
  teamInfo: TeamInfo
  gameInfo: GameInfo
  soccerInfo: SoccerInfo | null
  tennisInfo: TennisInfo | null
  beachVolleyBallInfo: BeachVolleyBallInfo | null
  eSportInfo: ESportInfo | null
  basketballInfo: BasketballInfo | null
  baseballInfo: BaseballInfo | null
  volleyBallInfo: VolleyballInfo | null
  markets?: Market[] | null // 将接口sosports/sports/v1/GetEvents返回的 markets 放入events，根据eventId 关联
}

export interface GetEventsParams extends queryTime {
  query?: string
  from?: string
  until?: string
  includeMarket?: string
}
export interface SportsEvents {
  events: MatchEvent[]
  markets: Market[] | null
}
export interface BetTypeView {
  label: () => string // 盘口文字
  balls: string // 让球信息、大小值
  price: number // 盘口赔率
  market: Market // 盘口对应market
  selection: MarketSelection // 盘口对应 selection
  key: string // 盘口对应主客
  betTypes: Array<number> //1：Handicap； 让球， 7：上半场让球，17：下半场让球	h : for home team (主队); a : for away team (客队)
  priceStatus: 'equal' | 'up' | 'down' // equal up down 赔率变化指示（颜色、箭头）
  hidden: boolean
}
export interface IParlayBetInfo extends BetTypeView {
  eventInfo: MatchEvent
  market: Market
  selection: MarketSelection
}
export interface IPlaceBetPayload {
  vendorTransId: string // 厂商注单号码
  sportType: number // 体育项目 ID
  marketId: number // 盘口ID
  price: number // 赔率
  point: number // 球头
  key: string // 投注类型选项
  stake: number // 投注金额
  oddsOption: number // 下注选项 0：不接受盘口变更(预设) ; 1：只接受更好的赔率; 2：接受任何赔率变更
  oddsType: number // 盘口赔率类型，默认 3 欧洲赔率. 0 : by member setting (default) ; 1 : Malay Odds ; 2 : Hong Kong Odds ; 3 : Decimal Odds ; 4 : Indo Odds ; 5 : American Odds
  language: string // 指定欲回应的数据语系
}
export interface IPlaceBetRes {
  betPrice: number
  transId: number
  currentPrice: number
  stake: number
  betStatus: number // 注单状态 0 ：下注成功 ；1 ：下注失败
  betAcceptSecond: number
  /**null代表打/PlaceBet的时候拿到OddsChange */
  ticketStatus: string | null // 注单状态 running/reject/waiting/null
  maxBet: number
  minBet: number
}
export interface ISingleTicketPayload {
  sportType: number
  marketId: number
  key: string
  oddsType: number // 盘口赔率类型
}
export interface ISingleTicketRes {
  sportType: number //
  marketId: number //
  isDecimalType: boolean //
  point: number //
  key: string //
  betType: number //
  oddsType: number //
  price: number //
  status: string //
  maxBet: number //
  minBet: number //
  liveHomeScore: number //
  liveAwayScore: number
}
export interface IParlayTicketsrPayload {
  language: string
  parlayTickets: IParlayTickets[]
}
export interface IParlayTicketsrRes {
  /**串关赛事相关信息 */
  combos: IComboInfo[]
  /**盘口赔率相关信息 */
  priceInfo: IPriceInfo[]
}
export interface IComboInfo {
  /**串关组合 https://github.com/Saba-sports/OddsDirectAPI_CN/wiki/Parlay-Combo-Enumeration */
  comboType: string
  /**串关组合名称含注单张数 */
  comboTypeName: string
  /**赔率 */
  price: number
  /**注单数 */
  betCount: number
  /**最小下注限额 */
  minBet: number
  /**最大下注限额 */
  maxBet: number
  /**赔率小于串关最小限制赔率列表 */
  abnormalMarketList: ParlayAbnormalMarket[]
}
export interface SystemParlayInfoType {
  /**结算的赢利或亏损金额 */
  settlementAmount: number

  /**下注金额 */
  stake: number
  /**下注赔率 */
  price: number
}
export interface GetSystemParlayDetailsType {
  systemParlayInfo: Array<SystemParlayInfoType>
}
export interface IComboAmount extends IComboInfo {
  /**投注金额 */
  amount: number | null
}
export interface ParlayAbnormalMarket {
  /**盘口ID */
  marketId: number
  /**赔率最小限制 */
  minOdds: number
}
export interface IParlayTickets {
  /**体育项目 ID */
  sportType: number
  /**盘口ID */
  marketId: number
  /**球头, 预设为0 */
  point: number
  /**投注类型选项 */
  key: string
}
export interface IPriceInfo {
  /**
   * @example
   * 0 ： 可下注状态
    6 ： 球头已不存在
    12 ： 赔率调整中
    18 ： [E-Sport 专用] 赛事状态改变 ( In-Play 和 Starting Soon)
    22 ： 赛事比分改变
    23 ： 赛事球头改变
   */
  stateCode: number
  /**该串关选择的状态信息 */
  stateMessage: string
  /**投注当下实时赔率 */
  currentPrice: number
  /**盘口ID */
  marketId: number
  /**投注类型选项 */
  key: string
  /**体育项目ID */
  sportType: number
  /**球头 */
  point: number
  /**投注类型 */
  betType: number
  /**盘口赔率类型
   * @example
   * 2 : Hong Kong Odds
   * 3 : Decimal Odds
   */
  oddsType: number
  /**盘口状态 */
  status: string
  /**主队得分 */
  liveHomeScore: number
  /**客对得分 */
  liveAwayScore: number
}
export interface IOddsTypes {
  key: number
  label: string
}
export interface IPlaceParlayBetPayload {
  betInfo: {
    vendorTransId: string
    priceOption: number
    tickets: ITikets[]
    combos: ICobbos[]
    language: string
  }
}
export interface ITikets {
  sporttype: number
  marketid: number
  point: number
  key: string
  price: number
}
export interface ICobbos {
  combotype: string // 三串一 等类型常量
  stake: number
}
export interface IParlayResult {
  response: IPlaceParlayBetRes
  combos: IComboAmount[]
}
export interface IPlaceParlayBetRes {
  transId: string
  sysTransId: string
  luckyTransId: number
  totalStake: number
  maxBet: number
  minBet: number
  betStatus: number // 0 ：下注成功 ；1 ：下注失败
  parlayBetAcceptSec: number
  parlayTicketStatus: string
  singleTickets: SingleTicketInfo[]
  currentCombos: CurrentComboInfo[]
  priceInfo: IPriceInfo[]
  /**失败原因 */
  falidReason: string
}
export interface SingleTicketInfo {
  transId: string
  price: number
  stake: number
  maxBet: number
  minBet: number
  ticketStatus: string
}
export interface CurrentComboInfo {
  comboPrice: number
  comboType: string
  stake: number
  betCount: number
  maxBet: number
  abnormalMarketList: ParlayAbnormalMarket[]
}
export interface Sport {
  /**体育项目ID */
  sportType: number
  /**体育项目名称 */
  sportName: string
  /**该体育项目的滚球赛事数量 */
  liveGameCount: number
  /**该体育项目的非滚球赛事数量 */
  gameCount: number
  /**该体育项目的串关赛事数量	 */
  parlayGame: number
  /**该体育项目的优胜冠军赛事数量 */
  outrightGame: number
}

// 临时标记&&
export interface SportsAuth {
  tokenType: 'Bearer'
  token: string
  sabaUrl: string
}

export interface queryTime {
  from?: string
  until?: string
}

export interface League {
  leagueId: number
  leagueName: string
  sportType: number
  sportName: string
  liveGameCount: number
  gameCount: number
  isParlay: boolean
}

export interface GetStreamingParams {
  sportType: number
  streamingOption: number
  channelCode: string
}

export interface SportsStreaming {
  /**中國IP */
  streamingUrl: string
  /**非中國IP */
  streamingUrlH5: string
  streamingUrlCN: string
  streamingUrlNonCN: string
  streamingUrlStreamer: string
}
