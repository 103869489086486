import {
  AfterRechargeActivityItem,
  ApiActiveIsShowV2Response,
  ApiActiveReceiveOnePayload,
  ApiActiveReceiveOneResponse,
  ApiActivetaskGetprizePayload,
  ApiActivetaskGetprizeResponse,
  ApiDoMatchBetRequest,
  ApiEventCollectWordsCompile,
  ApiEventCollectWordsExtractAll,
  ApiEventGet,
  ApiEventGetCollectWordsImage,
  ApiEventIntegral,
  ApiEventInvestDoInvestRes,
  ApiEventInvestReceiveRes,
  ApiEventReceiveReward,
  ApiEventReceiveSingle,
  ApiEventSignReceive,
  ApiGetInterestResponse,
  ApiLuckyIntegralList,
  ApiMatchBetRecordList,
  ApiPromoteDetailsType,
  ApiUserGiftRecv1Payload,
  ApiUserGiftRecv1Response,
  CheckCodePhoneNumberReq,
  CheckCodePhoneNumberResp,
  CustomActivityListItem,
  DisableActivePopResq,
  DiscountCodeData,
  DiscountCodeResp,
  EventCategoriesResData,
  FastEntryList,
  GetByTemplateReq,
  GetRankingListParams,
  HKPromoteActiveData,
  IsShowDiscountResp,
  LotteryResult,
  LuckyBetRecord,
  PromoteDetailsTypeList,
  RankingItem,
  Record,
  RotaryType,
  TurntableTaskList
} from '@/api/event/type'
import { GlobalService } from '@/context'
export { apiEventAndDiscountsRedDot } from './apiEventAndDiscountsRedDot/apiEventAndDiscountsRedDot'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'

/**
 * 获取活动分类和活动列表
 * @returns
 */

export const apiEventCategoryAndActivity =
  async (): Promise<EventCategoriesResData> => {
    const response = await GlobalService.get<EventCategoriesResData>({
      url: '/active/category',
      customParams: {
        staticKeyOmit: ['platformType'],
        keyOmit: true,
        cache: 60 * 1000,
        tryStaticMode: true
      },
      headers: {
        deviceModel: useDeviceType().deviceModel,
        devicetype: useDeviceType().deviceType
      }
    })
    return response?.data?.data as EventCategoriesResData
  }
/**
 * 获取活动接口
 * @param activeId
 * @returns
 */
export const apiEventGet = (
  activeId: number,
  isRefresh = false
): Promise<ApiEventGet> =>
  GlobalService.post<ApiEventGet>({
    url: '/active/get',
    data: {
      activeId
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      cache: isRefresh ? 0 : true
    }
  }).then((res) => res.data.data as ApiEventGet)

/** 活动排行榜更多详情 */
export const apiEventGetRankingList = (
  data: GetRankingListParams
): Promise<{ list: RankingItem[] }> =>
  GlobalService.post<{ list: RankingItem[] }>({
    url: '/active/getRankingList',
    data
  }).then((res) => res.data.data as { list: RankingItem[] })

/**
 * 获取幸运活动接口
 */
export const apiEventIntegral = (): Promise<ApiEventIntegral> =>
  GlobalService.get<ApiEventIntegral>({
    url: '/active/getOneLuckyIntegral'
  }).then((res) => res.data.data as ApiEventIntegral)

/**
 * 活动-获取HK推广活动
 * @param activeId
 * @returns
 */
export const apiHKPromoteActiveGet = (): Promise<HKPromoteActiveData> =>
  GlobalService.post<HKPromoteActiveData>({
    url: '/active/hkPromoteActive',
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as HKPromoteActiveData)

/**
 * 活动-HK推广活动 领取
 */
export const apiEventReceiveHK = (payload: {
  activeId: number
  receiveId: string
}) =>
  GlobalService.post<ApiEventReceiveSingle>({
    url: '/active/receiveOne',
    data: {
      ...payload
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiEventReceiveSingle)

/**
 * 显示转盘活动公告接口
 * @param activeId
 * @param rotaryType
 * @returns
 */
export const apiEventGetAnnouncement = (activeId: number, rotaryType: number) =>
  GlobalService.post<Array<Record>>({
    url: '/active/announcement',
    data: {
      activeId,
      rotaryType
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data || [])
/**
 * 转盘-任务列表
 * @param activeId
 * @returns
 */
export const apiEventGetTurntableTaskList = (activeId: number) =>
  GlobalService.post<TurntableTaskList>({
    url: '/active/turntableTaskList',
    data: {
      activeId
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data)
/**
 * 转盘活动 我的中奖记录接口
 * @param activeId
 * @returns
 */
export const apiEventGetMyLogs = (activeId: number) =>
  GlobalService.post<Array<Record>>({
    url: '/active/my_logs',
    data: {
      activeId,
      page: 1
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data || [])

/**
 * @deprecated
 */
export const apiEventTurntableAction = (
  activeId: number,
  receiveId: RotaryType
) =>
  GlobalService.post<LotteryResult>({
    url: '/active/receive',
    data: {
      activeId,
      receiveId
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  })

/**
 * @deprecated
 * 一键领取接口 (v3.8后废弃)
 * 打码 救援金 推广
 */
export const apiEventReceiveAll = (
  activeId: number,
  requestDeviceType: number
): Promise<ApiEventReceiveReward> =>
  GlobalService.post<ApiEventReceiveReward>({
    url: '/active/receiveall',
    data: {
      activeId,
      requestDeviceType
    },
    customParams: {
      cache: false,
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiEventReceiveReward)
/**
 * 集字活动-抽字, 领取接口
 * @param activeId 活动id
 * @param isAll 是否一键抽字（1 表示全部抽字 0单个抽字）
 * @returns
 */
export const apiEventCollectWordsExtract = (
  activeId: number,
  isAll: number
): Promise<ApiEventGetCollectWordsImage[]> =>
  GlobalService.post<ApiEventGetCollectWordsImage[]>({
    url: '/active/extract_word',
    data: {
      activeId,
      isAll
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiEventGetCollectWordsImage[])
/**
 * 集字活动-一键抽字(v3.8废弃)
 */
export const apiEventCollectWordsExtractAllWord = (
  activeId: number
): Promise<ApiEventCollectWordsExtractAll> =>
  GlobalService.post<ApiEventCollectWordsExtractAll>({
    url: '/active/extract_allWord',
    data: {
      activeId
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiEventCollectWordsExtractAll)
/**
 * 集字活动-合成
 * @param activeId
 * @returns
 */
export const apiEventCollectWordsCompile = (
  activeId: number
): Promise<ApiEventCollectWordsCompile> =>
  GlobalService.post<ApiEventCollectWordsCompile>({
    url: '/active/group_word',
    data: {
      activeId
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiEventCollectWordsCompile)

/**
 * 集字/充值/打码单次领取
 */
export const apiEventReceiveSingle = (payload: {
  activeId: number
  receiveId: string
}) =>
  GlobalService.post<ApiEventReceiveSingle>({
    url: '/active/receiveOne',
    data: {
      ...payload
    },
    customParams: {
      cache: false,
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiEventReceiveSingle)

/**
 * @deprecated v3.8后接口将弃用
 * 投资活动/救援金/推广领取
 */
export const apiEventInvestReceive = (payload: {
  activeId: number
  receiveId: number
  requestDeviceType: number
}) =>
  GlobalService.post<ApiEventInvestReceiveRes>({
    url: '/active/receive',
    data: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiEventInvestReceiveRes)

/**
 * 投资接口
 */
export const apiEventInvestDoInvest = (payload: {
  activeId: number
  amount: number
  ruleId: number
}) =>
  GlobalService.post<ApiEventInvestDoInvestRes>({
    url: '/active/investment_handler',
    data: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiEventInvestDoInvestRes)

/**
 * @deprecated
 * 签到活动领取 v3.8后废弃
 */
export const apiEventSignReceive = (
  activeId: number,
  receiveId: string
): Promise<ApiEventSignReceive> =>
  GlobalService.post<ApiEventSignReceive>({
    url: '/active/receiveOne',
    data: {
      activeId,
      receiveId
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiEventSignReceive)

/**
 * 转盘-幸运值详情列表
 * @param activeId
 * @param page
 * @param size
 * @returns
 */
export const apiLuckyIntegralList = (
  activeId: number,
  page = 1,
  size = 20
): Promise<ApiLuckyIntegralList> =>
  GlobalService.post({
    url: '/active/luckyIntegralList',
    data: {
      activeId,
      page,
      size
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiLuckyIntegralList)
/**
 * @deprecated
 * 活动单次领取
 * @param activeId
 * @param receiveId
 * @returns
 */
export const apiEventReceive = (
  activeId: number,
  receiveId = 0,
  requestDeviceType: number
): Promise<unknown> =>
  GlobalService.post({
    url: '/active/receive',
    data: {
      activeId,
      receiveId,
      requestDeviceType
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  })
/**
 * 申请_自定义
 * @param activeId 活动id
 * @param answers 申请答案
 * @param requestDeviceType 设备类型 1:iOS ,2:Android ,3:H5 4: Web
 * @returns Promise
 */
export const apiRequestReward = (
  activeId: number,
  answers: {
    id: number
    answer: string
  }[],
  requestDeviceType: number
): Promise<unknown> =>
  GlobalService.post({
    url: '/active/request_reward',
    data: {
      activeId,
      answers,
      requestDeviceType
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  })

/**
 * 竞猜-下注
 * @returns Promise
 */
export const apiActiveDoMatchBet = (
  data: ApiDoMatchBetRequest
): Promise<unknown> =>
  GlobalService.post({
    url: '/active/doMatchBet',
    data,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  })

/**
 * 竞猜-下注记录
 * @returns Promise
 */
export const apiMatchBetRecordList = (
  activeId: number,
  page = 1,
  size = 20
): Promise<ApiMatchBetRecordList> =>
  GlobalService.post({
    url: '/active/matchBetRecordList',
    data: {
      activeId,
      page,
      size
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiMatchBetRecordList)
/**
 * 获取快捷活动列表
 * @returns Promise
 */
export const apiGetQucikList = (isRefresh = false): Promise<FastEntryList> =>
  GlobalService.get({
    url: '/active/quickList',
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    // 缓存1分钟
    customParams: {
      staticKeyOmit: ['platformType'],
      keyOmit: true,
      cache: isRefresh ? 0 : 60 * 1000,
      tryStaticMode: true
    }
  }).then((res) => res.data.data as FastEntryList)

/**
 * 获取幸运注单获奖列表
 * @returns Promise
 */
export const apiGetLuckyBetRecord = (data: {
  activeId: number
}): Promise<LuckyBetRecord> =>
  GlobalService.post({
    url: '/active/luckyOrderList',
    data,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as LuckyBetRecord)
/** 充值活动首存弹框
 * @deprecated 该接口已废弃,不可再调用
 * 标记日期 1/28 标记编号(相同编号的逻辑,要一起删除) 544125478
 */
// export const apiGetPopRechargeList = (): Promise<PopRechargeListResp> =>
//   GlobalService.post({
//     url: '/active/get_active_pop',
//     data: { popType: 1 },
//     headers: {
//       deviceModel: useDeviceType().deviceModel,
//       devicetype: useDeviceType().deviceType
//     }
//   }).then((res) => {
//     const { disablePop, popData } = res.data.data as {
//       disablePop: number
//       popData: PopRechargeListResp['actives']
//     }
//     return {
//       disablePop,
//       actives: popData
//     } as PopRechargeListResp
//   })
/**
 * 优惠码-检查手机号是否可用
 * @returns Promise
 */
export const apiCheckCodePhoneNumber = (
  data: CheckCodePhoneNumberReq
): Promise<CheckCodePhoneNumberResp> =>
  GlobalService.post({
    url: '/active/checkCodePhoneNumber',
    data,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as CheckCodePhoneNumberResp)
/**
 * 优惠码-使用优惠码
 * @returns Promise
 */
export const apiUseDiscountCode = (
  data: DiscountCodeData
): Promise<DiscountCodeResp> =>
  GlobalService.post({
    url: '/active/useDiscountCode',
    data,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as DiscountCodeResp)
/**
 * @deprecated
 * 优惠码-查询是否显示优惠码
 * @returns Promise
 */
export const apiIsShowDiscount = (): Promise<IsShowDiscountResp[]> =>
  GlobalService.post({
    url: '/active/isShowDiscount',
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as IsShowDiscountResp[])

/**
 * 获取活动列表byTemplate 查询指定的类型活动列
 * 比如查询红包活动，看是否含有红包活动，如果没有红包活动则销毁红包轮询
 */
export const apiGetByTemplate = (
  data: GetByTemplateReq
): Promise<ApiEventGet[]> =>
  GlobalService.get({
    url: '/active/getByTemplate',
    data,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      staticKeyOmit: ['language', 'platformType'],
      cache: 60 * 1000,
      keyOmit: true,
      tryStaticMode: true
    }
  }).then((res) => res.data.data as ApiEventGet[])

/**
 * 活动-获取自定义页面活动
 */
export const apiCustomPageList = (): Promise<CustomActivityListItem[]> =>
  GlobalService.get({
    url: '/active/customPageList',
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      staticKeyOmit: ['platformType'],
      keyOmit: true,
      tryStaticMode: true
    }
  }).then((res) => res.data.data as CustomActivityListItem[])

/**
 * 首充活动弹窗 禁用(永不提示)
 */
export const apiDisableActivePop = (
  data: DisableActivePopResq
): Promise<unknown> =>
  GlobalService.post({
    url: '/active/disable_active_pop',
    data,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  })
/**
 * 获取推广详情
 * @param activeId
 * @param userName
 * @param isPass
 * @param size
 * @param page
 */
export const apiPromotionDetails = (
  data: ApiPromoteDetailsType
): Promise<PromoteDetailsTypeList> =>
  GlobalService.post({
    url: '/active/promote_details',
    data,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as PromoteDetailsTypeList)

/**
 * 活动-领取单个活动（新接口，v3.7小版本后调用）
 */
export const apiActiveReceiveOne = (payload: ApiActiveReceiveOnePayload) =>
  GlobalService.post<ApiActiveReceiveOneResponse>({
    url: '/active/receiveOne',
    data: payload,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiActiveReceiveOneResponse)

/**
 * 任务-单个领取
 */
export const apiActivetaskGetprize = (payload: ApiActivetaskGetprizePayload) =>
  GlobalService.post<ApiActivetaskGetprizeResponse>({
    url: '/activetask/getprize',
    data: payload,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiActivetaskGetprizeResponse)

/**
 * VIP领取奖励（新）
 */
export const apiUserGiftRecv1 = (payload: ApiUserGiftRecv1Payload) =>
  GlobalService.post<ApiUserGiftRecv1Response>({
    url: '/user/giftRecv1',
    data: payload,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiUserGiftRecv1Response)

/**
 * 利息宝领取
 */
export const apiGetInterest = () =>
  GlobalService.post<ApiGetInterestResponse>({
    url: '/yuebao/getInterest',
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiGetInterestResponse)

/**
 * VIP/返水/利息宝 开关配置
 */
export const apiActiveIsShowV2 = (): Promise<ApiActiveIsShowV2Response> =>
  GlobalService.get<ApiActiveIsShowV2Response>({
    url: '/active/isShowV2',
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      tryStaticMode: true,
      staticKeyOmit: ['currency', 'language', 'platformType'],
      keyOmit: true,
      appendDefault: true
    }
  }).then((res) => res.data.data as ApiActiveIsShowV2Response)

/**
 * 活动-待领取 手动派发领取
 */
export const apiActiveReceiveManualSend = (
  payload: ApiActiveReceiveOnePayload
) =>
  GlobalService.post<ApiActiveReceiveOneResponse>({
    url: '/active/receiveManualSend',
    data: payload,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiActiveReceiveOneResponse)

/** 获取 充值后弹窗数据列表 */
export const apiAfterRechargeActivity = (): Promise<
  AfterRechargeActivityItem[]
> =>
  GlobalService.post({
    url: '/active/afterRechargePop',
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as AfterRechargeActivityItem[])
