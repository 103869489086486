import { Base, Component, Prop } from '@/vue-property-decorator'
import style from './style.module.scss'

type Props = {
  titleContent: NonNullable<BaseRenderContent>
}

@Component<CommonTitle>({ name: 'CommonTitle' })
export default class CommonTitle extends Base<unknown, Props> {
  @Prop({ required: true })
  readonly titleContent!: Props['titleContent']

  render() {
    return (
      <span class={style.main}>
        <span class={style.flag}></span>
        {/* 自定活动文案会超长, 故使用自动文本，原始代码暂做注释处理 */}
        <auto-text
          maxSize={30}
          width={1170}
          height={44}
          text={this.titleContent as string}
        />
        {/* <span>{this.titleContent}</span> */}
      </span>
    )
  }
}
