import { Base, Component, Emit, Prop } from '@/vue-property-decorator'
import { Button } from '@/plugins/ant-design-vue/tsx-support'
import { useI18n } from '@/i18n'
import Animation from '@/utils/Animation'
import AppTypes from '@/vue-types'
import style from './style.module.scss'

type ButtonAttributes = VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
  typeof Button.Tsx,
  unknown
>

type Props = {
  duration?: number
  disabled?: ButtonAttributes['disabled']
  loading?: ButtonAttributes['loading']
  type?: ButtonAttributes['type']
}

type Events = {
  onClick: Event
  onComplete: void
}

type ScopedSlots = {
  default?: {
    /**
     * 是否正在倒计时中
     */
    isCountingDown: boolean
    /**
     * 倒计时的值
     */
    countDownValue: number
  }
}

@Component<CountdownBtn>({ name: 'CountdownBtn' })
class CountdownBtn extends Base<unknown, Props, Events, ScopedSlots> {
  @Prop(AppTypes.number.def(60))
  private duration!: NonNullable<Props['duration']>
  @Prop(AppTypes.bool)
  private disabled!: Props['disabled']
  @Prop()
  private loading!: Props['loading']
  @Prop(AppTypes.string.def('link'))
  private type!: NonNullable<Props['type']>

  private countDownAnimation!: Animation | null

  /**
   * -1 时代表未进入倒计时状态
   */
  private countDownValue = -1

  private get isCountingDown() {
    return this.countDownValue > -1
  }

  private get isDisabled() {
    return this.disabled || this.isCountingDown
  }

  @Emit('click')
  private onClick() {
    return
  }
  @Emit('complete')
  private onComplete() {
    return
  }

  public reset() {
    this.countDownAnimation?.kill()
    this.countDownValue = -1
    this.countDownAnimation = null
  }

  public doCountDown() {
    if (this.isDisabled) return
    const duration = this.duration
    this.countDownValue = duration
    this.countDownAnimation?.kill()
    this.countDownAnimation = new Animation({
      time: duration
    })
      .to({ time: 0 }, duration * 1000, 'none')
      .on(Animation.EventType.UPDATE, (result) => {
        this.countDownValue = parseInt(result.time)
      })
      .on(Animation.EventType.COMPLETE, () => {
        this.reset()
        this.onComplete()
      })
  }

  private defaultRender() {
    const { t } = useI18n()
    if (this.isCountingDown) {
      return t('lobby.modal.login.form.smsCode.counting', {
        seconds: this.countDownValue
      })
    }
    return (
      <span class={style.getCode}>
        {t('lobby.modal.login.form.smsCode.send')}
      </span>
    )
  }

  render() {
    return (
      <Button.Tsx
        type={this.type}
        loading={this.loading}
        disabled={this.isDisabled}
        {...{
          on: {
            click: this.onClick
          }
        }}
      >
        {this.$scopedSlots?.default?.({
          isCountingDown: this.isCountingDown,
          countDownValue: this.countDownValue
        }) || this.defaultRender()}
      </Button.Tsx>
    )
  }
}

export default CountdownBtn
