import { Base, Component, Emit, Prop, Watch } from '@/vue-property-decorator'
import { ContryInfos } from '@/api/common/type'
import { GlobalConst } from '@/context'
import { Icon, Select } from '@/plugins/ant-design-vue/tsx-support'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import Site from '@/controller/Site'
import style from './index.module.scss'

export interface Props {
  /** 上次选择的区号 */
  lastPhoneSegment?: boolean
  /** 只有一个区号时是否展示 */
  onlyOneHide?: boolean
  /** 是否是内联到手机输入框的样式 */
  inline?: boolean
}

export interface Event {
  onChange: string
  onCountry?: ContryInfos
}
@Component<PhoneSegment>({ name: 'PhoneSegment' })
export default class PhoneSegment extends Base<unknown, Props, Event> {
  @Prop(AppTypes.bool.def(true))
  private lastPhoneSegment!: Props['lastPhoneSegment']
  @Prop()
  private onlyOneHide!: Props['onlyOneHide']
  @Prop()
  private inline!: Props['inline']

  /** 初始时的手机区号 */
  private phoneSegment = ''
  /** 初始时的手机区号 */
  private loading = false
  private fetchError = false

  private get countrys() {
    return useMainStore().countryInfos
  }

  private get country() {
    return this.countrys.find((d) => d.phoneNumberSegment === this.phoneSegment)
  }

  private get showSegment() {
    if (this.onlyOneHide) {
      return this.countrys.length !== 1
    }
    return true
  }

  private get selectValue() {
    if (this.country?.countryName && this.phoneSegment) {
      return `${this.country?.countryName}=${this.phoneSegment}`
    }
    return
  }

  @Watch('phoneSegment', { immediate: true })
  @Emit('change')
  protected onChange() {
    return this.phoneSegment
  }

  @Watch('country', { immediate: true })
  @Emit('country')
  protected onCountry() {
    return this.country
  }

  /** 初始化手机区号 */
  private async initSmsCountry() {
    this.loading = true
    const error = await Site.initSmsCountry()
    this.loading = false
    this.fetchError = Boolean(error)
    if (!this.fetchError) {
      this.initPhoneSegment()
    }
  }
  /** 初始化默认手机区号 */
  private initPhoneSegment() {
    const { ipCheck, defaultCountry, getAutoFillData } = useMainStore()
    const cacheData = getAutoFillData()
    if (!this.countrys.length) return

    const sortCountry = [
      /** 用户上次选择的区号 */
      this.lastPhoneSegment ? cacheData.phoneSegment : undefined,
      /** ip推荐区号 */
      ipCheck.recommendAreaCode,
      /** 区号列表返回的默认区号 */
      defaultCountry?.phoneNumberSegment,
      /** 兜底默认展示列表第一个区号 */
      this.countrys[0].phoneNumberSegment
    ]
    const findCountry = sortCountry.find((phoneSegment) =>
      this.countrys.find((item) => item.phoneNumberSegment === phoneSegment)
    )

    if (findCountry) this.phoneSegment = findCountry
  }

  mounted() {
    this.initSmsCountry().finally(this.initPhoneSegment)
  }

  render() {
    const { t } = useI18n()

    return (
      <span
        class={style.segment}
        v-show={this.showSegment}
        data-inline={this.inline}
      >
        <span
          v-show={this.fetchError}
          class={style.retry}
          onClick={this.initSmsCountry}
        >
          <icon-sprite
            class={[
              style.retryIcon,
              [
                {
                  [GlobalConst.CssClassName.Animate.Animated]: this.loading
                },
                GlobalConst.CssClassName.Animate.Spin,
                GlobalConst.CssClassName.Animate.Repeat.infinite
              ]
            ]}
            sprite={createSvgSprite('comm_icon_retry')}
          />
        </span>

        <div class={style.selectedIcon}>
          {this.country && (
            <my-img src={this.country.icon} width={28} height={21} />
          )}
        </div>
        <Select.Tsx
          show-search
          class={GlobalConst.CssClassName.Lobby.Antd.SelectSearch}
          dropdownMatchSelectWidth={false}
          dropdownClassName={style.dropdown}
          dropdownStyle={{ display: 'flex' }}
          notFoundContent={t('lobby.modal.noData')}
          optionLabelProp={'label'}
          value={this.selectValue}
          onChange={(val: string) => {
            this.phoneSegment = val.split('=')[1]
          }}
        >
          {this.countrys.map((country) => (
            <Select.Option.Tsx
              value={`${country.countryName}=${country.phoneNumberSegment}`}
              label={country.phoneNumberSegment}
              key={`${country.countryName}=${country.phoneNumberSegment}`}
            >
              <div class={style.segmentDropdownItem}>
                <Icon.Tsx
                  component={{
                    functional: true,
                    render() {
                      return (
                        <my-img src={country.icon} width={28} height={21} />
                      )
                    }
                  }}
                />
                <span>
                  {`${country.countryName}（${country.phoneNumberSegment}）`}
                </span>
              </div>
            </Select.Option.Tsx>
          ))}
        </Select.Tsx>
      </span>
    )
  }
}
