import { LocaleMessages } from 'vue-i18n'
import { PngSprite, SvgSprite } from '@/components/icon-sprite'
import { ValueOf } from 'type-fest'
import { createPngSprite } from '@/utils/business-utils/assets'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { useI18n } from '@/i18n'

/**
 *
 * code: string //货币code
  symbol: string //货币符号
  thousandsSeparator: string //千分位分隔符
  decimalSeparator: string //小数点分隔符
  symbolOnLeft: boolean //货币符号是否显示在左边
  spaceBetweenAmountAndSymbol: boolean //值和货币符号之间是否需要空格  $ 123.00 ，$123.00
  decimalDigits: number //该币种默认小数位数
  negativeFirst: boolean //负号是否显示在首位 true: -$123.00 false: $-123.00
 */
export type CurrencyCode = keyof typeof config
export type CurrencySymbol = ValueOf<typeof config>['symbol']
const config = {
  AED: {
    code: 'AED',
    symbol: 'د.إ.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  AFN: {
    code: 'AFN',
    symbol: '؋',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  ALL: {
    code: 'ALL',
    symbol: 'Lek',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    negativeFirst: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2
  },
  AMD: {
    code: 'AMD',
    symbol: '֏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    negativeFirst: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2
  },
  ANG: {
    code: 'ANG',
    symbol: 'ƒ',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    negativeFirst: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2
  },
  AOA: {
    code: 'AOA',
    symbol: 'Kz',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  ARS: {
    code: 'ARS',
    symbol: '$',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  AUD: {
    code: 'AUD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  AWG: {
    code: 'AWG',
    symbol: 'ƒ',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  AZN: {
    code: 'AZN',
    symbol: '₼',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  BAM: {
    code: 'BAM',
    symbol: 'КМ',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  BBD: {
    code: 'BBD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  BDT: {
    code: 'BDT',
    symbol: '৳',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 0,
    negativeFirst: true
  },
  BGN: {
    code: 'BGN',
    symbol: 'лв.',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  BHD: {
    code: 'BHD',
    symbol: 'د.ب.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 3,
    negativeFirst: true
  },
  BIF: {
    code: 'BIF',
    symbol: 'FBu',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  BMD: {
    code: 'BMD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  BND: {
    code: 'BND',
    symbol: '$',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  BOB: {
    code: 'BOB',
    symbol: 'Bs',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  BRL: {
    code: 'BRL',
    symbol: 'R$',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  BSD: {
    code: 'BSD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  BTN: {
    code: 'BTN',
    symbol: 'Nu.',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 1,
    negativeFirst: true
  },
  BWP: {
    code: 'BWP',
    symbol: 'P',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  BYR: {
    code: 'BYR',
    symbol: 'р.',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  BYN: {
    code: 'BYN',
    symbol: 'р.',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  BZD: {
    code: 'BZD',
    symbol: 'BZ$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  CAD: {
    code: 'CAD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  CDF: {
    code: 'CDF',
    symbol: 'FC',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  CHF: {
    code: 'CHF',
    symbol: 'CHF',
    thousandsSeparator: "'",
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  CLP: {
    code: 'CLP',
    symbol: '$',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 0,
    negativeFirst: true
  },
  CNY: {
    code: 'CNY',
    symbol: '¥',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  USDT: {
    code: 'USDT',
    symbol: 'U',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  COP: {
    code: 'COP',
    symbol: '$',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  CRC: {
    code: 'CRC',
    symbol: '₡',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  CUC: {
    code: 'CUC',
    symbol: 'CUC',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  CUP: {
    code: 'CUP',
    symbol: '$MN',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  CVE: {
    code: 'CVE',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  CZK: {
    code: 'CZK',
    symbol: 'Kč',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  DJF: {
    code: 'DJF',
    symbol: 'Fdj',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  DKK: {
    code: 'DKK',
    symbol: 'kr.',
    thousandsSeparator: '',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  DOP: {
    code: 'DOP',
    symbol: 'RD$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  DZD: {
    code: 'DZD',
    symbol: 'د.ج.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  EGP: {
    code: 'EGP',
    symbol: 'ج.م.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  ERN: {
    code: 'ERN',
    symbol: 'Nfk',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  ETB: {
    code: 'ETB',
    symbol: 'ETB',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  EUR: {
    code: 'EUR',
    symbol: '€',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  FJD: {
    code: 'FJD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  FKP: {
    code: 'FKP',
    symbol: '£',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  GBP: {
    code: 'GBP',
    symbol: '£',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  GEL: {
    code: 'GEL',
    symbol: 'GEL',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  GHS: {
    code: 'GHS',
    symbol: '₵',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  GIP: {
    code: 'GIP',
    symbol: '£',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  GMD: {
    code: 'GMD',
    symbol: 'D',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  GNF: {
    code: 'GNF',
    symbol: 'FG',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  GTQ: {
    code: 'GTQ',
    symbol: 'Q',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  GYD: {
    code: 'GYD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  HKD: {
    code: 'HKD',
    symbol: 'HK$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  HNL: {
    code: 'HNL',
    symbol: 'L.',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  HRK: {
    code: 'HRK',
    symbol: 'kn',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  HTG: {
    code: 'HTG',
    symbol: 'G',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  HUF: {
    code: 'HUF',
    symbol: 'Ft',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  IDR: {
    code: 'IDR',
    symbol: 'Rp',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  ILS: {
    code: 'ILS',
    symbol: '₪',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  INR: {
    code: 'INR',
    symbol: '₹',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  IQD: {
    code: 'IQD',
    symbol: 'د.ع.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  IRR: {
    code: 'IRR',
    symbol: '﷼',
    thousandsSeparator: ',',
    decimalSeparator: '/',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  ISK: {
    code: 'ISK',
    symbol: 'kr.',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 0,
    negativeFirst: true
  },
  JMD: {
    code: 'JMD',
    symbol: 'J$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  JOD: {
    code: 'JOD',
    symbol: 'د.ا.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 3,
    negativeFirst: true
  },
  JPY: {
    code: 'JPY',
    symbol: '¥',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  KES: {
    code: 'KES',
    symbol: 'KSh',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  KGS: {
    code: 'KGS',
    symbol: 'сом',
    thousandsSeparator: ' ',
    decimalSeparator: '-',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  KHR: {
    code: 'KHR',
    symbol: '៛',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  KMF: {
    code: 'KMF',
    symbol: 'CF',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  KPW: {
    code: 'KPW',
    symbol: '₩',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  KRW: {
    code: 'KRW',
    symbol: '₩',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  KWD: {
    code: 'KWD',
    symbol: 'د.ك.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 3,
    negativeFirst: true
  },
  KYD: {
    code: 'KYD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  KZT: {
    code: 'KZT',
    symbol: '₸',
    thousandsSeparator: ' ',
    decimalSeparator: '-',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  LAK: {
    code: 'LAK',
    symbol: '₭',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  LBP: {
    code: 'LBP',
    symbol: 'ل.ل.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  LKR: {
    code: 'LKR',
    symbol: '₨',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 0,
    negativeFirst: true
  },
  LRD: {
    code: 'LRD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  LSL: {
    code: 'LSL',
    symbol: 'M',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  LYD: {
    code: 'LYD',
    symbol: 'د.ل.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 3,
    negativeFirst: true
  },
  MAD: {
    code: 'MAD',
    symbol: 'د.م.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  MDL: {
    code: 'MDL',
    symbol: 'lei',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  MGA: {
    code: 'MGA',
    symbol: 'Ar',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  MKD: {
    code: 'MKD',
    symbol: 'ден.',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  MMK: {
    code: 'MMK',
    symbol: 'K',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  MNT: {
    code: 'MNT',
    symbol: '₮',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  MOP: {
    code: 'MOP',
    symbol: 'MOP$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  MRO: {
    code: 'MRO',
    symbol: 'UM',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  MTL: {
    code: 'MTL',
    symbol: '₤',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  MUR: {
    code: 'MUR',
    symbol: '₨',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  MVR: {
    code: 'MVR',
    symbol: 'MVR',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 1,
    negativeFirst: true
  },
  MWK: {
    code: 'MWK',
    symbol: 'MK',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  MXN: {
    code: 'MXN',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  MYR: {
    code: 'MYR',
    symbol: 'RM',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  MZN: {
    code: 'MZN',
    symbol: 'MT',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  NAD: {
    code: 'NAD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  NGN: {
    code: 'NGN',
    symbol: '₦',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  NIO: {
    code: 'NIO',
    symbol: 'C$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  NOK: {
    code: 'NOK',
    symbol: 'kr',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  NPR: {
    code: 'NPR',
    symbol: '₨',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  NZD: {
    code: 'NZD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  OMR: {
    code: 'OMR',
    symbol: '﷼',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 3,
    negativeFirst: true
  },
  PAB: {
    code: 'PAB',
    symbol: 'B/.',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  PEN: {
    code: 'PEN',
    symbol: 'S/.',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  PGK: {
    code: 'PGK',
    symbol: 'K',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  PHP: {
    code: 'PHP',
    symbol: '₱',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  PKR: {
    code: 'PKR',
    symbol: '₨',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  PLN: {
    code: 'PLN',
    symbol: 'zł',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  PYG: {
    code: 'PYG',
    symbol: '₲',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  QAR: {
    code: 'QAR',
    symbol: '﷼',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  RON: {
    code: 'RON',
    symbol: 'L',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  RSD: {
    code: 'RSD',
    symbol: 'Дин.',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  RUB: {
    code: 'RUB',
    symbol: '₽',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  RWF: {
    code: 'RWF',
    symbol: 'RWF',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  SAR: {
    code: 'SAR',
    symbol: '﷼',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  SBD: {
    code: 'SBD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SCR: {
    code: 'SCR',
    symbol: '₨',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SDD: {
    code: 'SDD',
    symbol: 'LSd',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SDG: {
    code: 'SDG',
    symbol: '£‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SEK: {
    code: 'SEK',
    symbol: 'kr',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  SGD: {
    code: 'SGD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SHP: {
    code: 'SHP',
    symbol: '£',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SLL: {
    code: 'SLL',
    symbol: 'Le',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SOS: {
    code: 'SOS',
    symbol: 'S',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SRD: {
    code: 'SRD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  STD: {
    code: 'STD',
    symbol: 'Db',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SVC: {
    code: 'SVC',
    symbol: '₡',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  SYP: {
    code: 'SYP',
    symbol: '£',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  SZL: {
    code: 'SZL',
    symbol: 'E',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  THB: {
    code: 'THB',
    symbol: '฿',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  TJS: {
    code: 'TJS',
    symbol: 'TJS',
    thousandsSeparator: ' ',
    decimalSeparator: ';',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  TMT: {
    code: 'TMT',
    symbol: 'm',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  TND: {
    code: 'TND',
    symbol: 'د.ت.‏',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 3,
    negativeFirst: true
  },
  TOP: {
    code: 'TOP',
    symbol: 'T$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  TRY: {
    code: 'TRY',
    symbol: '₺',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  TTD: {
    code: 'TTD',
    symbol: 'TT$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  TVD: {
    code: 'TVD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  TWD: {
    code: 'TWD',
    symbol: 'NT$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  TZS: {
    code: 'TZS',
    symbol: 'TSh',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  UAH: {
    code: 'UAH',
    symbol: '₴',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  UGX: {
    code: 'UGX',
    symbol: 'USh',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  USD: {
    code: 'USD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  UYU: {
    code: 'UYU',
    symbol: '$U',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  UZS: {
    code: 'UZS',
    symbol: 'сўм',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  VEB: {
    code: 'VEB',
    symbol: 'Bs.',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  VEF: {
    code: 'VEF',
    symbol: 'Bs. F.',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  VND: {
    code: 'VND',
    symbol: '₫',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 0,
    negativeFirst: true
  },
  VUV: {
    code: 'VUV',
    symbol: 'VT',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 0,
    negativeFirst: true
  },
  WST: {
    code: 'WST',
    symbol: 'WS$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  XAF: {
    code: 'XAF',
    symbol: 'F',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  XCD: {
    code: 'XCD',
    symbol: '$',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  XBT: {
    code: 'XBT',
    symbol: 'Ƀ',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  XOF: {
    code: 'XOF',
    symbol: 'F',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  XPF: {
    code: 'XPF',
    symbol: 'F',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  YER: {
    code: 'YER',
    symbol: '﷼',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    decimalDigits: 2,
    negativeFirst: true
  },
  ZAR: {
    code: 'ZAR',
    symbol: 'R',
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  ZMW: {
    code: 'ZMW',
    symbol: 'ZK',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  },
  WON: {
    code: 'WON',
    symbol: '₩',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    negativeFirst: true
  }
}

type CurrencyAssetsMap = {
  /**
   * 币种本身的资源
   */
  NORMAL: PngSprite
  /** 任务余额宝图标 */
  TASK_YEB: PngSprite
  /** 任务金币图标 */
  TASK_CZ: PngSprite
  /** 我的页面 */
  MINE_YEB: SvgSprite
  MINE_TX: SvgSprite
  MINE_ZHMX: SvgSprite
  MINE_TZJL: SvgSprite
  /** 顶部个人中心下拉菜单 */
  HEADER_ZHMX: SvgSprite
  HEADER_TZJL: SvgSprite
  /** 充值金币svg图标 */
  PAY_JB: SvgSprite
  /** 底部导航 */
  TABBAR_TX?: PngSprite
  TABBAR_YEB?: PngSprite
  /** h5首页中部导航 */
  HOME_NAV_TX?: PngSprite
  HOME_NAV_YEB?: PngSprite
}

export class Currency {
  public static currenciesFormatConfig(): Record<
    string,
    ValueOf<typeof config>
  > {
    return Object.entries(Currency.map()).reduce((pre, [key, item]) => {
      if (item.formatConfig) {
        pre[key] = item.formatConfig
      }

      return pre
    }, {} as Record<string, ValueOf<typeof config>>)
  }
  /**
   * 多币种注册
   */
  public static registerIconMap(): Record<
    string,
    {
      icon: PngSprite
      title: string | LocaleMessages
    }
  > {
    return Object.entries(Currency.map()).reduce(
      (pre, [key, item]) => {
        pre[key] = {
          title: item.title,
          icon: item.assets.NORMAL
        }
        return pre
      },
      {} as Record<
        string,
        {
          icon: PngSprite
          title: string | LocaleMessages
        }
      >
    )
  }

  /**
   * 所有模块多币种Icon
   */
  public static commonIconMap(): Record<string, CurrencyAssetsMap> {
    return Object.entries(Currency.map()).reduce((pre, [key, item]) => {
      pre[key] = item.assets
      return pre
    }, {} as Record<string, CurrencyAssetsMap>)
  }

  private static iconMap(): Omit<CurrencyAssetsMap, 'PAY_JB' | 'NORMAL'> {
    return {
      TASK_YEB: createPngSprite('img_yeb'),
      TASK_CZ: createPngSprite('img_cz'),
      MINE_YEB: createSvgSprite('icon_wd_yeb1'),
      MINE_TX: createSvgSprite('icon_wd_tx1'),
      MINE_ZHMX: createSvgSprite('icon_wd_zhmx'),
      MINE_TZJL: createSvgSprite('icon_wd_tzjl'),
      HEADER_ZHMX: createSvgSprite('icon_grzx_zhmx'),
      HEADER_TZJL: createSvgSprite('icon_grzx_tzjl'),
      TABBAR_TX: createPngSprite('sprite_main_icon_btm_tx'),
      TABBAR_YEB: createPngSprite('sprite_main_icon_btm_yeb'),
      HOME_NAV_YEB: createPngSprite('sprite_main_icon_dt_1yeb'),
      HOME_NAV_TX: createPngSprite('sprite_main_icon_dt_1tx')
    }
  }

  private static map(): Record<
    string,
    {
      title: string | LocaleMessages
      assets: CurrencyAssetsMap
      formatConfig?: ValueOf<typeof config>
    }
  > {
    const { t } = useI18n()
    return {
      CNY: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_ChineseMainland'), //美国：美元
          PAY_JB: createSvgSprite('comm_icon_sqb')
        },
        title: t('lobby.modal.currency.CNY'),
        formatConfig: config.CNY
      },
      USD: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_UnitedStates'), //美国：美元
          PAY_JB: createSvgSprite('comm_icon_sqb_mxn')
        },
        title: t('lobby.modal.currency.USD'),
        formatConfig: config.USD
      },
      HKD: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_HongKong'), //香港：港币
          PAY_JB: createSvgSprite('comm_icon_sqb_hkd')
        },
        title: t('lobby.modal.currency.HKD'),
        formatConfig: config.HKD
      },
      THB: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Thailand'), //泰国：泰铢,flag_1x1_Thailand
          PAY_JB: createSvgSprite('comm_icon_sqb_thb')
        },
        title: t('lobby.modal.currency.THB'),
        formatConfig: config.THB
      },
      VND: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Vietnam'), //越南：越南盾,flag_1x1_Vietnam
          PAY_JB: createSvgSprite('comm_icon_sqb_vnd')
        },
        title: t('lobby.modal.currency.VND'),
        formatConfig: config.VND
      },
      IDR: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Indonesia'), //印尼：印尼盾,flag_1x1_Indonesia
          PAY_JB: createSvgSprite('comm_icon_sqb_idr')
        },
        title: t('lobby.modal.currency.IDR'),
        formatConfig: config.IDR
      },
      INR: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_India'), //印度：印度卢比,flag_1x1_India
          PAY_JB: createSvgSprite('comm_icon_sqb_inr')
        },
        title: t('lobby.modal.currency.INR'),
        formatConfig: config.INR
      },
      AUD: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Australia'), //澳大利亚：澳元
          PAY_JB: createSvgSprite('comm_icon_sqb_aud')
        },
        title: t('lobby.modal.currency.AUD'),
        formatConfig: config.AUD
      },
      GBP: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_UnitedKingdom'), //英国：英镑
          PAY_JB: createSvgSprite('comm_icon_sqb_gbp')
        },
        title: t('lobby.modal.currency.GBP'),
        formatConfig: config.GBP
      },
      KRW: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_SouthKorea'), //韩国：韩元
          PAY_JB: createSvgSprite('comm_icon_sqb_krw')
        },
        title: t('lobby.modal.currency.KRW'),
        formatConfig: config.KRW
      },
      JPY: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Japan'), //日本：日元
          PAY_JB: createSvgSprite('comm_icon_sqb')
        },
        title: t('lobby.modal.currency.JPY'),
        formatConfig: config.JPY
      },
      IDRK: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Indonesia'), //印度尼西亚：印尼盾,flag_1x1_Indonesia
          PAY_JB: createSvgSprite('comm_icon_sqb_idr')
        },
        title: t('lobby.modal.currency.IDR'),
        formatConfig: config.IDR
      },
      PHP: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Philippines'), //菲律宾：菲律宾比索
          PAY_JB: createSvgSprite('comm_icon_sqb_php')
        },
        title: t('lobby.modal.currency.PHP'),
        formatConfig: config.PHP
      },
      BRL: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Brazil'), //巴西：巴西雷亚尔
          PAY_JB: createSvgSprite('comm_icon_sqb_brl')
        },
        title: t('lobby.modal.currency.BRL'),
        formatConfig: config.BRL
      },
      MXN: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Mexico'), //墨西哥：墨西哥比索
          PAY_JB: createSvgSprite('comm_icon_sqb_mxn')
        },
        title: t('lobby.modal.currency.MXN'),
        formatConfig: config.MXN
      },
      EUR: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Europe'), //欧洲：欧元
          PAY_JB: createSvgSprite('comm_icon_sqb_eur')
        },
        title: t('lobby.modal.currency.EUR'),
        formatConfig: config.EUR
      },
      RUB: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Russia'), //俄罗斯：俄罗斯卢比,flag_1x1_Russia
          PAY_JB: createSvgSprite('comm_icon_sqb_rub')
        },
        title: t('lobby.modal.currency.RUB'),
        formatConfig: config.RUB
      },
      MMK: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Myanmar'), //缅甸：缅甸元,flag_1x1_Myanmar
          PAY_JB: createSvgSprite('comm_icon_sqb_mmk')
        },
        title: t('lobby.modal.currency.MMK'),
        formatConfig: config.MMK
      },
      AED: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_UnitedArabEmirates'), //阿拉伯/中东：迪拉姆,flag_1x1_UnitedArabEmirates
          PAY_JB: createSvgSprite('comm_icon_sqb_aed')
        },
        title: t('lobby.modal.currency.AED'),
        formatConfig: config.AED
      },
      KHR: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Cambodia'), //柬埔寨瑞尔
          PAY_JB: createSvgSprite('comm_icon_sqb_khr')
        },
        title: t('lobby.modal.currency.KHR'),
        formatConfig: config.KHR
      },
      BDT: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Bangladesh'), //塔卡
          PAY_JB: createSvgSprite('comm_icon_sqb_bdt')
        },
        title: t('lobby.modal.currency.BDT'),
        formatConfig: config.BDT
      },
      NGN: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Nigeria'), //奈拉
          PAY_JB: createSvgSprite('comm_icon_sqb_ngn')
        },
        title: t('lobby.modal.currency.NGN'),
        formatConfig: config.NGN
      },
      TRX: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_trx'), //波场
          PAY_JB: createSvgSprite('comm_icon_sqb_trx')
        },
        title: t('lobby.modal.currency.english')
      },
      TRY: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Turkey'), //土耳其里拉
          PAY_JB: createSvgSprite('comm_icon_sqb_try')
        },
        title: t('lobby.modal.currency.TRY')
      },
      MAD: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_Morocco'), //摩洛哥迪拉姆
          PAY_JB: createSvgSprite('comm_icon_sqb_mad')
        },
        title: t('lobby.modal.currency.MAD')
      },
      'USDT1:1': {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_USDT'), // USDT,flag_1x1_USDT
          PAY_JB: createSvgSprite('comm_icon_sqb_usdt')
        },
        title: t('lobby.modal.currency.english'),
        formatConfig: config.USDT
      },
      BTC: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_BTC'), // btc,flag_1x1_BTC
          PAY_JB: createSvgSprite('comm_icon_sqb_btc')
        },
        title: t('lobby.modal.currency.BTC')
      },
      ETH: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_ETH'), // eth,flag_1x1_ETH
          PAY_JB: createSvgSprite('comm_icon_sqb_eth')
        },
        title: t('lobby.modal.currency.ETH')
      },
      'USDT1:7': {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_USDT'), // USDT,flag_1x1_USDT
          PAY_JB: createSvgSprite('comm_icon_sqb_usdt')
        },
        title: t('lobby.modal.currency.chinese'),
        formatConfig: config.USDT
      },
      'USDC1:7': {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_USDC'), // USDT,flag_1x1_USDC
          PAY_JB: createSvgSprite('comm_icon_sqb_usdc')
        },
        title: t('lobby.modal.currency.chinese')
      },
      'USDC1:1': {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_USDC'), // USDT,flag_1x1_USDC
          PAY_JB: createSvgSprite('comm_icon_sqb_usdc')
        },
        title: t('lobby.modal.currency.english')
      },
      'BTC1:100000': {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_BTC'), // btc,flag_1x1_BTC
          PAY_JB: createSvgSprite('comm_icon_sqb_btc')
        },
        title: t('lobby.modal.currency.chinese')
      },
      'BTC1:10000': {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_BTC'), // btc,flag_1x1_BTC
          PAY_JB: createSvgSprite('comm_icon_sqb_btc')
        },
        title: t('lobby.modal.currency.english')
      },
      'ETH1:10000': {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_ETH'), // eth,flag_1x1_ETH
          PAY_JB: createSvgSprite('comm_icon_sqb_eth')
        },
        title: t('lobby.modal.currency.chinese')
      },
      'ETH1:1000': {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_ETH'), // eth,flag_1x1_ETH
          PAY_JB: createSvgSprite('comm_icon_sqb_eth')
        },
        title: t('lobby.modal.currency.english')
      },
      /*
       * 上面是使用currencyDisplay枚举的后端虚拟币钟数据
       * 下面几个重复的是因为币种列表字段改造后，登录成功返回的不是currencyDisplay格式，而是新的currenyCode
       */
      BTC1: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_BTC'), // btc,flag_1x1_BTC
          PAY_JB: createSvgSprite('comm_icon_sqb_btc')
        },
        title: t('lobby.modal.currency.BTC')
      },
      ETH1: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_ETH'), // eth,flag_1x1_ETH
          PAY_JB: createSvgSprite('comm_icon_sqb_eth')
        },
        title: t('lobby.modal.currency.ETH')
      },
      USDT: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_USDT'), // USDT,flag_1x1_USDT
          PAY_JB: createSvgSprite('comm_icon_sqb_usdt')
        },
        title: t('lobby.modal.currency.english'),
        formatConfig: config.USDT
      },
      USDT1: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_USDT'), // USDT,flag_1x1_USDT
          PAY_JB: createSvgSprite('comm_icon_sqb_usdt')
        },
        title: t('lobby.modal.currency.chinese'),
        formatConfig: config.USDT
      },
      USDC: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_USDC'), // USDT,flag_1x1_USDC
          PAY_JB: createSvgSprite('comm_icon_sqb_usdc')
        },
        title: t('lobby.modal.currency.english')
      },
      USDC1: {
        assets: {
          ...this.iconMap(),
          NORMAL: createPngSprite('sprite_main_flag_1x1_USDC'), // USDT,flag_1x1_USDC
          PAY_JB: createSvgSprite('comm_icon_sqb_usdc')
        },
        title: t('lobby.modal.currency.chinese')
      }
    }
  }
}

/**
 * config不再抛出，业务层引用请用
 * Currency.currenciesFormatConfig()代替
 */
// export default config
