import { CategoryIds } from '@/api/common/type'
import { GameCategoryIds } from '@/views/game/consts/categorys'
import { SvgSprite } from '@/components/icon-sprite'
import { createSvgSprite } from '@/utils/business-utils/svg'
import { omit } from 'lodash'
import { useMainStore } from '@/store/index'

interface LayoutGameMenu {
  normal: SvgSprite
  active: string
  cnDropImg?: string //126、128下拉展示的游戏图片
  enDropImg?: string
  leftMenuBgImg?: string // 5198定制 h5左侧菜单背景图
  menuBgImg?: string // 5198定制 PC 菜单背景图
  rightMenuCollapsedBg?: string // 5198定制 pc 右侧菜单 折叠状态-背景图
  rightMenuExpandBg?: string // 5198 pc 右侧菜单 展开状态-背景图
  centerBottomBg?: string // 5198 pc 内容底部 游戏图
}

export default class Resource {
  get skinName() {
    const { siteInfos } = useMainStore()
    return siteInfos?.type
  }
  /**
   * 游戏列表
   */
  static get gameMenu(): Record<number | string, LayoutGameMenu> {
    return {
      /**
       * 棋牌
       */
      [GameCategoryIds.Chess]: {
        normal: createSvgSprite('icon_dtfl_qp_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_qp_1.png',
        cnDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_qp.png',
        enDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_qp_en.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2qp.png',
        menuBgImg: '/lobby_asset/{layout}-{bg}-common/web/home/bg_dt_qp2.png',
        rightMenuCollapsedBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc1_2qp.png',
        rightMenuExpandBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc2_2qp.png',
        centerBottomBg:
          '/lobby_asset/{layout}-{bg}-common/web/home/img_dt_qp2.png'
      },
      /**
       * 捕鱼
       */
      [GameCategoryIds.Fish]: {
        normal: createSvgSprite('icon_dtfl_by_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_by_1.png',
        cnDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_by.png',
        enDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_by_en.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2by.png',
        menuBgImg: '/lobby_asset/{layout}-{bg}-common/web/home/bg_dt_by2.png',
        rightMenuCollapsedBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc1_2by.png',
        rightMenuExpandBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc2_2by.png',
        centerBottomBg:
          '/lobby_asset/{layout}-{bg}-common/web/home/img_dt_by2.png'
      },
      /**
       * 电子
       */
      [GameCategoryIds.Electronic]: {
        normal: createSvgSprite('icon_dtfl_dz_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_dz_1.png',
        cnDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_dz.png',
        enDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_dz_en.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2dz.png',
        menuBgImg: '/lobby_asset/{layout}-{bg}-common/web/home/bg_dt_dz2.png',
        rightMenuCollapsedBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc1_2dz.png',
        rightMenuExpandBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc2_2dz.png',
        centerBottomBg:
          '/lobby_asset/{layout}-{bg}-common/web/home/img_dt_dz2.png'
      },
      /**
       * 真人
       */
      [GameCategoryIds.Real]: {
        normal: createSvgSprite('icon_dtfl_zr_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_zr_1.png',
        cnDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_zr.png',
        enDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_zr_en.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2zr.png',
        menuBgImg: '/lobby_asset/{layout}-{bg}-common/web/home/bg_dt_zr2.png',
        rightMenuCollapsedBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc1_2zr.png',
        rightMenuExpandBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc2_2zr.png',
        centerBottomBg:
          '/lobby_asset/{layout}-{bg}-common/web/home/img_dt_zr2.png'
      },
      /**
       * 体育
       */
      [GameCategoryIds.Sports]: {
        normal: createSvgSprite('icon_dtfl_ty_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_ty_1.png',
        cnDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_ty.png',
        enDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_ty_en.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2ty.png',
        menuBgImg: '/lobby_asset/{layout}-{bg}-common/web/home/bg_dt_ty2.png',
        rightMenuCollapsedBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc1_2ty.png',
        rightMenuExpandBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc2_2ty.png',
        centerBottomBg:
          '/lobby_asset/{layout}-{bg}-common/web/home/img_dt_ty2.png'
      },
      /**
       * 斗鸡
       */
      [GameCategoryIds.Cockfighting]: {
        normal: createSvgSprite('icon_dtfl_douji_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_douji_1.png',
        cnDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_ty.png',
        enDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_ty_en.png' //126，128不配置，测试占位用
      },
      /**
       * 电竞
       */
      [GameCategoryIds.Gaming]: {
        normal: createSvgSprite('icon_dtfl_dianjing_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_dianjing_1.png',
        cnDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_dj.png',
        enDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_dj_en.png'
      },
      /**
       * 彩票
       */
      [GameCategoryIds.Lottery]: {
        normal: createSvgSprite('icon_dtfl_cp_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_cp_1.png',
        cnDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_cp.png',
        enDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_cp_en.png'
      },
      /**
       * 区块链
       */
      [GameCategoryIds.BlockChain]: {
        normal: createSvgSprite('icon_dtfl_qkl_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_qkl_1.png',
        cnDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_qkl.png',
        enDropImg:
          '/lobby_asset/{layout}-{bg}-{skin}/web/home/img_dt_xl_qkl_en.png'
      },
      /**
       * 俱乐部
       */
      [GameCategoryIds.Club]: {
        normal: createSvgSprite('icon_dtfl_jlb_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_jlb_1.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2jlb.png'
      },
      /**
       * 朋友局
       */
      [GameCategoryIds.ClubFriend]: {
        normal: createSvgSprite('icon_dtfl_pyj_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_pyj_1.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2pyj.png'
      },
      /**
       * 热门
       */
      [GameCategoryIds.Hot]: {
        normal: createSvgSprite('icon_dtfl_rm_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_rm_1.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2rm.png',
        menuBgImg: '/lobby_asset/{layout}-{bg}-common/web/home/bg_dt_rm2.png',
        rightMenuCollapsedBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc1_2rm.png',
        rightMenuExpandBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc2_2rm.png',
        centerBottomBg:
          '/lobby_asset/{layout}-{bg}-common/web/home/img_dt_rm2.png'
      },
      /**
       * 最近
       */
      [GameCategoryIds.Recent]: {
        normal: createSvgSprite('icon_dtfl_zj_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_zj_1.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2zj.png',
        rightMenuCollapsedBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc1_2zj.png',
        rightMenuExpandBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc2_2zj.png'
      },
      /**
       * 收藏
       */
      [GameCategoryIds.Collenct]: {
        normal: createSvgSprite('icon_dtfl_sc_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_sc_1.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2sc.png',
        rightMenuCollapsedBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc1_2sc.png',
        rightMenuExpandBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc2_2sc.png'
      },
      /**
       * 试玩
       */
      [GameCategoryIds.Demo]: {
        normal: createSvgSprite('icon_dtfl_sw_0'),
        active:
          '/lobby_asset/{layout}-{bg}-common/common/_sprite/icon_dtfl_sw_1.png',
        leftMenuBgImg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_zc4_2sw.png',
        rightMenuCollapsedBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc1_2sw.png',
        rightMenuExpandBg:
          '/lobby_asset/{layout}-{bg}-common/web/common/btn_yc2_2sw.png'
      }
    }
  }
  /**
   * 游戏tab
   */
  static get gameTab(): Record<number, string> {
    return {
      /**
       * 搜索
       */
      [GameCategoryIds.Search]:
        '/lobby_asset/common/common/_sprite/icon_dtfl_2ss_1.png',
      /**
       * 热门
       */
      [GameCategoryIds.Hot]:
        '/lobby_asset/common/common/_sprite/icon_dtfl_2rm_1.png',
      /**
       * 最近
       */
      [GameCategoryIds.Recent]:
        '/lobby_asset/common/common/_sprite/icon_dtfl_2zj_1.png',
      /**
       * 收藏
       */
      [GameCategoryIds.Collenct]:
        '/lobby_asset/common/common/_sprite/icon_dtfl_2sc_1.png',
      /**
       * 试玩
       */
      [GameCategoryIds.Demo]:
        '/lobby_asset/common/common/_sprite/icon_dtfl_2sw.png'
    }
  }
  /**
   * 活动列表
   */
  static get eventMenu(): Record<
    number | string,
    {
      normal: SvgSprite
      active: string
    }
  > {
    return {
      /**
       * 全部
       */
      [CategoryIds.zh]: {
        normal: createSvgSprite('icon_dtfl_zh_0'),
        active: ''
      },
      /**
       * 棋牌
       */
      [CategoryIds.qp]: Resource.gameMenu[GameCategoryIds.Chess],
      /**
       * 斗鸡
       */
      [CategoryIds.djc]: Resource.gameMenu[GameCategoryIds.Cockfighting],
      [CategoryIds.dj]: Resource.gameMenu[GameCategoryIds.Cockfighting],
      /**
       * 捕鱼
       */
      [CategoryIds.by]: Resource.gameMenu[GameCategoryIds.Fish],
      /**
       * 真人
       */
      [CategoryIds.zr]: Resource.gameMenu[GameCategoryIds.Real],
      /**
       * 彩票
       */
      [CategoryIds.cp]: Resource.gameMenu[GameCategoryIds.Lottery],
      /**
       * 电子
       */
      [CategoryIds.dz]: Resource.gameMenu[GameCategoryIds.Electronic],
      /**
       * 体育
       */
      [CategoryIds.ty]: Resource.gameMenu[GameCategoryIds.Sports],
      /**
       * 电竞
       */
      [CategoryIds.dianjing]: Resource.gameMenu[GameCategoryIds.Gaming],
      /**
       * 区块链
       */
      [CategoryIds.blockchain]: Resource.gameMenu[GameCategoryIds.BlockChain]
    }
  }
  /**
   * 中心钱包
   */
  static get centerWalletMenu(): Record<
    number | string,
    {
      normal: SvgSprite
      active: string
    }
  > {
    return {
      ...omit(this.gameMenu, [GameCategoryIds.Hot]),
      [GameCategoryIds.All]: {
        normal: createSvgSprite('icon_dtfl_zh_0'),
        active: ''
      }
    }
  }
  // static get map(): Record<
  //   number,
  //   {
  //     normal: SvgSprite
  //     active: string
  //   }
  // > {
  //   return Object.keys(this.gameMenu).reduce(
  //     (
  //       pre: Record<
  //         number | string,
  //         {
  //           normal: SvgSprite
  //           active: string
  //         }
  //       >,
  //       key
  //     ) => {
  //       pre[key] = this.gameMenu[key]
  //       return pre
  //     },
  //     {}
  //   )
  // }
}
