import { ThemeComponentType } from './type'
import { nodeThemeConfig } from '@/context/const'

export default {
  [ThemeComponentType.HomePage]: {
    com: () =>
      import(
        /* webpackChunkName: "home@theme=27" */ '@/views/game/themes/theme-27/home'
      ),
    pc: nodeThemeConfig.ThemeSkinType.EUROPEAN_AMERICAN
  },
  [ThemeComponentType.SearchPage]: {
    com: () =>
      import(
        /* webpackChunkName: "search@theme=27" */ '@/views/game/themes/theme-27/search'
      ),
    pc: nodeThemeConfig.ThemeSkinType.EUROPEAN_AMERICAN
  },
  [ThemeComponentType.SubGamePage]: {
    com: () =>
      import(
        /* webpackChunkName: "subGame@theme=27" */ '@/views/game/themes/theme-27/subGame'
      ),
    pc: nodeThemeConfig.ThemeSkinType.EUROPEAN_AMERICAN
  },
  [ThemeComponentType.EmbeddedPage]: {
    com: () =>
      import(
        /* webpackChunkName: "embedded@theme=27" */ '@/views/game/themes/theme-27/embedded'
      ),
    pc: nodeThemeConfig.ThemeSkinType.EUROPEAN_AMERICAN
  },
  [ThemeComponentType.Footer]: {
    com: () =>
      import(
        /* webpackChunkName: "footer@theme=27" */ '@/layouts/components/footer/themes/theme-27'
      ),
    pc: nodeThemeConfig.ThemeSkinType.EUROPEAN_AMERICAN
  },
  [ThemeComponentType.FloatBar]: {
    com: () =>
      import(
        /* webpackChunkName: "floatBar@theme=27" */ '@/layouts/components/floatBar/themes/theme-27'
      ),
    pc: nodeThemeConfig.ThemeSkinType.EUROPEAN_AMERICAN
  },
  [ThemeComponentType.Styles]: {
    com: () =>
      import(
        /* webpackChunkName: "style@theme=27" */ '@/views/global-styles/themes/theme-27'
      ),
    pc: nodeThemeConfig.ThemeSkinType.EUROPEAN_AMERICAN
  },
  [ThemeComponentType.Layouts]: {
    com: () =>
      import(
        /* webpackChunkName: "layout@theme=27" */ '@/layouts/themes/theme-27'
      )
  }
}
