import type { Language } from '@/i18n'

export type DateFormatType =
  | 'ymdhms'
  | 'ymdhm'
  | 'ymd'
  | 'mdhm'
  | 'mdhms'
  | 'hms'
  | 'ms'
  | 'md'

export type DateFormatItem = Record<DateFormatType, string>

/**
 * 目前仅处理了 en_US/zh_CN/zh_TW/th_TH/vi_VN/ko_KR/ja_JP/pt_PT/es_ES/de_DE/fr_FR
 */
export const DateFormatMap: Record<Language, DateFormatItem> = {
  en_US: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  zh_CN: {
    ymdhms: 'YYYY-MM-DD HH:mm:ss',
    ymdhm: 'YYYY-MM-DD HH:mm',
    ymd: 'YYYY-MM-DD',
    md: 'MM-DD',
    mdhm: 'MM-DD HH:mm',
    mdhms: 'MM-DD HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  zh_TW: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  th_TH: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  vi_VN: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  ko_KR: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY- HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  ja_JP: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  pt_PT: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  es_ES: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  de_DE: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  fr_FR: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  it_IT: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  ru_RU: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  my_MM: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  id_ID: {
    ymdhms: 'DD-MM-YYYY HH:mm:ss',
    ymdhm: 'DD-MM-YYYY HH:mm',
    ymd: 'DD-MM-YYYY',
    md: 'DD-MM',
    mdhm: 'DD-MM HH:mm',
    mdhms: 'DD-MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  hi_IN: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  tl_PH: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  km_KH: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  te_IN: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  ta_LK: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  mr_IN: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  kn_IN: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  tr_TR: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  bn_BN: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  },
  pa_PA: {
    ymdhms: 'DD/MM/YYYY HH:mm:ss',
    ymdhm: 'DD/MM/YYYY HH:mm',
    ymd: 'DD/MM/YYYY',
    md: 'DD/MM',
    mdhm: 'DD/MM HH:mm',
    mdhms: 'DD/MM HH:mm:ss',
    hms: 'HH:mm:ss',
    ms: 'mm:ss'
  }
}
