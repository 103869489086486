import { GlobalService } from '@/context'
import {
  ResetPassFirstVerifyPayload,
  ResetPassSecondVerifyPayload,
  SecurityActionVerifyParams,
  SecurityActionVerifyRes,
  SecurityBindCpf,
  SecurityBindGA,
  SecurityEmailCode,
  SecurityGoogleAuthCode,
  SecurityModifyEmail,
  SecurityModifyLoginPwd,
  SecurityModifyPhone,
  SecurityModifyQuestion,
  SecurityModifyWithdrawPwd,
  SecurityPhoneCode,
  SecuritySwitchGA,
  SecurityThirdWayBind,
  SecurityVerifyModifyReq,
  SecurityVerifyModifyRes,
  SecurityVerifyModifyType,
  ThirdBindResponseData
} from './type'
import { SecondAuthType, SecondVerifyType } from '@/api/common/type'
import { merge } from 'lodash'
import { useMainStore } from '@/store/index'

/**
 * 安全中心第一步的密码验证
 */
export const apiSecurityVerifyModify = <
  T extends SecurityVerifyModifyType = SecurityVerifyModifyType.LOGIN_PASS
>(
  payload: SecurityVerifyModifyReq<T>
) => {
  return GlobalService.post<SecurityVerifyModifyRes<T>>({
    url: '/user/security/verifymodify',
    data: payload
  })
}

/**
 * 修改绑定手机号接口
 */
export const apiSecurityModifyPhone = (payload: SecurityModifyPhone) => {
  return GlobalService.post({
    url: '/user/security/modifyphone',
    // url: '/user/security/forceBindPhone',
    data: payload
  })
}

/**
 * 设置CPF
 */
export const apiSecurityBindCpf = (payload: SecurityBindCpf) => {
  return GlobalService.post({
    url: '/user/security/bindCpf',
    data: payload
  })
}

/**
 * 修改绑定邮箱接口
 */
export const apiSecurityModifyEmail = (payload: SecurityModifyEmail) => {
  return GlobalService.post({
    /** 屏蔽强制绑定接口，统一使用安全中心接口 */
    // url: '/user/security/forceBindEmail'
    url: '/user/security/bindEmail',
    data: payload
  })
}

/**
 * 安全中心-修改登录密码
 */
export const apiSecurityModifyLoginPwd = (payload: SecurityModifyLoginPwd) => {
  return GlobalService.post({
    url: '/user/changepass',
    data: payload
  })
}
/**
 * 安全中心-修改提现密码
 */
export const apiSecurityModifyWithDrawPwd = (
  payload: SecurityModifyWithdrawPwd
) => {
  return GlobalService.post({
    url: '/user/security/modifywithdrawpass',
    data: payload
  })
}
/**
 * 安全中心-设置密保问题
 */
export const apiSecuritySetQuestion = (payload: SecurityModifyQuestion) => {
  return GlobalService.post({
    url: '/user/passquint',
    data: payload
  })
}
/**
 * 安全中心-修改密保问题
 */
export const apiSecurityModifyQuestion = (payload: SecurityModifyQuestion) => {
  return GlobalService.post({
    url: '/user/editquint',
    data: payload
  })
}

/**
 * 安全中心-绑定GA
 */
export const apiSecurityBindGA = (payload: SecurityBindGA) => {
  return GlobalService.post({
    /** 屏蔽强制绑定接口，统一使用安全中心接口 */
    //  url: '/user/security/forceGoogleAuth'
    url: '/user/security/bindgoogleauth',
    data: payload
  })
}
/**
 * 安全中心-修改GA 状态
 */
export const apiSecurityModifyGA = (payload: SecuritySwitchGA) => {
  return GlobalService.post({
    url: '/user/security/switchgoogleauth',
    data: payload
  })
}

/**
 * 安全中心-绑定三方
 */
export const apiSecurityBindThirdWay = (payload: SecurityThirdWayBind) => {
  payload = merge(
    {
      os_type: useMainStore().osType
    },
    payload
  )
  return GlobalService.post<ThirdBindResponseData>({
    url: '/user/security/thirduser/bind',
    data: payload
  })
}
/**
 * 登录后获取当前账号的问题ID
 */
export const apiGetAccountQuestionId = () => {
  return GlobalService.post<{ question: number }>({
    url: '/user/getaccountquestion'
  })
}

/**
 * 行为验证
 * @param payload
 * @param  type: number
 * @param info_type: number
 * @param pass_question_id: number
 * @param pass_question_answer: string
 * @param withdraw_pass: string
 * @param login_pass: string
 * @param googleCode: string
 * @param phone: string
 * @param phoneCode: string
 * @param email: string
 * @param emailCode: string
 * @returns
 */

export const apiSecurityActionVerify = <
  T extends SecondAuthType = SecondAuthType.LOGIN_PWD
>(
  payload: SecurityActionVerifyParams<T>
) => {
  return GlobalService.post<SecurityActionVerifyRes<T>>({
    url: '/user/security/actionVerify',
    data: payload
  })
}

/**
 * 验证手机短信
 */
export const apiSecurityVerifyPhoneCode = (payload: SecurityPhoneCode) => {
  return GlobalService.post({
    url: '/user/security/verifyPhoneCode',
    data: payload
  })
}
/**
 * 验证邮箱验证码
 */
export const apiSecurityVerifyEmailCode = (payload: SecurityEmailCode) => {
  return GlobalService.post({
    url: '/user/security/verifyEmailCode',
    data: payload
  })
}
/**
 * 验证谷歌验证码
 */
export const apiSecurityVerifyGoogleAuthCode = (
  payload: SecurityGoogleAuthCode
) => {
  return GlobalService.post({
    url: '/user/security/verifyGoogleAuthCode',
    data: payload
  })
}
/**
 * 验证取款验证码
 */
export const apiSecurityVerifyWithdrawPass = (payload: {
  oldWithdrawPasswd?: string
  withdraw_pass: string
}) => {
  return GlobalService.post({
    url: '/user/security/verifyWithdrawPass',
    data: payload,
    customParams: { noErrorMessage: true }
  })
}
/**
 * 验证取款验证码
 */
export const apiSecurityVerifyLoginPass = (payload: { login_pass: string }) => {
  return GlobalService.post({
    url: '/user/security/verifyLoginPass',
    data: payload
  })
}
/**
 * 验证取款验证码
 */
export const apiSecuritySecondVerify = (payload: SecondVerifyType) => {
  return GlobalService.post({
    url: '/user/security/secondVerify',
    data: payload
  })
}

/**
 * 忘记密码-第一步验证
 */
export const apiResetPassFistVerify = (
  payload: ResetPassFirstVerifyPayload
) => {
  return GlobalService.post({
    url: '/user/notLogin/resetPassVerify',
    data: payload
  })
}

/**
 * 忘记密码-第二步验证
 */
export const apiResetPassSecondVerify = (
  payload: ResetPassSecondVerifyPayload
) => {
  return GlobalService.post({
    url: '/user/notlogin/resetpasswd',
    data: payload
  })
}
