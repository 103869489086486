import {
  Base,
  Component,
  ModelSync,
  Prop,
  Watch
} from '@/vue-property-decorator'
import { Debounce } from 'lodash-decorators'
import { merge } from 'lodash'
import { nodeThemeConfig } from '@/context/const'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import AutoShrinkText from '@/components/auto-shrink-text'
import BusinessUtils from '@/utils/business-utils'
import CommonBadge from '../badge'
import DrageScrollArea from '@/components/drageScrollArea'
import HallCustomization from '@/controller/hallCustomization'
import SideTabHoliday from './holiday'
import Skin from '@/controller/Skin'
import Validator from '@/utils/Validator'
import style from './style.module.scss'

type Props = {
  tabsNavHiddenForOne?: boolean
  tabsNavHidden?: boolean
  renderList: RenderItem[]
  value?: RenderItem['value']
  needCacheComponentsList?: string[]
  persistedActiveName?: string
  tabsWidth?: string | number
  tabsScrollWidth?: string
  tabsHeight?: string | number
  preTriggerHook?: (value: RenderItem) => Promise<void>
  isScrollY?: boolean
  showHoverTips?: boolean
  scrollHeight?: string
  badgeClass?: string // 角标样式
  tabsForm?: TabsForm //大厅自定义配置需求要活动中心和首页取不同的图片
  scrollAutoCenter?: boolean
  tabsInModal?: boolean //tabs是否在modal弹窗内
  customRender?: boolean // 自定义渲染，需要完全由自己控制渲染
  textAutoShrink?: boolean
}
//tabs组件自定义样式特殊处理
export enum TabsForm {
  default = 'default',
  game = 'game',
  event = 'event'
}
export type RenderItem = {
  hidden?: boolean | (() => boolean)
  tag?:
    | BaseRenderContent
    | ((isActive: boolean) => NonNullable<BaseRenderContent>)
  icon?:
    | BaseRenderContent
    | ((isActive: boolean) => NonNullable<BaseRenderContent> | null)
  label:
    | NonNullable<BaseRenderContent>
    | ((item?: RenderItem) => NonNullable<BaseRenderContent>)
  value: string | number
  key?: string
  isVertical?: boolean // 高屏模式下竖直排列icon和文案
  isButton?: boolean // 如果是button，不切换绑定值，并去掉默认tab样式
  isFixedToBottom?: boolean // 是否固定在侧边栏滚动条下面
  itemClass?: string
  scope?: string
  badgeCount?: number // 右上角的圆形徽标数字
  liBg?: string | (() => string) // li背景图
}
/**
 * 纯UI渲染tabs组件
 * 使用方法可以参考[/center/report/index.tsx]
 */
@Component({ name: 'CommonSideTabs' })
export default class CommonSideTabs extends Base<
  unknown,
  Props,
  {
    onTrigger: (value: RenderItem['value']) => void
  },
  {
    default?: RenderItem
    label?: RenderItem
  }
> {
  /**
   * 当前选中值
   */
  @ModelSync('value', 'trigger', { required: true })
  current!: Props['value']

  @Prop(AppTypes.looseBool)
  tabsNavHiddenForOne: Props['tabsNavHiddenForOne']

  /**
   * 是否隐藏导航
   */
  @Prop(AppTypes.looseBool)
  tabsNavHidden: Props['tabsNavHidden']

  /**
   * 滚动自动居中
   */
  @Prop(AppTypes.bool.def(false))
  scrollAutoCenter: Props['scrollAutoCenter']

  /**
   * 鼠标放上去是否显示内容
   */
  @Prop(AppTypes.looseBool.def(true))
  showHoverTips!: NonNullable<Props['showHoverTips']>
  /**
   * 控制是否将当前激活项记录在地址栏
   */
  @Prop(AppTypes.string.def('current'))
  persistedActiveName!: NonNullable<Props['persistedActiveName']>

  /**
   * 需要被缓存的组件名称
   */
  @Prop(AppTypes.array.def([]))
  needCacheComponentsList: Props['needCacheComponentsList']

  /**
   * 渲染tab列表
   */
  @Prop({ required: true })
  renderList!: Props['renderList']

  /**
   * tab切换前置钩子
   */
  @Prop({ required: false })
  preTriggerHook!: Props['preTriggerHook']

  /**
   * tab宽度
   */
  @Prop({ default: 130 })
  tabsWidth?: Props['tabsWidth']
  /**
   * tab高度
   */
  @Prop()
  tabsHeight?: Props['tabsHeight']
  /**
   * tab 滚动区域宽度
   */
  @Prop()
  tabsScrollWidth?: Props['tabsScrollWidth']

  /**
   *
   */
  @Prop({ default: '100%' })
  scrollHeight?: Props['scrollHeight']
  /**
   * 是否沿x轴滚动
   */
  @Prop({ default: false })
  isScrollY?: Props['isScrollY']
  /**
   * 自定义角标样式
   */
  @Prop({ default: '' })
  badgeClass?: Props['badgeClass']
  /**
   * 是否是活动中心使用，用来区分后台自定义配图取值路径
   */
  @Prop({ default: TabsForm.default })
  tabsForm?: Props['tabsForm']

  /**
   * 是否在弹窗里
   */
  @Prop({ default: false })
  tabsInModal?: Props['tabsInModal']

  /**
   * 自定义渲染内容
   */
  @Prop({ default: false })
  customRender?: Props['customRender']
  /**
   * 文本是否自动缩放
   */
  @Prop({ default: true })
  textAutoShrink?: Props['textAutoShrink']

  @Debounce()
  @Watch('current')
  protected onCurrentChange() {
    if (this.isKeptAlive) {
      return
    }
    if (this.persistedActiveName) {
      this.$router.replace({
        query: merge({}, this.$route.query, {
          [this.persistedActiveName]: this.current
        })
      })
    }
    if (this.scrollAutoCenter) {
      this.autoCenter()
    }
  }

  /**
   * 默认的路由选项
   */
  @Watch('$route', { immediate: true })
  protected applyPersistedActiveName() {
    if (this.persistedActiveName) {
      const current = this.$route.query[
        this.persistedActiveName
      ] as Props['value']
      if (!Validator.isEmpty(current)) {
        this.current =
          typeof this.current === 'number' ? Number(current) : current
      }
    }
  }

  get ulWidth() {
    return typeof this.tabsWidth === 'string'
      ? this.tabsWidth
      : BusinessUtils.px2rem(`${this.tabsWidth ?? 130}px`)
  }

  private get btnImg() {
    //game页和event页要从后台自定义图片读取，后台没配才读本地的
    const isHallCustomization =
      this.tabsForm === TabsForm.event || this.tabsForm === TabsForm.game
    const { siteInfos } = useMainStore()
    const {
      isEuropeanAmerican,
      isStake,
      isHK,
      isTheme18,
      isTheme19,
      isTheme20,
      isTheme21,
      isTheme28
    } = Skin.currentTheme()
    const isNoBg =
      (isEuropeanAmerican ||
        isStake ||
        isHK ||
        isTheme18 ||
        isTheme19 ||
        isTheme20 ||
        isTheme28 ||
        isTheme21) &&
      siteInfos?.backgroundColor === nodeThemeConfig.ThemeSkinBg.NO_BG

    const skinPath = isNoBg ? 'common' : '{skin}'
    return {
      active: {
        normal:
          isHallCustomization && HallCustomization.hellTabsActive
            ? HallCustomization.hellTabsActive
            : `/lobby_asset/{layout}-{bg}-{skin}/web/common/btn_zc1_1.png`,
        vertical:
          isHallCustomization && HallCustomization.hellBigTabsActive
            ? HallCustomization.hellBigTabsActive
            : `/lobby_asset/{layout}-{bg}-{skin}/web/common/btn_zc2_1.png`
      },
      original: {
        normal:
          isHallCustomization && HallCustomization.hellTabsBg
            ? HallCustomization.hellTabsBg
            : `/lobby_asset/{layout}-{bg}-${skinPath}/web/common/btn_zc1_2.png`,
        vertical:
          isHallCustomization && HallCustomization.hellBigTabsBg
            ? HallCustomization.hellBigTabsBg
            : `/lobby_asset/{layout}-{bg}-${skinPath}/web/common/btn_zc2_2.png`
      }
    }
  }

  /**
   * tab点击事件
   * @param item
   * @returns
   */
  private async handleClick(_event: Event, item: RenderItem) {
    if (item.value === this.current) {
      return
    }
    if (this.preTriggerHook) {
      await this.preTriggerHook(item)
    }
    if (item.isButton) {
      return
    }
    this.current = item.value
    // 滚动区域点击后选中居中
    if (this.isScrollY) {
      this.autoCenter()
    }
  }

  /**
   * 自动居中
   */
  @Debounce(200)
  private autoCenter() {
    const elements = document.querySelectorAll('.common-tabs-nav [data-key]')
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index]
      const id = element.getAttribute('data-key')?.replace('side-item', '')
      if (id === this.current?.toString()) {
        // 进行重复的多次出现函数(因为手机性能的原因,每个手机的执行该函数的第一次时机是不一样的,多来几次,响应快的能够立即响应,慢的也不至于错过时机而丢失效果)
        const delayAppearNearest = (delay: number) => {
          setTimeout(() => {
            element.scrollIntoView({ inline: 'center', block: 'nearest' })
          }, delay)
        }
        delayAppearNearest(75)
        delayAppearNearest(150)
        delayAppearNearest(300)
      }
    }
  }
  /**
   * 渲染tab
   * @param item
   * @param isSubItem 是一级子集样式
   * @returns
   */
  private renderItem(item: RenderItem, isSubItem = true) {
    const hidden = item.hidden instanceof Function ? item.hidden() : item.hidden
    if (hidden) return null

    const child =
      (item.label instanceof Function ? item.label(item) : item.label) ||
      this.$scopedSlots?.label?.(item)

    let height
    if (typeof this.tabsHeight === 'string' && !item.isButton) {
      height = this.tabsHeight
    } else if (typeof this.tabsHeight === 'number' && !item.isButton) {
      height = BusinessUtils.px2rem(`${this.tabsHeight}px`)
    }

    const isActive = this.current === item.value
    const { isWeb } = useMainStore()
    const { isHolidayThemeVisible } = Skin
    const prop = {
      class: {
        [style.active]: isActive,
        active: isActive,
        [style.item]: true,
        ['subItem']: isSubItem,
        [style.isButton]: !!item.isButton,
        isButton: !!item.isButton,
        [style.hasIcon]: !!item.icon,
        [style.isVertical]: !!item.isVertical && !item.isButton,
        isVertical: !!item.isVertical && !item.isButton,
        ...(item.itemClass && { [item.itemClass]: true })
      },
      style: {
        width: this.ulWidth,
        ...(height ? { height } : {})
      },
      directives: this.showHoverTips
        ? [
            {
              name: 'inner-text-to-title'
            }
          ]
        : []
    }
    const liBg = item.liBg instanceof Function ? item.liBg() : item.liBg
    let imgSrc
    if (typeof liBg === 'string') {
      imgSrc = liBg
    } else {
      imgSrc =
        this.btnImg[isActive ? 'active' : 'original'][
          item.isVertical ? 'vertical' : 'normal'
        ]
    }

    const iconContent =
      typeof item.icon === 'function' ? item.icon(isActive) : item.icon
    return (
      <li
        key={item?.key || item.value}
        data-key={`side-item${item.value}`}
        {...prop}
        onClick={(event) => this.handleClick(event, item)}
      >
        {/* 节日点缀 */}
        {isHolidayThemeVisible && isActive && (
          <SideTabHoliday isVertical={!!item.isVertical} />
        )}
        {!item.isButton && (
          <my-img class={style.itemBg} src={imgSrc as string} alt=""></my-img>
        )}

        {item.tag &&
          (typeof item.tag === 'function' ? item.tag(isActive) : item.tag)}
        {iconContent && (
          <div
            class={{
              [style.icon]: true,
              icon: true
            }}
          >
            {iconContent}
          </div>
        )}
        <div class={[style.text, 'text']}>
          {/* 2024.05.13 根据反馈48099，和产品确认，把活动模块的侧边栏，字体自动收缩组件，替换回span，超长省略 */}
          {/* 其他模块保持，使用字体自动收缩组件,替换默认span兼容文案超长的场景 */}
          {typeof child === 'string' ? (
            this.textAutoShrink ? (
              <AutoShrinkText
                limitHeight={isWeb ? `0.5rem` : `0.62rem`}
                minFontSize={isWeb ? `0.12rem` : `0.14rem`}
                text={child}
              ></AutoShrinkText>
            ) : (
              <span domPropsInnerHTML={child}></span>
            )
          ) : (
            child
          )}
        </div>
      </li>
    )

    // return this.functionalBadge(
    //   item.badgeCount,
    //   <div {...opt} onClick={(event) => this.handleClick(event, item)}>
    //     {!item.isButton && <my-img class={style.itemBg} src={imgSrc}></my-img>}
    //     {item.tag &&
    //       (typeof item.tag === 'function' ? item.tag(isActive) : item.tag)}
    //     {item.icon && (
    //       <div class={style.icon}>
    //         {typeof item.icon === 'function' ? item.icon(isActive) : item.icon}
    //       </div>
    //     )}
    //     <div class={style.text}>
    //       {typeof child === 'string' ? <span>{child} </span> : child}
    //     </div>
    //   </div>
    // )
  }

  /**
   * 包裹使用高级函数
   */
  private sectionWrapper(
    content: VueTsxSupport.JSX.Element,
    opt: { isScrollY: boolean }
  ) {
    if (opt.isScrollY) {
      return (
        <my-scroll
          direction="y"
          trigger="none"
          height={this.scrollHeight}
          width={`calc(100% + ${BusinessUtils.px2rem('10px')})`}
        >
          <DrageScrollArea direction="y">{content}</DrageScrollArea>
        </my-scroll>
      )
    }

    return content
  }

  renderTabItem(item: RenderItem) {
    if (item?.badgeCount && item?.badgeCount > 0) {
      return (
        <CommonBadge
          class={[this.badgeClass || '', 'subItem']}
          value={item?.badgeCount}
        >
          {this.renderItem(item, false)}
        </CommonBadge>
      )
    }
    return this.renderItem(item)
  }

  get tabs() {
    const list = (
      <ul
        data-len={this.renderList.length}
        class={{
          'common-tabs-nav': true,
          [style.tabs]: true,
          [style['hidden-for-one']]: !!this.tabsNavHiddenForOne,
          [style.hidden]: !!this.tabsNavHidden
        }}
        style={{
          width: this.tabsScrollWidth || this.ulWidth
        }}
      >
        {this.renderList
          .filter((i) => !i.isFixedToBottom)
          .map(this.renderTabItem)}
      </ul>
    )
    if (this.isScrollY) {
      return this.sectionWrapper(list, { isScrollY: true })
    }
    return this.sectionWrapper(list, { isScrollY: false })
  }

  render() {
    return (
      <section class={[style.sideTabsRoot, 'common-tabs-sideTabsRoot']}>
        <div class={[style.tabsWrapper, 'common-tabs-sideWrapper']}>
          <div class="common-tabs-box">{this.tabs}</div>
          {/* 需要排除到滚动条外的按钮 */}
          {this.renderList
            .filter((i) => i.isFixedToBottom)
            .map(this.renderTabItem)}
        </div>
        <div class={['common-tabs-content']}>
          <keep-alive include={this.needCacheComponentsList}>
            {this.renderList.map((item) => {
              if (this.customRender || item.value === this.current) {
                return (
                  this.$slots[item.scope || (item.label as string)] ||
                  this.$scopedSlots?.default?.(item)
                )
              }
              return null
            })}
          </keep-alive>
        </div>
      </section>
    )
  }
}
