import { Base, Component, Emit, Prop, Watch } from '@/vue-property-decorator'
import { Input } from '@/plugins/ant-design-vue/tsx-support'
import { createSvgSprite } from '@/utils/business-utils/svg'
import { merge } from 'lodash'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import style from './style.module.scss'
interface Props {
  emailCheckTemplate: string
  disabled?: boolean
}

interface Events {
  onComplete: string
}

@Component<EmailCheckInput>({ name: 'EmailCheckInput' })
export default class EmailCheckInput extends Base<unknown, Props, Events> {
  @Prop(AppTypes.string.isRequired)
  private emailCheckTemplate!: Props['emailCheckTemplate']

  @Prop(AppTypes.looseBool)
  private disabled!: Props['disabled']

  private inputValue = ''

  private isFocus = false

  /**
   * 传入的模板的解析
   */
  private get templateParse() {
    return BusinessUtils.emailCheckParse(this.emailCheckTemplate)
  }

  @Emit('complete')
  private onComplete() {
    return `${this.templateParse.prefix}${this.inputValue}@${this.templateParse.emaildomain}`
  }

  @Watch('inputValue')
  protected onInput() {
    if (
      (this.inputValue || '').length >= this.templateParse.placeholder.length
    ) {
      this.onComplete()
    }
  }

  render() {
    return (
      <section
        class={[
          style.main,
          this.isFocus ? style.mainFocus : '',
          'emailCheckinputmain'
        ]}
      >
        <div class={style.fix}>
          <icon-sprite sprite={createSvgSprite('input_icon_yj2')} />
          {this.templateParse.prefix}
        </div>
        <div class={[style.input, this.disabled ? style.valuePassed : '']}>
          <Input.Tsx
            v-model={this.inputValue}
            maxlength={this.templateParse.placeholder.length}
            disabled={this.disabled}
            {...merge({
              attrs: {
                maxLength: this.templateParse.placeholder.length
              }
            })}
            onBlur={() => {
              if (this.isFocus) {
                this.isFocus = false
              }
            }}
            onFocus={() => {
              if (!this.isFocus) {
                this.isFocus = true
              }
            }}
          ></Input.Tsx>
        </div>
        <div class={style.fix}>
          {this.templateParse.suffix}@{this.templateParse.emaildomain}
        </div>
      </section>
    )
  }
}
