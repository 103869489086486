/**
 * 特殊字符
 */
export const SPECIAL_SYMBOLS_REG =
  /[`~!@#$%^&*()_<>?:{}|\/;\\[\]~！￥……•​（）——\-+={}|《》？：“”〝〞ˆˇ"【】、；，。、﹍﹎︵︷︿︹︽_﹁﹃︻︶︸﹀︺︾ˉ﹂﹄︼¡¿﹏﹋﹌︴｛「｛｝］［​]/g

/**
 * emoji表情
 */
export const EMOJI_REG =
  /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/g

/**
 * 越南字符
 */

export const VI_VN_STRING = {
  Á: 'A',
  À: 'A',
  Ạ: 'A',
  Ả: 'A',
  Ã: 'A',
  Ă: 'A',
  Ắ: 'A',
  Ằ: 'A',
  Ặ: 'A',
  Ẳ: 'A',
  Ẵ: 'A',
  Â: 'A',
  Ấ: 'A',
  Ầ: 'A',
  Ậ: 'A',
  Ẩ: 'A',
  Ẫ: 'A',
  á: 'A',
  à: 'A',
  ạ: 'A',
  ả: 'A',
  ã: 'A',
  ă: 'A',
  ắ: 'A',
  ằ: 'A',
  ặ: 'A',
  ẳ: 'A',
  ẵ: 'A',
  â: 'A',
  ấ: 'A',
  ầ: 'A',
  ậ: 'A',
  ẩ: 'A',
  ẫ: 'A',
  Đ: 'D',
  đ: 'D',
  É: 'E',
  È: 'E',
  Ẹ: 'E',
  Ẻ: 'E',
  Ẽ: 'E',
  Ê: 'E',
  Ế: 'E',
  Ề: 'E',
  Ệ: 'E',
  Ể: 'E',
  Ễ: 'E',
  é: 'E',
  è: 'E',
  ẹ: 'E',
  ẻ: 'E',
  ẽ: 'E',
  ê: 'E',
  ế: 'E',
  ề: 'E',
  ệ: 'E',
  ể: 'E',
  ễ: 'E',
  Í: 'I',
  Ì: 'I',
  Ị: 'I',
  Ỉ: 'I',
  Ĩ: 'I',
  í: 'I',
  ì: 'I',
  ị: 'I',
  ỉ: 'I',
  ĩ: 'I',
  Ó: 'O',
  Ò: 'O',
  Ọ: 'O',
  Ỏ: 'O',
  Õ: 'O',
  Ô: 'O',
  Ố: 'O',
  Ồ: 'O',
  Ộ: 'O',
  Ổ: 'O',
  Ỗ: 'O',
  Ơ: 'O',
  Ớ: 'O',
  Ờ: 'O',
  Ợ: 'O',
  Ở: 'O',
  Ỡ: 'O',
  ó: 'O',
  ò: 'O',
  ọ: 'O',
  ỏ: 'O',
  õ: 'O',
  ô: 'O',
  ố: 'O',
  ồ: 'O',
  ộ: 'O',
  ổ: 'O',
  ỗ: 'O',
  ơ: 'O',
  ớ: 'O',
  ờ: 'O',
  ợ: 'O',
  ở: 'O',
  ỡ: 'O',
  Ú: 'U',
  Ù: 'U',
  Ụ: 'U',
  Ủ: 'U',
  Ũ: 'U',
  Ư: 'U',
  Ứ: 'U',
  Ừ: 'U',
  Ự: 'U',
  Ử: 'U',
  Ữ: 'U',
  ú: 'U',
  ù: 'U',
  ụ: 'U',
  ủ: 'U',
  ũ: 'U',
  ư: 'U',
  ứ: 'U',
  ừ: 'U',
  ự: 'U',
  ử: 'U',
  ữ: 'U',
  Ỳ: 'Y',
  Ý: 'Y',
  Ỵ: 'Y',
  Ỷ: 'Y',
  Ỹ: 'Y',
  ỳ: 'Y',
  ý: 'Y',
  ỵ: 'Y',
  ỷ: 'Y',
  ỹ: 'Y'
}
/**
 * 数字
 */
export const DIGIT_REG = /[0-9]/g

/** 匹配通用域名 */
export const DOMAIN_REG = /^(?:https?:\/\/)?(?:www\.)?([^:/?#]+)/i
