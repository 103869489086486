import {
  CurrentPeriodRewardResponse,
  RechargeFundSettingResponse,
  RechargeListPayload,
  RechargeListResponse,
  rechargeFundReceivePayload
} from './type'
import { GlobalService } from '@/context'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
/**
 * 公积金充值活动 - 充值明细列表
 * */
export const apiGetRechargeList = (payload: RechargeListPayload) => {
  return GlobalService.post<RechargeListResponse>({
    url: '/active/rechargeFund/recharge/list',
    data: payload,
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as RechargeListResponse)
}
/**
 * 公积金充值活动 - 活动配置
 * */
export const apiGetRechargeFundSetting = () => {
  return GlobalService.get<RechargeFundSettingResponse>({
    url: '/active/rechargeFund/setting/get',
    customParams: {
      staticKeyOmit: ['platformType'],
      keyOmit: true,
      tryStaticMode: true
    }
  }).then((res) => res.data.data as RechargeFundSettingResponse)
}
/**
 * 公积金充值活动 - 当前周期奖励
 * */
export const apiGetCurrentPeriodReward = () => {
  return GlobalService.post<CurrentPeriodRewardResponse>({
    url: '/active/rechargeFund/currentPeriodReward/get'
  }).then((res) => {
    const data = res.data.data as CurrentPeriodRewardResponse
    return data
  })
}
/**
 * 公积金充值活动 - 取出公积金
 * */
export const rechargeFundReceive = (payload: rechargeFundReceivePayload) => {
  return GlobalService.post({
    url: '/active/rechargeFund/receive',
    data: payload,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data)
}
