import { Base, Component, Emit, Prop } from '@/vue-property-decorator'
import { Popup } from '@/plugins/vant/tsx-support'

type Props = {
  value: boolean
  position?: 'top' | 'bottom' | 'left' | 'right'
  overlayStyle?: Record<string, string | number>
  duration?: number | string
  closeOnClickOverlay?: boolean
  overlayClass?: string
}

@Component<PopupH5>({
  name: 'PopupH5'
})
export default class PopupH5 extends Base<unknown, Props> {
  /** 是否显示Popup */
  @Prop({ required: true, default: false })
  private value!: Props['value']

  /** 属性设置弹出位置 */
  @Prop({ required: false, default: 'left' })
  private position!: Props['position']

  /** 动画时长，单位秒 */
  @Prop({ required: false, default: 0.3 })
  private duration!: Props['duration']

  /** 属性设置弹出位置 */
  @Prop({ required: false, default: () => ({}) })
  private overlayStyle!: Props['overlayStyle']

  /** 自定义遮罩层类名 */
  @Prop({ required: false, default: '' })
  private overlayClass!: Props['overlayClass']

  /** 是否在点击遮罩层后关闭 */
  @Prop({ required: false, default: true })
  private closeOnClickOverlay!: Props['closeOnClickOverlay']

  /**是否在点击遮罩层后关闭 */
  @Emit('clickOverlay')
  private clickOverlay() {
    console.log('clickOverlay...')
  }

  /**点击弹出框 */
  @Emit('clickPopup')
  private popupClick() {
    console.log('clickPopup...')
  }
  render() {
    const {
      value,
      position,
      overlayStyle,
      closeOnClickOverlay,
      overlayClass,
      duration
    } = this
    return (
      <Popup
        overlay-class={overlayClass}
        value={value}
        close-on-click-overlay={closeOnClickOverlay}
        duration={duration}
        overlay={true}
        position={position}
        overlayStyle={overlayStyle}
        on-click-overlay={this.clickOverlay}
        onclick={this.popupClick}
      >
        {this.$slots?.default}
      </Popup>
    )
  }
}
