import './style.scss'
import { BarTrigger, ElementObj } from './types'
import { Base, Component, Emit, Prop, Ref } from '@/vue-property-decorator'
import { Debounce } from 'lodash-decorators'
import { addResizeListener, removeResizeListener } from './resize-event'
import { raf } from '@/utils/Tool'
import { useMainStore } from '@/store/index'
import { v4 } from 'uuid'
import Animation from '@/utils/Animation'
import AppTypes, { string } from '@/vue-types'
import Bar from './bar'

export interface MyScrollProps {
  refKey?: string //绑定的key，如果两个组件refKey一致 则 滚动逻辑会进行同步
  width?: string //容器宽度
  height?: string //容器高度
  direction?: 'x' | 'y' | 'all' //需要渲染的滚动条类型
  trigger?: BarTrigger //滚动条出现的时机
  /**
   * 滑块的默认样式
   */
  barStyle?: StyleValue
  /**
   * 轨道的默认样式
   */
  thumbStyle?: StyleValue
  lock?: boolean
  /**
   * 是否启用拖拽，默认关闭
   */
  dragable?: boolean
  /**步长：拖拽最小单位 */
  stepWidth?: number
  /**
   * 是否使用自带的滚动条
   */
  useSystemBar?: boolean
}

export interface MyScrollScopedSlots {
  default?: void
}
export interface ScrollPosition {
  x: number
  y: number
  scrollWidth: number
}
export interface MyScrollEvents {
  onScroll: Event
  onScrollStart: Event
  onScrollEnd: Event
  onScrollChange: (position: ScrollPosition) => void
}

interface State {
  vThumbHeight: string
  hThumbWidth: string
  moveX: string
  moveY: string
  hover: boolean
  hasVBar: boolean
  hasHBar: boolean
}

const scrollContext: Record<string, Set<MyScroll>> = {}
const DefaultStep = 1
const Duration = 200
/**
 * 自定义实现的滚动条组件，实现了样式、滚动事件，点击滚动事件；
 * 加入了内容拖拽；可用设置拖拽最小单位 stepWidth
 */
@Component<MyScroll>({
  name: 'MyScroll'
})
export default class MyScroll extends Base<
  State,
  MyScrollProps,
  MyScrollEvents,
  MyScrollScopedSlots
> {
  private scrollBySynced = false
  private uuid = v4()
  private scrollTimeout: null | number = null
  state = {
    vThumbHeight: '0', //竖向滚动条滑块高度
    hThumbWidth: '0', //横向滚动条滑块宽度
    moveX: '0%', //横向滚动条位移百分比
    moveY: '0%', //纵向滚动调位移百分比
    hover: false, //鼠标是否hover上去
    hasVBar: false, //判断纵向滚动条是否应该渲染
    hasHBar: false //判断横向滚动调是否应该渲染
  }
  /**
   * 是否启用拖拽，默认关闭；移动端不触发
   */
  @Prop({ default: false })
  dragable?: MyScrollProps['dragable']
  @Prop({ default: DefaultStep })
  stepWidth?: MyScrollProps['stepWidth']

  @Prop({ default: false })
  useSystemBar!: NonNullable<MyScrollProps['useSystemBar']>

  @Prop(AppTypes.string)
  private readonly refKey!: string
  @Prop(AppTypes.string.def('100%'))
  private readonly width!: string
  @Prop(AppTypes.string.def('100%'))
  private readonly height!: string
  @Prop(string<'x' | 'y' | 'all'>().def('all'))
  private readonly direction!: NonNullable<MyScrollProps['direction']>
  @Prop(string<BarTrigger>().def('hover'))
  private readonly trigger!: NonNullable<BarTrigger>
  @Prop(
    AppTypes.style.def({
      backgroundColor: ''
    })
  )
  private readonly barStyle!: NonNullable<StyleValue>
  @Prop(
    AppTypes.style.def({
      backgroundColor: 'var(--theme-scroll-bar-bg-color)'
    })
  )
  private readonly thumbStyle!: NonNullable<StyleValue>

  @Ref()
  private readonly wrapRef!: HTMLDivElement

  @Prop(AppTypes.bool.def(false))
  private readonly lock!: boolean
  private ticking = false //raf触发锁
  /** 滚动锁，防止滚动事件死循环 */
  private lockScroll = false

  private handleScrollRaf(event: Event) {
    if (!this.ticking) {
      raf(() => {
        this.handleScroll(event)
        this.handleStepScroll()
      })
      this.ticking = true
    }
  }
  /** 按设置的步长来实现最小滚动 */
  @Debounce(50)
  private handleStepScroll() {
    if (this.isDragging || this.stepWidth === DefaultStep || this.lockScroll) {
      return
    }
    this.setStep()
  }

  @Emit('scroll')
  private handleScroll(event: Event) {
    // 如果存在定时器，清除它（这意味着滚动没有结束）
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    } else {
      // 如果定时器不存在，表示滚动刚刚开始
      this.$emit('scrollStart')
    }

    // 设置一个新的定时器
    // 如果在设置的时间内没有新的滚动事件触发，则认为滚动结束
    this.scrollTimeout = window.setTimeout(() => {
      // 如果定时器不存在，表示滚动刚刚开始
      this.$emit('scrollEnd')
      this.scrollTimeout = null // 清除定时器ID
    }, 150) // 150毫秒后没有滚动事件触发

    if (!event.target) {
      return
    }
    const { scrollTop, clientHeight, scrollLeft, clientWidth } =
      event.target as HTMLElement

    this.setState({
      moveX: `${(scrollLeft * 100) / clientWidth}%`,
      moveY: `${(scrollTop * 100) / clientHeight}%`
    })
    this.ticking = false
  }
  /**滚动条位置改变事件 */
  @Emit('scrollChange')
  private scrollChange(position: ScrollPosition) {
    return position
  }

  public scrollTo({ x, y }: { x?: number; y?: number }, duration = 200) {
    x = x ?? this.wrapRef.scrollLeft
    y = y ?? this.wrapRef.scrollTop
    const to = (target: { x: number; y: number }) => {
      if (this.wrapRef) {
        this.wrapRef.scrollLeft = target.x
        this.wrapRef.scrollTop = target.y
      }
    }
    this.scrollChange({ x, y, scrollWidth: this.wrapRef?.scrollWidth })
    if (duration) {
      new Animation({
        x: this.wrapRef.scrollLeft,
        y: this.wrapRef.scrollTop
      })
        .to({ x, y }, duration)
        .on(Animation.EventType.UPDATE, to)
        .on(Animation.EventType.COMPLETE, to)
    } else {
      to({ x, y })
    }
  }

  private bindScrollSyncHandler() {
    if (this.isKeptAlive) {
      return
    }
    if (this.scrollBySynced) return
    const { scrollLeft, scrollTop } = this.wrapRef
    const list = scrollContext[this.uuid]
    const sync = () => {
      for (const context of list) {
        if (context !== this) {
          this.scrollBySynced = true
          if (context.direction !== 'x') {
            context.wrapRef.scrollTop = scrollTop
          }
          if (context.direction !== 'y') {
            context.wrapRef.scrollLeft = scrollLeft
          }
          this.$nextTick(() => {
            this.scrollBySynced = false
          })
        }
      }
    }
    sync()
  }

  private scrollContextCollection() {
    scrollContext[this.uuid] = scrollContext[this.uuid] || new Set()
    if (this.wrapRef) {
      scrollContext[this.uuid].add(this)
      this.wrapRef.removeEventListener('scroll', this.bindScrollSyncHandler)
      this.wrapRef.addEventListener('scroll', this.bindScrollSyncHandler)
    }
  }

  private removeScrollCollection() {
    this.wrapRef.removeEventListener('scroll', this.bindScrollSyncHandler)
    scrollContext[this.uuid].delete(this)
  }

  created() {
    this.uuid = this.refKey || this.uuid
  }

  private get withBarCalc() {
    return !this.useSystemBar && this.trigger !== 'none'
  }

  private updateBar() {
    if (this.wrapRef && this.withBarCalc) {
      const heightPercentage =
        (this.wrapRef.clientHeight * 100) / this.wrapRef.scrollHeight
      const widthPercentage =
        (this.wrapRef.clientWidth * 100) / this.wrapRef.scrollWidth

      this.setState({
        hasVBar: heightPercentage < 100,
        hasHBar: widthPercentage < 100,
        vThumbHeight: heightPercentage < 100 ? `${heightPercentage}%` : '',
        hThumbWidth: widthPercentage < 100 ? `${widthPercentage}%` : ''
      })
    }
  }

  get isWeb() {
    return useMainStore().isWeb
  }

  private isDragging = false
  private startX = 0
  private scrollLeft = 0

  private startY = 0
  private scrollTop = 0
  private showMask = false
  /**
   * 鼠标按下事件处理
   * @param event 鼠标事件
   */
  handleMouseDown(event: MouseEvent) {
    this.isDragging = true
    if (this.direction === 'x') {
      this.startX = event.pageX - this.wrapRef!.offsetLeft
      this.scrollLeft = this.wrapRef!.scrollLeft
    } else {
      this.startY = event.pageY - this.wrapRef!.offsetTop
      this.scrollTop = this.wrapRef!.scrollTop
    }
  }
  handleTouchStart(event: TouchEvent) {
    if (this.isKeptAlive) {
      return
    }
    const touch = event.touches[0]
    this.isDragging = true

    if (this.direction === 'x') {
      this.startX = touch.pageX - this.wrapRef!.offsetLeft
      this.scrollLeft = this.wrapRef!.scrollLeft
    } else {
      this.startY = touch.pageY - this.wrapRef!.offsetTop
      this.scrollTop = this.wrapRef!.scrollTop
    }
  }

  /**
   * 鼠标移动事件处理移动距离与速度
   * @param event 鼠标事件
   * @returns
   */
  handleMouseMove(event: MouseEvent) {
    if (this.isKeptAlive) {
      return
    }
    if (!this.isDragging) return
    let walk: number
    if (this.direction === 'x') {
      const x = event.pageX - this.wrapRef!.offsetLeft
      walk = (x - this.startX) * 1 // 控制滚动速度
      this.wrapRef!.scrollLeft = this.scrollLeft - walk
    } else {
      const y = event.pageY - this.wrapRef!.offsetTop
      walk = (y - this.startY) * 1 // 控制滚动速度
      this.wrapRef!.scrollTop = this.scrollTop - walk
    }
    this.scrollChange({
      x: this.wrapRef!.scrollLeft,
      y: this.wrapRef!.scrollTop,
      scrollWidth: this.wrapRef?.scrollWidth
    })
    // 移动距离大于20px,则开启遮罩层,避免触发点击事件. iscrool
    if (Math.abs(walk) > 20) {
      this.showMask = true
    }
  }
  handleTouchMove(event: TouchEvent) {
    if (this.isKeptAlive) {
      return
    }
    if (!this.isDragging) return

    const touch = event.touches[0]
    let walk: number
    if (this.direction === 'x') {
      const x = touch.pageX - this.wrapRef!.offsetLeft
      walk = (x - this.startX) * 1 // 控制滚动速度
      this.wrapRef!.scrollLeft = this.scrollLeft - walk
    } else {
      const y = touch.pageY - this.wrapRef!.offsetTop
      walk = (y - this.startY) * 1 // 控制滚动速度
      this.wrapRef!.scrollTop = this.scrollTop - walk
    }

    this.scrollChange({
      x: this.wrapRef!.scrollLeft,
      y: this.wrapRef!.scrollTop,
      scrollWidth: this.wrapRef?.scrollWidth
    })

    // 移动距离大于20px，则开启遮罩层，避免触发点击事件
    if (Math.abs(walk) > 20) {
      this.showMask = true
    }
  }

  /**
   * 若传入 stepWidth 属性，则将根据步长来实现拖拽最小单位
   * 鼠标松开或离开触发
   */
  @Debounce(100)
  private setStep() {
    const step: number = this.stepWidth as number
    if (step !== DefaultStep) {
      let currentPosition = this.wrapRef.scrollLeft
      const walk = currentPosition - this.scrollLeft
      // 确保滚动过的是完整的step
      if (walk > 0) {
        currentPosition = Math.ceil(this.wrapRef.scrollLeft / step) * step
      } else if (walk < 0) {
        currentPosition = Math.floor(this.wrapRef.scrollLeft / step) * step
      }

      this.scrollLeft = currentPosition
      this.lockScroll = true
      this.scrollTo({ [this.direction]: currentPosition }, Duration * 2)
      this.freeLockScroll()
    }
  }

  /** 延迟释放锁，以解决来回拖拽死循环问题 */
  @Debounce(Duration * 3)
  freeLockScroll() {
    this.lockScroll = false
  }
  /**
   * 鼠标离开或松开事件
   */
  handleMouseUpOrLeave() {
    if (this.isKeptAlive) {
      return
    }
    if (this.dragable && this.isDragging) {
      this.isDragging = false
      this.showMask = false
      this.setStep()
    }
  }

  mounted() {
    this.updateBar() // 初始化调用一次，计算滚动条默认高度
    this.scrollContextCollection()
    if (this.withBarCalc) {
      addResizeListener(this.wrapRef as unknown as ElementObj, this.updateBar) // 监听元素变化，如果容器DOM变化触发更新
    }
    this.registerDragable()
  }

  registerDragable() {
    if (!this.dragable) return
    this.$nextTick(() => {
      const isSupportTouch = 'ontouchstart' in window
      if (this.isWeb || !isSupportTouch) {
        this.wrapRef?.addEventListener('mousemove', this.handleMouseMove)
        this.wrapRef?.addEventListener('mouseup', this.handleMouseUpOrLeave)
      } else {
        /**
         * 移动端开启了dragable的还是先注册，现在stake版式需要关联stepWidth控制
         * 触摸步长
         */
        this.wrapRef?.addEventListener('touchstart', this.handleTouchStart, {
          passive: true
        })
        this.wrapRef?.addEventListener('touchmove', this.handleTouchMove)
        this.wrapRef?.addEventListener('touchend', this.handleMouseUpOrLeave)
        this.wrapRef?.addEventListener('touchcancel', this.handleMouseUpOrLeave)
      }
    })
    this.scrollChange({ x: 0, y: 0, scrollWidth: this.wrapRef?.scrollWidth })
  }

  removeDragable() {
    if (!this.dragable) return
    this.wrapRef?.removeEventListener('mousemove', this.handleMouseMove)
    this.wrapRef?.removeEventListener('mouseup', this.handleMouseUpOrLeave)
    this.wrapRef?.removeEventListener('touchstart', this.handleTouchStart)
    this.wrapRef?.removeEventListener('touchmove', this.handleTouchMove)
    this.wrapRef?.removeEventListener('touchend', this.handleMouseUpOrLeave)
    this.wrapRef?.removeEventListener('touchcancel', this.handleMouseUpOrLeave)
  }

  beforeDestroy() {
    this.removeScrollCollection()
    if (this.withBarCalc) {
      removeResizeListener(
        this.wrapRef as unknown as ElementObj,
        this.updateBar
      )
    }

    this.removeDragable()
  }

  render() {
    return (
      <div
        class={`my-scrollbar my-scrollbar-${this.trigger}`}
        style={{ width: this.width, height: this.height }}
      >
        {
          <div
            class={{
              'my-scrollbar-wrap': true,
              'my-scrollbar-wrap-lock': this.lock,
              [`my-scrollbar-wrap-${this.direction}`]: true,
              'my-scrollbar-wrap-use-systembar': this.useSystemBar
            }}
            ref={'wrapRef'}
            onScroll={this.handleScrollRaf}
            {...{
              on: this.dragable
                ? {
                    mousedown: this.handleMouseDown,
                    mouseleave: this.handleMouseUpOrLeave.bind(this)
                  }
                : {}
            }}
          >
            {this.dragable ? (
              <div
                class={{
                  masklayer: true,
                  'show-mask': this.showMask
                }}
              ></div>
            ) : null}
            <div class="my-scrollbar-content">
              {this.$scopedSlots.default?.()}
            </div>
          </div>
        }
        {(() => {
          if (!this.state.hasVBar && !this.state.hasHBar) {
            return null
          }
          if (!this.wrapRef || !this.withBarCalc) return null

          return [
            this.direction !== 'x' && (
              <Bar
                {...{
                  props: {
                    size: this.state.vThumbHeight
                  }
                }}
                direction="vertical"
                parentRef={this.wrapRef}
                move={this.state.moveY}
                barStyle={this.barStyle}
                thumbStyle={this.thumbStyle}
                v-show={this.state.hasVBar}
                class="my-scrollbar-bar-v-bar"
              />
            ),
            this.direction !== 'y' && (
              <Bar
                {...{
                  props: {
                    size: this.state.hThumbWidth
                  }
                }}
                direction="horizontal"
                parentRef={this.wrapRef}
                move={this.state.moveX}
                barStyle={this.barStyle}
                thumbStyle={this.thumbStyle}
                v-show={this.state.hasHBar}
                class="my-scrollbar-bar-h-bar"
              />
            )
          ]
        })()}
      </div>
    )
  }
}
