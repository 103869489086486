import { Base, Component, Prop } from '@/vue-property-decorator'
import { Holiday } from '@/store/index/type'
import { RouteName } from '@/router/config'
import { assetsPath } from '@/utils/business-utils/assets'
import { useMainStore } from '@/store/index'
import Skin from '@/controller/Skin'
import style from './style.module.scss'

type Props = {
  isVertical: boolean
}
/** 活动等页面横向布局，经典版H5首页横向+竖向布局 */
@Component<SideTabHoliday>({
  name: 'SideTabHoliday'
})
export default class SideTabHoliday extends Base<unknown, Props> {
  @Prop()
  isVertical!: boolean

  private getImage(
    width: number,
    height: number,
    className:
      | string
      | {
          [x: string]: boolean
        },
    fileName = 'btn_zc1_jr'
  ) {
    return (
      <my-img
        width={width}
        height={height}
        options={{ type: 'div' }}
        class={className}
        alt=""
        src={assetsPath(
          `/lobby_asset/festival/{holiday}/common/common/${fileName}.png`
        )}
      />
    )
  }

  private renderHoliday() {
    const { isWeb } = useMainStore()
    const isHome = RouteName.GAME === this.$route.name
    switch (Skin.holidayThemeType) {
      case Holiday.MidAutumn:
        return [
          isHome &&
            this.getImage(60, 48, {
              [style.midAutumnHr]: true,
              [style.verital]: this.isVertical
            }),
          this.getImage(30, 18, style.topRight, 'btn_zc1_jr2')
        ]
      case Holiday.NationalDay:
        //国庆节侧边栏都要展示灯笼
        return this.getImage(24, 29, style.topRight)
      case Holiday.MotherOfGod:
        return this.getImage(24, 31, style.rightBottom)
      case Holiday.MotherOfGod2:
        return this.getImage(35, 35, style.rightBottom)
      case Holiday.Dussehra:
        return [
          this.getImage(
            this.isVertical ? 58 : 27,
            this.isVertical ? 72 : 34,
            style.topLeft
          ),
          this.getImage(
            this.isVertical ? 48 : 28,
            this.isVertical ? 36 : 21,
            style.rightBottom,
            'btn_zc1_jr2'
          )
        ]
      case Holiday.HalloweenPurple:
        return this.getImage(47, 31, style.rightBottom)
      case Holiday.HalloweenBlue:
        return this.getImage(47, 31, style.rightBottom)
      default:
        return [
          this.getImage(
            isWeb ? 40 : 50,
            isWeb ? 40 : 50,
            style.rightBottom,
            'btn_zc1_jr2'
          ),
          this.getImage(isWeb ? 40 : 50, isWeb ? 40 : 50, style.topLeft)
        ]
    }
  }

  render() {
    return <div class={style.holidayWrap}>{this.renderHoliday()}</div>
  }
}
