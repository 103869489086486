import {
  Base,
  Component,
  Mixins,
  Prop,
  Ref,
  Watch
} from '@/vue-property-decorator'
import { assetsPath } from '@/utils/business-utils/assets'
import { createOssAssetsPathOrigialRetry } from '@/utils/business-utils/retry'
import { isEqual } from 'lodash'
import { loadImage } from '@/utils/Loader'
import { random } from '@/utils/string'
import AppTypes, { object, oneOfType } from '@/vue-types'
import ImageUtils from '@/utils/ImageUtils'
import OssAssets from '@/mixins/business/ossAssets'
import SkeletonBox from '../skeleton-box'
import UnitMixins, { Props as UnitMixinsProps } from '@/mixins/unit'
import axios from 'axios'

interface RemoveAttrsParams {
  attrs: string
}

interface Operation {
  name: string
  params: RemoveAttrsParams
}

function processSvg(svgContent: string, operation: Operation): string {
  if (operation.name === 'removeAttrs') {
    const attrsPattern = operation.params.attrs
    // 构建正则表达式，匹配指定的属性
    const regex = new RegExp(`\\s+(${attrsPattern})="[^"]*"`, 'g')
    // 删除匹配的属性
    const updatedSvgContent = svgContent.replace(regex, '')
    return updatedSvgContent
  }
  // 如果提供了其他操作类型，可以在这里添加处理逻辑
  return svgContent // 如果没有匹配的操作，返回原始内容
}

export type SvgSprite = {
  id?: string
  viewBox?: string
  content?: string
  url?: string
}

export type PngSprite = {
  x: number
  y: number
  width: number
  height: number
  image: string
  id?: string
  total_width: number
  total_height: number
}

type PxData = {
  r: number
  g: number
  b: number
  a: number
}

export interface Props extends UnitMixinsProps {
  standalone?: string
  sprite: SvgSprite | PngSprite
  scale?: number
  /** 开启骨架模式 图片加载时候会有骨架屏的滚动背景占位 */
  useSkeleton?: boolean
  linearGradientConfig?: LinearGradientConfig
  useCanvas?: boolean
  /**
   * 操作像素点,例如进行颜色叠加
   */
  createPxData?: (pxData: PxData) => Partial<PxData>
}

export interface IconScopedSlots {
  default?: void
}

export interface LinearGradientConfig {
  angle?: number
  colorStop: Array<{
    progress: number
    color: string
  }>
}
@Component<IconSprite>({
  name: 'IconSprite'
})
export default class IconSprite extends Mixins<
  Base<unknown, Props, unknown, IconScopedSlots>,
  UnitMixins,
  OssAssets
>(Base, UnitMixins, OssAssets) {
  @Prop(oneOfType([object<SvgSprite>(), object<PngSprite>()]).isRequired)
  private readonly sprite!: Props['sprite']
  @Prop(AppTypes.number.def(1))
  private readonly scale!: NonNullable<Props['scale']>
  @Prop({ type: Boolean, default: false })
  private readonly useSkeleton!: NonNullable<Props['useSkeleton']>

  @Prop({ type: String })
  private readonly standalone!: NonNullable<Props['standalone']>

  private get isSvgMode() {
    if (this.standalone) {
      return this.standalone.includes('.svg')
    }
    return (this.innerSprite as SvgSprite)?.viewBox
  }

  @Prop({
    type: Function
  })
  private createPxData!: Props['createPxData']

  @Prop(AppTypes.looseBool.def(false))
  private readonly useCanvas!: NonNullable<Props['useCanvas']>
  @Prop(
    object<LinearGradientConfig>().def({
      angle: 180,
      colorStop: [
        // {
        //   progress: 0,
        //   color: 'red'
        // },
        // {
        //   progress: 1,
        //   color: 'blue'
        // }
      ]
    })
  )
  private readonly linearGradientConfig!: NonNullable<
    Props['linearGradientConfig']
  >

  @Ref('svg')
  protected readonly svgContainer!: HTMLElement

  private get innerSprite() {
    const url = (this.sprite as SvgSprite)?.url
    const image = (this.sprite as PngSprite)?.image
    return {
      ...this.sprite,
      url: url ? assetsPath(url) : url,
      image: image ? assetsPath(image) : image
    }
  }
  private standaloneLoading = false
  private renderSrc = ''
  private img!: HTMLImageElement
  private canvas!: HTMLCanvasElement
  private drawImg(canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    const sprite = this.innerSprite as PngSprite
    ctx.drawImage(
      this.img,
      sprite.x,
      sprite.y,
      sprite.width,
      sprite.height,
      0,
      0,
      sprite.width,
      sprite.height
    )
    /**
     * 传入了操作像素的操作的就进行像素操作
     */
    if (this.createPxData) {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const data = imageData.data
      ImageUtils.traversePixel(imageData, ({ pxStartIndex, pxData }) => {
        const {
          r = pxData.r,
          g = pxData.g,
          b = pxData.b,
          a = pxData.a
        } = this.createPxData?.(pxData) || {}

        data[pxStartIndex + 0] = r
        data[pxStartIndex + 1] = g
        data[pxStartIndex + 2] = b
        data[pxStartIndex + 3] = a
      })

      ctx.putImageData(imageData, 0, 0)
    }
  }

  /**
   * 以最小内容区（裁剪透明像素后的区域）做线性渐变
   */
  private createLinearGradient(canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    if (this.colorStop.length) {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const [startX, startY, endX, endY] =
        ImageUtils.calcPixelBoundaries(imageData)

      const colorWidth = (endX || 0) - (startX || 0)
      const colorHeight = (endY || 0) - (startY || 0)

      const { x0, y0, x1, y1 } = ImageUtils.calculateGradientCoordinate(
        colorWidth,
        colorHeight,
        this.linearGradientConfig.angle ?? 180
      )

      const gi = ctx.createLinearGradient(
        startX + x0,
        startY + y0,
        startX + x1,
        startY + y1
      )
      this.colorStop.forEach(({ progress, color }) => {
        gi.addColorStop(progress, color)
      })
      ctx.fillStyle = gi
      ctx.fillRect(startX || 0, startY || 0, colorWidth, colorHeight)
      ctx.globalCompositeOperation = 'destination-atop'
      this.drawImg(canvas)
      ctx.globalCompositeOperation = 'source-in'
    }
  }

  private destroyCanvas() {
    if (this.canvas) {
      this.$el.removeChild(this.canvas)
      this.$delete(this, 'canvas')
    }
  }

  private get colorStop(): LinearGradientConfig['colorStop'] {
    return JSON.parse(JSON.stringify(this.linearGradientConfig.colorStop))
  }

  /**
   * Svg模式且传入了渐变配置
   */
  private get svgGradientConfig() {
    return this.isSvgMode && this.colorStop.length
      ? this.linearGradientConfig
      : undefined
  }

  // private uniqueId = 'id-' + uuid.v4()
  private uniqueId = 'id-' + random()
  /**
   * 需要检测的目标资源路径
   */
  protected get ossTargetSrc() {
    if (this.standalone) {
      return this.standalone
    }
    return this.innerSprite.image || this.innerSprite.url || ''
  }

  private drawContent(canvas: HTMLCanvasElement, clear = true) {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    if (clear) {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    }
    this.drawImg(canvas)
    this.createLinearGradient(canvas)
  }
  @Watch('sprite')
  @Watch('scale')
  @Watch('useCanvas')
  @Watch('createPxData')
  @Watch('linearGradientConfig.angle')
  @Watch('colorStop', { deep: true })
  private async init(val?: unknown, oldValue?: unknown) {
    if (typeof val !== 'undefined' && isEqual(val, oldValue)) return
    if (this.isSvgMode && (this.innerSprite as SvgSprite).id) {
      if (this.standalone) {
        this.handleSvgStandalone()
      }
      return
    }
    const url = this.innerSprite.image || this.innerSprite.url || ''
    if (/^blob:/.test(url) && !this.useCanvas) {
      this.renderSrc = url
      return
    }
    this.img = (await createOssAssetsPathOrigialRetry((origin) => {
      return loadImage(
        this.ossLobbyAssetPathOrigin
          ? url.replace(this.ossLobbyAssetPathOrigin || '', origin)
          : url
      )
    })) as HTMLImageElement

    this.renderSrc = this.img.src

    if (this.isSvgMode || !this.useCanvas) {
      return this.destroyCanvas()
    }
    const canvas = this.canvas || document.createElement('canvas')

    const sprite = this.innerSprite as PngSprite
    const scale = this.scale

    canvas.width = sprite.width
    canvas.height = sprite.height

    this.drawContent(canvas)
    canvas.style.width = `${(scale * sprite.width) / this.baseUnit}${this.unit}`
    canvas.style.height = `${(scale * sprite.height) / this.baseUnit}${
      this.unit
    }`
    canvas.style.position = 'absolute'
    canvas.style.left = '50%'
    canvas.style.top = '50%'
    canvas.style.marginLeft =
      (-sprite.width * scale) / this.baseUnit / 2 + this.unit
    canvas.style.marginTop =
      (-sprite.height * scale) / this.baseUnit / 2 + this.unit
    if (!this.canvas) {
      this.$el.appendChild(canvas)
      this.canvas = canvas
    }
  }
  mounted() {
    this.init()
  }

  private async handleSvgStandalone() {
    if (this.standalone) {
      const url = this.standalone
      this.standaloneLoading = true
      const response = await createOssAssetsPathOrigialRetry((origin) => {
        return axios.get(
          this.ossLobbyAssetPathOrigin
            ? url.replace(this.ossLobbyAssetPathOrigin || '', origin)
            : url
        )
      }).finally(() => {
        this.standaloneLoading = false
      })
      if (response?.data) {
        const div = document.createElement('div')
        /**
         * 简单校验svg资源格式
         */
        if (/^<svg/.test(response.data)) {
          div.innerHTML = processSvg(response.data, {
            name: 'removeAttrs',
            params: {
              attrs: '(fill|stroke)'
            }
          })
          const $svg = div.childNodes[0] as SVGElement
          const $container = this.svgContainer
          if ($container) {
            $container.innerHTML = `${$svg.innerHTML}\n${
              this.svgGradientConfig
                ? `<defs>
                <linearGradient id=${this.uniqueId} x1="0.5" x2="0.5" y2="1">
                  ${this.svgGradientConfig?.colorStop
                    .map(
                      ({ progress, color }, index) =>
                        `<stop offset=${progress} stop-color=${color} key=${index} />`
                    )
                    .join('\n')}
                </linearGradient>
              </defs>`
                : ''
            }`
            $container.setAttribute(
              'viewBox',
              $svg.getAttribute('viewBox') || ''
            )
          }
        }
      }
    }
  }
  render() {
    if (this.isSvgMode) {
      const sprite = this.innerSprite as SvgSprite
      const secondarySlots = this.$slots?.secondary
      return (
        <SkeletonBox
          boxType="i"
          status={
            this.standalone
              ? this.standaloneLoading
                ? 'loading'
                : 'none'
              : this.useSkeleton
              ? 'svg'
              : 'none'
          }
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...(secondarySlots
              ? {
                  position: 'relative'
                }
              : {})
          }}
        >
          <svg
            {...{
              ref: 'svg',
              attrs: {
                width: '1em',
                height: '1em',
                fill:
                  (this.svgGradientConfig && `url(#${this.uniqueId})`) ||
                  'currentColor',
                'aria-hidden': 'true',
                focusable: 'false',
                ...this.$attrs
              }
            }}
          >
            {!this.standalone && [
              <use
                {...{
                  attrs: {
                    'xlink:href': sprite.id || this.renderSrc
                  }
                }}
              />,
              this.svgGradientConfig && (
                <defs>
                  {/**
                   * ToDo:
                   * x1 y1 ===> x2,y2 和 this.svgGradientConfig.angle 的算法映射
                   */}
                  <linearGradient id={this.uniqueId} x1="0.5" x2="0.5" y2="1">
                    {this.svgGradientConfig?.colorStop.map(
                      ({ progress, color }, index) => (
                        <stop
                          offset={progress}
                          stop-color={color}
                          key={index}
                        />
                      )
                    )}
                  </linearGradient>
                </defs>
              )
            ]}
          </svg>
          {this.$slots?.default || this.$scopedSlots.default?.()}
          {secondarySlots && (
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: 0
              }}
            >
              {secondarySlots}
            </div>
          )}
        </SkeletonBox>
      )
    } else {
      const sprite = this.innerSprite as PngSprite
      const scale = this.scale
      return (
        <SkeletonBox
          boxType="i"
          status={
            this.standalone
              ? this.standaloneLoading
                ? 'loading'
                : 'none'
              : this.useSkeleton
              ? 'img'
              : 'none'
          }
          {...{
            attrs: {
              'data-id': sprite?.id,
              'aria-hidden': 'true',
              focusable: 'false'
            },
            style: {
              display: 'inline-block',
              position: 'relative',
              width: `${(sprite.width * scale) / this.baseUnit}${this.unit}`,
              height: `${(sprite.height * scale) / this.baseUnit}${this.unit}`,
              ...(this.useCanvas
                ? {}
                : {
                    backgroundImage: `url(${this.renderSrc})`,
                    backgroundPosition: `${
                      (-sprite.x * scale) / this.baseUnit
                    }${this.unit} ${(-sprite.y * scale) / this.baseUnit}${
                      this.unit
                    }`,
                    backgroundSize: `${
                      (sprite.total_width * scale) / this.baseUnit
                    }${this.unit} ${
                      (sprite.total_height * scale) / this.baseUnit
                    }${this.unit}`
                  })
            }
          }}
        >
          {this.$slots?.default || this.$scopedSlots.default?.()}
        </SkeletonBox>
      )
    }
  }
}
