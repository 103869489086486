import { Base, Component, Prop } from '@/vue-property-decorator'
import { Bind, Debounce } from '@/utils/Decorator'
import { Currency } from '@/config/currencies.config'
import { GlobalConst } from '@/context'
import { Icon } from '@/plugins/ant-design-vue/tsx-support'
import { PngSprite } from '@/components/icon-sprite'
import { RouteName } from '@/router/config'
import { UserInfos } from '@/api/common/type'
import {
  assetsPath,
  createPngSprite,
  createSvgSprite
} from '@/utils/business-utils/assets'
import { useGameStore } from '@/views/game/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import Skin from '@/controller/Skin'
import style from './style.module.scss'

type Props = {
  hiddenSymbol?: boolean
  fontSize?: number | `${string}px`
  mode?: 'flex'
  rowAlign?: 'center' | 'start' | 'end' | 'space-between' | 'space-around'
  columnAlign?: 'center' | 'start' | 'end' | 'space-between' | 'space-around'
  noUnderline?: boolean
  /** 前缀图标 */
  prefixArrow?: VueTsxSupport.JSX.Element | (() => VueTsxSupport.JSX.Element)
  /** 刷新icon名 */
  freshSvgName?: string
  /** 刷新icon png名 */
  freshPngName?: string | PngSprite
  /** 刷新icon png 缩放宽度  */
  freshPngScale?: number
}
export interface Events {
  onIconClick: string
}

@Component<CurrencyInfos>({
  name: 'CurrencyInfos'
})
export default class CurrencyInfos extends Base<unknown, Props, Events> {
  @Prop(AppTypes.bool.def(false))
  hiddenSymbol!: Props['hiddenSymbol']

  @Prop(AppTypes.string.def('flex'))
  mode!: Props['mode']

  @Prop(AppTypes.string.def('center'))
  rowAlign!: Props['rowAlign']

  @Prop(AppTypes.string.def('center'))
  columnAlign!: Props['columnAlign']

  @Prop(AppTypes.string.def(`18px`))
  fontSize!: Props['fontSize']

  @Prop(AppTypes.bool.def(false))
  noUnderline?: Props['noUnderline']

  @Prop({ default: null })
  readonly prefixArrow?: Props['prefixArrow']

  @Prop(AppTypes.string.def('comm_icon_sx'))
  freshSvgName!: Props['freshSvgName']

  @Prop(AppTypes.string.def(''))
  freshPngName!: Props['freshPngName']

  @Prop({ required: false, default: 24 })
  private freshPngScale!: Props['freshPngScale']

  @Bind()
  @Debounce()
  private async updateCurrency() {
    this.$emit('iconClick')
    useMainStore().logoutAndUpdatePrize(true)
  }

  @Bind()
  private goCenterWallet() {
    this.$router.push({
      name: RouteName.CENTER_WALLET
    })
  }

  private renderPrefixArrow() {
    return typeof this.prefixArrow === 'function'
      ? this.prefixArrow()
      : this.prefixArrow
  }

  private renderDefaultImage(isHK: boolean, scale: number, icon: PngSprite) {
    const classes = [
      style.currencySwitch,
      isHK ? style.hkCurrency : 'currency-switch'
    ]
    const imgSrc = isHK
      ? assetsPath(
          `/lobby_asset/{layout}-{bg}-common/common/_sprite/img_hb_wallet.png`
        )
      : '/lobby_asset/common/common/_sprite/img_hb_frame.png'

    return (
      <my-img class={classes} src={imgSrc} options={{ type: 'div' }}>
        {!isHK && <icon-sprite scale={scale} sprite={icon} />}
      </my-img>
    )
  }

  private currencySwitchIcon(scale: number, icon: PngSprite) {
    const { isHK } = Skin.currentTheme()

    return this.prefixArrow
      ? this.renderPrefixArrow()
      : this.renderDefaultImage(isHK, scale, icon)
  }

  render() {
    const { t } = useI18n()
    const { isGamePlaying, isGameScoring } = useGameStore()
    const { userInfos, isFetchingPrize, isWeb } = useMainStore()
    const { isStake, isCrownGold, isGalaxyGold, isTheme15, isTheme26 } =
      Skin.currentTheme()
    const currencyIconMap = Currency.registerIconMap()
    const { game_gold = 0 } = userInfos as UserInfos
    const icon =
      currencyIconMap[
        (userInfos?.currency ||
          'CNY') as unknown as keyof typeof currencyIconMap
      ].icon
    const scale =
      (isWeb
        ? isStake
          ? 20
          : isCrownGold || isGalaxyGold || isTheme15 || isTheme26
          ? 16
          : 28
        : isStake
        ? 26
        : 30) / icon.width

    const refreshPngIcon = this.freshPngName
      ? createPngSprite(`sprite_main_${this.freshPngName}`)
      : createSvgSprite('comm_icon_sx')

    let refreshPngScale: number
    if (this.freshPngName) {
      refreshPngScale =
        (this.freshPngScale ?? 24) / (refreshPngIcon as PngSprite)?.width
    }

    return (
      <div
        class={[
          style.main,
          'currencyInfoBox',
          'main',
          this.hiddenSymbol ? style.noSymbolmain : ''
        ]}
        style={{
          '--row-align': this.rowAlign,
          '--mode': this.mode,
          '--column-align': this.columnAlign
        }}
      >
        {!this.hiddenSymbol && this.currencySwitchIcon(scale, icon)}
        <section
          class={[style['currency-count'], 'currency-count']}
          style={{ 'font-size': BusinessUtils.px2rem(this.fontSize ?? '18px') }}
        >
          {isGamePlaying ? (
            <div class={[style.gaming, 'gaming']}>
              {t('lobby.header.moneyInGame')}
            </div>
          ) : (
            <div class={['countTextBox']}>
              {isGameScoring || (isFetchingPrize && game_gold === 0) ? (
                <span class={style['gaming']}>{t('lobby.game.loadinged')}</span>
              ) : (
                <span onClick={this.goCenterWallet}>
                  <count-to
                    class={[
                      style['count-to'],
                      this.noUnderline ? style.noUnderline : '',
                      'count-to'
                    ]}
                    value={+game_gold}
                  />
                </span>
              )}
              <Icon.Tsx
                onClick={this.updateCurrency}
                class={[
                  style['refresh-icon'],
                  [
                    {
                      [GlobalConst.CssClassName.Animate.Animated]:
                        isFetchingPrize
                    },
                    GlobalConst.CssClassName.Animate.Spin,
                    GlobalConst.CssClassName.Animate.Repeat.infinite
                  ],
                  'refresh-icon'
                ]}
                component={{
                  functional: true,
                  render: () => {
                    return (
                      <icon-sprite
                        scale={refreshPngScale}
                        sprite={
                          this.freshPngName
                            ? createPngSprite(
                                `sprite_main_${this.freshPngName}`
                              )
                            : createSvgSprite(this.freshSvgName!)
                        }
                      />
                    )
                  }
                }}
              />
            </div>
          )}
        </section>
      </div>
    )
  }
}
