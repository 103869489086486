import {
  ApiCanReceiveRewardListResponse,
  ApiReceiveResponse,
  ApiRewardListResponse,
  ApiSignalReceivePayload,
  ApiUserNameResponse,
  ApiUserVipDetailsResponse,
  ApiVipDetailsResponese,
  ApiVipInfoResponese,
  ApiVipReceivePayload,
  ApiVipReceiveResponse
} from '@/api/vip/type'
import { AxiosRequestConfig } from 'axios'
import { GlobalService } from '@/context'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
/**
 * vip列表
 */
export const apiVipDetails = () =>
  GlobalService.post<ApiVipDetailsResponese>({
    url: '/user/vipdetails',
    customParams: {
      cache: true
    }
  }).then((res) => res.data.data || ({} as ApiVipDetailsResponese))
/**
 * vip等级信息
 */
export const apiVipInfo = (cache = true) =>
  GlobalService.post<ApiVipInfoResponese>({
    url: '/user/vipInfo',
    customParams: {
      cache
    }
  }).then((res) => res.data.data || ({} as ApiVipInfoResponese))

/**
 * 用户vip详情
 */
export const apiUserVipDetails = () =>
  GlobalService.post<ApiUserVipDetailsResponse>({
    url: '/user/vip',
    customParams: {
      cache: true
    }
  }).then((res) => res.data.data as ApiUserVipDetailsResponse)
/**
 * 用户name
 */
export const apiUserName = () =>
  GlobalService.post<ApiUserNameResponse>({
    url: '/user/info'
  }).then((res) => res.data.data as ApiUserNameResponse)
/**
 * 领取奖励
 */
export const apiVipReceiveReward = (payload: ApiVipReceivePayload) =>
  GlobalService.post<ApiVipReceiveResponse>({
    url: '/user/giftRecv1',
    data: payload,
    customParams: {
      noErrorMessage: true,
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiVipReceiveResponse)

/**
 * 可领取活动列表
 */
export const apiGetRewardList = () =>
  GlobalService.post<Array<ApiRewardListResponse>>({
    url: '/active/getreward',
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data || [])

/**
 * 待领取
 */
export const apiCanReceiveRewardList = () =>
  GlobalService.post<ApiCanReceiveRewardListResponse>({
    url: '/active/unreceiveAwardList',
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiCanReceiveRewardListResponse)

/**
 * 单次领取奖励
 */
export const apiGetSignalReward = (
  payload: ApiSignalReceivePayload,
  customParams?: AxiosRequestConfig['customParams']
) =>
  GlobalService.post<ApiVipReceiveResponse>({
    url: '/active/receivereward',
    data: payload,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams
  }).then((res) => res.data.data as ApiVipReceiveResponse)

/**
 * 单次领取奖励 待领取页面
 */
export const apiGetReward = (payload: ApiSignalReceivePayload) =>
  GlobalService.post<ApiReceiveResponse>({
    url: '/active/receivereward',
    data: payload,
    customParams: {
      silentOnError: true
    }
  }).then((res) => res.data.data as ApiReceiveResponse)

/**
 * 一键领取
 */
export const apiGetBatchReward = () =>
  GlobalService.post<ApiVipReceiveResponse>({
    url: '/active/receiverewardall',
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiVipReceiveResponse)
