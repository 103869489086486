import {
  Base,
  Component,
  Emit,
  ModelSync,
  Prop,
  Ref
} from '@/vue-property-decorator'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import NumberPassword from '../number-password-input'
import style from './style.module.scss'
interface Props {
  value?: string
  phoneNumberTemplate: string
  disabled?: boolean
}

interface Events {
  onInput: string
  onComplete: string
  onBlur: string
}

@Component<PhoneInput>({ name: 'PhoneInput' })
export default class PhoneInput extends Base<unknown, Props, Events> {
  @ModelSync('value', 'input', AppTypes.string.isRequired.def(''))
  protected phoneNumber!: NonNullable<Props['value']>
  @Prop(AppTypes.string.isRequired)
  private phoneNumberTemplate!: Props['phoneNumberTemplate']
  @Ref()
  private numberPasswordRef!: NumberPassword

  @Prop(AppTypes.looseBool)
  private disabled!: Props['disabled']

  private inputValue = ''

  private onInput(value: string) {
    this.inputValue = value
    this.phoneNumber = this.phoneNumberTemplate.replace(
      this.templateParse.placeholder,
      value
    )
  }

  created() {
    this.inputValue = this.phoneNumber
      .replace(this.templateParse.areaCode, '')
      .replace(new RegExp(`^${this.templateParse.prefix}`), '')
      .replace(new RegExp(`${this.templateParse.suffix}$`), '')
  }

  /**
   * 传入的模板的解析
   */
  private get templateParse() {
    return BusinessUtils.phoneNumberParse(this.phoneNumberTemplate)
  }

  @Emit('complete')
  private onComplete() {
    return this.phoneNumber
  }

  @Emit('blur')
  private onBlur() {
    return this.phoneNumber
  }

  public clear() {
    this.numberPasswordRef.clear()
  }

  /**
   * 聚焦事件
   */
  public focus() {
    this.numberPasswordRef.focus()
  }

  render() {
    return (
      <section class={style.main} onClick={this.focus}>
        <div class={style.fix}>
          <span class={style.areaCode}>
            {this.templateParse.areaCode.slice(0, -1)}
          </span>
          {this.templateParse.prefix}
        </div>
        <div class={style.input}>
          <NumberPassword
            ref={'numberPasswordRef'}
            inputValueVisible={true}
            value={this.inputValue}
            onInput={this.onInput}
            onBlur={this.onBlur}
            autoCompleteSubmit={true}
            onComplete={() => {
              this.onComplete()
            }}
            maxlength={this.templateParse.placeholder.length}
            disabled={this.disabled}
            isBorder={true}
            showAsterisk={true}
          />
        </div>
        <div class={style.fix}>{this.templateParse.suffix}</div>
      </section>
    )
  }
}
