import { GetAvatarListData, UpdateUserInfoPayload } from './type'
import { GlobalService } from '@/context'

/**
 * 获取头像列表
 */
export const apiGetAvatarList = () => {
  return GlobalService.post<Array<GetAvatarListData>>({
    url: '/user/avatars'
  })
}

/**
 * 修改头像
 */
export const apiUpdateAvatar = (payload: GetAvatarListData) => {
  return GlobalService.post<Array<GetAvatarListData>>({
    url: '/user/updateUserAvatars',
    data: payload
  })
}

/**
 * 修改用户信息
 */
export const apiUpdateUserInfo = (payload: UpdateUserInfoPayload) => {
  return GlobalService.post<unknown>({
    url: '/user/settings/modifyinfo',
    data: payload
  })
}
