export abstract class SingletonStrategy {
  // 静态的 map 存储不同类的单例实例
  private static instances: { [className: string]: SingletonStrategy } = {}

  // 标识是否为单例类
  static isSingleton = true

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public constructor() {}

  // 静态的 getInstance 方法，用于获取一个类的单例对象
  // 这里的泛型 T 表示任何继承了 SingletonStrategy 的类型
  public static getInstance<T extends SingletonStrategy>(this: new () => T): T {
    // const className = this.name
    // this.name 由于代码被压缩后会被统一改变为单个字符串，拿不到原始类名，故策略类中添加一个静态属性，标识类的名字
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const className = (this as any)?.className
    // 如果对应类名的单例对象还没创建，就创建并存储到 map 中
    if (!SingletonStrategy.instances.hasOwnProperty(className)) {
      SingletonStrategy.instances[className] = new this()
    }

    return SingletonStrategy.instances[className] as T
  }
}
