import {
  ApiTaskCollectPrize,
  TaskCollectPrizeReq,
  TaskSaveAddress,
  TaskSaveAddressParams
} from '@/api/task/type'
import { GlobalService } from '@/context'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
import { useEventStore } from '@/views/event/store'

/**
 * 单个任务领取
 * @param req
 * @returns
 */
export const apiTaskCollectPrize = (
  req: TaskCollectPrizeReq
): Promise<ApiTaskCollectPrize> =>
  GlobalService.post<ApiTaskCollectPrize>({
    url: '/activetask/getprize',
    data: {
      ...req
    },
    customParams: {
      noErrorMessage: true,
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ApiTaskCollectPrize)

/**
 * 新人福利弹窗保存网址
 */
export const apiTaskSaveAddress = (
  data: TaskSaveAddressParams
): Promise<TaskSaveAddress> =>
  GlobalService.post<TaskSaveAddress>({
    url: '/activetask/task_save_address',
    data,
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    customParams: {
      noErrorMessage: true
    }
  }).then((res) => res.data.data as TaskSaveAddress)

/**
 * 返回新人福利保存网址的URL参数，因为多个地方是用所以抽离成公共方法
 * */
export const getSaveAddress = async () => {
  const { saveUrlUuid, setHasAddSaveAddress, hasAddSaveAddress } =
    useEventStore()
  if (hasAddSaveAddress) {
    return saveUrlUuid
  }
  await apiTaskSaveAddress({
    address: saveUrlUuid,
    actionType: 1
  })
  setHasAddSaveAddress(true)
  return saveUrlUuid
}
