import { Base, Component } from '@/vue-property-decorator'

@Component<MyEmpty>({
  name: 'MyEmpty'
})
export default class MyEmpty extends Base<unknown, unknown, unknown> {
  render() {
    return <template></template>
  }
}
