import { useMainStore } from '@/store/index'

/** 缩小字体函数的入参 */
type DecreaseTextSizeParams = {
  /** 外部盒子(需要指定空间宽度) */
  outBox: HTMLElement
  /** 内部盒子(实际放文字的盒子) */
  contentBox: HTMLElement
  /** 最小文字限制 */
  minFontSize?: number
  /** 字体最多显示多少行 */
  rowCount?: number
  /** 限制的高度,超过高度,则开始缩小字体
   * 使用场景:比如2行高度是40px,但是我希望在最多只要30px,所以它就会在换行的同时就直接缩小字体
   */
  limitHeight?: number
  /** 颜色变量值 */
  colorVarKey?: string
}

class DecreaseTextSize {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  private static instance: DecreaseTextSize
  public static getInstance() {
    if (!this.instance) {
      this.instance = new DecreaseTextSize()
    }
    return this.instance
  }

  /**
   * 缩小字体大小,如果内部字体的长度超过了外部盒子,则缩小字号,直到缩小到最小字体为目
   * @return 返回最终的字号大小.
   */
  public run(params: DecreaseTextSizeParams) {
    const { colorVarKey } = params
    // 取得原始字体大小,原始字体大小
    const initalFontSizePx = this.getOriginFontsize(params)
    /** 最小字号(优先使用传入的,若未传,则使用默认) */
    const minFontSizePx = this.getMinFontSizePx(params, initalFontSizePx)
    // 取得盒子的高度限制
    const boxLimitHeight = this.getBoxLimitHeight(params, initalFontSizePx)
    // 递归缩小字号
    const narrowSize = this.narrowFontSize(
      params,
      boxLimitHeight,
      initalFontSizePx,
      minFontSizePx
    )
    // 保存分组的全局变量
    if (colorVarKey) {
      document.body.style.setProperty(colorVarKey, `${narrowSize}px`)
    }
    return narrowSize
  }

  /** 取得原始字体大小 */
  private getOriginFontsize(params: DecreaseTextSizeParams) {
    const { colorVarKey, outBox } = params
    // 取得原始字体大小,原始字体大小
    const oriFontSizeStr = window
      .getComputedStyle(outBox)
      .fontSize.replace('px', '')
    let oriFontSize = Number(oriFontSizeStr)
    // 如果有分组,则比较当前元素与当前分组字号大小,两者取最小值
    if (colorVarKey) {
      const curGlobalSize = Number(
        document.body.style.getPropertyValue(colorVarKey)?.replace('px', '')
      )
      if (curGlobalSize && curGlobalSize < oriFontSize) {
        oriFontSize = curGlobalSize
      }
    }
    return oriFontSize
  }

  /** 取得盒子高度限制 */
  private getBoxLimitHeight(
    params: DecreaseTextSizeParams,
    initalFontSizePx: number
  ) {
    const { limitHeight, rowCount } = params
    /** 以下特殊语言,扩展行高,并不直接去掉多余下边距(bottomExhibiteSize),是因为任何语言下都有可能会出现英语g这种带尾巴的,或者语言本身也包含下边界很低的
     * my:如果它的行高没有1.7,则文字必然会重叠,它的上边界很高,下边界很低
     * ta:如果它的行高没有1.5,则容易漏出下方内容,它的上边界很高
     */
    const langHeightMap = {
      my_MM: 1.5,
      ta_LK: 1.5,
      vi_VN: 1.4
    } as const
    const lineHeight =
      langHeightMap[useMainStore()?.language as keyof typeof langHeightMap] ||
      1.2
    // 比较高度,优先使用限制高度,若没有再使用计算高度
    const boxLimitHeight =
      limitHeight || initalFontSizePx * (rowCount as number) * lineHeight + 1
    return boxLimitHeight
  }

  /** 获得以PX为单位的最小字号 */
  private getMinFontSizePx(
    params: DecreaseTextSizeParams,
    initalFontSizePx: number
  ) {
    const { minFontSize } = params
    // 如果不存在,则取默认值
    if (!minFontSize) {
      return useMainStore().isWeb ? 12 : 8
    }
    // 如果传入值小于1,则表示初始值*百分比获得最终值
    if (minFontSize <= 1) {
      return initalFontSizePx * minFontSize
    }
    // 若不是前两种情况,则传入的是正常的计算好的数值,则直接使用
    return minFontSize
  }

  /** 递归缩小字号 */
  private narrowFontSize(
    params: DecreaseTextSizeParams,
    boxLimitHeight: number,
    initalFontSizePx: number,
    minFontSizePx: number
  ): number {
    const { contentBox, outBox } = params

    // 外部盒子的宽度
    const outWidth = outBox.clientWidth
    /** 递归缩小字号 */
    const _recurNarrow_ = (): number => {
      // 如果内容宽度小于外部盒子,并且高度小于2行字的高度,则结束递归
      if (
        contentBox.scrollWidth <= outWidth &&
        // +1 相当于向上取整,避免误差
        contentBox.scrollHeight <= boxLimitHeight
      )
        return initalFontSizePx
      // 12px以前缩小幅度是1px,12px以后缩小幅度是0.5px
      const decreaseNum = initalFontSizePx > 12 ? 1 : 0.5
      // 如果已经超过了最小字号的限制,则结束递归
      if (initalFontSizePx - decreaseNum < (minFontSizePx as number)) {
        return minFontSizePx
      }
      initalFontSizePx -= decreaseNum
      contentBox.style.fontSize = initalFontSizePx + 'px'
      return _recurNarrow_()
    }
    // 将内容字体还原成初始字号
    contentBox.style.fontSize = initalFontSizePx + 'px'
    //递归缩小
    const narrowSize = _recurNarrow_()
    // 得到需要的值之后,清空掉设置的值
    contentBox.style.fontSize = ''
    return narrowSize
  }
}

const instance = DecreaseTextSize.getInstance()
/** 缩小文字字号 */
export const decreaseTextSize = instance.run.bind(instance)
