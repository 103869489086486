import axios, { AxiosResponse } from 'axios'

/**
 * 前端资源预加载处理
 */
export class Preload {
  /**
   * 控制同一时间只下载一个文件，避免网络拥堵
   */
  loading = false
  url = ''
  /**
   * 踢出过已缓存的文件，还需要继续提前缓存的文件列表
   */
  source = [] as string[]
  timeid = 0
  /**
   * 已缓存过列表
   */
  cached = (window.localStorage.CachedView || '')
    .split(',')
    .filter((v: string) => v) as string[]

  constructor(url = '/manifest.json?' + Date.now()) {
    this.url = url
    // 延迟10秒后开始预加载
    setTimeout(() => {
      if (!localStorage.nopreload) {
        this.load()
      }
    }, 10000)
  }

  /**
   * 开始预先加载处理
   */
  private load(): void {
    axios.get(this.url).then((res: AxiosResponse<Record<string, string>>) => {
      const source = Object.values(res.data).filter((x) =>
        /assets\/(\d+|pay|recharge|event)\.\S+\.(js|css)$/.test(x)
      )
      // const overdue = this.cached.filter((v: string) => !source.includes(v))
      this.cached = this.cached.filter((v: string) =>
        source.some((x) => x.includes(v))
      )
      // 充值支付业务为核心功能需要优先预加载
      this.source = source
        .filter(
          (x: string) =>
            !this.cached.some((v) => !x.startsWith('theme') && x.includes(v))
        )
        .sort((a) => (/pay|recharge|event/.test(a) ? -1 : 0))

      if (this.source.length > 0) {
        // 如果有文件则每隔1秒加载一个文件
        this.timeid = window.setInterval(() => {
          this.next()
        }, 1000)
      }
    })
  }

  public prefetch(url: string) {
    const link = document.createElement('link')
    link.rel = 'prefetch'
    link.href = url
    link.as = url.includes('.js') ? 'script' : 'style'
    document.head.appendChild(link)
  }

  /**
   * 加载下一个文件
   * @returns
   */
  public async next(): Promise<void> {
    if (this.loading) {
      return
    }
    const url = this.source.shift() as string
    if (!url) {
      window.clearInterval(this.timeid)
    } else {
      this.loading = true
      fetch(url)
        .then(() => {
          this.cache(url)
        })
        .finally(() => {
          this.loading = false
        })

      // this.prefetch(url)
    }

    // else if (url.includes('.js')) {
    //   this.loading = true
    //   this.script(url)
    //     .then(() => {
    //       this.cache(url)
    //     })
    //     .finally(() => {
    //       this.loading = false
    //     })
    // } else if (url.includes('.css')) {
    //   this.loading = true
    //   this.style(url)
    //     .then(() => {
    //       this.cache(url)
    //     })
    //     .finally(() => {
    //       this.loading = false
    //     })
    // }
  }

  /**
   * 加载式样文件
   * @param url 文件链接
   * @returns
   */
  public async style(url: string): Promise<void> {
    if (!url) {
      return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
      const element = document.createElement('link')
      element.rel = 'stylesheet'
      element.onload = function () {
        // element.remove()
        resolve()
      }
      element.onerror = function (e) {
        // element.remove()
        reject(e)
      }
      element.href = url
      // append到最后，防止部分模块写法不标准引起式样覆盖问题
      document.getElementsByTagName('head')[0].appendChild(element)
    })
  }

  /**
   * 加载javascript
   * @param url 文件链接
   * @returns
   */
  public async script(url: string): Promise<void> {
    if (!url) {
      return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
      const element = document.createElement('script')
      element.onload = function () {
        // element.remove()
        resolve()
      }
      element.onerror = function (e) {
        // element.remove()
        reject(e)
      }
      element.src = url
      document.getElementsByTagName('head')[0].appendChild(element)
    })
  }

  /**
   * 防止加载到一半用户刷新或者退出，减少下一次进入页面时重复加载相同的文件所以每加载一个文件就更新一次
   * @param link
   */
  private cache(link: string) {
    this.cached.push((link.match(/\.([a-z\d]+)\./i) || []).pop() || link)
    window.localStorage.CachedView = this.cached.join(',')
  }
}

export default Preload
