import {
  Base,
  Component,
  Emit,
  ModelSync,
  Prop,
  Watch
} from '@/vue-property-decorator'
import { Debounce } from 'lodash-decorators'
import { Tab, Tabs } from '@/plugins/vant/tsx-support'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { merge } from 'lodash'
import { useI18n } from '@/i18n'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import SubscriptComp from '@/components/business-components/subscript'
import Validator from '@/utils/Validator'
import style from './style.module.scss'

type Props = {
  tabsNavHiddenForOne?: boolean
  tabsNavHidden?: boolean
  tabsTransparent?: boolean
  renderList: RenderItem[]
  value?: RenderItem['value']
  needCacheComponentsList?: string[]
  persistedActiveName?: string
  itemWidth?: string | number
  tabType?: TabTypes
  itemHeight?: string | number
  itemSpace?: string | number
  tabsLeftMargin?: string | number
  preTriggerHook?: (value: RenderItem) => Promise<void>
  isScrollX?: boolean
  overflowEllipsis?: boolean
  showHoverTips?: boolean
  scrollWidth?: string
  itemListWrapperClassName?: CSSClassValue
  showCallbackIcon?: boolean
  isButtonTab?: boolean
  /**是否需要自动居中 */
  needAutoCenter?: boolean
  isSelectAnimat?: boolean
  /** 是否居中布局 */
  isLayoutCenter?: boolean
  animatUlId?: string
  soleId: string
  goBack?: () => void
  listenScroll?: boolean
}

export type RenderItem = {
  hidden?: boolean | (() => boolean)
  label:
    | NonNullable<BaseRenderContent>
    | ((item?: RenderItem) => NonNullable<BaseRenderContent>)
  value: string | number
  scope?: string
}

export enum TabTypes {
  ICON = 'icon',
  TEXT = 'text',
  LARGE_TEXT = 'largeText'
}

@Component<CommonTabsH5>({ name: 'CommonTabsH5' })
export default class CommonTabsH5 extends Base<
  unknown,
  Props,
  {
    onTrigger: (value: RenderItem['value']) => void
  },
  {
    default?: RenderItem
    label?: RenderItem
  }
> {
  /**
   * 当前选中值
   */
  @ModelSync('value', 'trigger', { required: true })
  current!: Props['value']

  @Prop(AppTypes.looseBool)
  tabsNavHiddenForOne: Props['tabsNavHiddenForOne']

  /**
   * 是否隐藏导航
   */
  @Prop(AppTypes.looseBool)
  tabsNavHidden: Props['tabsNavHidden']

  /**
   * 是否透明背景导航
   */
  @Prop(AppTypes.looseBool.def(false))
  tabsTransparent: Props['tabsTransparent']
  /**
   * 是否是按钮tab
   */
  @Prop(AppTypes.looseBool.def(false))
  isButtonTab: Props['isButtonTab']
  /**
   * 是否超出省略
   */
  @Prop(AppTypes.looseBool.def(false))
  overflowEllipsis!: NonNullable<Props['overflowEllipsis']>

  /**
   * 鼠标放上去是否显示内容
   */
  @Prop(AppTypes.looseBool.def(true))
  showHoverTips!: NonNullable<Props['showHoverTips']>
  /**
   * 控制是否将当前激活项记录在地址栏
   */
  @Prop(AppTypes.string.def('current'))
  persistedActiveName!: NonNullable<Props['persistedActiveName']>

  /**
   * 需要被缓存的组件名称
   */
  @Prop(AppTypes.array.def([]))
  needCacheComponentsList: Props['needCacheComponentsList']

  /**
   * 渲染tab列表
   */
  @Prop({ required: true })
  renderList!: Props['renderList']

  /**
   * tab切换前置钩子
   */
  @Prop({ required: false })
  preTriggerHook!: Props['preTriggerHook']

  /**
   * tab宽度
   */
  @Prop({ default: 'auto' })
  itemWidth?: Props['itemWidth']

  /**
   * tab类型 目前美术规范tabs目前有三种样式 带Icon 纯文字 纯文字（大）
   * 传入参数优先级大于tabType
   */
  @Prop({ default: 'text' })
  tabType?: Props['tabType']

  /**
   * tab item高度
   */
  @Prop()
  itemHeight?: Props['itemHeight']

  /**
   * tab item间距
   */
  @Prop()
  itemSpace?: Props['itemSpace']

  /**
   * tab标签左边距
   */
  @Prop({ default: 0 })
  tabsLeftMargin?: Props['tabsLeftMargin']

  /**
   * tabs wrapper class
   */
  @Prop(AppTypes.cssClass)
  itemListWrapperClassName?: Props['itemListWrapperClassName']

  /**是否展示返回按钮 */
  @Prop(AppTypes.bool.def(false))
  showCallbackIcon!: Props['showCallbackIcon']

  /**是否需要在改变路由的时候，自动居中 */
  @Prop({ default: true })
  needAutoCenter?: Props['needAutoCenter']

  /** 是否开启切换动画 */
  @Prop({ default: true })
  isSelectAnimat?: Props['isSelectAnimat']

  /** 唯一id  避免一个页面内有多个tab找错元素 */
  @Prop({ default: 'tabs-h5' })
  soleId!: Props['soleId']

  @Prop({ default: false })
  isLayoutCenter!: Props['isLayoutCenter']

  /**
   * 传入的返回回调
   */
  @Prop(AppTypes.func.def(undefined))
  goBack?: Props['goBack']

  /** 是否监听滚动 */
  @Prop({ default: false })
  listenScroll?: Props['listenScroll']

  private isDragging = false
  private startX = 0
  private scrollLeft = 0
  private showMask = false
  private waitMaskTimer: string | number = 0

  t = useI18n().t

  @Debounce()
  @Watch('current')
  protected onCurrentChange() {
    if (this.isKeptAlive) {
      return
    }
    if (this.persistedActiveName) {
      this.$router.replace({
        query: merge({}, this.$route.query, {
          [this.persistedActiveName]: this.current
        })
      })
    }
    this.$nextTick(() => {
      this.setActiveClientWith()
    })
  }

  @Watch('$route', { immediate: true })
  protected applyPersistedActiveName() {
    if (this.persistedActiveName) {
      const current = this.$route.query[
        this.persistedActiveName
      ] as Props['value']
      if (!Validator.isEmpty(current)) {
        this.current =
          typeof this.current === 'number' ? Number(current) : current
      }
    }
  }

  get getTabsNavDiv() {
    return this.$el.querySelector('.van-tabs__nav') as HTMLDivElement
  }

  mounted(): void {
    // 首次进入设置滑块宽度
    setTimeout(() => {
      this.setActiveClientWith()
    }, 100)

    // 鼠标拖动事件
    if (this.getTabsNavDiv) {
      this.getTabsNavDiv.addEventListener('mousedown', this.handleMouseDown)
      this.getTabsNavDiv.addEventListener(
        'mouseleave',
        this.handleMouseUpOrLeave
      )
      if (this.listenScroll) {
        this.getTabsNavDiv.addEventListener('scroll', this.handleScroll)
      }
    }
    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener('mouseup', this.handleMouseUpOrLeave)
  }

  beforeDestroy() {
    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.handleMouseUpOrLeave)
  }

  handleMouseDown(event: MouseEvent) {
    if (this.isKeptAlive) {
      return
    }
    this.isDragging = true
    this.startX = event.pageX
    this.scrollLeft = this.getTabsNavDiv?.scrollLeft
  }
  // 鼠标拖动计算距离
  handleMouseMove(event: MouseEvent) {
    if (this.isKeptAlive) {
      return
    }
    if (!this.isDragging) return
    const walk = (event.pageX - this.startX) * 1 // 控制滚动速度
    this.getTabsNavDiv.scrollLeft = this.scrollLeft - walk
    // 移动距离大于20px,则开启遮罩层,避免触发点击事件.
    if (Math.abs(walk) > 20) {
      this.showMask = true
    }
  }
  // 停止拖动赋值
  handleMouseUpOrLeave() {
    if (this.isKeptAlive) {
      return
    }
    this.isDragging = false
    clearTimeout(this.waitMaskTimer)
    // 去mask
    setTimeout(() => {
      this.showMask = false
    }, 0)
  }
  /**
   * tab切换项样式
   */
  get itemStyle() {
    // 传入参数优先级大于tabType
    // 美术规范icon与纯文字类型字体22 间距30 高70
    // 纯文字（大）类型字体24 间距60 高80
    const normItemHeight = this.tabType === TabTypes.LARGE_TEXT ? 80 : 70
    const normItemSpace = this.tabType === TabTypes.LARGE_TEXT ? 60 : 30
    const normFontSize = this.tabType === TabTypes.LARGE_TEXT ? 24 : 22

    return (index: number) => {
      const {
        itemWidth = 180,
        itemHeight = normItemHeight,
        itemSpace = normItemSpace,
        tabsLeftMargin = 20
      } = this

      const style = {
        width: BusinessUtils.px2rem(`${itemWidth}px`),
        height: BusinessUtils.px2rem(`${itemHeight}px`),
        lineHeight:
          itemWidth === 'auto'
            ? BusinessUtils.px2rem(`${itemHeight}px`)
            : 'normal',
        marginRight: BusinessUtils.px2rem(`${itemSpace}px`),
        fontSize: BusinessUtils.px2rem(`${normFontSize}px`),
        marginLeft:
          index === 0 ? BusinessUtils.px2rem(`${tabsLeftMargin}px`) : ''
      }

      return style
    }
  }
  /**
   * 返回按钮
   */
  get backBtnStyle() {
    const normItemHeight = this.tabType === TabTypes.LARGE_TEXT ? 80 : 70
    const { itemHeight = normItemHeight } = this
    // 这里-2是留出边框位置
    return {
      height: BusinessUtils.px2rem(`${+itemHeight - 2}px`)
    }
  }
  /**
   * 首次进入设置滑块宽度
   */
  protected setActiveClientWith() {
    this.$nextTick(() => {
      const itemDom = this.$el.querySelector(
        `.${this.soleId}${this.current} .tabTitle`
      ) as HTMLDivElement
      const currentTabContainer = this.$el.querySelector(
        `.${this.soleId}${this.current}`
      ) as HTMLDivElement
      const lineDom = this.$el.querySelector(
        `.van-tabs__line`
      ) as HTMLDivElement
      if (lineDom) {
        lineDom.style.width = itemDom?.offsetWidth + 'px'
        if (!this.isLayoutCenter) {
          lineDom.style.transform = `translateX(${currentTabContainer.offsetLeft}px)`
        }
      }
    })
  }

  /**
   * 自动居中
   * @param el
   */
  private autoCenter(el: HTMLDivElement) {
    if (this.needAutoCenter) {
      el?.scrollIntoView?.({
        inline: 'center',
        block: 'nearest',
        behavior: 'smooth'
      })
    }
  }

  /** onChange */
  private onChange() {
    this.$nextTick(() => {
      const dom = this.$el.querySelector(`.van-tab--active`)
      this.autoCenter(dom as HTMLDivElement)
    })
  }
  /** beforeChange */
  private async beforeChange(value: string | number) {
    // 滑动时阻止切换
    if (this.showMask) {
      return false
    }
    const item =
      this.renderList.find((x) => x.value === value) ?? this.renderList[0]
    if (this.preTriggerHook) {
      await this.preTriggerHook(item)
    }
    return true
  }

  @Emit('scroll')
  handleScroll(ev: Event) {
    return ev
  }

  render() {
    return (
      <section>
        <div
          class={{
            [style.tabs]: true,
            [style.tabsCenter]: !!this.isLayoutCenter,
            [this.itemListWrapperClassName as string]: true,
            [style.hiddenNav]:
              (this.tabsNavHiddenForOne && this.renderList.length === 1) ||
              !!this.tabsNavHidden,
            [style.tabsWrapperTabsTransparent]: !!this.tabsTransparent,
            [style.hasWidth]: this.itemWidth !== 'auto',
            [style.showCallbackIcon]: !!this.showCallbackIcon
          }}
        >
          <Tabs
            id={this.soleId}
            v-model={this.current}
            animated={this.isSelectAnimat}
            onchange={this.onChange}
            beforeChange={this.beforeChange}
            line-width={30}
          >
            {this.renderList.map((item, index) => {
              // 隐藏
              const hidden =
                item.hidden instanceof Function ? item.hidden() : item.hidden
              if (hidden) return null
              const child =
                (item.label instanceof Function
                  ? item.label(item)
                  : item.label) || this.$scopedSlots?.label?.(item)
              return (
                // item.value为number时 缓存会失效，但这里先去掉value的toString 解决输入输出不一致问题
                <Tab
                  name={item.value}
                  title-class={[this.soleId + item.value]}
                  title-style={this.itemStyle(index)}
                  data-index={index}
                  key={item.value}
                >
                  {/* 标题 */}
                  <div
                    slot="title"
                    class={{
                      tabTitle: true,
                      ellipsis: !!this.overflowEllipsis
                    }}
                    {...{
                      directives: this.showHoverTips
                        ? [
                            {
                              name: 'inner-text-to-title'
                            }
                          ]
                        : []
                    }}
                  >
                    {this.current === item.value && this.isButtonTab && (
                      <SubscriptComp />
                    )}
                    {typeof child === 'string' ? (
                      <span domPropsInnerHTML={child}></span>
                    ) : (
                      child
                    )}
                  </div>
                  {/* 内容区域 */}
                  <template slot="default">
                    <keep-alive include={this.needCacheComponentsList}>
                      {this.$slots[item.scope || (item.label as string)] ||
                        this.$scopedSlots?.default?.(item)}
                    </keep-alive>
                  </template>
                </Tab>
              )
            })}
          </Tabs>
          {/* 返回按钮 */}
          {this.showCallbackIcon && (
            <div
              class={style.backBtn}
              style={this.backBtnStyle}
              onClick={() => {
                if (this.goBack) {
                  this.goBack()
                  return
                }
                this.$router.back()
              }}
            >
              <icon-sprite
                class={style.icon}
                sprite={createSvgSprite('comm_icon_fh')}
              />
            </div>
          )}
        </div>
      </section>
    )
  }
}
