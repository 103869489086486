import { JumpPosition } from '../aside/type'

export interface HotGame {
  gameId: number //	游戏ID
  categoryId?: string
  gameName: string //	游戏对应语言名称
  hotType: 1 | 2 //	1:平台 2：游戏
  platformId?: number //	平台ID
  platfromId?: number //	平台ID(go 的单词拼错了，后端改动较大，暂时做兼容)
  gameCategoryId: number //	游戏类别ID
  favoriteStatus?: 0 | 1 // 可收藏游戏状态，0未收藏，1收藏
  screenDirection: 0 | 1 //	屏幕方向：0=横屏, 1=竖屏
  gameIcon: string //	游戏图标
  gameIconLarge: string //	游戏大图标
  recommendStatus: boolean //	是否推荐：false-否, true-是
  gameStatus: GameSwitchStatus //	游戏状态：0:维护中 1：正常
  isExternalLink?: boolean //true 外链打开游戏
  platformName: string //	平台名称
  logo: string
  demoModeSupport?: boolean //是否支持试玩游戏
  isCompositePicture: boolean // 是否合成宣传图片
  compositePictureIcon: string // 合成宣传图片
  isCompositePictureHot: boolean // 是否合成热门图片
  compositePictureHotIcon: string // 合成热门图片
  brand?: string
  minimumEntryBalance: number // 最低准入金额
  isAnchorHot?: boolean
  externalLink?: string
}

export enum GameSwitchStatus {
  CLOSE = 0,
  OPEN = 1
}
export interface Game extends HotGame {
  maintainStatus: boolean //?
  minimumEntryBalance: number // 进入游戏最小余额
  slogan: string // ?
}

export interface PlatformCategories extends HotGame {
  maintainStatus: boolean //平台维护true 维护中
  removeStatus: boolean //	平台开关：false-关闭,true-开启
  icon: string //	平台图标
  largeIcon: string // 平台大图
  platformLogo: string
  iconBackGround: string // 背景
  platformCategoriesCount: number //	平台类别数量
  platformId: number //	平台ID
  remarks: string //	备注
  updateBy: string //	操作人
  updateTime: string //	更新时间(yyyy-MM-dd HH:mm:ss)
  gamePlatformCateName: string // 平台名称
  slogan: string // 宣传语
  brand: string // 平台名
  hotType: 1 | 2 // 1平台  2游戏
  gameCategoryId: number
  customLargeIcon: 0 | 1 | true | false // 0 false 展示默认游戏名字，  1 true 是自定义
  platformIcon: string
  /** 是否为主播号 */
  anchorSupport: boolean
}

export type GameListPayload = {
  size?: number
  current?: number
}

export interface HotGamePayload {
  gameMode?: number
  name?: string
  platformId?: number
  categoryId?: number
  gameCategoryId?: number
  /**
   * 试玩游戏
   */
  demoModeSupport?: boolean
  /**
   * 主播号
   */
  anchorSupport?: boolean
  current?: number
  size?: number
}

export enum GameIconType {
  /** 默认-1，go那边比较特殊不传值java处理有问题 */
  Normal = -1,
  /** 方形 */
  Square = 0,
  /** 竖版 */
  Vertical = 1
}

export interface GamePayload extends GameListPayload {
  gameMode?: number
  gameName?: string
  gameCategoryId?: number
  platformId?: number
  /**
   * 试玩游戏
   */
  demoModeSupport?: boolean
  /**
   * 主播号
   */
  anchorSupport?: boolean
  /** 游戏图片版式（方形|竖版） */
  gameIconType?: GameIconType
}

export interface AddfavoritePayload {
  gameId: number
  platformId?: number
  gameCategoryId: number
}
/** 退出游戏（下分），找回余额业务 */
export interface PlatformLogout {
  platformId: number | undefined
}

/**
 * 0非试玩 1试玩
 */
export enum GameType {
  FORMAL = 0,
  DEMO = 1
}

export interface GameUrlPayload {
  gameid: number
  platfromid: number
  os_type?: number // 操作系统类型:1-IOS,2-Android,3-pc
  user_type?: GameType.FORMAL | GameType.DEMO //0非试玩 1试玩
  promoteInfo?: string // 推广链接信息
  exitUrl?: string
  /**玩家注册时候的渠道cid */
  cid?: string
}

export interface SelfOperatedGameUrl {
  url: Array<{ url: string }>
  socket_url: Array<{ url: string }>
  game_url: string
  platfromid: number
  gameid: number
  gameId: number // 后端问题
  platfromId: number // 后端问题
  categoryId: number
  direction: 0 | 1 // 游戏方向:0为横屏,1为竖屏
  gameName: string
  UserName: string
  UserIdx: number
}

export interface GameCategorieInfos {
  gameId: number
  demoModeSupport: 0 | 1
  /** 自定义图片 0-否, 1-是 */
  customIcon: 0 | 1
  gameCategoryId: number
  platformId: number
  /** 维护状态 0:不维护 1:维护 */
  maintainStatus: 0 | 1
  /** 最小准入余额 */
  minimumEntryBalance: number
  /** 平台图标 */
  platformIcon: string
  /** 平台logo */
  platformLogo: string
  platformName: string
  /** 宣传语 */
  slogan: string
  /** Web内嵌:0 外链:1 */
  isExternalLink: string
}

export interface GameCategorieExtLink {
  icon: string
  /** 外部跳转链接 */
  externalLinks: string
  linkName: string
  jumpPosition: JumpPosition
}

export enum JumpPositionType {
  /** 活动类型 */
  Event = 20,
  /** 任务类型 */
  Task = 30
}

export interface GameLogout {
  game_gold: number
  /** 继续游戏信息 */
  lastgameinfo: SelfOperatedGameUrl
}

export type PlatformExchangeInfo = Record<string, HasRateType>

export enum HasRateType {
  /** 没有汇率 */
  No = 0,
  /** 有汇率 */
  Have = 1
}
