import { Base, Component, Prop } from '@/vue-property-decorator'
import style from './style.module.scss'

interface Props {
  value?: number | string
}
/**
 * 布局盒子.
 * 1.带有数字角标.
 * 2.带有边框/padding/圆角.
 */
@Component<CommonBadge>({ name: 'CommonBadge' })
export default class CommonBadge extends Base<unknown, Props> {
  @Prop()
  value?: Props['value']
  render() {
    let text = this.value
    // 如果是一个数字，并且大于99 则展示和99+
    if (this.value && !isNaN(Number(this.value)) && Number(this.value) > 99) {
      text = '99+'
    }
    return (
      <div class={[style.content, 'common-badge-content']}>
        {this.$slots?.default}
        {text ? <div class={[style.badge, 'common-badge']}>{text}</div> : null}
      </div>
    )
  }
}
