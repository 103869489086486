import defaultModalQueue from './queue'

export interface AutoModal {
  name: string

  /**
   * 什么时机请求下一个弹窗
   * 当前弹窗请求完成 | 当前弹窗关闭
   */
  loadNextTiming?: 'afterLoaded' | 'afterClosed'

  /**
   * 当前弹窗状态
   * 等待 | 已经加载完可以显示 | 请求失败，进入下一个 | 超时，清空队列
   */
  status?: 'waiting' | 'loaded' | 'failed' | 'timeout'

  /**
   * 弹窗异步请求方法
   * @returns
   */
  load?: () => Promise<unknown>

  // 返回是否会显示，有的弹窗不满足条件就不展示，只能是同步方法，异步方法放到load里面
  show: (modalName: string) => boolean
}

class AutoModalManager {
  private isStarted = false // 是否已经开始
  private timeout = 5 * 1000 // 超时5s则情况所有队列
  private isPaused = false // 暂停
  private isDestroyed = false // 销毁

  private hasVisibleModal = false // 当前是否有显示中的弹窗

  private currentLoadingIndex = -1 // 当前加载的队列指针
  private currentShowingIndex = -1 // 当前显示的队列指针

  private queue: AutoModal[] = []

  /** 顺序队列是否执行完 */
  public isFinished = false

  add(modals: AutoModal | AutoModal[]) {
    const modalList = Array.isArray(modals) ? modals : [modals]
    modalList.forEach((modal) => {
      this.queue.push({
        loadNextTiming: 'afterLoaded',
        status: 'waiting',
        ...modal
      })
    })

    // 队列load结束之后添加新的弹窗，则继续执行load
    const isNewModal = this.queue.length !== modalList.length
    const hasLoadedDone =
      this.currentLoadingIndex === this.queue.length - 1 - modalList.length

    if (isNewModal && hasLoadedDone) {
      this.loadNext()
    }
  }

  /** 强制修改当前弹窗标识，谨慎使用，可能造成多个弹窗重叠问题 */
  hideAllModal() {
    this.hasVisibleModal = false
  }

  /**
   * 重置状态，并从头开始
   */
  restart() {
    this.currentLoadingIndex = -1
    this.currentShowingIndex = -1
    this.hasVisibleModal = false
    this.isFinished = false
    this.queue.forEach((modal) => {
      modal.status = 'waiting'
    })
    this.isStarted = false
    this.start()
  }

  /**
   * 开始运行
   */
  start() {
    // console.info('MODAL:Start')
    if (!this.isStarted || !this.isDestroyed) {
      this.isStarted = true
      this.loadNext()
    }
  }

  /**
   * 暂停
   */
  pause() {
    // console.info('MODAL:Pause')
    this.isPaused = true
  }

  /**
   * 继续进行
   */
  resume() {
    if (!this.isStarted || !this.isPaused || this.isDestroyed) {
      return
    }
    this.isPaused = false

    // 指针回退一下，继续先显示上一个
    this.currentLoadingIndex = Math.max(--this.currentLoadingIndex, -1)
    this.currentShowingIndex = Math.max(--this.currentShowingIndex, -1)

    // console.info(
    //   'MODAL:Resume',
    //   this.currentLoadingIndex,
    //   this.currentShowingIndex
    // )

    this.loadNext()
    this.showNext()
  }

  /**
   * 弹窗关闭，检测是否是当前显示的自动弹窗
   * 需要显示调用
   * @param name
   */
  onModalClose(name: string) {
    const modal = this.queue[this.currentShowingIndex]
    if (this.hasVisibleModal && modal?.name === name) {
      this.hasVisibleModal = false

      // console.info('MODAL:OnClose', name)

      // 当前关闭的是最后一个弹窗，则队列全部结束
      if (this.currentShowingIndex === this.queue.length - 1) {
        this.isFinished = true
        return
      }

      /**
       * 当前弹窗是关闭之后才去请求下一个，所以下一个肯定还没有请求，所以肯定不能显示，这里要先请求
       * 否则，下一个可能已经请求成功了，可以直接尝试显示
       */
      if (modal.loadNextTiming === 'afterClosed') {
        this.loadNext()
      } else {
        this.showNext()
      }
    }
  }

  /**
   * 一个5s的promise定时器
   * @returns
   */
  private delay() {
    return new Promise((resolve) => {
      setTimeout(resolve, this.timeout, 'timeout')
    })
  }

  /**
   * 请求弹窗数据，超过5s就超时
   * @param loadFunc
   * @returns
   */
  private async load(loadFunc: AutoModal['load']) {
    if (!loadFunc) {
      return 'loaded'
    }

    return Promise.race([loadFunc(), this.delay()])
      .then((value) => {
        if (value === 'timeout') return value

        return 'loaded'
      })
      .catch(() => {
        return 'failed'
      })
  }

  destroy() {
    // console.info('MODAL:destroy')
    this.isDestroyed = true
  }

  /**
   * 请求下一个弹窗接口
   * @returns
   */
  private async loadNext() {
    if (this.isPaused || this.isDestroyed) return

    const next = this.currentLoadingIndex + 1

    const modal = this.queue[next]
    if (!modal) return

    this.currentLoadingIndex++

    const status = (await this.load(modal.load)) as AutoModal['status']
    modal.status = status

    // console.info('MODAL:Load', modal.name, status)

    // 请求失败，直接请求下一个弹窗，不管当前触发下一个弹窗的时机是afterLoaded还是afterClosed
    if (status === 'failed') {
      this.loadNext()
    }

    // 请求成功，如果触发下一个弹窗的机制是afterLoaded，则继续请求，否则等待当前弹窗关闭
    else if (modal.loadNextTiming === 'afterLoaded') {
      this.loadNext()
    }

    this.showNext()
  }

  /**
   * 显示下一个弹窗
   * @returns
   */
  private showNext() {
    // 暂停队列 或者 有弹窗正在显示
    if (this.isPaused || this.hasVisibleModal || this.isDestroyed) return

    const nextIndex = this.currentShowingIndex + 1

    const modal = this.queue[nextIndex]

    // 没有弹窗 或者 弹窗没有加载完 或者超时
    if (!modal || modal.status === 'waiting' || modal.status === 'timeout')
      return

    this.currentShowingIndex++
    // 当前要显示的弹窗请求失败，则继续显示下一个
    if (modal.status === 'failed') {
      this.showNext()
      return
    }

    this.hasVisibleModal = true
    const isShow = modal.show(modal?.name)

    // console.info('MODAL:Show', modal.name, isShow)

    if (!isShow) {
      this.onModalClose(modal.name)
    }
  }
}

const autoModalManager = new AutoModalManager()
autoModalManager.add(defaultModalQueue)

export default autoModalManager
