import { HolidayComponentType, HolidayConfigInfo, HolidayType } from './type'
import MyEmpty from '@/components/my-empty'

// 节日主题维度配置(暂时废弃，配置先保留，以便后续其他模块接入)
const holidayConfig: Record<HolidayType, HolidayConfigInfo> = {
  [HolidayType.MidAutumn]: {
    [HolidayComponentType.Main]: {
      com: MyEmpty
    }
  }
} as Record<HolidayType, HolidayConfigInfo>

export default holidayConfig
