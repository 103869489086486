import {
  Base,
  Component,
  Emit,
  ModelSync,
  Prop,
  Ref,
  VueTsx,
  Watch
} from '@/vue-property-decorator'
import { LocaleMessages } from 'vue-i18n'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { findLastIndex } from 'lodash'
import { useI18n } from '@/i18n'
import AppTypes from '@/vue-types'
// const comm_icon_hide = createSvgSprite('comm_icon_hide')
// const comm_icon_show = createSvgSprite('comm_icon_show')
import style from './style.module.scss'

interface Props {
  value?: string
  inputValueVisible?: boolean
  disabled?: boolean
  labelTitle?: string | LocaleMessages
  showLabel?: boolean
  enterEvent?: () => void
  autoCompleteSubmit?: boolean
  isBorder?: boolean
  showAsterisk?: boolean
}

interface Events {
  onInput: string
  onComplete: string
  onBlur: string
}

@Component<NumberPasswordInput>({ name: 'NumberPasswordInput' })
export default class NumberPasswordInput extends Base<unknown, Props, Events> {
  @ModelSync('value', 'input', { required: true })
  private inputValue!: NonNullable<Props['value']>

  @Ref()
  private pwd!: HTMLInputElement

  @Prop(AppTypes.looseBool.def(false))
  private inputValueVisible!: NonNullable<Props['inputValueVisible']>

  @Prop(AppTypes.looseBool.def(false))
  private disabled!: Props['disabled']

  @Prop(AppTypes.string)
  private labelTitle!: Props['labelTitle']

  @Prop(AppTypes.looseBool)
  private showLabel!: Props['showLabel']

  @Prop(AppTypes.func.def(() => ({})))
  private enterEvent!: Props['enterEvent']

  @Prop(AppTypes.looseBool.def(false))
  private isBorder: Props['isBorder']
  /** 是否展示*号 */
  @Prop(AppTypes.looseBool.def(false))
  private showAsterisk!: Props['showAsterisk']

  /**
   * 是否自动触发complete方法
   */
  @Prop(AppTypes.looseBool.def(true))
  private autoCompleteSubmit!: Props['autoCompleteSubmit']

  private isFocus = false
  private showPwd = false

  /** 是否组合输入 */
  private isComposing = false

  /** 存放所有格子的val */
  private inputListVal = Array.from({ length: this.length })
  /** 当前聚焦的格子下标 */
  private curIndex = 0

  public focus() {
    this.pwd.focus()
    setTimeout(() => {
      this.isFocus = true
    }, 10)
  }

  public clear() {
    this.inputValue = ''
    this.inputListVal = Array.from({ length: this.length })
    this.curIndex = 0
  }

  private get length() {
    return Number(this.$attrs.maxlength) || 6
  }

  private onInputValueInput(value: string) {
    const result = value.replace(/[^\d]/g, '')
    /** 获取val最后一个值填充到对应选中的格子 */
    this.inputListVal[this.curIndex] = result.charAt(result.length - 1)
    /** 更新完格子数据后，自动聚焦到中为空的格子 */
    this.curIndex = this.inputListVal.findIndex((item) => !item)
    this.inputValue = this.inputListVal.join('')
  }

  @Watch('inputValue')
  protected onInputValueChange() {
    /**
     * 如果监听外层组件清空form数据，就清空组件内部状态
     */
    if (this.inputValue === '') {
      this.clear()
    }
    if (
      (this.inputValue || '').length >= this.length &&
      this.autoCompleteSubmit
    ) {
      this.onComplete()
    }
  }

  @Emit('complete')
  private onComplete() {
    return this.inputValue
  }
  @Emit('blur')
  private onBlur() {
    return this.inputValue
  }

  /**
   * 监听删除按键，清楚对应格子和val数据
   */
  private changeInputDelete() {
    /** 查找格子中最后一个有数据的索引，删除数据后再把聚焦设为前一个有数据的格子 */
    const preIndex = findLastIndex(this.inputListVal, (item) => item)
    if (preIndex >= 0) {
      this.curIndex = preIndex
      this.inputListVal[preIndex] = ''
      this.inputValue = this.inputValue.substring(0, this.inputValue.length - 1)
    } else {
      this.inputValue = ''
    }
  }

  private changeInputKey(e: KeyboardEvent) {
    if (['Delete'].includes(e.key) || ['Backspace'].includes(e.key)) {
      this.changeInputDelete()
      return
    }
    if (['ArrowUp', 'ArrowRight', 'ArrowBottom', 'ArrowLeft'].includes(e.key)) {
      e.preventDefault()
    } else if (this.enterEvent && ['Enter'].includes(e.key)) {
      this.enterEvent()
    }
  }
  render() {
    const { t } = useI18n()
    return (
      <section
        class={[
          style.main,
          this.isBorder && this.isFocus
            ? style.mainFocus
            : this.isBorder
            ? style.mainBorder
            : '',
          this.isBorder && this.disabled ? style.numberPassed : ''
        ]}
      >
        {this.showLabel && (
          <div class={[style.label, 'number-password-input__label']}>
            <span>
              {this.labelTitle || t('lobby.center.security.withdrawalPwd.tab')}
            </span>

            <span
              class={{ [style.icon]: true, [style.disable]: this.disabled! }}
              onClick={() => (this.showPwd = !this.showPwd)}
            >
              {this.showPwd ? (
                <icon-sprite
                  class={style.show}
                  sprite={createSvgSprite('comm_icon_show')}
                />
              ) : (
                <icon-sprite
                  class={style.hidden}
                  sprite={createSvgSprite('comm_icon_hide')}
                />
              )}
            </span>
          </div>
        )}
        <input
          class={[style.input, this.isFocus ? style.inputFocus : '']}
          ref={'pwd'}
          type={'number'}
          maxlength={this.length}
          value={this.inputValue}
          onKeydown={(e) => this.changeInputKey(e)}
          onInput={(e) => {
            if (!this.isComposing && e.data) {
              this.onInputValueInput(e.data)
            }
          }}
          onCompositionstart={() => {
            this.isComposing = true
          }}
          onCompositionend={(e) => {
            this.isComposing = false
            if (e.data) {
              this.onInputValueInput(e.data)
            }
          }}
          disabled={this.disabled}
          onBlur={() => {
            if (this.isFocus) {
              this.isFocus = false
              this.onBlur()
            }
          }}
          onFocus={() => {
            if (!this.isFocus) {
              this.isFocus = true
            }
          }}
        />
        <ul
          {...{
            directives: [
              {
                name: 'click-outside',
                value: () => {
                  if (this.isFocus) {
                    this.isFocus = false
                    this.onBlur()
                  }
                }
              }
            ]
          }}
          class={[
            style['pwd-wrap'],
            'pwd-wrap',
            this.disabled ? 'disabled' : ''
          ]}
          onClick={VueTsx.modifiers.stop(this.focus)}
        >
          {this.inputListVal.map((val, i) => {
            const fill = !!val && !!this.inputValue
            const active = this.curIndex === i
            return (
              <li
                key={i}
                class={{
                  'pwd-input-item': true,
                  'pwd-input-item-active': active,
                  'pwd-input-item-fill': fill,
                  [style.active]: active,
                  [style.fill]: fill
                }}
                onClick={() => {
                  if (this.disabled) return
                  /** 聚集下标设置为当前点击的元素，清楚对应inputVal，和格子数据 */
                  this.curIndex = this.inputValue.length
                  if (this.curIndex === this.inputListVal.length) {
                    this.curIndex = this.inputListVal.length - 1
                  }
                }}
              >
                {(this.inputValue && this.showPwd) || this.inputValueVisible ? (
                  <span class="number-input__text">
                    {this.inputListVal[i] ||
                      (this.showAsterisk
                        ? this.curIndex === i && this.isFocus
                          ? ''
                          : '*'
                        : '')}
                  </span>
                ) : (
                  <i></i>
                )}
              </li>
            )
          })}
        </ul>
      </section>
    )
  }
}
