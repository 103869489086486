import { GlobalConst, GlobalManager } from '@/context'
import { IpCheck } from '@/service/site/entity/site'
import { MaintainType } from '@/api/common/type'
import { SiteRepository } from '@/service/site/repository/site'
import { apiGetMaintainInfo, getAccessRestrictedInfo } from '@/api/common'
import { apiServiceListV2 } from '@/api/service'
import {
  formatMaintainTimeInfo,
  openHack,
  windowOpen
} from '@/utils/business-utils'
import { pick } from 'lodash'
import { urlFormatter } from '@/utils/FormatterUtils'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import Modal from './Modal'
import Polling, { LobbyPollingType } from './Polling'
import to from 'await-to-js'
import type { SiteMaintainPayload } from '@/context/events'

/**
 * 控制页面 跳转
 */
export default class Page {
  public static to503Lock = false
  public static transformUnit(html: string) {
    const $div = document.createElement('div')
    $div.innerHTML = html
    const originalBaseFontSize = 38
    const targetBaseFontSize = 19
    const multiple = originalBaseFontSize / targetBaseFontSize
    const baseUnit = 100
    const step = (node: Node) => {
      const htmlNode = node as HTMLElement
      if (htmlNode.getAttribute) {
        const styleValue = htmlNode.getAttribute('style')
        if (styleValue) {
          htmlNode.setAttribute(
            'style',
            styleValue.replace(/(\d+)px/g, (match) => {
              const pxValue = Number(parseFloat(match)) * multiple
              return `${pxValue / baseUnit}rem`
            })
          )
        }
      }

      for (const child of node.childNodes) {
        step(child)
      }
    }
    step($div)
    return $div.innerHTML
  }
  public static end403() {
    if (useMainStore().areaStatus) {
      useMainStore().areaStatus = false
      Modal.close('iframeAccessRestrictedPopup')
      Polling.batch('recover')
    }
  }
  public static async to403(log?: string) {
    const result = await getAccessRestrictedInfo()
    const staffall = await apiServiceListV2().catch((e) => console.log(e))
    const accessRestrictedInfo = { ...result.data.data, ...staffall }
    if (accessRestrictedInfo?.accessRestrictedContent) {
      accessRestrictedInfo.accessRestrictedContent = this.transformUnit(
        accessRestrictedInfo.accessRestrictedContent
      )
    }
    let logId = ''
    const payloadId = 'page-403-payload'
    sessionStorage.setItem(payloadId, JSON.stringify(accessRestrictedInfo))
    if (log) {
      logId = 'page-403-log'
      sessionStorage.setItem(logId, log)
    }
    const { siteInfos } = useMainStore()
    const title = siteInfos?.title || siteInfos?.siteName || ''
    const url = urlFormatter(`${window.location.origin}/pages/403/index.html`, {
      logId,
      payloadId,
      title,
      /**
       * 临时加上解决线上问题
       */
      t: Math.ceil(Date.now() / (1000 * 60 * 5))
    })
    useMainStore().areaStatus = true
    Modal.open('iframeAccessRestrictedPopup', {
      iframeSrc: url,
      h5FullScreen: true,
      pcFullScreen: true,
      noScroll: true,
      backgroundColor: accessRestrictedInfo.accessRestrictedBackgroundColor
    })
  }

  public static async to503(
    options: SiteMaintainPayload & {
      requestInfo: Record<string, unknown>
    }
  ) {
    if (Page.to503Lock) return
    Page.to503Lock = true
    try {
      options = options || {
        isSiteFreeze: false,
        isSiteFreezeAndMaintain: false,
        ipCheck: false
      }

      const useIframe = true
      const {
        isSiteFreeze = false,
        isSiteFreezeAndMaintain = false,
        ipCheck = false
      } = options
      /**
       * 冻结就只展示冻结文案
       */
      const onlyDefault = isSiteFreeze
      /**
       * 冻结+维护就优先展示维护文案，没有才展示冻结文案
       */
      const tryDefault = isSiteFreezeAndMaintain

      const isSiteMaintain = !isSiteFreeze && !isSiteFreezeAndMaintain

      const payloadKey = `page-503-payload`
      let onMaintainUpdateTimeout: number

      /**
       * 整个流程结束
       */
      const end = () => {
        clearTimeout(onMaintainUpdateTimeout)
        GlobalManager.Polling.destroy(LobbyPollingType.MAINTAININFO)
        const mainStore = useMainStore()
        mainStore.$patch({
          siteMaintenanceStatus: false
        })
      }

      const formatMaintainDataToHtml = (maintainInfo: MaintainType) => {
        const { siteConfig, language } = useMainStore()
        return {
          siteConfig,
          ...maintainInfo,
          staffall: {
            online: {
              type: 1,
              url: maintainInfo.online_service
            }
          },
          maintainTimeInfo: formatMaintainTimeInfo(maintainInfo),
          requestInfo: options.requestInfo,
          language: GlobalConst.ServiceLanguageMap[language] || ''
        }
      }

      /**
       * 维护数据发生更新的情况下触发
       */
      const onMaintainUpdate = (maintainInfo: MaintainType) => {
        const timeDiff = maintainInfo.end_time * 1000 - Date.now()
        if (timeDiff > 0) {
          GlobalManager.Polling.destroy(LobbyPollingType.MAINTAININFO)
          /**
           * 在结束时间
           */
          onMaintainUpdateTimeout = window.setTimeout(() => {
            /**
             * 打开后进行轮询进行更新或者关闭操作
             */
            GlobalManager.Polling.create({
              key: LobbyPollingType.MAINTAININFO,
              leading: true,
              interval: 1000 * 60 * 5,
              callback: async () => {
                const [error, newMaintainInfo] = await to(
                  apiGetMaintainInfo(false, false)
                )
                if (error && error.message === 'request-error') {
                  return maintainManager.end()
                }
                /**
                 * 如果新的数据和上次的不同就通知更新UI，并开启新的轮询
                 */
                if (
                  newMaintainInfo &&
                  JSON.stringify(newMaintainInfo) !==
                    JSON.stringify(maintainInfo)
                ) {
                  maintainManager.update(
                    formatMaintainDataToHtml(newMaintainInfo)
                  )

                  onMaintainUpdate(newMaintainInfo)
                }
              }
            })
          }, timeDiff)
        } else {
          console.info('当前时间大于维护结束时间', {
            timeDiff,
            now: Date.now(),
            endTime: maintainInfo.end_time * 1000
          })
        }
      }

      /**
       * iframe 通信
       */
      const message2Iframe = (messages: Record<string, string>) => {
        Object.entries(messages).forEach(([key, value]) => {
          sessionStorage.setItem(key, value)
        })
      }

      const maintainManager = {
        update: (formatMaintainDataToHtml: Record<string, unknown>) => {
          message2Iframe({
            [payloadKey]: JSON.stringify(formatMaintainDataToHtml)
          })
        },
        start: async (maintainInfo: MaintainType) => {
          const { siteMaintenanceStatus, areaStatus } = useMainStore()
          if (siteMaintenanceStatus || !areaStatus) return
          const mainStore = useMainStore()
          const code = 503
          const { siteInfos } = useMainStore()
          const formatMaintainData = formatMaintainDataToHtml(maintainInfo)
          const formatMaintainDataString = JSON.stringify(formatMaintainData)

          const title =
            siteInfos?.title || siteInfos?.siteName || 'Access Restricted'

          const url = urlFormatter(
            `${window.location.origin}/pages/${code}/index.html`,
            {
              payloadKey,
              title,
              useIframe,
              /**
               * 临时加上解决线上问题
               */
              t: Math.ceil(Date.now() / (1000 * 60 * 5))
            }
          )
          mainStore.$patch({
            siteMaintenanceStatus: true
          })
          if (useIframe) {
            message2Iframe({
              [payloadKey]: formatMaintainDataString
            })
            Modal.open('iframeMaintainPopup', {
              iframeSrc: url
            }).then(() => {
              /**
               * 标准维护时才做更新
               * 非站点冻结且非冻结+维护的场景才做轮询更正
               */
              if (isSiteMaintain) {
                onMaintainUpdate(maintainInfo)
              }
            })
          } else {
            window.location.href = url
          }
        },
        end: () => {
          if (useIframe) {
            Modal.close('iframeMaintainPopup')
          } else {
            /**
             * noting
             */
          }
          end()
        }
      }

      const [error, maintainInfo] = await to(
        apiGetMaintainInfo(onlyDefault, tryDefault)
      )

      const hasMaintainInfoJson = !error && !!maintainInfo
      /**
       * 有维护json的情况下不可走缓存，出错也不可走缓存
       */
      if (ipCheck && isSiteMaintain) {
        const infos = await SiteRepository.ipCheck(
          hasMaintainInfoJson
            ? {
                useCache: false,
                useCacheInError: () => {
                  return false
                }
              }
            : undefined
        ).catch((res) => {
          const raw = new IpCheck({
            areaStatus: true,
            isAreaAllowed: true,
            isInMaintenance: true
          })
          raw.axios = {
            config: res?.config || { url: '/ipCheck', headers: {} },
            data: res?.data?.data
          }
          return raw
        })

        useMainStore().ipCheck = infos

        /**
         * 无维护json的情况下才响应地域限制逻辑
         * 有维护json的情况下
         */
        if (hasMaintainInfoJson) {
          /**
           * 是否是白名单（地域未限制且未维护判断为白名单）
           */
          const isWhiteList = infos.isAreaAllowed && !infos.isInMaintenance
          if (!isWhiteList) {
            maintainManager.start(maintainInfo)
          }
        } else {
          if (!infos.isAreaAllowed) {
            GlobalManager.Page.to403(
              JSON.stringify({
                data: infos
              })
            )
          }
        }
      } else {
        /**
         * errror
         * 1. 表示未请求到维护json文件，未下发维护的文件 (error === 'request-error')
         * 2. 请求到json文件了但是时间对不上，（数据不健康）
         */
        if (error) {
          options?.onError?.()
        } else {
          maintainManager.start(maintainInfo)
        }
      }
    } catch (error) {
    } finally {
      Page.to503Lock = false
      options?.onCallback?.()
    }
  }

  /**
   * reload时
   * 默认保留id和cid参数
   */
  public static toRoot(
    pickQuery: string[] = [
      'id',
      'cid',
      'aid',
      'afId',
      'gtagId',
      'gtmId',
      'fb_dynamic_pixel',
      'tiktokBaesCode',
      'kwaiPixelBaseCode',
      'ks_px_test',
      'pixel_id',
      'click_id',
      /** 马甲包af应用s2s保留参数 */
      'appsflyer_id',
      'appsflyer_appId',
      'appsflyer_auth',
      /**
       * 马甲包、极速包相关环境判断依据
       */
      'isDebug',
      'isWgPackage',
      'WgPackage',
      'SpeedPackaeInit',
      /**
       * mock.开头的参数保留
       */
      ...Object.keys(GlobalManager.App.$route.query).filter((key) =>
        /mock.[\S]+/.test(key)
      ),
      /**
       * fixed.开头的参数保留
       */
      ...Object.keys(GlobalManager.App.$route.query).filter((key) =>
        /fixed.[\S]+/.test(key)
      )
    ]
  ) {
    const url = urlFormatter(
      `${window.location.origin}/home/game?gameCategoryId=0`,
      pick(GlobalManager.App.$route.query, pickQuery)
    )
    location.href = url
  }

  /**
   * 打开客服
   */
  public static async toCustomerService() {
    await openHack(
      async () => {
        const online =
          useMainStore()?.serviceInformation?.customer?.onlineCustomers[0]
        if (online) {
          return online.customerUrl
        } else {
          const { t } = useI18n()
          GlobalManager.Modal.message({
            type: 'warning',
            content: t('lobby.common.tips.customerNotEnabled')
          })
          return Promise.reject()
        }
      },
      { createDelay: 500 }
    )
  }

  public static toDownloadAppSite() {
    const store = useMainStore()
    if (
      !store.siteURLInfos?.download_domain_url ||
      store.siteURLInfos?.download_domain_url.length === 0 ||
      !store.siteURLInfos?.download_domain_url[0].domain
    ) {
      const { t } = useI18n()
      GlobalManager.Modal.message({
        type: 'warning',
        content: t('lobby.common.tips.downloadAppPageNotEnabled')
      })
    } else {
      windowOpen(store.siteURLInfos?.download_domain_url[0].domain)
    }
  }

  public static check403(blackList: string[], countryCode?: string): boolean {
    if (blackList.length === 0) {
      return false
    }
    if (!countryCode) {
      return true
    }
    if (blackList.includes(countryCode)) {
      return true
    }
    return false
  }
}
