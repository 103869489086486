import { GlobalService } from '@/context'
import { ReportviewPayload, ReportviewResponse } from './type'
/**
 * 上报渠道、代理
 * @param payload
 * @returns
 */
export const apiReportview = (payload: ReportviewPayload) => {
  return GlobalService.post<ReportviewResponse>({
    url: '/promote/binding/reportview',
    customParams: {
      noEncrypt: true
    },
    data: payload
  }).then((res) => res.data.data)
}
