import type { SiteConfig } from '@/api/common/type'
declare global {
  interface Window {
    LOBBY_SITE_CONFIG: SiteConfig
  }
}

/**
 * 冻结LOBBY_SITE_CONFIG上注入的属性以优化性能
 */
try {
  Object.freeze(window.LOBBY_SITE_CONFIG.INJECT_DATA)
  Object.freeze(window.LOBBY_SITE_CONFIG.LOG)
} catch (error) {}

export default window.LOBBY_SITE_CONFIG

export const defaultBaseUrl: string = location.origin
