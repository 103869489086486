import { ThemeConfigInfo, ThemeSkinType } from './type'
import theme17Config from './theme-17'
// import { nodeThemeConfig } from '@/context/const'
import theme10Config from './theme-10'
import theme12Config from './theme-12'
import theme13Config from './theme-13'
import theme14Config from './theme-14'
import theme15Config from './theme-15'
import theme16Config from './theme-16'
import theme18Config from './theme-18'
import theme19Config from './theme-19'
import theme1Config from './theme-1'
import theme20Config from './theme-20'
import theme21Config from './theme-21'
import theme24Config from './theme-24'
import theme25Config from './theme-25'
import theme26Config from './theme-26'
import theme27Config from './theme-27'
import theme28Config from './theme-28'
import theme2Config from './theme-2'
import theme31Config from './theme-31'
import theme33Config from './theme-33'
import theme34Config from './theme-34'
import theme35Config from './theme-35'
import theme36Config from './theme-36'
import theme3Config from './theme-3'
import theme4Config from './theme-4'
import theme5Config from './theme-5'
import theme6Config from './theme-6'
import theme7Config from './theme-7'
import theme9Config from './theme-9'

// 版式维度配置
const themeConfig: Record<ThemeSkinType, ThemeConfigInfo> = {
  [ThemeSkinType.NORMAL]: theme1Config,
  [ThemeSkinType.EUROPEAN_AMERICAN]: theme2Config,
  [ThemeSkinType.CYAN_BLUE]: theme3Config,
  [ThemeSkinType.STAKE]: theme4Config,
  [ThemeSkinType.CROWN_GOLD]: theme5Config,
  [ThemeSkinType.GALAXY_GOLD]: theme6Config,
  [ThemeSkinType.DQ]: theme7Config,
  [ThemeSkinType.THEME_17]: theme17Config,
  [ThemeSkinType.HK]: theme9Config,
  [ThemeSkinType.THEME_12]: theme12Config,
  [ThemeSkinType.THEME_13]: theme13Config,
  [ThemeSkinType.THEME_14]: theme14Config,
  [ThemeSkinType.THEME_15]: theme15Config,
  [ThemeSkinType.THEME_16]: theme16Config,
  [ThemeSkinType.THEME_26]: theme26Config,
  [ThemeSkinType.THEME_27]: theme27Config,
  [ThemeSkinType.THEME_28]: theme28Config,
  [ThemeSkinType.THEME_18]: theme18Config,
  [ThemeSkinType.THEME_19]: theme19Config,
  [ThemeSkinType.THEME_20]: theme20Config,
  [ThemeSkinType.THEME_24]: theme24Config,
  [ThemeSkinType.THEME_21]: theme21Config,
  [ThemeSkinType.THEME_31]: theme31Config,
  [ThemeSkinType.THEME_34]: theme34Config,
  [ThemeSkinType.THEME_33]: theme33Config,
  [ThemeSkinType.THEME_35]: theme35Config,
  [ThemeSkinType.THEME_25]: theme25Config,
  [ThemeSkinType.THEME_36]: theme36Config,
  [ThemeSkinType.THEME_10]: theme10Config
}

export default themeConfig
