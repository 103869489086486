/**
 * 游戏类型
 */
export enum GameCateEnum {
  /**
   * 全部游戏
   */
  All = 0,
  /**
   * 棋牌
   */
  Chess = 1,
  /**
   * 捕鱼
   */
  Fish = 2,
  /**
   * 街机
   */
  Street = 3,
  /**
   * 真人
   */
  Real = 4,
  /**
   * 体育
   */
  Sports = 5,
  /**
   * 斗鸡
   */
  Cockfighting = 6,
  /**
   * 电竞
   */
  Gameing = 7,
  /**
   * 彩票
   */
  Lottery = 8,
  /** 区块链 */
  Blockchain = 11
}

/**
 * 佣金结算状态
 */
export enum CommissionStatusEnum {
  /**
   * 未结算
   */
  UnSettle = 0,
  /**
   * 已结算
   */
  HasSettle = 1
}

export interface GetMyCommissionDetailPayload {
  day: number
  game_cateid: GameCateEnum
  page: number
  pagesize: number
  page_num?: number
  member_username?: string // 搜索用户账号
}

export interface CommissionDetailItem {
  idx: number
  settle_day: number // 结算日期
  settle_time: number // 结算时间戳
  parent_name: number // 上级代理
  username: string // 成员账户
  bet: number // 贡献投注额/净盈利
  commission: number // 贡献佣金额
  deposit: number // 存款
  reached: boolean // 存款是否达标
}

export interface CommissionDetailResponse {
  direct_bet: number // 合计直属业绩
  direct_commission: number // 直属佣金
  other_bet: number // 其他业绩
  other_commission: number // 其他佣金
  total_commission: number // 总佣金
  total_records: number
  totalRecords: number
  valid_num: number // 人数
  direct_valid_num: number // 直属人数
  other_valid_num: number // 其他人数
  more: false
  list: Array<CommissionDetailItem>
}

/** new 我的佣金 - 列表*/
export interface apiGetMyCommissionRequest {
  startTime: number // 开始时间 - Unix时间戳
  endTime: number // 结束时间 - Unix时间戳
  pageSize: number // 分页大小
  page: number // 页码
}
export interface apiGetMyCommissionResponse {
  total: number // 总数
  totalCommission: number // 总佣金
  totalDirectCommission: number // 总直属佣金
  totalOtherCommission: number // 总其他佣金
  list: myCommissionItem[] // 数据集合
}

export interface myCommissionItem {
  settleDay: number // 结算日期
  settleDayUnix: number // 结算日期时间戳
  performance: number // 绩效
  directCommission: number // 直属佣金
  otherCommission: number // 其他佣金
  totalCommission: number // 总佣金
  contributeNumDirect: number // 直属佣金贡献人数
  contributeNumOther: number // 其他佣金贡献人数
}

export interface MyCommissionDetailRequest {
  settleDay: number // 结算日（使用列表返回的settleDay）
  commissionType: CommissionType // 佣金类型，1-直属，2-其他
  pageSize: number // 分页大小
  page: number // 页码
}

export interface MyCommissionDetailResponse {
  settleDay: number // 结算日
  settleDayUnix: number // 结算日时间戳
  validNum: number // 有效人数
  total: number // 总数
  list: MyCommissionDetailiItem[] // 数据集合
}

// myCommissionDetailV2 接口定义
export interface MyCommissionDetailiItem {
  /** 用户id */
  userIdx: number
  /** 用户账号 */
  account: string
  /** 帐号-加密 */
  encryption: string
  /** 会员等级 */
  vipLevel: number
  /** 绩效 */
  performance: number
  /** 佣金比例 */
  commissionRate: number
  /** 佣金金额 */
  commission: number
  /** 游戏类型 */
  gameCategory: string
}

export enum CommissionType {
  Direct = 1,
  Other = 2
}
