import { omit } from 'lodash'
import localforage from 'localforage'
import type { AxiosRequestConfig } from 'axios'

const responseStore = localforage.createInstance({
  name: 'network-response'
})

export type CacheDep<T = string> =
  | ((id: string, axiosRequestConfig: AxiosRequestConfig) => string)
  | Array<T>
export abstract class Cacheable {
  public abstract has(id: string, config?: CacheConfig): boolean
  public abstract set(id: string, value: unknown, config?: CacheConfig): void
  public abstract get(id: string, config?: CacheConfig): Promise<unknown>
  public abstract delete(id: string): void
}

type CacheItem = {
  id: string
  expiration: number
  expiredOnReload: boolean
  url: string
}

export type CacheStore = {
  state: Array<CacheItem>
}

export type CacheConfig = {
  axiosRequestConfig: AxiosRequestConfig
  /**
   * 控制缓存时间
   */
  cacheTime?: number
  /**
   * 控制缓存依赖id
   */
  cacheDep?: CacheDep<string>
  /**
   * 控制get和has时检测是否过期
   */
  checkExpiration?: boolean
}

type CacheOptions = {
  namespace?: (key: string) => string
  clear?: (item: CacheItem) => boolean
  /**
   * 初始是否将原数据过期，比如刷新后要求不命中缓存的场景
   */
  expired?: boolean
  /**
   * 初始是否装载
   */
  setup?: boolean

  /**
   * 最久的过期时间,单位毫秒
   */
  maxExpiredTime?: number
}

export class Cache<T = string> extends Cacheable {
  constructor(
    createStore: () => CacheStore = () => ({ state: [] }),
    options: CacheOptions = {}
  ) {
    super()
    this.options = options
    this.createStore = createStore
    const setup = this.options.setup ?? true
    if (setup) {
      this.setup()
    }
  }
  private createStore: () => CacheStore = () => ({ state: [] })
  private store: CacheStore = { state: [] }
  private options: CacheOptions = {}

  private get namespace() {
    return (
      this.options.namespace ||
      ((key: string) => {
        return `${key}`
      })
    )
  }

  public setup() {
    this.store = this.createStore()
    this.clear()
    if (this.options.expired) {
      this.expire(true)
    }
  }

  /** 取消已缓存的接口 URL 列表 */
  public cancelCacheUrls(urlList: string[]) {
    this.expire(false, urlList)
  }

  /**
   * 默认的缓存依赖
   */
  public dep(_config?: AxiosRequestConfig): CacheDep<T> {
    return []
  }

  /**
   * 默认命中缓存的逻辑
   */
  public defaultUse(_config?: AxiosRequestConfig): boolean {
    return false
  }

  protected clear() {
    const now = Date.now()
    /**
     * 默认清除超过7天的数据
     */
    const maxExpiredTime =
      this.options.maxExpiredTime ?? 1000 * 60 * 60 * 24 * 7
    const clear =
      this.options.clear ||
      (() => {
        return false
      })
    this.store.state = this.store.state.filter((item) => {
      const need = !clear(item) || now - item.expiration <= maxExpiredTime
      if (!need) {
        responseStore.removeItem(this.namespace(item.id))
      }
      return need
    })
  }

  protected id(
    id: string,
    cacheDep: CacheDep<string>,
    axiosRequestConfig: AxiosRequestConfig
  ) {
    if (typeof cacheDep === 'function') {
      return cacheDep(id, axiosRequestConfig)
    }

    if (Array.isArray(cacheDep)) {
      return `${id}:${(cacheDep as string[]).join(':')}`
    }

    return id
  }

  protected beforeSet(
    _item: { id: string; expiration: number; raw: unknown },
    _config: CacheConfig = {
      axiosRequestConfig: {}
    }
  ) {
    /**
     *
     */
  }

  protected afterGet(
    _item: { id: string; expiration: number; raw: unknown },
    _config: CacheConfig = {
      axiosRequestConfig: {}
    }
  ) {
    /**
     *
     */
  }

  protected expire(isReload = false, urlList: string[] = []) {
    const now = Date.now()
    const expiration = now - 1
    this.store.state.forEach((item) => {
      /**
       * 未过期的数据强行过期
       */
      const doExpired =
        isReload || urlList.includes(item?.url)
          ? item.expiredOnReload && item.expiration > now
          : item.expiration
      if (doExpired) {
        item.expiration = expiration
      }
    })
  }

  public has(
    id: string,
    {
      checkExpiration = false,
      cacheDep = (id: string) => id,
      axiosRequestConfig = {}
    }: CacheConfig = {
      axiosRequestConfig: {}
    }
  ) {
    const target = this.store.state.find(
      (it) => it.id === this.id(id, cacheDep, axiosRequestConfig)
    )
    if (checkExpiration) {
      const result = target && target.expiration > Date.now()
      return !!result
    } else {
      return !!target
    }
  }

  public set(
    id: string,
    value: unknown,
    {
      cacheTime = 5 * 60 * 1000,
      cacheDep = (id: string) => id,
      axiosRequestConfig = {}
    }: CacheConfig = {
      axiosRequestConfig: {}
    }
  ): void {
    const targetId = this.id(id, cacheDep, axiosRequestConfig)
    const index = this.store.state.findIndex((it) => it.id === targetId)
    const cacheExpiredOnReloadConfig =
      axiosRequestConfig?.customParams?.cacheExpiredOnReload
    const cacheExpiredOnReload =
      typeof cacheExpiredOnReloadConfig === 'function'
        ? cacheExpiredOnReloadConfig()
        : cacheExpiredOnReloadConfig
    const item = Object.freeze({
      id: targetId,
      raw: value,
      url: axiosRequestConfig?.url || '',
      expiration: Date.now() + cacheTime,
      expiredOnReload: cacheExpiredOnReload ?? true
    })
    this.beforeSet(item, { cacheTime, cacheDep, axiosRequestConfig })
    const target = omit(item, ['raw'])
    if (index === -1) {
      this.store.state.push(target)
    } else {
      this.store.state.splice(index, 1, target)
    }
    responseStore.setItem(this.namespace(item.id), item.raw)
    this.clear()
  }

  public async get(
    id: string,
    {
      checkExpiration = false,
      cacheDep = (id: string) => id,
      axiosRequestConfig = {}
    }: CacheConfig = {
      axiosRequestConfig: {}
    }
  ): Promise<unknown> {
    if (
      this.has(id, {
        checkExpiration,
        cacheDep,
        axiosRequestConfig
      })
    ) {
      const target = this.store.state.find(
        (it) => it.id === this.id(id, cacheDep, axiosRequestConfig)
      )

      if (target) {
        const id = this.namespace(target.id)
        const raw = await responseStore.getItem(this.namespace(id))
        this.afterGet(
          {
            ...target,
            raw
          },
          { checkExpiration, cacheDep, axiosRequestConfig }
        )
        return Object.assign({}, raw, {
          WITH_CACHED: id
        })
      }
      return null
    }
  }

  public delete(
    id: string,
    { cacheDep = (id: string) => id, axiosRequestConfig = {} }: CacheConfig = {
      axiosRequestConfig: {}
    }
  ): void {
    const targetId = this.id(id, cacheDep, axiosRequestConfig)
    const index = this.store.state.findIndex((it) => it.id === targetId)
    this.store.state.splice(index, 1)
    responseStore.removeItem(this.namespace(targetId))
  }
}
