import { Base, Component, Mixins, Prop, Watch } from '@/vue-property-decorator'
import AppTypes, { string } from '@/vue-types'
import UnitMixins, { Props as UnitMixinsProps } from '@/mixins/unit'

export interface Props extends UnitMixinsProps {
  width?: number
  height?: number
  minSize?: number
  maxSize?: number
  text?: string
  mode?: 'normal' | 'inline'
  fontClass?: CSSClassValue
}

@Component<AutoText>({
  name: 'AutoText'
})
export default class AutoText extends Mixins<Base<unknown>, UnitMixins>(
  Base,
  UnitMixins
) {
  @Prop(string<NonNullable<Props['mode']>>().def('normal'))
  private readonly mode!: NonNullable<Props['mode']>
  @Prop(AppTypes.number)
  private readonly width!: Props['width']
  @Prop(AppTypes.number)
  private readonly height!: Props['height']
  @Prop(AppTypes.string)
  private readonly text!: Props['text']
  @Prop(AppTypes.number)
  private readonly minSize!: Props['minSize']
  @Prop(AppTypes.number)
  private readonly maxSize!: Props['maxSize']
  @Prop(AppTypes.cssClass)
  private readonly fontClass!: Props['fontClass']

  styles = {}

  mounted() {
    this.computeFontsize()
  }

  update() {
    this.computeFontsize()
  }

  @Watch('text')
  protected onTextChange() {
    this.computeFontsize()
  }

  private get isInLine() {
    return this.mode === 'inline'
  }

  createTempNode(str: string) {
    const tempNode = document.createElement('span')
    tempNode.innerText = str
    if (this.mode === 'inline') {
      tempNode.style.whiteSpace = 'nowrap'
    }
    document.body.appendChild(tempNode)
    return tempNode
  }

  getTempTextNodeWithFontSize(fontSize: number, spanNode: HTMLElement) {
    spanNode.style.fontSize = fontSize / this.baseUnit + this.unit
    return spanNode
  }
  computeFontsize() {
    this.$nextTick(() => {
      // 传递过来的文本或者插槽内容
      const text = this.text || (this.$el as HTMLElement).innerText
      const NUM = text.length
      if (NUM === 0) {
        return
      }

      // 获取宽度
      const W = this.width || this.$el.parentElement?.clientWidth || 0
      const H = this.height || this.$el.parentElement?.clientHeight || 0

      let fontSize = Math.sqrt((W * H) / NUM)
      // 创建临时span
      const tempNode = this.createTempNode(text)

      let currentNode = this.getTempTextNodeWithFontSize(fontSize, tempNode)
      while (
        this.isInLine
          ? currentNode.offsetWidth > W || currentNode.offsetHeight > H
          : Math.ceil(NUM / Math.floor(W / fontSize)) * fontSize <= H - 20
      ) {
        if (fontSize - 1 < 0) break
        fontSize -= 1

        currentNode = this.getTempTextNodeWithFontSize(fontSize, tempNode)
      }

      document.body.removeChild(tempNode)

      if (this.minSize) {
        fontSize = Math.max(this.minSize || 8, fontSize)
      }

      if (this.maxSize) {
        fontSize = Math.min(this.maxSize, fontSize)
      }
      this.styles = {
        fontSize: fontSize / this.baseUnit + this.unit,
        'line-height': 1.2,
        width: '100%'
      }
    })
  }

  render() {
    return (
      <div ref="box">
        <div style={this.styles} class={this.fontClass}>
          {this.$slots.default || this.text}
        </div>
      </div>
    )
  }
}
