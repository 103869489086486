import { Base, Component, Prop } from '@/vue-property-decorator'
import { string } from 'vue-types'
import { useMainStore } from '@/store/index'
import style from './style.module.scss'

export type Props = {
  /** svg img 各自的模式对应使用的loading状态不一样
   *  loading 自由控制
   *  none 则停止展示骨架背景
   */
  status?: 'svg' | 'img' | 'loading' | 'none'
  /** 标签类型 */
  boxType?: 'div' | 'span' | 'a' | 'ul' | 'li' | 'p' | 'section' | 'i'
}

export type Events = {
  onClick: (event?: MouseEvent) => void
}

/** 骨架盒子 */
@Component<SkeletonBox>({
  name: 'SkeletonBox'
})
export default class SkeletonBox extends Base<unknown, Props, Events, unknown> {
  @Prop(string<Required<Props>['boxType']>().def('span'))
  private readonly boxType!: NonNullable<Props['boxType']>

  @Prop(string<Required<Props>['status']>().def('svg'))
  private readonly status!: Props['status']

  private get loading() {
    const { loadingSvgSprite, loadingImgSprite } = useMainStore()
    return (
      (loadingSvgSprite && this.status === 'svg') ||
      (loadingImgSprite && this.status === 'img')
    )
  }

  render() {
    const data = {
      attrs: {
        ...this.$attrs
      },
      props: {
        ...this.$attrs
      },
      on: {
        ...this.$listeners
      }
    }

    return (
      <this.boxType
        {...data}
        class={
          this.status !== 'none'
            ? [
                style.main,
                this.loading ? style.loading : '',
                this.loading ? 'skeleton-loading' : '',
                this.status === 'loading' ? style.customize : '',
                this.status || '',
                'skeleton-box'
              ]
            : []
        }
      >
        {this.$slots?.default}
      </this.boxType>
    )
  }
}
