import { windowConfig } from '@/utils/window'
import FaceBookLoginStrategy from '../strategies/FacebookLogin'
import isMobile from '@/utils/isMobile'

export default class FacebookHelper {
  public static async initSDK() {
    const strategy = FaceBookLoginStrategy.getInstance()
    await strategy.initSDK()
  }
  public static facebookSharePopup() {
    const speedPackageConfig = windowConfig.speedPackageConfig
    const isIOS = isMobile().apple.device
    /** 只有极速包ios打不开fb分享功能 */
    if (speedPackageConfig && isIOS) {
      window.jsBridge?.postMessage(
        'facebookShare',
        JSON.stringify({ href: location.href })
      )
    } else {
      FB.ui(
        {
          display: 'popup',
          method: 'share',
          href: location.href
        },
        // callback
        function (response) {
          if (response && !response.error_message) {
            console.log('Posting completed.')
          } else {
            console.log('Error while posting.')
          }
        }
      )
    }
  }
}
