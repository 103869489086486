import {
  Base,
  Component,
  InjectReactive,
  Prop,
  Ref,
  Watch
} from '@/vue-property-decorator'
import { VNode } from 'vue'
import { decreaseTextSize } from './util/decreaseTextSize'
import { useMainStore } from '@/store/index'
import VeryLargeTextBox from './veryLargeTextBox'
import style from './style.module.scss'

type State = {
  /** 初始字号大小 */
  oriFontSize?: number
  /** 最终显示的字号 */
  finalFontSize?: number
}

type Props = {
  /** 盒子宽度,支持style样式的设置 默认100%  */
  width?: string
  /** 盒子的限制高度,若超过了限制高度,则开始缩小字体 */
  limitHeight?: string | number
  /** 默认字体大小,支持rem/px */
  fontSize?: string | number
  /** 最小字体大小,支持rem/px  */
  minFontSize?: string | number
  /** 字体 */
  text?: number | string | VNode | VNode[]
  /** 设置文字最多显示行数,超出显示省略号 */
  rowCount?: number
  /** 分组key,保证一组字大小一致 */
  groupKey?: string
}
@Component<AutoShrinkText>({ name: 'AutoShrinkText' })
/**
 * 自动收缩字体的盒子
 * 收缩逻辑: 优先换行,换行之后仍然放不下,再进行字体收缩
 * 代替默认的全局auto-text组件进行文字的收缩,那个组件有一个bug,对移动端兼容性不好.
 */
export default class AutoShrinkText extends Base<unknown, Props> {
  /** 超大文本父级盒子传递下来的高度 */
  @InjectReactive({ from: 'largeHeight', default: 0 }) largeHeight!: number

  /** 超级大的字的盒子(某些字,已经缩小到最小字号了,仍然显示不完全,就套一层该盒子)
   * 重点:当嵌套了该元素后,AutoShrinkText的[宽,高,字体大小]都需要*2, 若宽100%,不需要100%*2
   */
  public static VeryLargeTextBox = VeryLargeTextBox

  state: State = {
    oriFontSize: undefined,
    finalFontSize: undefined
  }
  @Prop() readonly fontSize!: Props['fontSize']
  @Prop() readonly minFontSize!: Props['minFontSize']
  @Prop({ default: '100%' }) readonly width!: Props['width']
  @Prop() readonly text!: Props['text']
  @Prop() readonly limitHeight!: Props['limitHeight']
  @Prop() readonly groupKey!: Props['groupKey']
  @Prop({ default: 2 }) readonly rowCount!: Props['rowCount']
  @Ref()
  private readonly innerTextRef!: HTMLSpanElement
  @Ref()
  private readonly autoShrinkTextRef!: HTMLSpanElement

  mounted() {
    //PS:关于此处两处的性能测试: 若第一次完成了赋值,第二次是空跑,性能是在1ms以内.所以用担心存在性能问题
    this.correctFontSize()
  }

  get mainStore() {
    return useMainStore()
  }

  /** 校正字体防抖, */
  private timerId!: NodeJS.Timeout

  // 当多语言切换时,需要进行全量的重新计算
  @Watch('mainStore.language')
  clearGlobalFontSize() {
    // 如果没有配置分组,则切换翻译时,不需要全量校正字号
    if (!this.groupKey) {
      return
    }
    // 有this.groupKey时,一定有colorVarKey
    document.body.style.removeProperty(this.colorVarKey as string)
    // 全量的重新校正有延迟,如果已经通过文本切换触发过校正,则不再重复校正
    this.timerId = setTimeout(() => {
      this.correctFontSize()
    }, 500)
  }

  // 当输入内容改变和屏幕尺寸改变时,需要重新计算字体大小.避免玩游戏的时候横竖屏切换时,它出现问题.
  @Watch('mainStore.rootFontSize')
  @Watch('text')
  watchText() {
    clearTimeout(this.timerId)
    // setTimout不可去,去掉的话,可能不会以最新的文字内容为准
    setTimeout(() => {
      this.correctFontSize()
    }, 0)
  }
  /** 将限制高度,由其它单位转成 */
  private get limitHeightNumber() {
    // 如果父级有传递高度下来,则直接使用父级传下来的高度
    if (this.largeHeight > 0) {
      return this.largeHeight
    }
    if (!this.limitHeight) {
      return 0
    }
    return this.unitPxRem2Number(this.limitHeight)
  }

  /** 将最小字号转为数字格式 */
  private get localMinFontSize() {
    if (!this.minFontSize) {
      return undefined
    }
    // 百分比,转换成小数 例: '75%'-> 0.75
    if (/^\d+(\.\d+)?%$/.test(this.minFontSize as string)) {
      return Number((this.minFontSize as string).replace('%', '')) / 100
    }
    return this.unitPxRem2Number(this.minFontSize)
  }

  /** px和rem单位转成px数字 */
  private unitPxRem2Number(size: string | number) {
    size = String(size)
    let sizePx = 0
    if (/^\d+(\.\d+)?px$/.test(size)) {
      sizePx = parseInt(size)
    } else if (/^\d+(\.\d+)?rem$/.test(size)) {
      // 如果rootFontSize已存在,则直接使用,若不存在,则通过api取(api取值更消耗性能)
      sizePx =
        parseFloat(size) *
        (this.mainStore.rootFontSize ||
          parseFloat(
            window
              .getComputedStyle(document.documentElement)
              .getPropertyValue('font-size')
          ))
    } else if (/^\d+(\.\d+)?$/.test(size)) {
      sizePx = Number(size)
    }
    return sizePx
  }

  /** 颜色变量值 */
  private get colorVarKey() {
    if (!this.groupKey) {
      return undefined
    }
    return `--auto-shrink-text-group-${this.groupKey}`
  }

  /** 校正字体大小 */
  public correctFontSize() {
    this.state.finalFontSize = undefined
    let finalSize: number | undefined
    /** 重试次数 */
    let tryTimes = 2
    // 从代码逻辑上来看完全没有问题,但偶发性的会 this.autoShrinkTextRef 不存在,若出现了,做一个异常捕获,不影响别的程序正常执行.
    const exeFun = () => {
      try {
        const width = this.innerTextRef.clientWidth
        const height = this.innerTextRef.clientHeight
        if (width === 0 && height === 0) {
          // WG开发者备注: Dom的宽度和高度大小为0. Dom存在,但可能是渲染有问题
          throw new Error(
            `WG notes: dom's width and height size are 0. dom exist, but it may be render problem!`
          )
        }
        /** 返回值最终字号 */
        finalSize = decreaseTextSize({
          contentBox: this.innerTextRef,
          outBox: this.autoShrinkTextRef,
          minFontSize: this.localMinFontSize,
          rowCount: this.rowCount,
          limitHeight: this.limitHeightNumber,
          colorVarKey: this.colorVarKey
        })
        this.state.finalFontSize = finalSize
      } catch (error) {
        // 先执行,发生错误时,等待一个宏任务周期后重试(错误一般就是dom没有渲染完成,等待一个周期,大概率已经渲染好了)
        tryTimes--
        if (tryTimes > 0) {
          setTimeout(() => {
            exeFun()
          }, 0)
        }
      }
    }
    exeFun()
  }

  /** 最后显示的字号,默认显示fontSize的字号,如果fontSize */
  get showFontSize() {
    if (this.colorVarKey) {
      return `var(${this.colorVarKey})`
    }
    const finalSize = this.state.finalFontSize
    if (finalSize) {
      return finalSize + 'px'
    }
    return this.fontSize
  }

  /** 底部扩展可视高度,避免g下方的尾巴被裁剪 */
  get bottomExhibiteSize() {
    const finalSize = this.state.finalFontSize
    // my和ta两种特殊语言,行高已经很高了,则不需要再扩展底部可见区域了.
    const serveLang = useMainStore()?.language
    if (['my_MM', 'ta_LK', 'vi_VN'].includes(serveLang)) {
      return '0px'
    }
    if (finalSize) {
      return finalSize / 8.5 + 'px'
    }
    return '3px'
  }

  render() {
    return (
      // 最外层,用于设置盒子可显示的宽度.同时设置默认字体值的大小.
      <span
        class={[style['auto-shrink-text'], 'auto-shrink-text']}
        style={{
          width: this.width,
          marginBottom: `-${this.bottomExhibiteSize}`,
          paddingBottom: this.bottomExhibiteSize,
          fontSize: this.fontSize
        }}
        ref={'autoShrinkTextRef'}
      >
        {/* 中间层,用来设置最终展示的结果值 */}
        <span
          class={style['middle-layer']}
          style={{
            fontSize: this.showFontSize
          }}
        >
          {/* 最终展现层,默认是集成中间层的字体,但若本身字体有所改变,则以自身为准  */}
          <span
            class={{
              [style['inner-text']]: true,
              'inner-text': true
            }}
            ref={'innerTextRef'}
            style={{
              '-webkit-line-clamp': this.rowCount
            }}
          >
            {this.text}
          </span>
        </span>
      </span>
    )
  }
}
