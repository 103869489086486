import { GlobalService } from '@/context'
import { ProportionData } from './type'
import usePromoteStore from '@/views/promote/store'

export const apiGetProportionIntroduce = () => {
  const { agent_id } = usePromoteStore().getAgentModeData
  return GlobalService.get<ProportionData>({
    url: '/promote/config/introduce',
    customParams: {
      cache: true,
      tryStaticMode: true,
      staticKeyOmit: ['platformType'],
      keyOmit: true
    },
    params: {
      agentId: agent_id
    }
  }).then((res) => res.data.data as ProportionData)
}
