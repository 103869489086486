import { RedUiStyleEnum } from '@/views/redPocket/components/item/type'

export interface BaseRedPocketItem {
  activeid: number
  activeId: number // 开红包 送红包 id为大写
  activeName: string
  receiveDeviceType: string
  redImgType: RedUiStyleEnum
  errorMsg: string
  logId: number
}

export interface ApiRedPocketItem1 extends BaseRedPocketItem {
  subtaskid: number
  redTime: number
  time: number
}

export interface ApiRedPocketItem2 extends BaseRedPocketItem {
  prize: number
  time: number
}

export interface ApiRedPocketItem3 extends BaseRedPocketItem {
  subtaskid: number
}

export interface ApiRedPocket {
  display: number
  redList: ApiRedPocketItem1[] | null
  avtiveRedList: ApiRedPocketItem2[] | null
  sendList: ApiRedPocketItem3[] | null
}

export interface RedPocketReq {
  active?: number
  subtaskid?: number
  code?: string
  logId: number
  isCloseRedPack?: number //是否关闭红包 1关闭 0 不关闭
}

export interface ApiRedPocketResult {
  prize: number
  gameGold: number
  also: number
}

export interface ApiRedPocketValidation {
  time: number
  start: number
  isValidate: number
  vcode: number
}

export enum RedTypeEnum {
  /**
   * 抢红包，输入密码
   */
  Grab = 'grab',
  /**
   * 开红包
   */
  Open = 'open',
  /**
   * 送红包
   */
  Send = 'send'
}

/**
 * 红包轮询时间 接口轮询频率
 */
export enum RedPocketPollingTimeEnum {
  Seconds10 = 10000, // 10秒
  Minutes3 = 180000, // 3分钟
  Minutes5 = 300000 // 5分钟
}

/**
 * 红包轮询次数
 */
export enum RedPocketPollingCountEnum {
  Count = 10 // 初始化轮询10次
}
