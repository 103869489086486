import {
  ChopOneKnifeAnnouncementData,
  ChopOneKnifeMyLogsData,
  PopChopOneResponse
} from '@/api/event/cutOnce/type'
import { GlobalService } from '@/context'
import { NewChopOneKnifeTurnData } from './type'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'

/**
 * 活动-新砍一刀 抽奖
 * @param activeId {活动ID}
 * @param CurrentLotteryCount {当前第几次抽奖 0：表示第一次抽奖，1:表示第二次抽奖}
 */
export const apiNewChopOneKnifeTurn = (payload: {
  activeId: number
}): Promise<NewChopOneKnifeTurnData> =>
  GlobalService.post<NewChopOneKnifeTurnData>({
    url: '/active/chop_one_knife_new_turn',
    data: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as NewChopOneKnifeTurnData)

/**
 * 活动-新砍一刀 我的记录
 * @param chop_one_knife_id
 */
export const apiNewChopOneKnifeMyLogs = (payload: {
  chop_one_knife_id: number
}): Promise<ChopOneKnifeMyLogsData> =>
  GlobalService.post<ChopOneKnifeMyLogsData>({
    url: '/active/chop_one_knife_new_my_logs',
    data: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ChopOneKnifeMyLogsData)

/**
 * 新砍一刀-所有活动（静态化弹窗）
 */
export const apiNewPopChopOneKnife = (): Promise<PopChopOneResponse[]> =>
  GlobalService.get<PopChopOneResponse[]>({
    url: '/active/pop_chop_one_knife_new',
    customParams: {
      tryStaticMode: true,
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as PopChopOneResponse[])

/**
 * 活动-新砍一刀 中奖公告
 * @param activeId {活动ID}
 */
export const apiNewChopOneKnifeAnnouncement = (payload: {
  activeId: number
}): Promise<ChopOneKnifeAnnouncementData> =>
  GlobalService.post<ChopOneKnifeAnnouncementData>({
    url: '/active/chop_one_knife_new_announcement',
    data: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    }
  }).then((res) => res.data.data as ChopOneKnifeAnnouncementData)
