import {
  Base,
  Component,
  Emit,
  ModelSync,
  Prop
} from '@/vue-property-decorator'
import { Select } from '@/plugins/ant-design-vue/tsx-support'
import { useI18n } from '@/i18n'
import AppTypes from '@/vue-types'
import Const from '@/context/const'
import DateUtils from '@/utils/DateUtils'
import VueI18n from 'vue-i18n'
import style from './style.module.scss'

export enum DateConditionEnum {
  TODAY = 0,
  YESTERDAY = 1,
  LAST_TOW_DAY = 2,
  LAST_SEVEN_DAY = 7,
  LAST_FIFTEEN_DAY = 15,
  LAST_THIRTY_DAY = 30,
  LAST_SIXTY_DAY = 60,
  THIS_MONTH,
  LAST_TOW_MONTH,
  LAST_THREE_MONTH,
  THIS_WEEK = -2,
  LAST_WEEK = -3,
  ALL = -1,
  CUSTOMER = -4
}

/**
 * 状态从10000开始
 */
export enum StateConditionEnum {
  ALL = 10000,
  UNDO,
  PENDING,
  DONE
}

type DateRadioButton = {
  label: VueI18n.TranslateResult | VueTsxSupport.JSX.Element
  value: DateConditionEnum | StateConditionEnum
}

type Props = {
  radioList: Array<DateConditionEnum | StateConditionEnum>
  selectMode?: boolean
  rootClass?: CSSClassValue
  getPopupContainer?: () => HTMLElement | null
  dropdownClassName?: CSSClassValue
  loading?: boolean
}

@Component<DateRadio>({ name: 'DateRadio' })
class DateRadio extends Base<
  unknown,
  Props,
  {
    onSelect: (payload: { currentTime: number; targetTime: number }) => void
  }
> {
  @ModelSync('dateType', 'change', { required: true })
  dateTypeValue!: DateConditionEnum | StateConditionEnum

  @Prop({ required: true })
  radioList!: Props['radioList']

  @Prop({ default: false })
  private selectMode!: Props['selectMode']

  @Prop({ default: undefined })
  private getPopupContainer!: Props['getPopupContainer']

  @Prop(AppTypes.cssClass)
  private rootClass!: Props['rootClass']

  @Prop(AppTypes.cssClass)
  private dropdownClassName!: Props['dropdownClassName']

  @Prop({ default: false })
  private loading: Props['loading']

  private get getRenderList() {
    const { t } = useI18n()
    const defaultConfig = [
      {
        label: t('lobby.center.components.dateRadio.today'),
        value: DateConditionEnum.TODAY
      },
      {
        label: t('lobby.center.components.dateRadio.yesterday'),
        value: DateConditionEnum.YESTERDAY
      },
      {
        label: t('lobby.center.components.dateRadio.lastTowDay'),
        value: DateConditionEnum.LAST_TOW_DAY
      },
      {
        label: t('lobby.center.components.dateRadio.lastSevenDay'),
        value: DateConditionEnum.LAST_SEVEN_DAY
      },
      {
        label: t('lobby.center.components.dateRadio.lastFifteenDay'),
        value: DateConditionEnum.LAST_FIFTEEN_DAY
      },
      {
        label: t('lobby.center.components.dateRadio.lastThirtyDay'),
        value: DateConditionEnum.LAST_THIRTY_DAY
      },
      {
        label: t('lobby.center.components.dateRadio.lastSixtyDay'),
        value: DateConditionEnum.LAST_SIXTY_DAY
      },
      {
        label: t('lobby.center.components.dateRadio.customize'),
        value: DateConditionEnum.CUSTOMER
      },
      {
        label: t('lobby.center.components.dateRadio.thisMonth'),
        value: DateConditionEnum.THIS_MONTH
      },
      {
        label: t('lobby.center.components.dateRadio.lastTwoMonth'),
        value: DateConditionEnum.LAST_TOW_MONTH
      },
      {
        label: t('lobby.center.components.dateRadio.lastThreeMonth'),
        value: DateConditionEnum.LAST_THREE_MONTH
      },
      {
        label: t('lobby.center.components.dateRadio.all'),
        value: DateConditionEnum.ALL
      },
      {
        label: t('lobby.center.components.dateRadio.thisWeek'),
        value: DateConditionEnum.THIS_WEEK
      },
      {
        label: t('lobby.center.components.dateRadio.lastWeek'),
        value: DateConditionEnum.LAST_WEEK
      },
      {
        label: t('lobby.center.components.dateRadio.all'),
        value: StateConditionEnum.ALL
      },
      {
        label: t('lobby.center.components.dateRadio.pending'),
        value: StateConditionEnum.PENDING
      },
      {
        label: t('lobby.center.components.dateRadio.undo'),
        value: StateConditionEnum.UNDO
      },
      {
        label: t('lobby.center.components.dateRadio.done'),
        value: StateConditionEnum.DONE
      }
    ]
    return this.radioList.map((item) => {
      const target = defaultConfig.find((s) => s.value === item)
      if (!target) {
        throw new Error(
          'radioList props must implement [StateConditionEnum] or [DateConditionEnum] '
        )
      }
      return target
    })
  }

  private renderItem(item: DateRadioButton) {
    return (
      <li
        class={[
          style.dateRadioItem,
          this.dateTypeValue === item.value ? style.active : ''
        ]}
        onClick={() => this.selectDate(item.value)}
      >
        <span>{item.label}</span>
      </li>
    )
  }

  @Emit('select')
  private selectDate(category: DateConditionEnum | StateConditionEnum) {
    this.dateTypeValue = category
    const currentTimestamp = DateUtils.timestamp()
    switch (category) {
      case DateConditionEnum.TODAY:
        return {
          currentTime: currentTimestamp,
          targetTime: DateUtils.getZeroTimestamp()
        }

      case DateConditionEnum.YESTERDAY:
        return {
          currentTime: DateUtils.getZeroTimestamp() - 1,
          targetTime: DateUtils.getZeroTimestamp(-1)
        }

      case DateConditionEnum.LAST_TOW_DAY:
        return {
          currentTime: currentTimestamp,
          targetTime: DateUtils.getZeroTimestamp(-2)
        }

      case DateConditionEnum.LAST_SEVEN_DAY:
        return {
          currentTime: currentTimestamp,
          targetTime: DateUtils.getZeroTimestamp(-7)
        }
      case DateConditionEnum.LAST_FIFTEEN_DAY:
        return {
          currentTime: currentTimestamp,
          targetTime: DateUtils.getZeroTimestamp(-15)
        }
      case DateConditionEnum.LAST_THIRTY_DAY:
        return {
          currentTime: currentTimestamp,
          targetTime: DateUtils.getZeroTimestamp(-30)
        }
      case DateConditionEnum.THIS_MONTH:
        return {
          currentTime: currentTimestamp,
          targetTime: DateUtils.getZeroTimestampInMonth()
        }
      case DateConditionEnum.LAST_TOW_MONTH:
        return {
          currentTime: currentTimestamp,
          targetTime: DateUtils.getZeroTimestampInMonth(-1)
        }
      case DateConditionEnum.LAST_THREE_MONTH:
        return {
          currentTime: currentTimestamp,
          targetTime: DateUtils.getZeroTimestampInMonth(-2)
        }
      case DateConditionEnum.ALL:
        return {
          currentTime: currentTimestamp,
          targetTime: -1
        }
      default:
        return {
          currentTime: currentTimestamp,
          targetTime: currentTimestamp
        }
    }
  }

  renderRadio() {
    return (
      <ul
        class={[style.dateRadio, this.rootClass ?? '']}
        data-len={this.getRenderList.length}
      >
        {this.getRenderList.map((item) => this.renderItem(item))}
      </ul>
    )
  }
  dateStart = ''
  renderSelect() {
    const _dropdownClassName = [
      style.dropSelect,
      style.selectDropdown,
      Const.CssClassName.Lobby.Antd.SelectSearch,
      this.dropdownClassName ?? '',
      'dateRadioDropdown'
    ]
    const dropdownClassName = _dropdownClassName.join(' ')
    return (
      <Select.Tsx
        class={[style.dateSelect, this.rootClass ?? {}]}
        dropdownClassName={dropdownClassName}
        value={this.dateTypeValue}
        onSelect={(value: number) => this.selectDate(value)}
        getPopupContainer={this.getPopupContainer}
        loading={this.loading}
        disabled={this.loading}
        {...{
          on: {
            dropdownVisibleChange: () => {
              setTimeout(() => {
                // 设置下拉内容不能超出视口
                const dropdownNode = document.querySelector(
                  '.dateRadioDropdown'
                ) as HTMLDivElement
                const bodyRect = document.body.getBoundingClientRect().left
                if (dropdownNode) {
                  const dropdownRect = dropdownNode.getBoundingClientRect()
                  const leftDistance = dropdownRect.left
                  // 重新设置left 最多只能贴边 避免还有一半在页面外
                  if (leftDistance < bodyRect) {
                    dropdownNode.style.left = bodyRect + 'px'
                  }
                }
              }, 50)
            }
          }
        }}
      >
        {this.getRenderList.map((item) => (
          <Select.Option.Tsx value={item.value} key={String(item.value)}>
            {item.label}
          </Select.Option.Tsx>
        ))}
      </Select.Tsx>
    )
  }

  render() {
    return this.selectMode ? this.renderSelect() : this.renderRadio()
  }
}

export default DateRadio
