import { Base, Component, Emit } from '@/vue-property-decorator'
import { readClipboard } from '@/utils/Tool'
import { useI18n } from '@/i18n'
import style from './style.module.scss'
type Events = {
  onComplete: string
}
@Component<CommonPaste>({ name: 'CommonPaste' })
export default class CommonPaste extends Base<unknown, unknown, Events> {
  private async handlePaste() {
    try {
      const content = await readClipboard()
      this.onComplete(content)
    } catch (error) {
      console.error(error)
    }
  }

  @Emit('complete')
  private onComplete(value: string) {
    return value
  }

  render() {
    const { t } = useI18n()
    return (
      <span onClick={this.handlePaste} class={[style.paste, 'paste']}>
        {this.$slots.default || (
          <inner-html text={t('lobby.common.tips.paste')} />
        )}
      </span>
    )
  }
}
