import {
  AccountInfo,
  FeeSettingData,
  FinanceGetExchangeRateByCurrencyCodeData,
  FinanceGetOnlinePayBankData,
  FinanceGetPayMaxChargeData,
  FinanceGetPayOrderDetailsData,
  FinanceGetPayOrderDetailsPayload,
  FinanceGetPayOrderListData,
  FinanceGetPayOrderListPayload,
  FinanceGetPayPlatformListData,
  FinanceGetPayPlatformListPayload,
  FinanceGetUserWalletInfoData,
  FinanceGetWalletPayRecommendMoneyListData,
  FinanceGetWalletPayRecommendMoneyListPayload,
  FinanceGetWithdrawAuditRecordData,
  FinanceGetWithdrawAuditRecordListData,
  FinanceGetWithdrawInfoData,
  FinanceGetWithdrawMaintainInfo,
  FinanceGetWithdrawRecordData,
  FinanceGetWithdrawRecordDataList,
  FinanceGetWithdrawRecordPayload,
  FinanceNoWalletBuyPayload,
  // FinanceGetYuebaoData,
  FinanceOnlinePayData,
  FinanceOnlinePayPayload,
  FinancePayGetOrderFeeData,
  FinancePayOfflineData,
  FinancePayOfflinePayload,
  FinanceReleaseWithdrawData,
  FinanceReleaseWithdrawPayload,
  FinanceTransferPayConfirmPayload,
  FinanceWalletSettingInfoData,
  FinanceWithdrawAccountParams,
  // FinanceWithdrawBindBankCardData,
  FinanceWithdrawBindBankCardPayload,
  FinanceWithdrawBindCryptoPayload,
  FinanceWithdrawBindOtherWalletPayload,
  // FinanceWithdrawBindCryptoData,
  FinanceWithdrawBindWechatOrAliPayPayload,
  // FinanceWithdrawBindWechatOrAliPayData,
  FinanceWithdrawOrDepositOrderProgressData,
  GetFinancePayListData,
  GetFinancePayTypeData,
  OrderStatusResponse,
  SmartPayContentResponse,
  SmartPaySettingAndSlogansResponse,
  SmartPayTitlePayload,
  VerifyWithdrawalPasswordData,
  VerifyWithdrawalPasswordPayload,
  WithdrawInfoV2Data,
  WithdrawSettingV2Data,
  preSubmitPayload
} from './type'
import { GlobalService } from '@/context'
import { getOsType } from '@/utils/loginRegister'

/* -----------------------充值相关接口----------------------- */
/**
 *
 * 没有通道合并：payplatformids ，id 可以不必填，payplatformid 必填
 * 有通道合并： payplatformids ，id 必填，payplatformid 不必填
 * 通道如果是钱包支付: payWay 必填
 * 是游戏内部充值：gameId，platformId 必填
 * 通道是需要真实付款人姓名：payRealName 必填
 * @description 在线充值
 * @returns
 */
export const apiFinanceOnlinePay = (
  url: string,
  payload: FinanceOnlinePayPayload,
  signKey: string
) => {
  return GlobalService.get<FinanceOnlinePayData>({
    url: url,
    params: payload,
    customParams: {
      noEncrypt: true,
      noResponseDataCodeChecked: true
    },
    headers: {
      'Web-Version': +new Date(
        document.documentElement.getAttribute('data-version') || ''
      ),
      'Sign-key': signKey
    }
  })
}
/**
 * @description 获取充值平台
 * @returns
 */
export const apiFinanceGetPayList = (payCurrency = '') => {
  return GlobalService.post<GetFinancePayListData>({
    url: '/pay/payListV3',
    data: { payCurrency } // 当选择全部时，该值传空
  })
}

// 获取充值平台(类型)v4
export const apiFinanceGetPayListV4 = (payCurrency = '') => {
  return GlobalService.post<GetFinancePayListData>({
    url: '/pay/payListV4',
    data: { payCurrency } // 当选择全部时，该值传空
  })
}

// 获取充值大类v4
export const apiFinanceGetPayTypeV4 = (type = 0) => {
  return GlobalService.post<GetFinancePayTypeData>({
    url: '/pay/payTypeV4',
    data: { type } // 充值类型ID
  })
}

/**
 * @description 获取平台支付通道
 * @param payload
 * @returns
 */
export const apiFinanceGetPayPlatformList = (
  payload: FinanceGetPayPlatformListPayload
) => {
  return GlobalService.post<FinanceGetPayPlatformListData>({
    url: '/pay/payplatformlistV3',
    data: payload
  })
}

/**
 * @description 获取充值订单列表
 * @param payload
 * @returns
 */
export const apiFinanceGetPayOrderList = (
  payload: FinanceGetPayOrderListPayload
) => {
  return GlobalService.post<FinanceGetPayOrderListData>({
    url: '/pay/orderListV3',
    data: payload
  })
}

/**
 * @description 线下充值(转账)
 * @param payload
 * @returns
 */
export const apiFinancePayOffline = (payload: FinancePayOfflinePayload) => {
  return GlobalService.post<FinancePayOfflineData>({
    url: '/pay/offlineOrderV3',
    data: payload
  })
}

/**
 * @description 钱包充值推荐金额列表
 * @param payload
 * @returns
 */
export const apiFinanceGetWalletPayRecommendMoneyList = (
  payload: FinanceGetWalletPayRecommendMoneyListPayload
) => {
  return GlobalService.post<FinanceGetWalletPayRecommendMoneyListData>({
    url: '/pay/walletMoneyRanges',
    data: payload
  })
}

/**
 * @description 转账充值确认
 * @param payload
 * @returns
 */
export const apiFinanceTransferPayConfirm = (
  payload: FinanceTransferPayConfirmPayload
) => {
  return GlobalService.post<null>({
    url: '/pay/transferConfirm',
    data: payload
  })
}

/**
 * @description 获取充值最大优惠金额
 * @param payload
 * @returns
 */
export const apiFinanceGetPayMaxCharge = () => {
  return GlobalService.get<FinanceGetPayMaxChargeData>({
    url: '/home/maxChargeRate',
    params: {
      osType: getOsType()
    },
    customParams: {
      cache: true,
      tryStaticMode: true,
      staticKeyOmit: ['platformType', 'language'],
      keyOmit: true
    }
  }).then((res) => res.data.data as FinanceGetPayMaxChargeData)
}

/**
 * @description 获取充值订单详情
 * @param payload
 * @returns
 */
export const apiFinanceGetPayOrderDetails = (
  payload: FinanceGetPayOrderDetailsPayload
) => {
  return GlobalService.post<FinanceGetPayOrderDetailsData>({
    url: '/pay/orderInfo',
    data: payload
  })
}

/**
 * @description 取消转账订单
 * @param payload
 * @returns
 */
export const apiFinanceTransferCancel = (payload: { order_no: string }) => {
  return GlobalService.post<FinanceGetPayOrderDetailsData>({
    url: '/pay/transferCancel',
    data: payload
  })
}

/**
 * 计算充值手续费 [muller]
 * @param payload
 * @returns
 */
export const apiFinanceGetPayOrderFee = (payload: {
  paymentId: number
  channelId: number
  amount: number
}) => {
  return GlobalService.post<FinancePayGetOrderFeeData>({
    url: '/pay/getPayOrderFee',
    data: payload
  })
}

/**
 * 获取网银银行卡列表 [Anbey]
 * @param payload
 * @returns
 */
export const apiFinanceGetOnlinePayBankList = (payload: {
  merchCode: string
  siteCode: string
  currency: string
}) => {
  return GlobalService.post<Array<FinanceGetOnlinePayBankData>>({
    url: '/api/lobby/finance/getPayChooseBank',
    data: payload
  })
}

/* -----------------------提现相关接口----------------------- */

/**
 * @description 验证提现密码是否正确
 * @param payload
 * @returns
 */
export const apiVerifyWithdrawalPassword = (
  payload: VerifyWithdrawalPasswordPayload
) => {
  return GlobalService.post<VerifyWithdrawalPasswordData>({
    url: '/certify/verifyWithdrawalPasswordV2',
    data: payload
  })
}

/**
 * @description 获取提现信息
 * @param payload
 * @returns
 */
export const apiFinanceGetWithdrawInfo = () => {
  return GlobalService.post<FinanceGetWithdrawInfoData>({
    url: '/certify/withdrawInfo'
  })
}
/**
 * @description 获取提现设置
 * @returns
 */
export const apiWithdrawSettingV2 = (isTryStaticMode = true) => {
  return GlobalService.get<WithdrawSettingV2Data>({
    url: '/certify/withdrawSettingV2',
    customParams: {
      tryStaticMode: isTryStaticMode,
      staticKeyOmit: ['platformType'],
      keyOmit: true
    }
  })
}
/**
 * @description 获取用户提现讯息(V2)
 * @returns
 */
export const apiWithdrawInfoV2 = () => {
  return GlobalService.post<WithdrawInfoV2Data>({
    url: '/certify/withdrawInfoV2'
  })
}

/**
 * @description 获取no钱包信息
 * @param payload
 * @returns
 */
export const apiFinanceUserWalletInfo = () => {
  return GlobalService.post<FinanceGetUserWalletInfoData>({
    url: '/certify/userWalletInfo'
  })
}
/**
 * @description 设置no钱包
 * @param
 * @returns
 */
export const apiFinanceUserwalletSetting = () => {
  return GlobalService.post<FinanceWalletSettingInfoData>({
    url: '/certify/walletSetting'
  })
}

/**
 * @description 发起提现
 * @param payload
 * @returns
 */
export const apiFinanceReleaseWithdraw = (
  payload: FinanceReleaseWithdrawPayload
) => {
  return GlobalService.post<FinanceReleaseWithdrawData>({
    url: '/certify/cachV2',
    data: payload,
    customParams: {
      noErrorMessage: true
    }
  })
}

/**
 * @description 提现记录
 * @param payload
 * @returns
 */
export const apiFinanceGetWithdrawRecord = (
  payload: FinanceGetWithdrawRecordPayload
) => {
  return GlobalService.post<FinanceGetWithdrawRecordDataList>({
    url: '/certify/withdrawRecord',
    data: payload
  })
}

/**
 * @description 绑定提现银行卡
 * @param payload
 * @returns
 */
export const apiFinanceWithdrawBindBankCard = (
  payload: FinanceWithdrawBindBankCardPayload,
  params: FinanceWithdrawAccountParams
) => {
  return GlobalService.post<AccountInfo>({
    url: '/certify/bindcard?withdrawal_uuid=' + params.withdrawalPassword,
    data: payload
  })
}

/**
 * @description 绑定支付宝微信账号[upi pix alipay wechat gcash]
 * @param payload
 * @returns
 */
export const apiFinanceWithdrawBindWechatOrAliPay = (
  payload: FinanceWithdrawBindWechatOrAliPayPayload,
  params: FinanceWithdrawAccountParams
) => {
  return GlobalService.post<AccountInfo>({
    url: '/certify/bindalipay?withdrawal_uuid=' + params.withdrawalPassword,
    data: payload
  })
}

/**
 * @description 绑定虚拟币账户
 * @param payload
 * @returns
 */
export const apiFinanceWithdrawBindCrypto = (
  payload: FinanceWithdrawBindCryptoPayload,
  params?: FinanceWithdrawAccountParams
) => {
  return GlobalService.post<AccountInfo>({
    url: '/certify/bindCrypto?withdrawal_uuid=' + params?.withdrawalPassword,
    data: payload
  })
}

/**
 * @description 绑定三方钱包
 * @param payload
 * @returns
 */
export const apiFinanceWithdrawBindOtherWallet = (
  payload: FinanceWithdrawBindOtherWalletPayload,
  params?: FinanceWithdrawAccountParams
) => {
  return GlobalService.post<AccountInfo>({
    url:
      '/certify/bindUserWallet?withdrawal_uuid=' + params?.withdrawalPassword,
    data: payload
  })
}

/**
 * @description 删除提现账户
 * @param payload
 * @returns
 */
export const apiFinanceDeleteWithdrawAccount = (payload: {
  id: number
  passwd: string
  accountType: number
}) => {
  return GlobalService.post<unknown>({
    url: '/certify/deleteAccount',
    data: payload
  })
}

/**
 * @description 设置默认账户
 * @param payload
 * @returns
 */
export const apiFinanceSetWithdrawDefaultAccount = (payload: {
  id: string
  accountType: number
}) => {
  return GlobalService.post<unknown>({
    url: '/certify/setdefault',
    data: payload
  })
}

/**
 * @description 稽核记录
 * @param payload
 * @returns
 */
export const apiFinanceWithdrawAuditRecordList = (payload: {
  page: number
  size: number
  status: number
}) => {
  return GlobalService.post<FinanceGetWithdrawAuditRecordListData>({
    url: '/certify/auditRecordPageList',
    data: payload
  })
}

/**
 * @description 稽核记录详情
 * @param payload
 * @returns
 */
export const apiFinanceWithdrawAuditRecordDetails = (id: number) => {
  return GlobalService.post<FinanceGetWithdrawAuditRecordData>({
    url: '/certify/auditDetail',
    data: { auditId: id }
  })
}

/**
 * @description 获取提现记录详情
 * @param payload
 * @returns
 */
export const apiFinanceGetWithdrawDetailsRecord = (order_no: string) => {
  return GlobalService.post<FinanceGetWithdrawRecordData>({
    url: '/certify/orderInfo',
    data: {
      order_no
    }
  })
}

/**
 * 获取提现手续费
 * @param payload
 * @returns
 */
export const apiFinanceGetWithdrawFee = (payload: {
  withdrawTypeId: number
  amount: number
}) => {
  return GlobalService.post<{
    fee: number
    currency: string
  }>({
    url: '/certify/getWithdrawFee',
    data: payload
  })
}

/**
 * @description oss上传二维码
 * @param payload
 * @returns
 */
export const apiFinanceWithdrawPutPay = (data: FormData) => {
  return GlobalService.upload<{ successLinks: string[] }>({
    url: '/certify/uploadpay',
    customParams: {
      autoFormData: true
    },
    data
  })
}

/**
 * @description 钱包订单跳转地址
 * @param payload
 * @returns
 */
export const apiFinanceGetWithdrawWalletQuery = () => {
  return GlobalService.post<string>({
    url: '/certify/walletQuery'
  })
}

/**
 * @description 获取汇率
 * @param payload
 * @returns
 */
export const apiFinanceGetExchangeRateByCurrencyCode = (payload: {
  siteCode: string
  queryRateType: 0 | 1 // 0充值 1提现
}) => {
  return GlobalService.get<FinanceGetExchangeRateByCurrencyCodeData>({
    url: '/api/lobby/finance/getSiteCurrencyExchangeRateForOss',
    params: payload,
    customParams: {
      tryStaticMode: true,
      staticKeyOmit: ['platformType', 'currency', 'language']
    }
  })
}

/**
 * @description 获取提现维护信息
 * @param siteId
 * @returns
 */
export const apiFinanceGetWithdrawMaintainInfo = (siteId: string) => {
  return GlobalService.post<FinanceGetWithdrawMaintainInfo>({
    url: '/certify/withdrawalChannelClosedQuery',
    data: {
      siteCode: siteId
    }
  })
}

/* ---------------------充值/提现气泡----------------- */

/**
 * 获取当前充值进度(No钱包)
 * @param payload
 * @returns
 */
export const apiFinanceDepositOrderProgress = (payload: {
  merchantMemberNo: string
  timestamp: number
}) => {
  return GlobalService.post<{
    data: FinanceWithdrawOrDepositOrderProgressData
  }>({
    url: '/api/lobby/wallet/pay/orderProgress',
    data: payload,
    customParams: {
      silentOnError: true,
      noResponseDataCodeChecked: true
    }
  })
}

/**
 * 获取当前提现进度(No钱包)
 * @param payload
 * @returns
 */
export const apiFinanceWithdrawOrderProgress = (payload: {
  merchantMemberNo: string
  timestamp: number
}) => {
  return GlobalService.post<{
    data: FinanceWithdrawOrDepositOrderProgressData
  }>({
    url: '/api/lobby/wallet/withdraw/orderProgress',
    data: payload,
    customParams: {
      silentOnError: true,
      noResponseDataCodeChecked: true
    }
  })
}

/**
 * no钱包-内置创建账号和充值接口
 * @param url
 * @param payload
 * @param signKey
 * @returns
 */

export const apiFinanceNoWalletBuy = (
  url: string,
  payload: FinanceNoWalletBuyPayload,
  signKey: string
) => {
  return GlobalService.post<Record<string, string>>({
    url,
    data: payload,
    customParams: {
      silentOnError: true,
      noEncrypt: true,
      noResponseDataCodeChecked: true
    },
    headers: {
      'Web-Version': +new Date(
        document.documentElement.getAttribute('data-version') || ''
      ),
      'Sign-key': signKey
    }
  })
}

export const apiFinancePreSubmit = async (
  url: string,
  payload: preSubmitPayload
) => {
  return GlobalService.get<string>({
    url: url,
    params: payload,
    customParams: {
      staticKeyOmit: ['currency', 'language', 'platformType', 'token'],
      noEncrypt: true,
      noResponseDataCodeChecked: true
    }
  })
}

/**
 * @description 每日免手续费次数
 * @param payload
 * @returns
 */
export const apiGetWithdrawFeeSetting = () => {
  return GlobalService.post<FeeSettingData>({
    url: '/certify/getWithdrawFeeSetting'
  })
}

/**
 * 获取智能弹窗公共及标题配置
 * @param data
 */
export const apiFinanceGetSmartPaySettingAndSlogans = async (
  params: SmartPayTitlePayload
) => {
  return GlobalService.get<SmartPaySettingAndSlogansResponse>({
    url: '/payPopup/settingAndSlogans',
    params,
    customParams: {
      silentOnError: true,
      tryStaticMode: true,
      staticKeyOmit: ['platformType', 'language'],
      keyOmit: true
    }
  })
}

/**
 * 获取智能弹窗大类通道内容
 * @param data
 */
export const apiFinanceGetSmartPayContent = async (
  data: SmartPayTitlePayload
) => {
  return GlobalService.post<SmartPayContentResponse>({
    url: '/payPopup/content',
    data,
    customParams: {
      silentOnError: true
    }
  })
}

/**
 * 刷新当前订单状态
 */
export const refreshOrderStatus = async (data: { orderNo: string }) => {
  return GlobalService.post<OrderStatusResponse>({
    url: '/pay/refreshStatus',
    data
  })
}
