/**
 * 全局的轮询管理
 * 便于集中处理轮询相关的逻辑，比如某些状态集中销毁，暂停，恢复等场景
 */
import { merge } from 'lodash'
import PollingManager from '@/utils/Polling'
export enum LobbyPollingType {
  /**
   * 轮询彩金池
   */
  JACKPOT_PRIZE = 'fetch/aside-jackpot-prize',
  /**
   * 轮询 虚拟彩金池 金币跳动
   */
  JACKPOT_AMOUNT = 'change-jackpot-amount',
  /**
   * 未登录状态 轮询 虚拟彩金池
   */
  JACKPOT_PRIZE_NO_LOGIN = 'jackpot-prize-no-login',
  /**
   * 轮询心跳
   */
  HEART_BEAT = 'fetch/heart-beat',
  /**
   * 轮询网络检测
   */
  NETWORK_DETECTION = 'fetch/aside-network-detection',
  /**
   * 红包
   */
  RED_POCKETS = 'fetch/event-red-pockets',
  /**
   * 活跃度重置 （无请求，仅做轮询web计算）
   */
  ACTIVITY_TIME = 'fetch/activity-time-calc',
  /**
   * No钱包提现/充值进度轮询
   */
  NO_WALLET_WITHDRAW_ORDER = 'fetch/no-wallet-order',
  /**
   * 维护信息轮询
   */
  MAINTAININFO = 'fetch/maintain-info-json'
}
export default merge(new PollingManager<LobbyPollingType>(), {
  LobbyPollingType
})
