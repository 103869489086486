import {
  AddMusicPayload,
  DelMusicPayload,
  MusicListPayload,
  MusicResponse,
  PrizePoolWinnnerListResponse,
  VirtualBonusPoolData
} from './type'
import { GlobalService } from '@/context'
import { gameOssDomin } from '@/service/game/entity/util/util'

/**
 * 音乐播放器
 * 获取音乐列表
 */
export const apiGetMusicList = (payload: MusicListPayload) => {
  payload = {
    page: 1,
    pageLimit: 500, // 暂时不做分页
    ...payload
  }
  return GlobalService.get<MusicResponse>({
    url: '/home/musiclist',
    customParams: {
      tryStaticMode: true,
      staticKeyOmit: ['platformType', 'currency', 'language'],
      keyOmit: true
    },
    params: payload
  }).then((res) => res.data.data)
}
/**
 * 添加音乐下载收藏
 */
export const apiAddMusic = (payload: AddMusicPayload) =>
  GlobalService.post({
    url: '/home/musicadd',
    data: payload
  }).then((res) => res.data)

/**
 * 添加音乐删除下载收藏
 */
export const apiDelMusic = (payload: DelMusicPayload) =>
  GlobalService.post({
    url: '/home/musicdel',
    data: payload
  }).then((res) => res.data)

/**
 * 获取大厅虚拟彩金池数据（只有json）
 */
export const getVirtualBonusPoolData = () =>
  GlobalService.get<VirtualBonusPoolData[]>({
    url: '/api/game/hall/listVirtualBonusPoolV2',
    // https://xewi7v-087-ddd-backup.s3.ap-east-1.amazonaws.com/hall/api/game/hall/listVirtualBonusPoolV2/currency/CNY.json
    customParams: {
      tryStaticMode: true,
      noErrorMessage: true,
      onlyStaticMode: true,
      staticKeyOmit: ['language', 'platformType']
    }
  }).then((res) => {
    // console.log('xxxxxx VirtualBonusPoolData', res.data)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const curData = res.data?.data as unknown as any[]
    const datas = []
    for (let i = 0; i < curData.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const info: any = {}
      info.id = curData[i].a
      info.showType = curData[i].b
      info.showPosition = curData[i].c
      info.jumpPosition = curData[i].d
      info.decimalPlaces = curData[i].e
      info.numericStyle = curData[i].f
      info.backgroundStyle = curData[i].g
      info.externalLinks = curData[i].h
      info.carouselName = curData[i].y
      info.fontTypeface = curData[i].j
      // 使用前端凭借oss桶
      info.backgroundUrl = gameOssDomin(curData[i].k)
      info.virtualPoolTimestamp = curData[i].m
      info.virtualPoolNumbers = curData[i].n
      datas.push(info)
    }
    // console.log('xxxxxx VirtualBonusPoolData 11111', datas)
    return { data: datas as VirtualBonusPoolData[] }
  })

/**
 * 彩金池中奖名单获取
 */
export const apiGetPrizePoolWinnnerList = () =>
  GlobalService.post<PrizePoolWinnnerListResponse>({
    url: '/api/lobby/prize-pool/winner-list',
    data: { size: 200, current: 1 }
  }).then((res) => res.data.data)
