import { useMainStore } from '@/store/index'
import GoogleLoginStrategy from '../strategies/GoogleLogin'

export default class GoogleHelper {
  public static get googleBtn() {
    return document.getElementById('google-btn-help') as HTMLElement
  }

  public static async initSDK() {
    const { threeWayConfig } = useMainStore()
    // 如果没开启谷歌无需加载 SDK
    if (!threeWayConfig.useGoogle) {
      return
    }
    const strategy = GoogleLoginStrategy.getInstance()
    await strategy.initSDK()
  }

  public static hiddenGoogleEvent(event: Event) {
    // 这里注册到监听器时找不到google DOM
    const googleBtn =
      this.googleBtn || document.getElementById('google-btn-help')
    if (!googleBtn || event.target === googleBtn) return
    googleBtn.style.display = 'none'
    document.removeEventListener('click', this.hiddenGoogleEvent)
  }

  public static showButton() {
    if (this.googleBtn.style.display === 'block') {
      // 避免重复添加事件
      document.removeEventListener('click', this.hiddenGoogleEvent)
    } else {
      this.googleBtn.style.display = 'block'
      // 监听点击事件，点击页面其它区域隐藏google按钮
      document.addEventListener('click', this.hiddenGoogleEvent)
    }
  }

  public static hideButton() {
    this.googleBtn.style.display = 'none'
    document.removeEventListener('click', this.hiddenGoogleEvent)
  }
}
