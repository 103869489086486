import {
  AccessRestrictedInfo,
  AccountVerifyListNotLoginReq,
  AccountVerifyListRes,
  AgentConfigRequest,
  AgentModeDataResponse,
  BindPhoneReq,
  BrandLogoInfosRes,
  BuriedConfigRequest,
  BuriedConfigResponse,
  ClubEntryAvailable,
  CurrencyInfos,
  DownloadUrlInfos,
  EmailCodeCreateReq,
  EventCategoriesAvailable,
  FooterInfoRes,
  ForceChangePwdReq,
  FrameBoxInfos,
  FrameSet,
  GeetestValidate,
  HomeLayoutInfos,
  IpBindInfos,
  LangItem,
  LinkType,
  MaintainType,
  NormalLoginFormData,
  NormalRegisterRequest,
  OptimizationSiteConfig,
  QuestionType,
  ReportDownloadUrlClickPayload,
  ResetPasswdNotLoginReq,
  RewardBindData,
  RewardBindDataBefore,
  RiskSmsVerifyReq,
  SMSLoginReq,
  SiteConfig,
  SiteInfos,
  SiteURLInfos,
  SmsCodeCreateReq,
  SmsCodeValidateReq,
  SmsCountry,
  SystemInfos,
  SystemStatus,
  SystemStatusReq,
  ThirdWayLoginNewAccountRes,
  ThirdWayLoginReq,
  UserAgreementRes,
  UserInfos,
  UserSmsCodeValidateReq,
  VerifyInputEmailNotLoginReq,
  VerifyPhoneInputReq,
  VerifyQuestionAndWithdrawPwdWithNotLoginReq,
  WebPushInfos
} from './type'
import { ForceType } from '../../views/notice/const'
import { GlobalCrypto, GlobalManager, GlobalService } from '@/context'
import { SelfOperatedGameUrl } from '../game/type'
import { createOssDomainRetry } from '@/utils/business-utils/retry'
import { getQueryString } from '@/utils/Tool'
import { merge } from 'lodash'
import { useMainStore } from '@/store/index'
import BusinessUtils, { formatMaintainTimeInfo } from '@/utils/business-utils'
import CryptoJS from 'crypto-js'
import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse
} from 'axios'

export const apiGetBaseSiteConfig = () => {
  return GlobalService.get<Omit<SiteConfig, 'INJECT_DATA'>>({
    url: '/api/lobby/webapi/h5/config/getInfo',
    customParams: {
      noEncrypt: true,
      tryStaticMode: true,
      staticKeyOmit: ['currency', 'platformType', 'language'],
      cache: true,
      cacheExpiredOnReload: false,
      useCacheInError(_, errorType) {
        return !!errorType
      }
    }
  })
}
/**
 * java 接口合并
 */
export const apiGetOptimizationSiteConfig = (
  options: {
    previewonly?: boolean
  } = {},
  config?: AxiosRequestConfig
) =>
  GlobalService.get<OptimizationSiteConfig>(
    merge<AxiosRequestConfig, AxiosRequestConfig | undefined>(
      {
        url: '/api/lobby/webapi/optimization/site/config',
        params: {
          ...(options.previewonly
            ? {
                previewonly: 5
              }
            : {})
        },
        customParams: {
          //预览模式强制永远json请求
          onlyStaticMode: !!options.previewonly,
          staticKeyOmit: !!options.previewonly
            ? ['language', 'platformType', 'currency']
            : ['currency'],
          tryStaticMode: true,
          noEncrypt: true,
          useCache: false,
          cache: true,
          useCacheInError(_, errorType) {
            return !!errorType
          },
          cacheExpiredOnReload: false
        }
      },
      config
    )
  )

/**
 * 2024/02/19 恢复该接口的定义，因为大接口及时性问题
 * 获取系统信息，变更频率较高
 */
export const apiGetSysInfo = (
  config: AxiosRequestConfig = {
    customParams: {
      /**
       * 默认开启缓存且任意出错都命中缓存,初始借助判断后端接口是否通时的场景需关闭缓存以及关闭错误时走缓存
       */
      cache: true,
      useCacheInError(_, errorType) {
        return !!errorType
      }
    }
  }
) => {
  const cache = config.customParams?.cache ?? true
  return GlobalService.get<SystemInfos>(
    merge(
      {
        url: '/home/getsysinfo',
        customParams: {
          noEncrypt: true
        },
        params: {
          ...(!cache
            ? {
                now: Date.now()
              }
            : {}),
          os_type: useMainStore().osType
        }
      },
      config
    )
  )
}

/**
 * go 接口合并
 */
export const apiGetSystemStatus = (
  payload: SystemStatusReq,
  config?: AxiosRequestConfig
) =>
  GlobalService.get<SystemStatus>(
    merge<AxiosRequestConfig, AxiosRequestConfig | undefined>(
      {
        url: '/system/status',
        customParams: {
          tryStaticMode: true,
          noEncrypt: true,
          cacheExpiredOnReload: false,
          useCache: false,
          cache: true,
          useCacheInError(_, errorType) {
            return !!errorType
          }
        },
        params: {
          ...payload
        }
      },
      config
    )
  )

/**
 * 获取站点信息
 */
export const apiGetSiteInfo = () =>
  GlobalService.get<SiteInfos>({
    url: '/api/lobby/site/getSiteInfo',
    customParams: {
      noEncrypt: true,
      tryStaticMode: true,
      cache: true,
      useCacheInError(_, errorType) {
        return !!errorType
      },
      cacheExpiredOnReload: false,
      staticKeyOmit: ['currency', 'platformType']
    }
    // headers: {
    //   siteCode: '024',
    //   language: 'zh',
    //   ip: '103.120.123.56'
    // }
  }).catch((e) => {
    const { health, response } =
      BusinessUtils.parseInjectData<'apiGetSiteInfo'>('apiGetSiteInfo')
    if (health) {
      return response!
    } else {
      throw e
    }
  })

/**
 * 获取当前站点短信地区
 */
export const apiGetSmsCountry = () =>
  GlobalService.get<SmsCountry>({
    url: '/home/smsCountry',
    customParams: {
      noEncrypt: true,
      tryStaticMode: true,
      cache: true,
      staticKeyOmit: ['platformType'],
      keyOmit: true
    }
  })

/**
 * 获取维护信息
 * @param onlyDefault 只请求兜底维护json
 * @param tryDefault 请求普通维护json失败后是否尝试请求兜底维护json
 * @param checkHealth 是否检测普通维护的时间是否健康
 * @returns
 */
export const apiGetMaintainInfo = async (
  onlyDefault = false,
  tryDefault = false,
  checkHealth = true
) => {
  // checkHealth = false
  const decrypt = (str: string) =>
    GlobalCrypto.decrypt(str, 'thanks,pig4cloud', {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })

  const useMockMaintainIframe = getQueryString('useMockMaintainIframe')

  const queryMaintain = () =>
    createOssDomainRetry((ossHost) => {
      return GlobalService.get<unknown, MaintainType>({
        baseURL: useMockMaintainIframe
          ? `${window.location.origin}/pages/503/`
          : ossHost,
        url: '/cocos/maintain-time.json',
        // url: 'https://localhost:8080/pages/503/maintain-time.json',
        customParams: {
          maxRetryCount: 0,
          noResponseDataCodeChecked: true,
          noErrorMessage: true,
          noEncrypt: true,
          autoBaseUrl: false,
          silentOnError: true,
          setDefaultRequestConfig: (config) => {
            config.headers = {}
            return config
          }
        },
        params: {
          timestamp: Date.now()
        }
      })
    })
      .then((response) => {
        const result =
          typeof response.data === 'string'
            ? JSON.parse(decrypt(response.data))
            : response.data

        if (checkHealth && formatMaintainTimeInfo(result).health) {
          return result
        } else {
          return Promise.reject()
        }
      })
      .catch(() => {
        return Promise.reject(new Error('request-error'))
      })

  const queryDefaultMaintain = () =>
    createOssDomainRetry<AxiosResponse<MaintainType>>((ossHost) => {
      return GlobalService.get<unknown, MaintainType>({
        baseURL: ossHost,
        url: '/cocos/maintain-time-default.json',
        customParams: {
          maxRetryCount: 0,
          noResponseDataCodeChecked: true,
          noErrorMessage: true,
          noEncrypt: true,
          autoBaseUrl: false,
          silentOnError: true,
          setDefaultRequestConfig: (config) => {
            config.headers = {}
            return config
          }
        },
        params: {
          timestamp: Date.now()
        }
      })
    }).then((response) => {
      const result =
        typeof response.data === 'string'
          ? JSON.parse(decrypt(response.data))
          : response.data
      if (result) {
        result._isDefault = true
      }
      return result
    })

  try {
    if (onlyDefault) return queryDefaultMaintain()
    if (!tryDefault) return queryMaintain()
    try {
      return await queryMaintain()
    } catch {
      return await queryDefaultMaintain()
    }
  } catch (error) {
    return Promise.reject()
  }
}

export const apiGetIpBindData = (payload: {
  agentName: string
  channelId: number
  currency?: string
}) =>
  GlobalService.post<IpBindInfos>({
    url: '/promote/getIpBindInfo',
    customParams: {
      noEncrypt: true,
      //极速包的时候用app环境进行绑定关系获取
      useAppEnv: !!window.SpeedPackaeInit?.isSpeedPackae
    },
    data: {
      ...payload
    }
  })

/**
 * 获取站点url信息
 */
export const apiGetSiteURLInfos = async () => {
  const response = await createOssDomainRetry<AxiosResponse>((ossHost) => {
    return GlobalService.get<unknown, MaintainType>({
      baseURL: ossHost,
      url: '/cocos/config_data.json',
      customParams: {
        maxRetryCount: 0,
        noResponseDataCodeChecked: true,
        silentOnError: true,
        noErrorMessage: true,
        noEncrypt: true,
        autoBaseUrl: false,
        setDefaultRequestConfig: (config) => {
          config.headers = {}
          return config
        }
      },
      params: {
        timestamp: Date.now()
      }
    })
  })

  const siteURLInfos: SiteURLInfos = JSON.parse(
    GlobalCrypto.decrypt(response.data, 'thanks,pig4cloud', {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
  )

  return siteURLInfos
}

/**
 * 获取站点皮肤资源版本号
 */
export const apiGetSkinAssetsHash = async () => {
  let assetsHash = {}
  const {
    type,
    backgroundColor,
    skinTypeValue,
    holidayThemes = []
  } = useMainStore()?.siteInfos || {}

  const urls = [
    `/siteadmin/skin/lobby_asset/${type}-${backgroundColor}-${skinTypeValue}/assets.hash.json`,
    ...holidayThemes
      // .slice(0, 2)
      .map(
        (it) =>
          `/siteadmin/skin/lobby_asset/festival/${it.themeType}/${type}-${backgroundColor}/assets.hash.json`
      )
  ]
  try {
    await createOssDomainRetry((ossHost) => {
      return Promise.all(
        urls.map(async (url) => {
          try {
            const response = await axios({
              baseURL: ossHost,
              url,
              customParams: {
                maxRetryCount: 0,
                noResponseDataCodeChecked: true,
                noEncrypt: true,
                autoBaseUrl: false
              },
              params: {
                timestamp: Date.now()
              }
            })
            assetsHash = {
              ...assetsHash,
              ...response.data
            }
          } catch (error) {}
        })
      )
    })
  } catch (error) {}

  return {
    data: {
      code: 1,
      data: assetsHash
    }
  }
}

/**
 * 获取俱乐部入口
 */
export const apiGetClubEntryAvailable = (
  config?: Parameters<typeof GlobalService.post>[0]
) =>
  GlobalService.get<ClubEntryAvailable>({
    url: '/club/entry',
    ...config
  }).then((res) => res.data.data)

/**
 * 获取优惠中心-活动任务返水是否显示
 */
export const apiGetEventCategoriesAvailable = () =>
  GlobalService.get<EventCategoriesAvailable>({
    url: '/active/is_show',
    customParams: {
      tryStaticMode: false
    }
  }).then((res) => res.data.data as EventCategoriesAvailable)

/**
 * 检测用户名是否存在
 */
export const apiCheckUsername = (payload: { username: string }) => {
  return GlobalService.post<{
    username: string
    available: boolean
  }>({
    url: '/home/checkusername' as typeof GlobalService.beforeLoginedEncryptFilter[number],
    data: {
      username: payload.username
    }
  })
}

/**
 * 登出
 */
export const apiLogout = (payload: { username: string }) => {
  const { userInfos } = useMainStore()
  return GlobalService.post<{
    username: string
    available: boolean
  }>({
    url: '/home/UserLogout',
    data: {
      useridx: payload.username,
      token: userInfos?.session_key
    }
  })
}
/**
 * 普通账号密码登入
 */
export const apiLogin = (
  payload: NormalLoginFormData,
  headers?: AxiosRequestHeaders
) => {
  return GlobalService.post<UserInfos>({
    url: '/home/login' as typeof GlobalService.beforeLoginedEncryptFilter[number],
    data: {
      ...GlobalManager.User.getIsPwaParams,
      ...GlobalManager.User.getIsP2a,
      ...GlobalManager.User.createLoginBasePayload,
      ...payload
    },
    headers,
    customParams: {
      autoBaseUrl: false
    }
  })
}

export const apiBindPhone = (payload: BindPhoneReq) => {
  return GlobalService.post<UserInfos>({
    url: '/user/phone',
    data: {
      ...payload
    }
  })
}

/**
 * 防刷风控-验证手机
 */
export const apiRiskVerifyPhone = (payload: RiskSmsVerifyReq) => {
  return GlobalService.post<UserInfos>({
    url: '/user/risk/verifyPhone',
    data: {
      ...payload
    }
  })
}
/**
 * 防刷风控-绑定手机
 */
export const apiRiskBindPhone = (payload: RiskSmsVerifyReq) => {
  return GlobalService.post<UserInfos>({
    url: '/user/risk/bindPhone',
    data: {
      ...payload
    }
  })
}
/**
 * 查询用户电话
 */
export const apiGetUserInfo = (payload: { username: string }) => {
  return GlobalService.post<{ phone: string }>({
    url: '/user/captcha/getUserInfo',
    data: {
      ...payload
    }
  })
}

/**
 * 短信登录
 */
export const apiSMSLogin = (
  payload: SMSLoginReq,
  headers?: AxiosRequestHeaders
) => {
  return GlobalService.post<UserInfos>({
    url: '/api/protected/user/smslogin' as typeof GlobalService.beforeLoginedEncryptFilter[number],
    data: {
      ...GlobalManager.User.getIsPwaParams,
      ...GlobalManager.User.getIsP2a,
      ...GlobalManager.User.createLoginBasePayload,
      ...payload
    },
    headers,
    customParams: {
      autoBaseUrl: false
    }
  })
}

/**
 * 三方登录(可能直接登录，或者引导注册)
 */
export const apiThirdWayLogin = (
  payload: ThirdWayLoginReq,
  headers?: AxiosRequestHeaders
) => {
  return GlobalService.post<UserInfos | ThirdWayLoginNewAccountRes>({
    url: '/home/thirdpartylogin',
    headers,
    data: {
      ...GlobalManager.User.getIsPwaParams,
      ...GlobalManager.User.getIsP2a,
      ...GlobalManager.User.createLoginBasePayload,
      ...payload
    }
  })
}

/**
 * 三方登录为引导注册模式时调用的接口（注册并自动登录）
 */
export const apiThirdWayRegister = (
  payload: ThirdWayLoginReq,
  headers?: AxiosRequestHeaders
) => {
  return GlobalService.post<UserInfos>({
    url: '/home/thirdpartyregister',
    headers,
    data: {
      ...GlobalManager.User.getIsP2a,
      ...GlobalManager.User.createLoginBasePayload,
      ...payload
    }
  })
}

/**
 * 快速登录
 */
export const apiFastLogin = (
  payload: { userkey: string },
  silentOnError = false
) => {
  const { systemInfos } = useMainStore()
  return GlobalService.post<UserInfos>({
    url: '/home/fastlogin',
    data: {
      ...GlobalManager.User.createLoginBasePayload,
      token: systemInfos?.token,
      userkey: payload.userkey
    },
    customParams: {
      silentOnError,
      autoBaseUrl: false
    }
  })
}

/**
 * 试玩账号切换币种
 */
export const apiGuestChangeCurrency = (
  currency = '',
  silentOnError = false
) => {
  const { userInfos, systemInfos } = useMainStore()
  return GlobalService.post<UserInfos & { login_token: string }>({
    url: '/home/updateGuestCurrency' as typeof GlobalService.beforeLoginedEncryptFilter[number],
    customParams: {
      silentOnError
    },
    data: {
      userIdx: userInfos?.username,
      currency: currency,
      token: systemInfos?.token
    }
  })
}

/**
 *
 */
export const apiGetLinkSetting = () => {
  return GlobalService.post<{ linkType: LinkType }>({
    url: '/promote/linksetting'
  })
}

/**
 * 更新货币信息
 */
export const apiUpdatePrize = () => {
  return GlobalService.post<{
    game_gold: number
    lastgameinfo: SelfOperatedGameUrl
  }>({
    url: '/home/prize'
  })
}

/**
 * 普通注册前验证参数
 */
export const apiRreRegisterVerify = (
  payload: NormalRegisterRequest,
  headers?: AxiosRequestHeaders
) => {
  const { systemInfos } = useMainStore()
  return GlobalService.post<{ username: string; password: string }>({
    url: '/home/verify/register' as typeof GlobalService.beforeLoginedEncryptFilter[number],
    customParams: {
      autoBaseUrl: false
    },
    headers,
    data: {
      ...GlobalManager.User.createLoginBasePayload,
      token: systemInfos?.token,
      ...payload
    }
  })
}
/**
 * 普通注册
 */
export const apiRegister = (
  payload: NormalRegisterRequest,
  headers?: AxiosRequestHeaders
) => {
  const { systemInfos } = useMainStore()
  return GlobalService.post<{ userkey: string }>({
    url: '/home/register' as typeof GlobalService.beforeLoginedEncryptFilter[number],
    customParams: {
      autoBaseUrl: false
    },
    headers,
    data: {
      ...GlobalManager.User.getIsP2a,
      ...GlobalManager.User.createLoginBasePayload,
      token: systemInfos?.token,
      ...payload
    }
  })
}

/**
 * 三方验证(登陆前)
 * 极验服务端二次验证
 */
export const apiLoginBeforeGeetestValidate = (payload: GeetestValidate) => {
  return GlobalService.post<string>({
    url: '/geetest/validate/v4',
    data: payload
  })
}

/**
 * 三方验证（登陆后）
 * 极验服务端二次验证
 */
export const apiLoginAfterGeetestValidate = (payload: GeetestValidate) => {
  return GlobalService.post<string>({
    url: '/user/geetest/validate/v4',
    data: payload
  })
}

export const apiGetHomeCaptcha = () => {
  return GlobalService.post<string>({
    url: '/home/captcha',
    customParams: {
      noEncrypt: true
    }
  })
}

export const apiGetSmsCode = (payload: SmsCodeCreateReq) => {
  return GlobalService.post<{
    msg: string
    isExist: boolean
    quirkyVerify?: boolean
  }>({
    url: '/shortmsg/create',
    data: payload
  })
}

export const apiGetSmsCodeAfterLogined = (payload: SmsCodeCreateReq) => {
  return GlobalService.post<{
    msg: string
  }>({
    url: '/user/security/createsms',
    data: payload
  })
}

export const apiVerifyPhone = (payload: UserSmsCodeValidateReq) => {
  return GlobalService.post<{
    msg: string
  }>({
    url: '/user/risk/verifyPhone',
    data: payload
  })
}

export const apiValidSmsCode = (payload: SmsCodeValidateReq) => {
  return GlobalService.post<{
    msg: string
  }>({
    url: '/shortmsg/validate',
    data: payload
  })
}

export const apiGetNotLoginAccountVerifyList = (
  payload: AccountVerifyListNotLoginReq,
  config?: Parameters<typeof GlobalService.post>[0]
) => {
  return GlobalService.post<AccountVerifyListRes>(
    merge(
      {
        url: '/user/notlogin/getAccountVerifyList',
        data: payload
      },
      config
    )
  )
}
/**
 * 获取问题列表根據帳號
 */
export const apiGetQuestion = () => {
  return GlobalService.post<{ question: Array<QuestionType> }>({
    url: '/user/getquestion'
  })
}
/**
 * 获取问题列表，全网通用，不区分账号
 */
export const apiGetQuestionWithNotLogin = () => {
  return GlobalService.post<{ question: Array<QuestionType> }>({
    url: '/user/notlogin/getquestion'
  })
}
/**
 * 未登录时找回密码验证密保问题或者提现密码
 */
export const apiVerifyQuestionAndWithdrawPwdWithNotLogin = (
  payload: VerifyQuestionAndWithdrawPwdWithNotLoginReq
) => {
  return GlobalService.post({
    url: '/user/notlogin/verificquestionandwithdrawpasswd',
    data: payload
  })
}
/**
 * 需要强制修改密码
 */
export const apiForceChangePwd = (payload: ForceChangePwdReq) => {
  return GlobalService.post<{ userKey: string }>({
    url: '/user/forcechangepassword',
    data: payload
  })
}

/**
 * 未登录时重置密码的验证
 */
export const apiResetpasswdNotLogin = (payload: ResetPasswdNotLoginReq) => {
  return GlobalService.post({
    url: '/user/notlogin/resetpasswd',
    data: payload
  })
}

/**
 * 未登录时验证邮箱输入的是否正确
 */
export const apiVerifyEmailNotLogin = (
  payload: VerifyInputEmailNotLoginReq,
  customParams?: AxiosRequestConfig['customParams']
) => {
  return GlobalService.post({
    url: '/user/notlogin/verifyEmail',
    data: payload,
    customParams
  })
}

/**
 * 未登录时验证手机号输入的是否正确
 */
export const apiVerifyPhoneNumberNotLogin = (
  payload: VerifyPhoneInputReq,
  customParams?: AxiosRequestConfig['customParams']
) => {
  return GlobalService.post({
    url: '/user/sms/verifyphone',
    data: payload,
    customParams
  })
}

/** 登录后行为验证手机号输入的是否正确
 */
export const apiVerifyPhoneNumberCaptcha = (
  payload: VerifyPhoneInputReq,
  config?: Pick<AxiosRequestConfig, 'customParams' | 'timeout'>
) => {
  return GlobalService.post({
    url: '/user/captcha/verifyPhone',
    data: payload,
    ...config
  })
}

/**
 * 登陆后发邮箱验证码（登录后）
 */
export const apiGetEmailCodeAfterLogin = (payload: EmailCodeCreateReq) => {
  return GlobalService.post({
    url: '/user/security/createmail',
    data: payload
  })
}

/**
 * 邮箱发送验证码(未登入)
 */
export const apiGetEmailCodeBeforceLogin = (payload: EmailCodeCreateReq) => {
  return GlobalService.post<{ isExist: boolean; quirkyVerify?: boolean }>({
    url: '/user/email/createmail',
    data: payload
  })
}

/**
 * 登录后验证邮箱输入的是否正确
 */
export const apiVerifyEmailAfterLogin = (
  payload: { email: string },
  customParams?: AxiosRequestConfig['customParams']
) => {
  return GlobalService.post({
    url: '/user/security/verifyEmail',
    data: payload,
    customParams
  })
}
/**
 * 登录后验证手机号输入的是否正确
 */
export const apiVerifyPhoneNumberAfterLogin = (
  payload: { phone: string },
  config?: Pick<AxiosRequestConfig, 'customParams' | 'timeout'>
) => {
  return GlobalService.post({
    url: '/user/verifyphone',
    data: payload,
    ...config
  })
}

/**
 * 获取推广赚钱广告语/自定代理mode
 */
export const apiGetPromoteAdvertise = () => {
  return GlobalService.get<AgentModeDataResponse>({
    url: '/promote/config/agentMode',
    customParams: {
      cache: true,
      tryStaticMode: true,
      staticKeyOmit: ['platformType', 'currency'],
      keyOmit: true
    }
  }).then((res) => res.data.data || {})
}

/**
 * 获取左上角品牌配置信息
 */
export const apiGetBrandLogoInfos = () => {
  return GlobalService.get<BrandLogoInfosRes>({
    url: '/api/lobby/brandLogo/getBrandLogoUse',
    customParams: {
      noEncrypt: true,
      cache: true
    }
  })
}

/**
 * 注册时的用户协议UI
 */
export const apiGetUserAgreementInfos = () => {
  return GlobalService.get<UserAgreementRes>({
    url: '/api/lobby/userAgreement/index/getInfo',
    customParams: {
      tryStaticMode: true,
      cache: true,
      staticKeyOmit: ['platformType', 'currency'],
      noEncrypt: true
    }
  })
}

/**
 * 获取页脚配置信息
 */
export const apiGetFooterInfos = () => {
  return GlobalService.get<FooterInfoRes>({
    url: '/api/lobby/footerConfig/getInfo',
    customParams: {
      tryStaticMode: true,
      cache: true,
      staticKeyOmit: ['currency', 'platformType']
    }
  }).then((res) => res.data.data || {})
}

/**
 * 获取公告弹框信息
 */
export const apiGetFrameBoxInfos = () => {
  return GlobalService.post<Array<FrameBoxInfos>>({
    url: '/message/frameBox/index'
  })
}

/**
 * 获取消息弹框信息
 */
export const apiGetMessageFrame = () => {
  return GlobalService.get<FrameSet>({
    url: '/message/frame',
    customParams: {
      tryStaticMode: true,
      cache: true
    }
  }).then((res) => {
    const { data } = res.data
    if (data) {
      const { notice = [], announcement = [], frame = [] } = data

      const updateForceType = (items: FrameBoxInfos[], type: ForceType) => {
        items.forEach((item) => {
          item.forceType = type
        })
      }

      updateForceType(notice, ForceType.NOTICE)
      updateForceType(announcement, ForceType.ANNOUNCEMENT)
      updateForceType(frame, ForceType.PUBLICIZE)

      return [...notice, ...announcement, ...frame]
    }
    return []
  })
}

/**
 * 获取App下载配置信息
 */
export const getDownloadUrl = () => {
  return GlobalService.get<DownloadUrlInfos>({
    url: '/download/getDownloadUrl',
    customParams: {
      silentOnError: true
    }
  })
}
/**
 * 获取App下载配置信息 （静态化）
 */
export const getListDownloadTemplate = () => {
  return GlobalService.get<DownloadUrlInfos>({
    url: '/download/template/listDownloadTemplate',
    customParams: {
      noEncrypt: true,
      tryStaticMode: true,
      staticKeyOmit: ['currency', 'platformType']
    }
  })
}

/**
 * 统计渠道包的下载量
 */
export const apiReportDownloadUrlClick = (
  payload: ReportDownloadUrlClickPayload
) => {
  return GlobalService.post({
    url: '/promote/binding/reportclick',
    data: payload,
    customParams: {
      noEncrypt: true
    }
  })
}

/**
 * 获取登录前的优惠绑定信息
 */
export const apiGetActiveRewardBindBeforeLogin = () => {
  return GlobalService.get<RewardBindDataBefore>({
    url: '/activetask/newcomer_benefit_reward',
    customParams: {
      staticKeyOmit: ['currency', 'language', 'platformType'],
      keyOmit: true,
      appendDefault: true,
      tryStaticMode: true,
      cache: true
    }
  }).then((res) => res?.data?.data)
}
/**
 * 获取登录后的优惠绑定信息
 */
export const apiGetActiveRewardBind = () => {
  return GlobalService.post<Array<RewardBindData>>({
    url: '/activetask/newcomer_benefit_pop'
  })
}

/**
 * 获取区域限制配置信息
 */
export const getAccessRestrictedInfo = () => {
  return GlobalService.get<AccessRestrictedInfo>({
    url: '/api/lobby/h5/config/getAccessRestrictedInfo',
    customParams: {
      tryStaticMode: true,
      cache: true,
      staticKeyOmit: ['platformType', 'currency'],
      noEncrypt: true
    }
  })
}

/**
 * 获取所有币种列表
 */
export const apiGetAllCurrency = () => {
  return GlobalService.get<Array<CurrencyInfos>>({
    url: '/api/lobby/currencyInfo/getAllCurrency',
    customParams: {
      noEncrypt: true,
      cache: true,
      tryStaticMode: true,
      staticKeyOmit: ['currency', 'platformType', 'language'],
      cacheDep: (id) => id,
      useCacheInError(_, errorType) {
        return !!errorType
      },
      cacheExpiredOnReload: false
    }
  })
}

/**
 * 首页版式配置获取
 * @deprecated
 */
export const apiGetHomeLayout = () => {
  return GlobalService.get<HomeLayoutInfos>({
    url: '/api/lobby/layoutDesign/getLayout',
    customParams: {
      noEncrypt: true
    }
  })
}

/**
 * web push海外极光配置接口
 * @deprecated
 * 从 /api/lobby/optimization/site/config 中取
 */
export const apiGetWebPush = () => {
  return GlobalService.get<WebPushInfos>({
    url: '/api/lobby/webPush/getInfo'
  })
}

/**
 * 获取极验验证方式
 */
export const apiGetGeetestRisk = (payload: { geeToken: string }) => {
  return GlobalService.post<{ riskType: string }>({
    url: '/risk/geetest/tokenQuery',
    data: payload,
    customParams: {
      noEncrypt: true
    }
  })
}

/*
 * 日志上报
 */
export const apiTraceReport = (data: Record<string, unknown>) => {
  return GlobalService.post({
    url: '/v1/netstat/player/logger',
    data,
    customParams: {
      noErrorMessage: true,
      silentOnError: true,
      noResponseDataCodeChecked: true,
      noEncrypt: true
    }
  })
}

/**
 * 获取后台语言配置
 * @param lan
 * @returns
 */
export const apiGetI18nMessage = async (lang?: string) => {
  const response = await GlobalService.get<LangItem[]>({
    url: '/customer/getWebTrans',
    params: {
      language: lang
    },
    customParams: {
      noEncrypt: true,
      tryStaticMode: true,
      silentOnError: true,
      cache: true,
      staticKeyOmit: ['currency', 'platformType'],
      keyOmit: true,
      /**
       * 默认的缓存依赖用的是当前的语言，不是params上的语言
       * 故传cacheDep方法重写成net中id算法
       */
      cacheDep: (id) => id,
      useCacheInError(_, errorType) {
        return !!errorType
      },
      cacheExpiredOnReload: false
    }
  }).catch((err) => {
    console.error(err)
  })
  //response已存入 apiGetI18nMessage-mock ,到时候改成get时,可参考数据结构
  return response
}

/**
 * 获取埋点配置
 */
export const apiGetBuriedConfig = (data: BuriedConfigRequest) =>
  GlobalService.post<Array<BuriedConfigResponse>>({
    url: '/promote/point/get',
    customParams: {
      cache: true
    },
    data
  }).then((res) => res.data.data as Array<BuriedConfigResponse>)

/**
 * 域名埋点
 */
export const apiPointerDomain = (data: AgentConfigRequest) =>
  GlobalService.post({
    url: '/promote/pointer/domain',
    data,
    customParams: {
      silentOnError: true,
      noEncrypt: true
    }
  })

/*
 * 心跳接口
 */
export const apiGetHeartBeat = () => {
  GlobalService.post({
    url: '/home/heartbeat',
    customParams: {
      noErrorMessage: true
    }
  })
}

/**
 * 获取大厅余额
 */
export const apiGetHomeGold = () =>
  GlobalService.post<{ game_gold: number }>({
    url: '/home/gold'
  })

/**
 * 判断极速包是否需要强更
 */
export const apiCheckForceUpdate = () =>
  GlobalService.get<{
    iosWebSpeedPkgForceUpdate: boolean
    androidWebSpeedPkgForceUpdate: boolean
    iosWebSpeedPkgVersion: string
    androidWebSpeedPkgVersion: string
  }>({
    url: '/api/lobby/webapi/forceUpdate/getForceUpdate',
    customParams: {
      tryStaticMode: true,
      staticKeyOmit: ['currency', 'platformType', 'language']
    }
  })
