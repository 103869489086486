import { ThemeComponentType } from './type'

export default {
  [ThemeComponentType.HomePage]: {
    com: () =>
      import(
        /* webpackChunkName: "home@theme=18" */ '@/views/game/themes/theme-18/home'
      )
  },
  [ThemeComponentType.SearchPage]: {
    com: () =>
      import(
        /* webpackChunkName: "search@theme=18" */ '@/views/game/themes/theme-18/search'
      )
  },
  [ThemeComponentType.SubGamePage]: {
    com: () =>
      import(
        /* webpackChunkName: "subGame@theme=18" */ '@/views/game/themes/theme-18/subGame'
      )
  },
  [ThemeComponentType.EmbeddedPage]: {
    com: () =>
      import(
        /* webpackChunkName: "embedded@theme=18" */ '@/views/game/themes/theme-18/embedded'
      )
  },
  [ThemeComponentType.Footer]: {
    com: () =>
      import(
        /* webpackChunkName: "footer@theme=18" */ '@/layouts/components/footer/themes/theme-18'
      )
  },
  [ThemeComponentType.FloatBar]: {
    com: () =>
      import(
        /* webpackChunkName: "floatBar@theme=18" */ '@/layouts/components/floatBar/themes/theme-18'
      )
  },
  [ThemeComponentType.Styles]: {
    com: () =>
      import(
        /* webpackChunkName: "style@theme=18" */ '@/views/global-styles/themes/theme-18'
      )
  },
  [ThemeComponentType.Layouts]: {
    com: () =>
      import(
        /* webpackChunkName: "layout@theme=18" */ '@/layouts/themes/theme-18'
      )
  }
}
