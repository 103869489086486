import { AxiosError } from 'axios'
import { Base, Component, Prop } from '@/vue-property-decorator'
import { GlobalConst, GlobalService } from '@/context'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { useI18n } from '@/i18n'
// const comm_icon_copy = createSvgSprite('comm_icon_copy')
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import style from './style.module.scss'

export enum DisplayType {
  /** 单个刷新图标样式 */
  InsufficientSpace,
  /** 文字+刷新图标样式 */
  SufficientSpace,
  /** 带兜底图刷新样式 */
  ListData
}

type Props = {
  displayType?: DisplayType
  height?: string
  loading?: boolean
  axiosError?: AxiosError | Error | null
  /** 需要展示刷新重试的错误类型，不传时所有错误类型都展示，多种错误类型可逗号分隔 */
  errorType?: 'netError' | 'dataError' | 'timeoutError'
  /** 直接显示超时错误，不依赖axiosError */
  isTimeoutError?: boolean
  retryClassName?: CSSClassValue
}

/**
 * 超时刷新组件
 * @Date 2024.5.7
 */
@Component<NetRetry>({ name: 'NetRetry' })
export default class NetRetry extends Base<unknown, Props> {
  /**
   * 类型
   */
  @Prop({ default: DisplayType.InsufficientSpace })
  displayType?: Props['displayType']

  /**
   * 是否超时重试
   */
  @Prop({ default: false })
  isTimeoutError?: Props['isTimeoutError']

  /**
   * 动画
   */
  @Prop({ default: false })
  loading?: Props['loading']

  /**
   * 占位高度
   */
  @Prop({ default: BusinessUtils.px2rem('500px') })
  height?: Props['height']

  /**
   * 错误json
   */
  @Prop()
  axiosError?: Props['axiosError']

  /**
   * 错误类型
   */
  @Prop()
  errorType?: Props['errorType']
  /**
   * class
   */
  @Prop()
  retryClassName?: Props['retryClassName']

  // 是否展示错误重试
  private get isShowErrorRetry() {
    const axiosError = this.axiosError as AxiosError
    const getErrorType = GlobalService.getErrorType(axiosError)

    if (
      this.isTimeoutError ||
      (axiosError && getErrorType && !this.errorType)
    ) {
      return true
    }

    return (
      !!this.axiosError &&
      !!this.errorType &&
      this.errorType.includes(getErrorType)
    )
  }

  specialSkin = ['1-1-5', '5-0-1', '6-0-1']

  get imgSrc() {
    const { skinId } = useMainStore()
    if (this.specialSkin.includes(skinId)) {
      return '/lobby_asset/{layout}-{bg}-{skin}/common/common/img_none_wlcs.png'
    }

    return '/lobby_asset/1-0-common/common/common/img_none_wlcs.png'
  }

  renderIconElement() {
    const { t } = useI18n()
    const animatedClasses = [
      GlobalConst.CssClassName.Animate.Spin,
      GlobalConst.CssClassName.Animate.Repeat.infinite
    ]

    switch (this.displayType) {
      case DisplayType.InsufficientSpace:
        return (
          <i
            class={[style.iconBox, 'retryIcon']}
            onClick={() => this.$emit('retry')}
          >
            <icon-sprite
              class={[
                style.icon,
                {
                  [GlobalConst.CssClassName.Animate.Animated]: !!this.loading
                },
                ...animatedClasses
              ]}
              sprite={createSvgSprite('comm_icon_retry')}
            />
          </i>
        )
      case DisplayType.SufficientSpace:
        return (
          <span
            class={{
              [style.sufficientSpace]: true,
              timeoutError: true,
              [this.retryClassName as string]: true
            }}
            onClick={() => this.$emit('retry')}
          >
            <span>{t('lobby.error.networkTimeout3')}</span>
            <icon-sprite
              class={[
                style.icon,
                {
                  [GlobalConst.CssClassName.Animate.Animated]: !!this.loading
                },
                ...animatedClasses
              ]}
              sprite={createSvgSprite('comm_icon_retry')}
            />
          </span>
        )
      case DisplayType.ListData:
        return (
          <div
            class={{
              [style.timeoutError]: true,
              [this.retryClassName as string]: true
            }}
            style={{
              height: this.height
            }}
          >
            <my-img src={this.imgSrc} width={210} height={210}></my-img>
            <p>
              {t('lobby.error.networkTimeout2')}
              <span class={style.btnRetry} onClick={() => this.$emit('retry')}>
                {t('lobby.modal.pay.retry')}
                <icon-sprite
                  class={[
                    style.icon,
                    {
                      [GlobalConst.CssClassName.Animate.Animated]:
                        !!this.loading
                    },
                    ...animatedClasses
                  ]}
                  sprite={createSvgSprite('comm_icon_retry')}
                />
              </span>
            </p>
          </div>
        )
      default:
        return (
          <i onClick={() => this.$emit('retry')}>
            <icon-sprite
              class={[
                style.icon,
                {
                  [GlobalConst.CssClassName.Animate.Animated]: !!this.loading,
                  ...animatedClasses
                }
              ]}
              sprite={createSvgSprite('comm_icon_retry')}
            />
          </i>
        )
    }
  }

  render() {
    const iconElement = this.renderIconElement()
    return this.isShowErrorRetry ? (
      iconElement
    ) : (
      <fragment>{this.$slots?.default}</fragment>
    )
  }
}
