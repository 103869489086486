export interface RechargeItem {
  id: number // 充值明细ID
  useridx: number // 用户useridx
  currency: string // 币种
  fund_detail_id: number // 关联一条dh_active_fund_detail
  recharge_amount: number // 充值金额
  recharge_amount2: string // 充值金额
  recharge_time: number // 充值时间（时间戳）
  recharge_time2: string // 充值时间
  add_fund_rate: number // 赠送比例
  add_fund_rate2: string // 赠送比例
  add_fund_amount: number // 赠送公积金
  add_fund_amount2: string // 赠送公积金
  old_fund_amount: number // 充值前公积金
  fund_amount: number // 最新公积金
  add_times: number // 赠送次数
  max_limit_add_times: number // 最大赠送次数限制（-1表示不限制）
  max_limit_fund_amount: number // 最大公积金限制（-1表示不限制）
  add_bet_code_rate: number // 投注倍数比例
  add_bet_code_amount: number // 新增投注量
  add_bet_code_amount2: string // 新增投注量
  receive_bet_code: number // 领取公积金需要的打码量
  created_time: number // 创建时间（时间戳）
  updated_time: number // 更新时间（时间戳）
}

export interface RechargeListPayload {
  time_day: number //0今天 1昨天 30：30天
}
export interface RechargeListResponse {
  list: RechargeItem[]
  current_query_total_recharge: number
}

export interface RechargeFundSettingResponse {
  id: number // 活动ID
  name: string // 活动名称
  currency: string // 货币
  member_levels: string // 会员层级ID，逗号分隔，全部=-1
  add_fund_rate: number // 赠送比例
  max_limit_fund_amount: number // 最大公积金限制 -1不限制
  max_limit_add_times: number // 最大赠送次数限制 -1不限制
  add_bet_code_rate_nofund: number // 无赠送公积金的充值要求投注倍数
  add_bet_code_rate_hasfund: number // 有赠送公积金的充值要求投注倍数
  fund_period: string // 公积金周期 month,3Month,6Month,year,noLimit
  bet_code_limit_platform: string // 打码限制平台
  distribute_type: string // 派发方式 userReceive-invalidIfExpired, userReceive-sendIfExpired
  task_condition: string // 领取限制，分割
  audit_platform_category_game: string // 稽核设置
  audit_times: number // 稽核倍数
  translate_rule_text: string // 活动规则翻译配置
  rule_text: string // 规则说明
  updated_time: string // 更新时间
  created_time: string // 创建时间
  translate_info: {
    rule_text: string
    is_system_translate: boolean
  }
  receive_device_type: string // 可领取设备设备类型
}

export enum CPFStatus {
  Init, //默认不能领取
  CanReceive, //可领取
  Received, //已领取
  Expired, //过期
  Send, //派发
  Cancel, //已取消
  NeedApply, //待审核
  NeedRequest, //待申请
  Reject, //申请拒绝
  Receipting //到帐中
}
export interface CurrentPeriodRewardResponse {
  id: number // ID
  useridx: number // 用户useridx
  currency: string // 币种
  receive_log_id: number // 待领取记录关联
  fund_period: string // 周期类型 month,3Month,6Month,year,noLimit
  fund_period_start_time: number // 周期开始时间（时间戳）
  fund_period_end_time: number // 周期结束时间（时间戳）
  fund_expire_time: number // 奖励过期时间（时间戳）
  fund_amount: number // 最新公积金
  total_recharge_amount: number // 总的充值金额
  add_fund_times: string // 增加充值公积金次数
  receive_bet_code: number // 领取公积金需要的打码量
  bet_code: number // 当前周期开始到现在用户打总码量
  receive_need_bet_code: number //用户所需总打码量
  bet_code_update_time: number // 更新用户打码时间（时间戳）
  remaining_bet_code: number // 剩余打码量
  status: CPFStatus // 状态 1. ing 2. canReceive 3 hasReceived 4 closed
  force_pass: number // 0. 没解除 1.强制解除，可以直接领取
  back_remark: string // 后台备注
  front_remark: string // 前台备注
  bet_detail: string // 临时字段
  oparator: string
  audit_times: number //稽核倍数,
  receive_time: number //领取时间
  updated_time: number // 更新时间（时间戳）
  created_time: number // 创建时间（时间戳）
  receive_log_client_status: number // 公积金取出状态
}
export interface rechargeFundReceivePayload {
  receive_log_id: number
}
