import { Base, Component, Mixins, Prop } from '@/vue-property-decorator'
import { VNode } from 'vue/types/umd'
import UnitMixins, { Props as UnitMixinsProps } from '@/mixins/unit'
import observerInstance from '@/context/observerInstance'
import style from './style.module.scss'
export interface Props extends UnitMixinsProps {
  tag?: string
  width?: number
  height?: number
  disabled?: boolean
  blurAnimation?: boolean
  keepObserver?: boolean
  rootMarginFun?: () => string
}
export interface Slots {
  default: {
    renderChild: boolean
  }
}

export interface Events {
  onClick: Event
}

@Component<CheckView>({
  name: 'CheckView'
})
export default class CheckView extends Mixins<
  Base<unknown, Props, Events, Slots>,
  UnitMixins
>(Base, UnitMixins) {
  private renderChild = false
  @Prop({
    default: 'div'
  })
  private readonly tag!: string

  @Prop()
  private readonly width!: Props['width']

  @Prop()
  private readonly height!: Props['height']

  @Prop({ default: false })
  private readonly disabled!: Props['disabled']

  @Prop({ default: false })
  private readonly blurAnimation!: Props['blurAnimation']

  private lazyRender(callback: () => void) {
    if (!window.IntersectionObserver) {
      callback()
      return
    }
    observerInstance.addLazyBox(this, {
      callback
    })
  }

  beforeDestroy() {
    this.doDestroy()
  }

  private doDestroy() {
    observerInstance.removeComponent(this)
  }

  private doRender() {
    this.renderChild = true
    this.$emit('show', this)
  }

  created(): void {
    if (this.disabled || window.wgMock.noLazy) {
      this.doRender()
    }
  }

  mounted(): void {
    if (!this.disabled) {
      if (window.wgMock.noLazy) return
      this.lazyRender(() => {
        this.doRender()
      })
    }
  }

  render(): VNode {
    const width = this.width
      ? this.width / this.baseUnit + this.unit
      : this.width

    const height = this.height
      ? this.height / this.baseUnit + this.unit
      : this.height

    return (
      <this.tag
        class={[style.main]}
        {...{
          attrs: {
            ...this.$attrs,
            'data-blur': this.blurAnimation ? 1 : 0,
            'data-show': this.renderChild ? 1 : 0
          },
          props: {
            ...this.$attrs,
            ...this.$props
          },
          style: {
            width,
            height
          },
          on: {
            ...this.$listeners,
            click: (e: Event) => {
              this.$emit('click', e)
            }
          }
        }}
      >
        {this.renderChild &&
          (this.$slots?.default ||
            this.$scopedSlots.default?.({
              renderChild: this.renderChild
            }))}
      </this.tag>
    )
  }
}
